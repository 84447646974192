import api from "api";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import { useContext, useEffect, useMemo, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Filter from "../components/Filter";
import { AppContext } from "../context/app-context";

import NewClaimSubmissionModal from "components/Claim/Modal/NewClaimSubmissionModal";
import EmployeeModal from "components/Employee/EmployeeModal";
import ExportToExcel from "components/ExportToExcel";
import Icon from "components/Icon";
import Loader from "components/Loader/Loader";
import ClearClaimModal from "components/Modal/ClearClaimModal";
import ManualOrderStatusModal from "components/Modal/ManualOrderStatusModal";
import ManualUpdateOrderQuantity from "components/Modal/ManualUpdateOrderQuantity";
import NotesModal from "components/Modal/NotesModal";
import ChangeOrderPayer from "components/Modal/Order/ChangeOrderPayer";
import OrderImportModal from "components/Modal/Order/OrderImportModal";
import OrderLogsModal from "components/Modal/Order/OrderLogsModal";
import OrderModal from "components/Modal/Order/OrderModal";
import PersonalizationModal from "components/Modal/PersonalizationModal";
import MFPagination from "components/Pagination/MFPagination";
import Stats from "components/Stats";
import Status from "components/Status";
import MainTable from "components/Table/MainTable";
import UserPasswordModal from "components/Users/UserPasswordModal";
import { MEMBER_ORDER_PERSONALIZE, ORDER_PERSONALIZE } from "constants/personalization";
import FileSaver from "file-saver";
import moment from "moment";
import { jsonToCSV } from "react-papaparse";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setMessage } from "store/features/general/generalAction";
import { personalizationLocalStorage, tdCheckBox } from "utils";
import "../../src/tableStyling.css";
import {
  ADMIN_ORDERS_DROPDOWN_OPTIONS,
  CONFIG,
  INSURANCE_PROVIDER,
  MESSAGE_MODES,
  PAGE_LIMIT,
  TABLE_QUICK_TOOLS,
  USER_TYPE_ADMIN,
  USER_TYPE_CLIENT,
  USER_TYPE_SUBAGENT,
  USER_TYPE_USER,
} from "../constant";
import { t } from "../stringConstants";
import {
  currentDate,
  downloadDataAsCSV,
  formatDateMDY,
  formatDateOfBirthDOB,
  formatEmployeesData,
  formatNumber,
  formatOrder,
  formatOrderedDateAws,
  formatPhoneNumber,
  getPhoneNumber,
  getValidDep,
  getValidGender,
  isValidDobField,
  isValidIDNumber,
  isValidName,
  sortingFilterInLC,
  tdEmail,
  tdOrderDate,
  tdPhone,
  tdTrackingID,
  userCompanyID,
} from "../utils";

const AdminOrders = (props) => {
  const [personalize, setPersonalize] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [allSelected, setAllSelected] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [openPersonalizationModal, setOpenPersonalizationModal] = useState(false);
  const [nextPageToken, setNextPageToken] = useState(null);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [openClearClaimModal, setOpenClearClaimModal] = useState("");
  const [openManualOrderQtyModal, setOpenManualOrderQtyModal] = useState(false);
  const [noteOrder, setNoteOrder] = useState(false);
  const [showPDFModal, setShowPDFModal] = useState(false);
  const [claimSubmissionModal, setClaimSubmissionModal] = useState(false);
  const [trackingIdImport, setTrackingIDImport] = useState(false);
  const [showChangePayerModal, setShowChangePayerModal] = useState(false);
  // const [claimReSubmissionModal, setClaimReSubmissionModal] = useState(false);
  const appContext = useContext(AppContext);
  const location = useLocation();

  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [openCreator, setOpenCreator] = useState(false);
  const [newItem, setNewItem] = useState({ scheduleName: "" });
  const [loading, setLoading] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [itemToDelete, setItemToDelete] = useState("");
  const [timeFilter, setTimeFilter] = useState("All Users");
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const [totalOrders, setTotalOrders] = useState(0);

  const [fullscreen, setFullscreen] = useState(true);
  const [orderModal, setOrderModal] = useState(false);
  const [openOrderDetail, setOpenOrderDetail] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState("");
  const [openImportModal, setOpenImportModal] = useState(false);
  const [employee, setEmployee] = useState({});
  const [updateOrderStatus, setUpdateOrderStatus] = useState(false);
  const dispatch = useDispatch();
  const quickTools = [TABLE_QUICK_TOOLS.checkbox, !appContext.user?.isUser() && TABLE_QUICK_TOOLS.notes];

  const searchTerms = [
    "Order Date",
    "First Name",
    "Last Name",
    "Email",
    "Phone",
    "Client",
    "Facility",
    "Sub Agent",
    "LTCS ID",
    "Order ID",
    "Insurance Provider",
    "Claim Status",
    "Insurance Status",
    "ID Number",
    "Order Qty",
    "Order Note",
    "Order Status",
    "Claim Submit",
    "Employee Status",
    "Ordered By",
    "Tracking Number",
  ];
  const [triggerFilter, setTriggerFilter] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    if (location.state) {
      setShowFilter(true);
      const filterTerm = location.state.term;
      const filterTermName = location.state.name;
      const nextTerm = location.state.filterTerm;
      const nextTermName = location.state.filterName;
      if (filterTerm && filterTermName) {
        setFilter({ ...filter, [filterTerm]: filterTermName });
        setTimeout(() => setShowFilter(false), 1);
      }
      if (nextTerm && nextTermName) {
        setFilter({
          ...filter,
          [nextTerm]: nextTermName,
          [filterTerm]: filterTermName,
        });
        setTimeout(() => setShowFilter(false), 1);
      }
    }
  }, [location.state]);

  const sortData = (schs, sortParam) => {
    let arr = [];

    if (!sortParam) {
      return [...schs].sort((a, b) =>
        `${b["firstName"]}${b["lastName"]}` < `${a["firstName"]}${a["lastName"]}`
          ? 1
          : `${a["firstName"]}${a["lastName"]}` < `${b["firstName"]}${b["lastName"]}`
          ? -1
          : 0
      );
    }

    if (sortDescending) {
      arr = [...schs].sort((a, b) => {
        let fieldA = a[sortParam] || "";
        let fieldB = b[sortParam] || "";
        let nameA = `${a["firstName"]}${a["lastName"]}`;
        let nameB = `${b["firstName"]}${b["lastName"]}`;
        if (fieldA && fieldB && fieldA == fieldB) {
          return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
        } else {
          return fieldA < fieldB ? -1 : 1;
        }
      });
    } else {
      arr = [...schs].sort((a, b) => {
        let fieldA = a[sortParam] || "";
        let fieldB = b[sortParam] || "";
        let nameA = `${a["firstName"]}${a["lastName"]}`;
        let nameB = `${b["firstName"]}${b["lastName"]}`;
        if (fieldA && fieldB && fieldA == fieldB) {
          return nameB < nameA ? -1 : nameB > nameA ? 1 : 0;
        } else {
          return fieldB < fieldA ? -1 : 1;
        }
      });
    }
    return arr;
  };

  useEffect(() => {
    const sortLS = sortingFilterInLC.get();
    if (sortLS.orders && sortLS.orders.sortBy) {
      setSortBy(sortLS.orders.sortBy);
      setSortDescending(sortLS.orders.sortDescending);
    }
    // if (sortLS.orders && sortLS.orders.filter && !location.state) {
    //   setFilter(sortLS.orders.filter);
    // }
    setPersonalize(
      appContext.user?.isUser()
        ? personalizationLocalStorage.get(appContext.user, "memberOrders", MEMBER_ORDER_PERSONALIZE)
        : personalizationLocalStorage.get(appContext.user, "orders", ORDER_PERSONALIZE)
    );

    setTotalOrders(appContext.getTotalOrders());
  }, [appContext?.company]);

  const getOrders = async () => {
    let userType = appContext.user.isUser() ? USER_TYPE_USER : USER_TYPE_ADMIN;
    let id = null;
    if (appContext.user.isUser()) {
      id = appContext.employeeRecord?.id;
      if (!id) {
        const empRecord = await api.getLoggedInUserEmployeeID(null, appContext.user.preferred_username);
        if (empRecord) {
          id = empRecord.id;
        }
      }
    } else if (appContext.user.isClient()) {
      userType = USER_TYPE_CLIENT;
    } else if (appContext.user.isSubAgent()) {
      userType = USER_TYPE_SUBAGENT;
    }

    const data = await api.getOrders(userType, id);

    const formatttedOrders = data.map((o) => formatOrder(o, appContext.employees));
    setOrders(formatttedOrders);
    setFilteredOrders(sortData(nestedFilter(formatttedOrders, filter), sortBy));
  };

  useEffect(() => {
    getOrders();
  }, [appContext.employees]);

  useEffect(() => {
    if (
      !appContext.user.isUser() &&
      appContext.eligibilityFiles.length > 0 &&
      appContext.eligibilityFiles[0].status === "Done" &&
      appContext.eligibilityFiles[0].fileType?.includes("Order")
    ) {
      getOrders();
    }
  }, [appContext.eligibilityFiles]);

  const getTotalOrders = (list) =>
    list.reduce((val, obj) => {
      return obj.testQty + val;
    }, 0);

  const getTotalClaimSubmit = (list, val) => {
    switch (val) {
      case "submit":
        return formatNumber(
          list.reduce((val, obj) => {
            return (obj.claim === "1" ? 1 : 0) + val;
          }, 0)
        );
      case "pending":
        return formatNumber(
          list.reduce((val, obj) => {
            return (obj.claim === "0" ? 1 : 0) + val;
          }, 0)
        );
      case "shipped":
        return formatNumber(
          list.reduce((val, obj) => {
            return (obj.orderStatus === "Delivered" ? 1 : 0) + val;
          }, 0)
        );
      case "delivered":
        return formatNumber(
          list.reduce((val, obj) => {
            return (obj.orderStatus === "Delivered" || obj.orderStatus === "Sent" ? obj.testQty : 0) + val;
          }, 0)
        );
      case "totalOrders":
        return formatNumber(
          list.reduce((val, obj) => {
            return obj.testQty + val;
          }, 0)
        );
      default:
        return 0;
    }
  };

  const getDuplicateOrder = async () => {
    let arr = [];
    let duplicateArr = [];
    for (let i = 0; i < orders.length; i++) {
      const order = orders[i];
      if (arr.findIndex((f) => f.orderId === order.orderId) !== -1) {
        duplicateArr.push(order);
      } else {
        arr.push(order);
      }
    }
    await downloadDataAsCSV(duplicateArr, "duplicate_order_ids.csv");

    console.log("getDuplicateOrder", duplicateArr);
  };

  const filterDays = (val) => {
    if (!val) return "-";
    const arr = val.split(",");
    return arr;
  };

  const getEmployeeOrder = () => {
    let arr = [];
    let duplicateArr = [];
    appContext.employees.map((m) => {
      const empOrders = orders.filter((f) => f.employeeID === m.id);

      const ordersQTy = empOrders.reduce((num, obj) => {
        return num + obj.testQty;
      }, 0);

      if (ordersQTy !== m.testOrdered) {
        arr.push(m);
      } else {
        duplicateArr.push(m);
      }
    });
    console.log({ duplicateArr, arr });
  };

  const createMember = async (user) => {
    try {
      setLoading(true);

      await api.updateEmployee(user, appContext.user);
      if (!user.onAlert) dispatch(setMessage(t("clientUpdatedSuccessfully"), MESSAGE_MODES.success));

      await appContext.resetEmployees();
      setLoading(false);
    } catch (error) {
      console.log("ERROR:User-", error);
      dispatch(setMessage(error.message, MESSAGE_MODES.error));
      setLoading(false);
    }
  };

  const handleUpdateOrder = async () => {
    let arr = [];
    const changeInsurance = appContext.employees.map((m) => {
      const empOrders = orders.filter((f) => f.employeeID === m.id);
      const findOrder = empOrders.findIndex((f) => f.payerId !== m.insuranceCompany);
      if (findOrder !== -1) {
        arr.push(m);
      }
    });

    console.log("arr", arr);
  };

  const handlePasswordClose = () => {
    setOpenPasswordModal(false);
  };

  const handleResetPassword = (obj) => {
    console.log("Password Obj", obj);
  };

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filters).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);
    //filters main array of objects

    const models = targetArray.filter((obj) => {
      //goes through each key being filtered for
      return filterKeys.every((key) => {
        if (!filters[key]?.length && !Object.keys(filters[key]).length) {
          return true;
        }

        if (key === "createdAt") {
          return Object.keys(filters[key]).length > 0
            ? new Date(obj.createdAt) > filters[key].startDate?._d && new Date(obj.createdAt) < filters[key].endDate?._d
            : obj.createdAt;
        }
        if (key === "orderDate") {
          return Object.keys(filters[key]).length > 0
            ? new Date(formatOrderedDateAws(obj.orderDate)) > filters[key].startDate?._d &&
                new Date(formatOrderedDateAws(obj.orderDate)) < filters[key].endDate?._d
            : obj.orderDate;
        }
        if (key === "siteID") {
          return obj[key] && obj[key] === filters[key];
        }
        if (key === "subAgentID") {
          return obj[key] && obj[key] === filters[key];
        }
        if (key === "claimStatus") {
          return obj[key] === filters[key];
        }
        if (key === "orderStatus") {
          return obj[key] === filters[key];
        }
        if (key === "clientID") {
          return obj[key] && obj[key] === filters[key];
        }
        if (key === "note") {
          if (filters[key] === "1" && obj.note?.message) return true;
          if (filters[key] === "0") return true;
          return false;
        }
        if (key === "testOrdered") {
          if (filters[key] === "1" && obj.testQty) return true;
          return false;
        }
        if (key === "employeeStatus") {
          return filter[key] === obj.employeeStatus;
        }
        if (key === "claim") {
          return obj[key] === filters[key];
        }
        if (key === "month") {
          return moment(formatOrderedDateAws(obj.orderDate)).isSame(new Date(), "month");
        }
        if (key === "year") {
          return moment(formatOrderedDateAws(obj.orderDate)).isSame(new Date(), "year");
        }
        if (key === "InsuranceStatus") {
          return obj[key] === filters[key];
        }
        if (key === "orderStatusFilter") {
          return obj["orderStatus"] === "Delivered" || obj["orderStatus"] === "Sent";
        }
        if (key === "testQty") {
          return obj[key] == filters[key];
        }
        if (key === "schrID") {
          return obj.pcn && obj.pcn.toLowerCase().includes(filters[key].toLowerCase());
        }
        if (key === "payerId") {
          return filters[key].indexOf(obj.payerId) !== -1;
        }
        return obj[key] && obj[key].toLowerCase().includes(filters[key].toLowerCase());
      });
    });
    return models;
  };

  const handleClose = () => {
    setOpenCreator(false);
    setOpenDeleteConfirmation(false);
  };

  const handleSaveFilter = () => {
    const pervSortLS = sortingFilterInLC.get();
    pervSortLS.orders = { sortBy, sortDescending, filter };
    sortingFilterInLC.save(pervSortLS);
  };

  useEffect(() => {
    if (filteredOrders.length > 0) {
      handleSaveFilter();
      setFilteredOrders(sortData(nestedFilter(orders, filter), sortBy));
    }
  }, [sortBy, sortDescending]);

  useEffect(() => {
    setFilteredOrders(sortData(nestedFilter(orders, filter), sortBy));
    setCurrentPage(1);
  }, [filter, orders]);

  const itemsToMap = useMemo(() => {
    const list = filteredOrders ? [...filteredOrders] : [];
    const ttlPages = list.length > PAGE_LIMIT ? Math.ceil(list.length / PAGE_LIMIT) : 1;
    setTotalPages(ttlPages);
    if (ttlPages > 1) {
      const indexOfLastUser = currentPage * PAGE_LIMIT;
      const indexOfFirstUser = indexOfLastUser - PAGE_LIMIT;
      return list.slice(indexOfFirstUser, indexOfLastUser);
    }
    return list;
  }, [filteredOrders, currentPage]);

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  const handleEdit = (item) => {
    const obj = { ...item, isNew: false };
    setNewItem(obj);
    setOpenCreator(true);
  };

  const handleConfirmDelete = async (isConfirm) => {
    setOpenDeleteConfirmation(false);
    if (!isConfirm) {
      setItemToDelete(null);
      return;
    }
    try {
      setLoading(true);
      const isDeleted = await api.deleteOrder(itemToDelete);
      if (isDeleted) {
        await getOrders();
        dispatch(setMessage("Order deleted successfully", MESSAGE_MODES.success));
      }
      setItemToDelete(null);
    } catch (error) {
      dispatch(setMessage(error.message, MESSAGE_MODES.error));
    }
    setLoading(false);
  };

  const handleChangePage = (number, direction) => {
    setCurrentPage(number);
  };

  const formatDOB = (dob) => {
    if (!dob) return "";
    const arr = dob.split("/");
    return `${arr[0].padStart(2, 0)}/${arr[1].padStart(2, 0)}/${arr[2]}`;
  };

  const parseDateOfBirth = (dob) => {
    if (!dob) return "";
    const arr = dob.split("/");
    return `${arr[2]}-${arr[0].padStart(2, 0)}-${arr[1].padStart(2, 0)}`;
  };

  const handleCheckboxChange = (e, user) => {
    const filteredList = checkboxes.filter((c) => c !== user.id);
    if (e.target.checked) {
      filteredList.push(user.id);
    }
    setCheckboxes(filteredList);
  };

  const toggleCheckboxes = (val) => {
    setCheckboxes(val ? filteredOrders.map((t) => t.id) : []);
  };

  const onHandleSubmitClaim = async () => {
    if (checkboxes.length === 0) {
      dispatch(setMessage("Please select the Order for Claim Submission", MESSAGE_MODES.error));
      return;
    }

    if (
      filteredOrders.filter(
        (f) => checkboxes.indexOf(f.id) !== -1 && (!f.claimStatus || (f.claimStatus && f.claimStatus === "Pending"))
      ).length === 0
    ) {
      dispatch(setMessage("Please select the Pending Orders", MESSAGE_MODES.error));
      return;
    }

    setClaimSubmissionModal(true);
    return;
    await downloadDataAsCSV(
      filteredOrders.map((d) => {
        let phNo = formatPhoneNumber(d.phoneNumber);
        phNo = phNo.replace("+1", "");
        const emp = appContext.employees.find((e) => e.id === d.employeeID);

        return {
          accept_assign: "Y",
          amount_paid: 0,
          auto_accident: "",
          auto_accident_state: "",
          bill_addr_1: "2268 LINCOLN AVE",
          bill_addr_2: "",
          bill_city: "ALTADENA",
          bill_id: "291U00000X",
          bill_name: "ALTA DIAGNOSTIC LABORATORIES, INC",
          bill_npi: "1063180354",
          bill_phone: "8185730434",
          bill_state: "CA",
          bill_taxid: "871701212",
          bill_taxid_type: "E",
          bill_taxonomy: "",
          bill_zip: "910015432",
          clia_number: "",
          diag_1: "Z20.822",
          diag_2: "",
          diag_3: "",
          diag_4: "",
          diag_5: "",
          diag_6: "",
          diag_7: "",
          diag_8: "",
          employment_related: "N",
          facility_addr_1: "2268 LINCOLN AVE",
          facility_addr_2: "",
          facility_city: "ALTADENA",
          facility_id: "",
          facility_name: "ALTA DIAGNOSTIC LABORATORIES, INC",
          facility_npi: "1063180354",
          facility_state: "CA",
          facility_zip: "",
          ins_addr_1: d.street,
          ins_addr_2: d.street2,
          ins_city: d.city,
          ins_dob: moment(d.dob).format("MM/DD/YYYY"),
          ins_employer: "",
          ins_group: "",
          ins_name_f: d.firstName,
          ins_name_l: d.lastName,
          ins_name_m: "",
          ins_number: emp?.medicareNo || d.medicalNo,
          ins_phone: phNo,
          ins_plan: "",
          ins_sex: emp?.sex || d.sex || d.gender,
          ins_state: d.state,
          ins_zip: d.zip,
          narrative: "",
          ord_prov_name_f: "",
          ord_prov_name_l: "",
          ord_prov_name_m: "",
          ord_prov_npi: "",
          other_ins_dob: "",
          other_ins_group: "",
          other_ins_medicare_code: "",
          other_ins_name_f: "",
          other_ins_name_l: "",
          other_ins_name_m: "",
          other_ins_number: "",
          other_ins_payment_date: "",
          other_ins_plan: "",
          other_ins_sex: "",
          other_pat_rel: "",
          other_payer_addr_1: "",
          other_payer_addr_2: "",
          other_payer_city: "",
          other_payer_name: "",
          other_payer_phone: "",
          other_payer_state: "",
          other_payer_typecode: "",
          other_payer_zip: "",
          other_payerid: "",
          pat_addr_1: d.street,
          pat_addr_2: d.street2,
          pat_city: d.city,
          pat_country: "",
          pat_dob: moment(d.dob).format("MM/DD/YYYY"),
          pat_name_f: d.firstName,
          pat_name_l: d.lastName,
          pat_name_m: "",
          pat_phone: formatPhoneNumber(d.phoneNumber),
          pat_rel: "18",
          pat_sex: d.gender,
          pat_ssn: "",
          pat_state: d.state,
          pat_zip: d.zip,
          pay_addr_1: "",
          pay_addr_2: "",
          pay_city: "",
          pay_state: "",
          pay_zip: "",
          payer_addr_1: "",
          payer_addr_2: "",
          payer_city: "",
          payer_name: "CA Medicare South",
          payer_order: "Primary",
          payer_state: "",
          payer_zip: "",
          payerid: "01192",
          pcn: d.pcn,
          prior_auth: "",
          prov_id: "",
          prov_name_f: "",
          prov_name_l: "",
          prov_name_m: "",
          prov_npi: "",
          prov_taxid: "",
          prov_taxid_type: "",
          prov_taxonomy: "",
          ref_name_f: "",
          ref_name_l: "",
          ref_name_m: "",
          ref_npi: "",
          remote_batchid: "",
          remote_claimid: d.orderId,
          remote_fileid: "",
          total_charge: "200",
          charge_1: "200",
          diag_ref_1: "A",
          from_date_1: "",
          mod1_1: "",
          mod2_1: "",
          mod3_1: "",
          mod4_1: "",
          narrative_1: "",
          place_of_service_1: "81",
          proc_code_1: "K1034",
          remote_chgid_1: "",
          thru_date_1: "",
          units_1: "8",
          diag_ref_2: "",
          from_date_2: "",
          mod1_2: "",
          mod2_2: "",
          mod3_2: "",
          mod4_2: "",
          narrative_2: "",
          place_of_service_2: "",
          proc_code_2: "",
          remote_chgid_2: "",
          thru_date_2: "",
          units_2: "",
          primaryInsurance: d.payerName,
          orderDate: d.orderDate,
        };
      }),
      "reSubmitClaim"
    );
  };

  const handleImportTrackingID = async (empData, subAgentID, orderMonth, shipmentDate) => {
    if (empData.length === 0) {
      dispatch(setMessage(t("emptyFile"), MESSAGE_MODES.error));
      setTrackingIDImport(false);
      return;
    }

    if (!orderMonth) {
      dispatch(setMessage("Please select order month", MESSAGE_MODES.error));
      return;
    }
    if (!shipmentDate) {
      dispatch(setMessage("Please select Shipment Date", MESSAGE_MODES.error));
      return;
    }
    setTrackingIDImport(false);
    setLoading(true);
    const ttlLength = empData.length;
    const newEmps = [];
    const error = [];
    for (let index = 0; index < ttlLength; index++) {
      const { data } = empData[index];
      const emp = { ...data };
      const employee = appContext.employees.find((f) => f.schrID === emp.schrID);
      emp.employeeID = employee?.id;
      if (!employee) {
        error.push(emp);
        continue;
      }
      emp.orderMonth = orderMonth.replace(/\D/g, "").substring(0, 6);
      emp.shipmentDate = shipmentDate;
      newEmps.push(emp);
    }
    // downloadDataAsCSV(newEmps, "trackingID");
    try {
      if (newEmps.length === 0 && error.length > 0) {
        dispatch(setMessage("Denied", MESSAGE_MODES.error));
        setLoading(false);
        return;
      }
      const fileName = `trackingId/trackingId_${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}.csv`;
      await api.saveFileOnBucket(newEmps, fileName);
      const text = `${newEmps.length} Order(s) Shipment Updated Successfully ${
        error.length > 0 ? `and ${error.length} Order(s) Denied` : ""
      }`;
      dispatch(setMessage(text, MESSAGE_MODES.success));
      setLoading(false);
    } catch (err) {
      console.log("Error:-", err);
      setLoading(false);
      dispatch(setMessage(err, MESSAGE_MODES.error));
    }
  };

  const handleImport = async (empData, subAgentID, orderMonth) => {
    if (empData.length === 0) {
      dispatch(setMessage(t("emptyFile"), MESSAGE_MODES.error));
      setOpenImportModal(false);
      return;
    }
    // if (!locationID) {
    // dispatch(setMessage("Facility/Agent was not selected", MESSAGE_MODES.error));
    //   return;
    // }
    if (!orderMonth) {
      dispatch(setMessage("Please select order month", MESSAGE_MODES.error));
      return;
    }
    const subAgent = await api.getSubAgentById(subAgentID || CONFIG.generalSubAgent);
    const client = await api.getLocationById(subAgent.locationID);
    const company = await appContext.getClientById(subAgent.clientID);
    const programName = [];
    const arr = [];
    const arrdata = [];
    const newEmpIds = [];
    const ttlLength = empData.length;

    const filterheader = ["firstName", "lastName", "dob", "medicareNo", "quantity"].every((c) =>
      Object.keys(empData[0].data).includes(c)
    );

    if (!filterheader) {
      dispatch(setMessage("Headers not matched", MESSAGE_MODES.error));
      setOpenImportModal(false);
      return;
    }

    setOpenImportModal(false);
    setLoading(true);

    for (let index = 0; index < ttlLength; index++) {
      const { data } = empData[index];
      const emp = { ...data };
      if (!emp.firstName && !emp.lastName && !emp.dob && !emp.medicareNo && !emp.quantity) continue;

      emp.firstName = getValidDep(emp.firstName || "");
      emp.lastName = getValidDep(emp.lastName || "");
      emp.phone = getPhoneNumber(emp.phone || "");
      emp.companyID = subAgent.locationID;
      emp.clientID = client.clientID;
      emp.subAgentID = subAgent.id;
      emp.testLimit = subAgent.testLimit || 8;
      emp.medicareNo = emp.medicareNo?.trim().toUpperCase();
      emp.automaticMonthly = (emp.automaticMonthly?.toLowerCase() || "No") === "yes";
      emp.orderDate = orderMonth.replace(/\D/g, "");
      if (emp.gender) {
        emp.gender = getValidGender(emp.gender);
      }
      if (emp.dob && emp.dob.includes("/")) {
        emp.dob = moment(emp.dob, "MM/DD/YYYY").format("MM/DD/YYYY");
      } else {
        emp.dob = moment(emp.dob).format("MM/DD/YYYY");
      }

      if (!emp.firstName || emp.firstName === " ") {
        emp.validation = "missing information";
        newEmpIds.push(emp);
        continue;
      }
      if (!isValidName(emp.firstName)) {
        emp.validation = `${emp.firstName} is invalid must contains at least 2 letters and Name should be realistic`;
        newEmpIds.push(emp);
        continue;
      }

      if (!emp.lastName || emp.lastName === " ") {
        emp.validation = "missing information";
        newEmpIds.push(emp);
        continue;
      }
      if (!isValidName(emp.lastName)) {
        emp.validation = `${emp.lastName} is invalid must contains at least 2 letters and Name should be realistic`;
        newEmpIds.push(emp);
        continue;
      }

      if (!emp.dob) {
        emp.validation = "missing information";
        newEmpIds.push(emp);
        continue;
      }

      if (!emp.medicareNo || !emp.medicareNo.trim()) {
        emp.validation = "missing information";
        newEmpIds.push(emp);
        continue;
      }

      if (!emp.medicareAgent || !emp.medicareAgent.trim()) {
        emp.validation = "isurance provider required missing information";
        newEmpIds.push(emp);
        continue;
      }

      if (!emp.quantity || !emp.quantity.trim()) {
        emp.validation = "Quantity Missing";
        newEmpIds.push(emp);
        continue;
      }
      if (emp.quantity < 1) {
        emp.validation = "Quantity must be positive";
        newEmpIds.push(emp);
        continue;
      }

      if (!isValidIDNumber(emp.medicareNo)) {
        emp.validation = "Medicare number invalid";
        newEmpIds.push(emp);
        continue;
      }

      if (
        emp.dob &&
        (!isValidDobField(emp.dob, "MM/DD/YYYY") ||
          !moment(emp.dob, "MM/DD/YYYY").isBetween("01/01/1900", currentDate()))
      ) {
        emp.validation = "invalid DOB";
        newEmpIds.push(emp);
        continue;
      }

      let findVal = null;
      if (emp.payerId) {
        findVal = INSURANCE_PROVIDER.find((f) => f.payerid === emp.payerId.padStart(5, 0));
      } else {
        findVal = INSURANCE_PROVIDER.find((f) =>
          f.label.toLowerCase().replace(/\s/g, "").includes(emp.medicareAgent.toLowerCase().replace(/\s/g, ""))
        );
      }
      if (!findVal) {
        emp.validation = "Medicare Agent inValid";
        newEmpIds.push(emp);
        continue;
      } else {
        emp.insuranceCompany = findVal.payerid;
        emp.payerId = findVal.payerid;
      }

      emp.validation = "Pass";
      newEmpIds.push(emp);

      // const orderObj = await api.createPreviousOrder(emp, appContext.user);
      // newEmpIds.push(orderObj);
    }

    if (newEmpIds.length === 0) {
      dispatch(setMessage(t("emptyFile"), MESSAGE_MODES.error));
      setLoading(false);
      return;
    }
    const filterFileList = newEmpIds.filter((f) => f.validation === "Pass");
    const percentage = Math.round((filterFileList.length / newEmpIds.length) * 100);

    if (percentage >= 80) {
      const fileName = `${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}_order_test.csv`;
      try {
        await api.addEligibilityCsv(newEmpIds, fileName, CONFIG.eligibilityBucket);

        const obj = {
          fileType: "EligibilityAndOrder",
          fileName,
          totalRecord: newEmpIds.length,
          clientID: company?.id,
          locationID: client?.id,
          locationName: client?.name,
          clientName: company?.name,
          subAgentName: subAgent?.name,
          subAgentID: subAgent?.id,
        };
        const res = await api.newEligibiliyFiles(obj, appContext.user);
        const text = `Thank You for Submitting ${newEmpIds.length} for order placement. Your request is being processed. You will be notified in couple of minutes.`;
        dispatch(setMessage(text, MESSAGE_MODES.success));
      } catch (err) {
        dispatch(setMessage(err.message, MESSAGE_MODES.error));
      }
    } else {
      dispatch(setMessage("Your Records not Correct more than 20% please recheck", MESSAGE_MODES.error));
      const content = jsonToCSV(newEmpIds);
      const fileContent = new Blob([content], { type: "csv" });
      await FileSaver.saveAs(fileContent, "ltc_order_Status.csv");
    }
    setLoading(false);
  };

  const handleDownloadSample = async () => {
    await downloadDataAsCSV(
      [
        {
          firstName: ["First"],
          middleName: ["middle"],
          lastName: ["last"],
          gender: "",
          dob: ["09/06/1945"],
          address: ["1249 Abc Town"],
          address2: "",
          city: ["City"],
          state: ["Maryland"],
          zip: ["20657"],
          phone: "",
          agentPin: ["20"],
          medicareType: ["Medicare Part B"],
          medicareAgent: ["Medicare"],
          payerId: ["1192"],
          medicareNo: ["6230df8dj26"],
          email: "",
          MBINumber: "",
          automaticMonthly: ["Yes"],
          quantity: ["2"],
        },
      ],
      t("orderSampleCsv")
    );
  };
  const handelViewModal = (order) => {
    const Emps = formatEmployeesData(
      appContext.employees,
      appContext.subAgents,
      appContext.locations,
      appContext.companies
    );
    const emp = Emps.find((f) => f.id === order.employeeID);
    if (emp) {
      setEmployee(emp);
      setOpenViewModal(true);
    } else {
      dispatch(setMessage("Member Deleted", MESSAGE_MODES.info));
    }
  };
  const handlePersonalization = async (data) => {
    setPersonalize([...data]);
    setOpenPersonalizationModal(false);
    const personalisationData = personalizationLocalStorage.saveAs(
      data,
      appContext.user?.isUser() ? "memberOrders" : "orders"
    );
    personalizationLocalStorage.save(JSON.stringify(personalisationData));
    if (!appContext.user.isUser()) {
      await api.udpatePersonalization(appContext.user.phone_number, personalisationData);
    }
  };

  const handleOrderStatus = () => {
    const filterOrders = orders.filter((f) => checkboxes.includes(f.id));
    if (filterOrders.every((e) => e.trackingNumber === filterOrders[0].trackingNumber)) {
      setUpdateOrderStatus(true);
    } else {
      dispatch(setMessage("Please Select Same Tracking number Order", MESSAGE_MODES.error));
    }
  };
  const tdOrderStatus = (item, user) => (
    <td
      className="table-column ellipsis linkedText"
      title={user.orderStatus}
      style={{ textAlign: "center" }}
      onClick={() => {
        setOpenOrderDetail(true);
        setSelectedOrder(user);
      }}
    >
      {user.orderStatus}
    </td>
  );

  const insuranceProviderFormatter = (insuranceCompany) => {
    if (!insuranceCompany) return "-";
    const item = INSURANCE_PROVIDER.find((i) => i.value === insuranceCompany);
    if (!item) return "-";
    return item?.label;
  };

  const handleRecheckEligibility = async () => {
    const filteredLists = filteredOrders.filter((f) => checkboxes.indexOf(f.id) !== -1);

    if (filteredLists.length === 0) return;

    const client = await api.getLocationById(filteredLists[0].locationID);
    const company = await appContext.getClientById(filteredLists[0].clientID);
    const subAgent = {
      id: filteredLists[0].subAgentID,
      name: filteredLists[0].subAgentName,
    };

    const fileList = filteredLists.map((e) => {
      const emp = appContext.employees.find((r) => r.id === e.employeeID);
      if (emp) {
        const dob = emp.dob.split("-");

        return {
          new: "",
          employeeID: emp.id,
          firstName: emp.firstName,
          lastName: emp.lastName,
          dob: `${dob[1]}/${dob[2]}/${dob[0]}`,
          medicareNo: emp.medicareNo || emp.medicalNo,
          medicareAgent: "CA Medicare South",
          payerId: "01192",
          groupId: "",
          insuranceCompany: "CA Medicare South",
          validation: "pass",
          dbOrderId: e.id,
        };
      } else {
        return {};
      }
    });

    setLoading(true);
    const key = userCompanyID.get() || "admin";
    const fileName = `${key}/${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}_date.csv`;
    try {
      await api.addEligibilityCsv(fileList, fileName, CONFIG.eligibilityBucket);
      const obj = {
        fileType: "Eligibility",
        fileName,
        totalRecord: fileList.length,
        clientID: company?.id,
        locationID: client?.id,
        locationName: client?.name,
        clientName: company?.name,
        subAgentName: subAgent?.name,
        subAgentID: subAgent?.id,
      };
      const res = await api.newEligibiliyFiles(obj, appContext.user);
      const text = `Thank you for submitting  ${fileList.length} records for eligibility checking. Your request is being processed. You will be notified in couple of minutes.`;
      dispatch(setMessage(text, MESSAGE_MODES.success));
      setCheckboxes([]);
      setLoading(false);
    } catch (error) {
      dispatch(setMessage(error.message, MESSAGE_MODES.error));
      console.log("Error:-", error.message);
      setLoading(false);
    }
  };

  const tdFormat = (item, user) => {
    if (item === "createdAt") return formatDateOfBirthDOB(user.createdAt);
    if (item === "dob") return formatDateMDY(user.dob);
    if (item === "isVaccinated") return parseBooleanValue(user.isVaccinated);
    if (item === "payerId") return insuranceProviderFormatter(user.payerId);
    if (item === "firstName") {
      return (
        <Link
          style={{ textDecoration: "none", color: "black", cursor: "pointer" }}
          to={{
            pathname: "/admin/billing",
            state: {
              name: user.id,
              term: "id",
            },
          }}
        >
          {user.firstName}
        </Link>
      );
    }
    if (item === "lastName") {
      return (
        <Link
          style={{ textDecoration: "none", color: "black", cursor: "pointer" }}
          to={{
            pathname: "/admin/billing",
            state: {
              name: user.id,
              term: "id",
            },
          }}
        >
          {user.lastName}
        </Link>
      );
    }
    if (item === "claim")
      return user.claim === "1" ? (
        <div className="star-icon-wrapper">
          <Status type="circle" size="md" color="green" crossIcon />
        </div>
      ) : (
        <div className="star-icon-wrapper">
          <Status type="circle" size="md" color="maroon" crossIcon />
        </div>
      );
    if (item === "claimStatus")
      return user.claimStatus === "Accepted" ? (
        <div className="star-icon-wrapper">
          <Status type="circle" size="md" color="green" crossIcon />
        </div>
      ) : user.claimStatus === "Rejected" ? (
        <div className="star-icon-wrapper">
          <Status type="circle" size="md" color="maroon" crossIcon />
        </div>
      ) : (
        ""
      );
    if (item === "InsuranceStatus")
      return user.InsuranceStatus === "Paid" ? (
        <div className="star-icon-wrapper">
          <Status type="circle" size="md" color="green" crossIcon />
        </div>
      ) : user.InsuranceStatus === "Approved" ? (
        <div className="star-icon-wrapper">
          <Status type="circle" size="md" color="lightgreen" crossIcon />
        </div>
      ) : user.InsuranceStatus === "Denied" ? (
        <div className="star-icon-wrapper">
          <Status type="circle" size="md" color="maroon" crossIcon />
        </div>
      ) : user.InsuranceStatus === "Pending" ? (
        <div
          className="star-icon-wrapper cursor-pointer"
          onClick={() => !appContext.isReadOnly() && setOpenClearClaimModal(user.id)}
        >
          <Status type="circle" size="md" crossIcon />
        </div>
      ) : (
        ""
      );
    if (item === "testOrderCategory") return TEST_ORDER_LIMIT_CATEGORY_VALUE[user.testOrderCategory];
    return user[item];
  };

  const tdFormatTitle = (item, user) => {
    if (item === "createdAt") return formatDateOfBirthDOB(user.createdAt);
    if (item === "dob") return formatDateMDY(user.dob);
    if (item === "isVaccinated") return parseBooleanValue(user.isVaccinated);
    if (item === "payerId") return insuranceProviderFormatter(user.payerId);

    if (item === "claim") return user.claim === "1" ? "Submitted" : "Pending";
    if (item === "testOrderCategory") return TEST_ORDER_LIMIT_CATEGORY_VALUE[user.testOrderCategory];
    return user[item];
  };

  const renderTd = (item, user) => {
    if (item.itemKey === "email") return tdEmail(user.email);
    if (item.itemKey === "phoneNumber") return tdPhone(user.phoneNumber);
    if (item.itemKey === "qaDone") return tdCheckBox(item, user);
    if (item.itemKey === "trackingNumber") return tdTrackingID(user.trackingNumber);
    if (item.itemKey === "orderStatus") return tdOrderStatus(item, user);
    if (item.itemKey === "orderDate") return tdOrderDate(item, user);
    if (item.itemKey === "testDone") {
      return tdCheckBox(item, user);
    }
    return (
      <td
        className="ellipsis"
        style={{
          textAlign: item.textAlign,
          textOverflow: item.textOverflow,
        }}
        title={tdFormatTitle(item.itemKey, user)}
      >
        {tdFormat(item.itemKey, user)}
      </td>
    );
  };

  const handleCellClick = (key, row, event) => {
    switch (key) {
      case TABLE_QUICK_TOOLS.checkbox:
        handleCheckboxChange(event, row);
        break;

      case TABLE_QUICK_TOOLS.notes:
        !appContext.isReadOnly() && setNoteOrder(row);
        break;

      case "orderStatus":
        setOpenOrderDetail(true);
        setSelectedOrder(row);
        break;

      default:
        break;
    }
  };

  const selectedItem = [
    { itemKey: "orderDate" },
    { itemKey: "firstName" },
    { itemKey: "lastName" },
    { itemKey: "testQty" },
    { itemKey: "street" },
    { itemKey: "city" },
    { itemKey: "state" },
    { itemKey: "zip" },
    { itemKey: "orderId" },
    { itemKey: "clientName" },
    { itemKey: "siteName" },
    { itemKey: "orderStatus" },
    { itemKey: "requestedByName" },
  ];

  return (
    <div style={{ flex: 1 }}>
      {!loading ? (
        <Row>
          <Col>
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                  }}
                >
                  {t("totalOrders")} ({formatNumber(filteredOrders.length)})
                </Card.Title>
                <div className="buttonHeader">
                  {!appContext.user?.isUser() && (
                    <Icon
                      handleClick={() => setShowFilter(!showFilter)}
                      title={"Filter"}
                      label={"Filter"}
                      iconType={"filter"}
                    />
                  )}

                  {!appContext.isReadOnly() && (
                    <Icon
                      handleClick={() => {
                        const filteredLists = filteredOrders.filter((f) => checkboxes.indexOf(f.id) !== -1);
                        const notApprovedOrders = filteredLists.filter(
                          (e) => e.InsuranceStatus && e.InsuranceStatus.toLowerCase() === "approved"
                        );
                        if (notApprovedOrders.length > 0) {
                          dispatch(
                            setMessage(
                              "You can not delete the approved order. please unselect it and try again",
                              MESSAGE_MODES.error
                            )
                          );
                          return;
                        }
                        setItemToDelete(filteredLists);
                        setOpenDeleteConfirmation(true);
                      }}
                      label="Delete"
                      title="Delete"
                      disabled={checkboxes.length === 0}
                      iconType={"binIcon"}
                    />
                  )}

                  {appContext.user.isUser() && (
                    <Icon
                      handleClick={() => {
                        if (
                          appContext.employeeRecord &&
                          (appContext.employeeRecord.testAvailable === 0 ||
                            appContext.employeeRecord.status === "inactive")
                        ) {
                          dispatch(setMessage("Your limit is completed or status is Inactive", MESSAGE_MODES.error));
                        } else {
                          setOrderModal(true);
                        }
                      }}
                      title={t("orderTest")}
                      label={t("orderTest")}
                      iconType="assignZoneIcon"
                    />
                  )}

                  <Icon
                    handleClick={async () => {
                      if (filteredOrders && filteredOrders.length > 0) {
                        setAllSelected(!allSelected);
                        toggleCheckboxes(!allSelected);
                      }

                      //  const list = await api.UpdateOrderForTest();

                      //  const list = filteredOrders
                      //  .filter((f) => f.orderStatus === "Sent")
                      //  .map((e) => {
                      //    return { ...e , trackingNumber : `m-${e.trackingNumber}` };
                      //  });
                      // const content = jsonToCSV(list);
                      // const fileContent = new Blob([content], { type: "csv" });
                      // await FileSaver.saveAs(fileContent, "employee_active_march_09.csv");
                    }}
                    label={!allSelected ? "Select All" : "Deselect All"}
                    title={!allSelected ? "Select All" : "Deselect All"}
                    iconType={"selectAllIcon"}
                  />

                  {!appContext.user.isUser() && !appContext.isReadOnly() && (
                    <>
                      <Icon
                        handleClick={() => {
                          setOpenImportModal(true);
                        }}
                        title={t("loadOrderData")}
                        label={"Import"}
                        iconType={"importIcon"}
                      />
                      {(appContext?.user?.phone_number === "+18888888888" ||
                        appContext?.user?.phone_number === "+17777777777") && (
                        <Icon
                          title={t("submitClaim")}
                          label={t("submitClaim")}
                          iconType={"acknowledgedPositiveIcon"}
                          handleClick={onHandleSubmitClaim}
                        />
                      )}
                      {/* <Icon
                        title={"PDF"}
                        label={"PDf"}
                        iconType={"acknowledgedPositiveIcon"}
                        handleClick={() => setShowPDFModal(true)}
                      /> */}
                    </>
                  )}

                  <Icon
                    handleClick={() => setOpenPersonalizationModal(true)}
                    title={t("personalize")}
                    label={t("personalize")}
                    iconType={"personalizeIcon"}
                  />
                  {(appContext?.user?.phone_number === "+18888888888" ||
                    appContext?.user?.phone_number === "+17777777777") && (
                    <Icon
                      handleClick={handleDownloadSample}
                      title={"Download Csv Sample"}
                      label={"Download"}
                      iconType={"downloadIcon"}
                    />
                  )}

                  <ExportToExcel
                    selectedItem={personalize.length > 0 && personalize.filter((m) => m.isCheck && m.itemKey)}
                    items={filteredOrders}
                    appContext={appContext}
                    filter={filter}
                    title="Orders Data"
                    sortBy={sortBy}
                    orderTests={true}
                  />
                  {!appContext.isReadOnly() && (
                    <Icon
                      handleClick={handleRecheckEligibility}
                      title={"ReCheck Eligibility"}
                      label={"Eligibility"}
                      disabled={checkboxes.length === 0}
                      iconType={"acknowledgedPositiveIcon"}
                    />
                  )}
                  {/* {(appContext?.user?.phone_number === "+18888888888" ||
                    appContext?.user?.phone_number === "+17777777777") && (
                    <Icon
                      title={t("createClaim")}
                      label={t("createClaim")}
                      iconType={"acknowledgedPositiveIcon"}
                      handleClick={() => setClaimReSubmissionModal(true)}
                    />
                  )} */}

                  {appContext?.user?.phone_number === "+18888888888" && (
                    <>
                      <Icon
                        handleClick={() => {
                          setTrackingIDImport(true);
                        }}
                        title={t("importTracking")}
                        label={"Import TrackingId"}
                        iconType={"importIcon"}
                      />
                      <Icon
                        iconType="assignZoneIcon"
                        handleClick={() => setOpenManualOrderQtyModal(true)}
                        title={"Update Order Quantity"}
                        disabled={checkboxes.length === 0}
                        label={"UOQ"}
                      />
                      <Icon
                        iconType="assignZoneIcon"
                        handleClick={handleOrderStatus}
                        title={"Update Order Status"}
                        disabled={checkboxes.length === 0}
                        label={"UOS"}
                      />
                      <Icon
                        iconType="assignZoneIcon"
                        handleClick={() => setShowChangePayerModal(true)}
                        title={"Change Insurance"}
                        disabled={checkboxes.length === 0}
                        label={"Change Insurance"}
                      />
                    </>
                  )}
                </div>
                {showFilter && (
                  <Filter
                    filterTerms={searchTerms}
                    setFilter={setFilter}
                    filter={filter}
                    triggerFilter={triggerFilter}
                    setTriggerFilter={setTriggerFilter}
                  />
                )}
                {appContext?.user?.phone_number === "+18888888888" && (
                  <div className="dashboard-wrapper">
                    <div className="d-flex w-100">
                      <div
                        className="stats-item"
                        style={{
                          borderRadius: "10px",
                        }}
                      >
                        <Stats
                          title={"Total Claims Submitted"}
                          totalCounts={getTotalClaimSubmit(orders, "submit")}
                          filterKey="claim"
                          value="1"
                          handelClick={(obj) => setFilter({ ...obj })}
                          color="black"
                        />
                        <Stats
                          title={"Total Claims Pending"}
                          totalCounts={getTotalClaimSubmit(orders, "pending")}
                          filterKey="claim"
                          value="0"
                          handelClick={(obj) => setFilter({ ...obj })}
                          color="black"
                        />
                      </div>
                      <div
                        className="stats-item"
                        style={{
                          borderRadius: "10px",
                        }}
                      >
                        <Stats
                          title={"Total Tests Ordered"}
                          totalCounts={getTotalClaimSubmit(orders, "totalOrders")}
                          filterKey="testOrdered"
                          value="1"
                          handelClick={(obj) => setFilter({ ...obj })}
                          color="black"
                        />
                        <Stats
                          title={"Total Tests Shipped"}
                          totalCounts={getTotalClaimSubmit(orders, "delivered")}
                          filterKey="orderStatusFilter"
                          value="delivered"
                          handelClick={(obj) => setFilter({ ...obj })}
                          color="black"
                        />
                      </div>
                    </div>
                  </div>
                )}
                <MFPagination
                  currentPage={currentPage}
                  handleChangePage={handleChangePage}
                  totalPages={totalPages}
                  nextPageToken={nextPageToken}
                  showSelectedRecord
                  totalSelected={checkboxes.length}
                />
              </Card.Header>
              <Card.Body className="table-full-width desktop-noScroll">
                <MainTable
                  cssClass={filteredOrders.length === 0 && "overFlow-y-hidden"}
                  columns={personalize}
                  rows={itemsToMap}
                  flipSort={flipSort}
                  sortBy={sortBy}
                  sortDescending={sortDescending}
                  widthToSkip={150}
                  selectedRows={checkboxes}
                  tools={quickTools}
                  dropDownOptions={!appContext.user?.isUser() && ADMIN_ORDERS_DROPDOWN_OPTIONS}
                  handleDropDownClick={(type, row) => {
                    if (type === "view") {
                      !appContext.isReadOnly() && handelViewModal(row);
                    }
                  }}
                  handleCellClick={handleCellClick}
                  customColumnCellRenderer={renderTd}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <Loader />
      )}
      {openDeleteConfirmation && (
        <ConfirmationModal
          show={openDeleteConfirmation}
          title="Delete Order"
          message="Are you sure, you want to delete Order?"
          handleConfirm={handleConfirmDelete}
          closeBtn
        />
      )}
      {openViewModal && (
        <EmployeeModal
          user={employee}
          handleClose={() => setOpenViewModal(false)}
          handleSave={createMember}
          resetCall={setOpenPasswordModal}
          users={appContext.employees}
        />
      )}
      {openPasswordModal && (
        <UserPasswordModal user={employee} handleClose={handlePasswordClose} handleSave={handleResetPassword} />
      )}
      {noteOrder && (
        <NotesModal
          handleClose={(obj) => {
            if (obj) {
              obj.note?.message
                ? dispatch(setMessage("Add Notes Successfully", MESSAGE_MODES.success))
                : dispatch(setMessage("Remove Notes Successfully", MESSAGE_MODES.success));
              const findVal = orders.find((f) => f.id === obj.id);
              if (findVal) {
                Object.assign(findVal, { note: obj.note });
                const index = orders.findIndex((f) => f.id === findVal.id);
                if (index !== -1) {
                  const data = [...orders];
                  data.splice(index, 1, findVal);
                  setOrders(data);
                  setFilteredOrders(sortData(nestedFilter(data, filter), sortBy));
                }
              }
            }
            setNoteOrder(null);
          }}
          data={noteOrder}
          order={true}
          user={appContext.user}
        />
      )}
      {orderModal && (
        <OrderModal
          handleClose={(isProcess, limitExccedEmployees, order) => {
            setOrderModal(false);
            if (isProcess) {
              if (limitExccedEmployees && limitExccedEmployees.length > 0) {
                const text = `We could not process your order at this time. You have reached your limit for this program. Please try again next month and check your eligibility`;
                dispatch(setMessage(text, MESSAGE_MODES.error));
              } else {
                dispatch(
                  setMessage(
                    `You have successfully ordered ${order.quantity} COVID-19 Antigen tests.`,
                    MESSAGE_MODES.success
                  )
                );
              }
              getOrders(true, filter);
            }
          }}
          employeeIds={appContext.employeeRecord ? [appContext.employeeRecord.id] : []}
          appContext={appContext}
          testAvailable={appContext.employeeRecord ? appContext.employeeRecord.testAvailable : 2}
        />
      )}
      {openClearClaimModal && (
        <ClearClaimModal
          handleClose={(val) => {
            if (val) {
              dispatch(setMessage(`Insurance Status ${val} Successfully`, MESSAGE_MODES.success));
              getOrders();
            }
            setOpenClearClaimModal("");
          }}
          orderID={openClearClaimModal}
        />
      )}
      {updateOrderStatus && (
        <ManualOrderStatusModal
          handleClose={(val) => {
            if (val) {
              getOrders();
            }
            setUpdateOrderStatus(false);
            setCheckboxes([]);
            setAllSelected(false);
          }}
          orders={checkboxes}
          appContext={appContext}
        />
      )}
      {openOrderDetail && (
        <OrderLogsModal
          order={selectedOrder}
          show={openOrderDetail}
          appContext={appContext}
          handleClose={() => {
            setOpenOrderDetail(false);
            setSelectedOrder("");
          }}
        />
      )}
      {openManualOrderQtyModal && (
        <ManualUpdateOrderQuantity
          handleClose={(val) => {
            if (val) {
              getOrders();
            }
            setOpenManualOrderQtyModal(false);
            setCheckboxes([]);
            setAllSelected(false);
          }}
          orders={checkboxes}
          appContext={appContext}
        />
      )}
      {openPersonalizationModal && (
        <PersonalizationModal
          data={JSON.stringify(personalize)}
          handleChange={handlePersonalization}
          show={openPersonalizationModal}
          handleClose={() => setOpenPersonalizationModal(false)}
        />
      )}
      {openImportModal && (
        <OrderImportModal
          handleClose={() => setOpenImportModal(false)}
          handleImport={handleImport}
          appContext={appContext}
          title="Import Order Data"
        />
      )}
      {trackingIdImport && (
        <OrderImportModal
          handleClose={() => setTrackingIDImport(false)}
          handleImport={handleImportTrackingID}
          appContext={appContext}
          trackingImport
          title="Import Tracking IDs"
        />
      )}
      {/* {showPDFModal && <PdfModal handleClose={() => setShowPDFModal(false)} />} */}
      {claimSubmissionModal && (
        <NewClaimSubmissionModal
          orders={filteredOrders.filter((f) => checkboxes.indexOf(f.id) !== -1)}
          employees={appContext.employees}
          handleClose={(val) => {
            setClaimSubmissionModal(false);
            if (val) {
              setCheckboxes([]);
              getOrders();
            }
          }}
          fileDir="claim"
        />
      )}
      {showChangePayerModal && (
        <ChangeOrderPayer
          orders={filteredOrders.filter((f) => checkboxes.indexOf(f.id) !== -1)}
          appContext={appContext}
          handleClose={(val) => {
            setShowChangePayerModal(false);
          }}
        />
      )}
      {/* {claimReSubmissionModal && (
        <ClaimResubmissionModal
          orders={filteredOrders.filter((f) => checkboxes.indexOf(f.id) !== -1)}
          user={appContext.employees}
          handleClose={(val) => {
            setClaimReSubmissionModal(false);
            if (val) {
              setCheckboxes([]);
              getOrders();
            }
          }}
          fileDir="claim"
        />
      )} */}
    </div>
  );
};

export default AdminOrders;
