import API from "api";
import logo from "assets/img/logo.jpg";
import { Auth } from "aws-amplify";
import Loader from "components/Loader/Loader";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import LocationModal from "components/Modal/LocationModal";
import MFPagination from "components/Pagination/MFPagination";
import {
  CONFIG,
  PAGE_LIMIT,
  TEST_ORDER_LIMIT_CATEGORY_VALUE,
  USER_TYPE_ADMIN,
  USER_TYPE_CLIENT,
  USER_TYPE_SUBAGENT,
  USER_TYPE_USER,
  newLocationObject,
} from "constant";
import { AppContext } from "context/app-context";
import { useContext, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { QRCode } from "react-qrcode-logo";
import {
  checkValidity,
  emailMsgToCreatedFacility,
  formatOrder,
  getPreRegistrationLink,
  sortingFilterInLC,
} from "utils";
import Filter from "../components/Filter";
import { t } from "../stringConstants";
// import EmployeeImportModal from "components/Modal/EmployeeImportModal";
import ImportErrorModal from "components/Modal/ImportErrorModal";
import LocationImportModal from "components/Modal/LocationImportModal";
import PersonalizationModal from "components/Modal/PersonalizationModal";
import MainTable from "components/Table/MainTable";
import { LOCATION_DROPDOWN_OPTIONS, MESSAGE_MODES, SUB_AGENT_HEADERS, TABLE_QUICK_TOOLS } from "constant";
import { LOCATION_PERSONALIZE } from "constants/personalization";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { useDispatch } from "react-redux";
import { setMessage } from "store/features/general/generalAction";
import {
  downloadDataAsCSV,
  formatNumber,
  getValidReg,
  importPhoneNoFormat,
  isValidEmail,
  personalizationLocalStorage,
  tdLink,
  validatePhone,
} from "utils";
import Icon from "../components/Icon";

const Locations = () => {
  const appContext = useContext(AppContext);
  const [locations, setLocations] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [selectItem, setSelectItem] = useState(newLocationObject);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const [triggerFilter, setTriggerFilter] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [showCopyText, setShowCopyText] = useState("");
  const [personalize, setPersonalize] = useState([]);
  const [openPersonalizationModal, setOpenPersonalizationModal] = useState(false);
  const [orders, setOrders] = useState([]);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [openErrModal, setOpenErrModal] = useState(false);
  const [title, setTitle] = useState("");
  const [errorData, setErrorData] = useState([]);
  const [successData, setsuccessData] = useState([]);
  const dispatch = useDispatch();
  const quickTools = [TABLE_QUICK_TOOLS.edit];

  const searchTerms = ["Name", "Contact Name", "Contact Email", "Contact Phone"];

  const currentItems = (locs) => {
    if (!locs || locs.length === 0) return [];
    const indexOfLastUser = currentPage * PAGE_LIMIT;
    const indexOfFirstUser = indexOfLastUser - PAGE_LIMIT;
    return locs.slice(indexOfFirstUser, indexOfLastUser);
  };

  let locationsToMap = currentItems(filteredLocations);

  const sortData = (schs, sortParam) => {
    if (sortDescending) {
      return [...schs].sort((a, b) => (a[sortParam] < b[sortParam] ? 1 : b[sortParam] < a[sortParam] ? -1 : 0));
    } else {
      return [...schs].sort((a, b) => (b[sortParam] < a[sortParam] ? 1 : a[sortParam] < b[sortParam] ? -1 : 0));
    }
  };

  useEffect(() => {
    const sortLS = sortingFilterInLC.get();
    if (sortLS.facilities && sortLS.facilities.sortBy) {
      setSortBy(sortLS.facilities.sortBy);
      setSortDescending(sortLS.facilities.sortDescending);
    }
    // if (sortLS.facilities && sortLS.facilities.filter) {
    //   setFilter(sortLS.facilities.filter);
    // }
    setPersonalize(personalizationLocalStorage.get(appContext.user, "facility", LOCATION_PERSONALIZE));
  }, [appContext?.company]);

  const getPageNumbers = (users) => {
    const pageNumbers = users.length > PAGE_LIMIT ? Math.ceil(users.length / PAGE_LIMIT) : 1;
    return pageNumbers;
  };

  let pageNumbers = getPageNumbers(locations);

  if (filteredLocations && filteredLocations.length > 0) {
    pageNumbers = getPageNumbers(filteredLocations);
  }

  const getOrders = async () => {
    let userType = appContext.user.isUser() ? USER_TYPE_USER : USER_TYPE_ADMIN;
    let id = null;
    if (appContext.user.isUser()) {
      id = appContext.employeeRecord?.id;
      if (!id) {
        const empRecord = await API.getLoggedInUserEmployeeID(null, appContext.user.preferred_username);
        if (empRecord) {
          id = empRecord.id;
        }
      }
    } else if (appContext.user.isClient()) {
      userType = USER_TYPE_CLIENT;
    } else if (appContext.user.isSubAgent()) {
      userType = USER_TYPE_SUBAGENT;
    }

    const data = await API.getOrders(userType, id);
    const formatttedOrders = data.map((o) => formatOrder(o));
    setOrders(formatttedOrders);
  };

  useEffect(() => {
    getOrders();
  }, []);

  useEffect(() => {
    if (appContext.locations && appContext.locations.length > 0) {
      formatFacility();
    }
    // setLocations(
    //   appContext.locations?.filter((f) => f.id !== CONFIG.generalFacility)
    // );
    // setFilteredLocations(
    //   appContext.locations?.filter((f) => f.id !== CONFIG.generalFacility)
    // );
  }, [appContext.locations, orders]);

  const formatFacility = () => {
    const facilities = appContext.locations?.filter((f) => f.id !== CONFIG.generalFacility);
    // const data = orders
    //   .filter((f) => f.siteID)
    //   .reduce(
    //     (obj, order) => {
    //       obj["generic"][order.siteID] =
    //         (obj["generic"][order.siteID] || 0) + order.testQty;
    //       if (moment(order.createdAt).isSame(new Date(), "month")) {
    //         obj["monthToDate"][order.siteID] =
    //           (obj["monthToDate"][order.siteID] || 0) + order.testQty;
    //       }
    //       return obj;
    //     },
    //     { generic: {}, monthToDate: {} }
    //   );

    // const locData = facilities.map((t) => {
    //   return {
    //     ...t,
    //     totalOrders: data["generic"][t.id] || 0,
    //     yearToDate: data["generic"][t.id] || 0,
    //     monthToDate: data["monthToDate"][t.id] || 0,
    //   };
    // });
    setLocations(facilities);
    setFilteredLocations(sortData(nestedFilter(facilities, filter), sortBy));
  };

  const handleSaveFilter = () => {
    const pervSortLS = sortingFilterInLC.get();
    pervSortLS.facilities = { sortBy, sortDescending, filter };
    sortingFilterInLC.save(pervSortLS);
  };

  useEffect(() => {
    if (locations.length > 0) {
      setFilteredLocations(sortData(nestedFilter(locations, filter), sortBy));
      setCurrentPage(1);
    }
  }, [filter]);

  useEffect(() => {
    if (filteredLocations.length > 0) {
      handleSaveFilter();
      setFilteredLocations(sortData(filteredLocations, sortBy));
    }
  }, [sortBy, sortDescending]);

  const handleConfirmDelete = async (isConfirm) => {
    if (!isConfirm) {
      setItemToDelete(null);
      return;
    }
    try {
      setLoading(true);
      const isDeleted = await API.deleteLocation(itemToDelete.id);
      if (isDeleted) {
        // await API.deleteUser(itemToDelete.phone_number);
        appContext.resetLocations();
        dispatch(setMessage("Facility/Agent deleted successfully", MESSAGE_MODES.success));
      } else {
        dispatch(
          setMessage("Facility/Agent can't be deleted becuase few members are linked with it", MESSAGE_MODES.success)
        );
      }
      setItemToDelete(null);
    } catch (error) {
      dispatch(setMessage(error.message, MESSAGE_MODES.error));
    }
    setLoading(false);
  };

  const OnHandleLocation = async (loc) => {
    const findVal = locations.find((f) => loc.id !== f.id && checkValidity(f.name) == checkValidity(loc.name));
    if (findVal) {
      dispatch(setMessage("Facility/Agent already exist", MESSAGE_MODES.error));
      return;
    }
    setOpenCreateModal(false);

    try {
      setLoading(true);

      if (loc.isNew) {
        const res = await API.newLocations(loc, appContext.user);
        if (res) {
          // const userCreated = await createUser({ ...res, ...loc });
          // if (userCreated) {

          // } else {
          //   await API.deleteLocation(res.id);
          // }
          dispatch(setMessage("Facility/Agent Created Successfully", MESSAGE_MODES.success));
        }
      } else {
        await API.updateLocation(loc);
        // const filterMembers = appContext.employees?.filter((f) => f.companyID === loc.id);

        // if (filterMembers.length > 0) {
        //   await API.updateMemberTestKit(filterMembers, loc);
        // }
        dispatch(setMessage("Facility/Agent Updated Successfully", MESSAGE_MODES.success));
        appContext.resetEmployees();
      }
      appContext.resetLocations();
      setSelectItem(newLocationObject);
      setLoading(false);
    } catch (err) {
      console.log("Error", err);
      dispatch(setMessage(error.message, MESSAGE_MODES.error));
    }
  };

  const createUser = async (newUser) => {
    const firstName = newUser.contact_name.split(" ")[0] || "";
    const lastName = newUser.contact_name.split(" ")[1] || "";
    const attributePayload = {
      preferred_username: newUser.phone_number,
      email: newUser.contact_email,
      phone_number: newUser.phone_number,
      "custom:role": "Employers",
      "custom:autoConfirm": "true",
      "custom:firstName": `${firstName}`,
      "custom:lastName": `${lastName}`,
      "custom:note": `${newUser.contact_name}`,
      "custom:labID": newUser.id,
      "custom:personalisation": JSON.stringify({
        personalize: "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17",
      }),
    };

    try {
      const signUp = await Auth.signUp({
        username: newUser.phone_number,
        password: newUser.password,
        attributes: attributePayload,
      });
      if (signUp) {
        const message = emailMsgToCreatedFacility(
          newUser.contact_name,
          newUser.phone_number.replaceAll(/[^0-9]/gi, ""),
          newUser.password
        );
        await API.sendEmail([
          {
            email: newUser.contact_email,
            subject: "SafeCamp LTC Team",
            msg: message,
          },
        ]);
      }
      return true;
    } catch (error) {
      console.log("ERROR createUser: ", error);
      dispatch(setMessage(error.message, MESSAGE_MODES.error));
      return false;
    }
  };

  const handleEdit = (item) => {
    const client = appContext.getClientById(item.clientID);
    setSelectItem({ ...item, isNew: false, client });
    setOpenCreateModal(true);
  };

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  const downloadQRCode = (site) => {
    const canvas = document.getElementById("qr-gen");
    const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${site.name}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  const handleCopyPRLink = (id) => {
    navigator.clipboard.writeText(getPreRegistrationLink(id));
    setShowCopyText(id);
    setTimeout(() => setShowCopyText(null), 2000);
  };

  const nestedFilter = (targetArray, filters) => {
    if (Object.keys(filters).length === 0) return targetArray;
    const filterKeys = Object.keys(filters);

    const models = targetArray.filter((obj) => {
      return filterKeys.every((key) => {
        if (!filters[key].length) {
          return true;
        }

        return obj[key] && obj[key].toLowerCase().includes(filters[key]);
      });
    });
    return models;
  };

  const handleChangePage = (number) => {
    setCurrentPage(number);
  };

  const handlePersonalization = async (data) => {
    setPersonalize([...data]);
    setOpenPersonalizationModal(false);
    const personalisationData = personalizationLocalStorage.saveAs(data, "facility");
    personalizationLocalStorage.save(JSON.stringify(personalisationData));
    await API.udpatePersonalization(appContext.user.phone_number, personalisationData);
  };

  const parseClient = (item) => {
    if (item.client) item.client.name;
    if (!item.clientID) return "N/A";

    if (appContext.companies) {
      const client = appContext.companies.find((c) => c.id === item.clientID);
      if (client) {
        return client.name;
      }
    }
    return "N/A";
  };

  const handleImport = async (locData, clientID) => {
    if (locData.length === 0) {
      dispatch(setMessage(t("emptyFile"), MESSAGE_MODES.error));
      setOpenImportModal(false);
      return;
    }
    if (!clientID) {
      dispatch(setMessage("Client was not selected", MESSAGE_MODES.error));
      return;
    }
    const client = appContext.getClientById(clientID);
    const arr = [];
    const arrdata = [];
    const locList = [...locations];
    const locationKeysArr = Object.keys(locData[0].data);
    if (locationKeysArr.length > 0) {
      let err = false;
      SUB_AGENT_HEADERS.forEach((f, i) => {
        if (!locationKeysArr.includes(f)) {
          dispatch(setMessage(`Column Header must be ${f}`, MESSAGE_MODES.error));
          setOpenImportModal(false);
          err = true;
          return;
        }
      });
      if (err) return;
    }

    setOpenImportModal(false);
    setLoading(true);
    const ttlLength = locData.length;
    for (let index = 0; index < ttlLength; index++) {
      const { data } = locData[index];
      const loc = {
        ...data,
        client,
        clientID: client.id,
        testLimit: client.testLimit,
        minTestOrderQty: client.minTestOrderQty,
      };

      if (Object.values(data).every((c) => c.length == 0)) continue;
      loc.phone_number = importPhoneNoFormat(loc.main_phone_number);
      loc.contactName = importPhoneNoFormat(loc.phoneNumber);
      loc.contact_name = `${loc.contact_first_name || ""} ${loc.contact_last_name || ""}`.trim();

      if (loc.name) {
        loc.name = getValidReg(loc.name);
        const findVal = locList.find((f) => checkValidity(f.name) == checkValidity(loc.name));

        if (findVal) {
          arr.push({
            message: `Facility/Agent record already exists on row ${index + 2}`,
            data: loc,
          });
          continue;
        }
      } else {
        arr.push({
          message: `name is required on row ${index + 2}`,
          data: loc,
        });
        continue;
      }

      if (!loc.main_phone_number) {
        arr.push({
          message: `main_phone_number is required on row ${index + 2}.`,
          data: loc,
        });
        continue;
      }

      const phone = formatPhoneNumberIntl(loc.main_phone_number);

      const phoneArr = phone.split(" ");
      loc.countryCode = phoneArr[0];

      if (loc.main_phone_number && !validatePhone(loc.phone_number)) {
        arr.push({
          message: `main_phone_number is invalid ${loc.main_phone_number} on row ${index + 2}`,
          data: loc,
        });
        continue;
      }
      if (!loc.contact_first_name) {
        arr.push({
          message: `contact_first_name is required on row ${index + 2}.`,
          data: loc,
        });
        continue;
      }
      if (!loc.contact_last_name) {
        arr.push({
          message: `contact_last_name is required on row ${index + 2}.`,
          data: loc,
        });
        continue;
      }

      if (!loc.contact_email) {
        arr.push({
          message: `contact_email is required on row ${index + 2}.`,
          data: loc,
        });
        continue;
      }

      if (loc.contact_email && !isValidEmail(loc.contact_email)) {
        arr.push({
          message: `Invalid contact_email ${loc.contact_email} on row ${index + 2}.`,
          data: loc,
        });
        continue;
      }

      locList.push(loc);
      (await API.newLocations(loc, appContext.user)) && arrdata.push("Success");
    }

    setOpenImportModal(false);
    if (arrdata.length == 0 && arr.length === 0) {
      dispatch(setMessage(t("emptyFile"), MESSAGE_MODES.error));
      setLoading(false);
      return;
    }
    appContext.resetLocations();
    setLoading(false);
    if (arr.length > 0 || arrdata.length > 0) {
      setErrorData(arr);
      setTitle("Facility/Agent");
      setsuccessData(arrdata);
      setOpenErrModal(true);
    }
  };

  const handleDownloadSample = async () => {
    await downloadDataAsCSV(
      // departments.map((d) => {
      //   return {
      //     name: d.name,
      //     callTime: formatCallTime(d.callTime),
      //     deptTimeZone: formatTimeZone(d.deptTimeZone),
      //     reminderOne: d.reminderOne,
      //     reminderTwo: d.reminderTwo,
      //   };
      // }),
      [
        {
          name: "",
          street: "",
          street2: "",
          city: "",
          state: "",
          zip: "",
          phoneNumber: "",
          webSite: "",
          contact_first_name: "",
          contact_last_name: "",
          contact_email: "",
          main_phone_number: "",
        },
      ],
      t("locCsvSampleName")
    );
  };

  const tdPreRegisteration = (user) => {
    return (
      <td className="ellipsis position-relative" style={{ textAlign: "center" }}>
        {user.preRegistration && (
          <div className="d-flex align-items-center justify-content-evenly qr-code-download">
            {showCopyText && showCopyText === user.id && (
              <div className="copy-link-text clipBoard-copy">Copied to clipboard!</div>
            )}
            <div onClick={() => handleCopyPRLink(user.id)}>
              <i className="fas fa-copy maroon" />
            </div>
            <div onClick={() => downloadQRCode(user)}>
              <i className="fas fa-download maroon"></i>
            </div>
            <span style={{ display: "none" }}>
              <QRCode
                id="qr-gen"
                ecLevel={"H"}
                value={getPreRegistrationLink(user.id)}
                enableCORS={true}
                logoImage={logo}
                size="250"
                logoHeight={"70"}
                logoWidth={"70"}
                fgColor={"#000"}
                bgColor={"#fff"}
              />
            </span>
          </div>
        )}
      </td>
    );
  };

  const tdFormat = (item, user) => {
    if (item === "testOrderCategory") return TEST_ORDER_LIMIT_CATEGORY_VALUE[user.testOrderCategory];
    return user[item];
  };

  const renderTd = (item, user) => {
    if (item.itemKey === "monthToDate") return tdLink(user.monthToDate, "month", user.id, "siteID");
    if (item.itemKey === "yearToDate") return tdLink(user.yearToDate, "year", user.id, "siteID");
    if (item.itemKey === "totalOrders") return tdLink(user.totalOrders, "", user.id, "siteID");
    if (item.itemKey === "preRegisterationLink") return tdPreRegisteration(user);
    return (
      <td
        className="ellipsis"
        style={{
          textAlign: item.textAlign,
          textOverflow: item.textOverflow,
        }}
        title={tdFormat(item.itemKey, user)}
      >
        {tdFormat(item.itemKey, user)}
      </td>
    );
  };

  const handleCellClick = (key, row, event) => {
    switch (key) {
      case TABLE_QUICK_TOOLS.edit:
        if (row.id !== "596daf9b-8f9d-451c-a28f-a458bac57ce4" && row.id !== "a081f285-4949-45bd-9516-ba2ff3e8d0b1") {
          handleEdit(row);
        }
        break;

      default:
        break;
    }
  };

  return (
    <div style={{ flex: 1 }}>
      <Row>
        <Col md="12">
          {!loading ? (
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                  }}
                >
                  {t("facilities")} ({filteredLocations ? formatNumber(filteredLocations.length) : 0})
                </Card.Title>
                <div className="buttonHeader">
                  <Icon
                    handleClick={() => setShowFilter(!showFilter)}
                    title={"Filter"}
                    label={"Filter"}
                    iconType={"filter"}
                  />

                  <Icon
                    handleClick={() => {
                      const obj = { ...newLocationObject };
                      if (appContext.user?.isClient()) {
                        Object.assign(obj, {
                          client: appContext.companies[0],
                          clientID: appContext.companies[0]?.id,
                          testLimit: appContext.companies[0]?.testLimit,
                          minTestOrderQty: appContext.companies[0]?.minTestOrderQty,
                        });
                      } else {
                        const client = appContext.companies.find((f) => f.id === CONFIG.generalClient);
                        Object.assign(obj, {
                          client,
                          clientID: client?.id,
                          testLimit: client?.testLimit,
                          minTestOrderQty: client?.minTestOrderQty,
                        });
                      }
                      setSelectItem(obj);

                      setOpenCreateModal(true);
                    }}
                    label={"Create"}
                    title={"Create Facility"}
                    iconType={"addUserIcon"}
                  />

                  {/* <Icon
                    handleClick={() => {
                      setOpenImportModal(true);
                    }}
                    title={"Import Facility/Agent"}
                    iconType="importIcon"
                    label="Import"
                  /> */}

                  {/* <Icon
                    handleClick={handleDownloadSample}
                    title={"Download Csv Sample"}
                    iconType="downloadIcon"
                    label="Download"
                  /> */}

                  <Icon
                    handleClick={() => setOpenPersonalizationModal(true)}
                    title={t("personalize")}
                    iconType="personalizeIcon"
                    label={t("personalize")}
                  />
                </div>
                {showFilter && (
                  <Filter
                    filterTerms={searchTerms}
                    setFilter={setFilter}
                    filter={filter}
                    triggerFilter={triggerFilter}
                    setTriggerFilter={setTriggerFilter}
                  />
                )}
                <MFPagination currentPage={currentPage} handleChangePage={handleChangePage} totalPages={pageNumbers} />
              </Card.Header>
              <Card.Body className="table-full-width desktop-noScroll">
                <MainTable
                  cssClass={locations.length === 0 && "overFlow-y-hidden"}
                  columns={personalize}
                  rows={locationsToMap}
                  flipSort={flipSort}
                  sortBy={sortBy}
                  sortDescending={sortDescending}
                  widthToSkip={160}
                  tools={quickTools}
                  dropDownOptions={LOCATION_DROPDOWN_OPTIONS}
                  handleDropDownClick={(type, row) => {
                    if (type === "delete") {
                      if (
                        row.id !== "596daf9b-8f9d-451c-a28f-a458bac57ce4" &&
                        row.id !== "a081f285-4949-45bd-9516-ba2ff3e8d0b1"
                      ) {
                        setItemToDelete(row);
                      }
                    }
                  }}
                  handleCellClick={handleCellClick}
                  customColumnCellRenderer={renderTd}
                />
              </Card.Body>
            </Card>
          ) : (
            <Loader />
          )}

          {itemToDelete && (
            <ConfirmationModal
              show={itemToDelete ? true : false}
              title="Delete Facility/Agent"
              message="Are you sure, you want to remove Facility/Agent?"
              handleConfirm={handleConfirmDelete}
            />
          )}
          {openCreateModal && (
            <LocationModal
              handleClose={() => {
                setSelectItem(newLocationObject), setOpenCreateModal(false);
              }}
              selectItem={selectItem}
              handleSave={OnHandleLocation}
              appContext={appContext}
            />
          )}

          {openPersonalizationModal && (
            <PersonalizationModal
              data={JSON.stringify(personalize)}
              handleChange={handlePersonalization}
              show={openPersonalizationModal}
              handleClose={() => setOpenPersonalizationModal(false)}
            />
          )}
          {openImportModal && (
            <LocationImportModal
              handleClose={() => setOpenImportModal(false)}
              handleImport={handleImport}
              appContext={appContext}
            />
          )}

          {openErrModal && (
            <ImportErrorModal
              location
              title={title}
              errData={errorData}
              successData={successData}
              handleClose={async () => {
                setOpenErrModal(false);
                setErrorData([]);
                setsuccessData([]);
                setTitle("");
                await appContext.resetLocations();
              }}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Locations;
