import React, { useState, useEffect, useCallback } from "react";
import Select from "react-select";
import { getValidName, toTitleCase } from "../../utils";
import { useDispatch } from "react-redux";
import { debounce } from "debounce";
import api from "api";
import { fetchDocuemntTypesAsync } from "store/features/documents/documentSlice";
import { createDocuemntTypesAsync } from "store/features/documents/documentSlice";

const EditableDropDown = (props) => {
  const { title, data, selectedOptionValue, selectedOptionLabel, onOptionChange, addNewItem } = props;
  const dispatch = useDispatch();
  const getLabelOrValue = () => {
    if (!data) return "";

    let item = [];
    if (selectedOptionValue) {
      item = data.filter((f) => f.value === selectedOptionValue)?.[0]?.label;
    }

    if (selectedOptionLabel) {
      item = data.filter((f) => f.label === selectedOptionValue)?.[0]?.value;
    }

    if (item) {
      return item;
    }
    return "";
  };

  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState(
    selectedOptionValue
      ? { value: selectedOptionValue, label: getLabelOrValue() }
      : selectedOptionLabel
      ? { value: getLabelOrValue(), label: selectedOptionLabel }
      : null
  );

  useEffect(() => {
    setOptions(data);
  }, [data]);

  useEffect(() => {
    dispatch(fetchDocuemntTypesAsync({ title: "" }));
  }, []);

  const handleTypeDelay = useCallback(
    debounce((data) => {
      handleChangeInputText(data);
    }, 500),
    []
  );

  const handleChangeInputText = async (title) => {
    setOptions(options.filter((f) => f.label.includes(title)));
  };

  let changeValue = true;
  const handleOnChange = (e) => {
    changeValue = false;
    setSelectedValue(e);
    if (onOptionChange) {
      onOptionChange(e);
    }
  };

  const handleChangeSelectInput = (e, r) => {
    const newValue = getValidName(r.prevInputValue);
    if (r.action === "input-change") {
      changeValue = true;
    }
    if (r.action === "input-blur" && newValue && changeValue) {
      setSelectedValue({ value: newValue, label: newValue });
      if (onOptionChange) {
        onOptionChange({ value: newValue, label: newValue });
      }
    }
  };

  const addNewDocument = async (title) => {
    dispatch(createDocuemntTypesAsync({ title }));
  };

  return (
    <Select
      options={options}
      blurInputOnSelect={true}
      menuPlacement="auto"
      placeholder={title}
      className="w-100"
      onInputChange={handleChangeSelectInput}
      value={selectedValue}
      onChange={handleOnChange}
    />
  );
};

export default EditableDropDown;
