import React, { useEffect, useState } from "react";
import { currencyformatter } from "constant";
import { Form } from "react-bootstrap";
import { formatDateMDY } from "utils";
import exclamationMarkIcon from "../../../../../assets/img/exclamation-mark.svg";

const ChargeInfoRow = ({ chargeData, index, chargeIndex, handleChargeEdit, checkboxes, handleCheckboxes, isClaimCpt }) => {
  const [charge, setCharge] = useState(chargeData || null);
  const [editable, setEditable] = useState({ row: null, column: null });

  useEffect(() => {
    formatChargeListView(chargeData);
  }, [chargeData]);

  const handleEdit = (row, column) => {
    if (column === "allowed") return;
    setEditable({ row, column });
  };

  const formatChargeListView = (chargeData) => {
    const adjustedAmount = chargeData.adjustment?.reduce((prev, ad) => {
      if (
        (ad.code === "45" && ad.group == "CO") ||
        (ad.code === "253" && ad.group == "CO") ||
        (ad.code === "45" && ad.group == "PR")
      ) {
        return parseFloat(ad.amount) + prev;
      }
    }, 0);

    const deductible = chargeData.adjustment?.reduce((prev, ad) => {
      if (ad.code === "1" && ad.group == "PR") {
        return parseFloat(ad.amount) + prev;
      }
    }, 0);

    const coinsurance = chargeData.adjustment?.reduce((prev, ad) => {
      if (ad.code === "2" && ad.group == "PR") {
        return parseFloat(ad.amount) + prev;
      }
    }, 0);

    const copay = chargeData.adjustment?.reduce((prev, ad) => {
      if (ad.code === "3" && ad.group == "PR") {
        return parseFloat(ad.amount) + prev;
      }
    }, 0);

    setCharge({ ...chargeData, adjustedAmount });
  };

  const columns = ["allowed", "deductible", "co_insurance", "copay", "write_off", "paid"];
  const handleSave = () => {
    // if (editable.column === "paid_amount") {
    //   checkAmountEquality();
    // }
    setEditable({ row: null, column: null });
  };
  const handleInputChange = (column, inputValue) => {
    if (/^-?(?!0\d*$)\d*\.?\d*$/.test(inputValue)) {
      handleChargeEdit(index, chargeIndex, column, inputValue, chargeData?.chgid, chargeData);
    }
  };

  return (
    <>
      <tr key={index} className="expended-row">
        <td className="text-center p-2">
          {handleCheckboxes && (
            <Form.Check>
              <Form.Check.Input
                className="border-dark"
                type="checkbox"
                checked={checkboxes.includes(chargeData.chgid)}
                value={chargeData.chgid}
                onChange={(event) => handleCheckboxes(event, chargeData.chgid)}
              />
            </Form.Check>
          )}
        </td>
        <td className="text-center p-2">{formatDateMDY(chargeData.from_dos)}</td>
        <td className="text-center p-2">{chargeData?.proc_code} {!isClaimCpt && <span title="CPT Code is not in claim list" ><img src={exclamationMarkIcon} className="era-exclamation-mark" /></span> }</td>
        <td className="text-center p-2 ellipsis" title={chargeData?.proc_desc || ""}>
          {chargeData?.proc_desc || ""}
        </td>
        <td className="text-center p-2">{currencyformatter.format(chargeData.charge || 0)}</td>
        {columns.map((column) => (
          <td
            className="text-center p-2 custom-height  custom-width"
            key={column}
            onClick={() => handleEdit(chargeIndex, column)}
          >
            {editable.row === chargeIndex && editable.column === column ? (
              <input
                className="custom-input"
                type="text"
                value={chargeData[column] || ""}
                onBlur={handleSave}
                onChange={(e) => handleInputChange(column, e.target.value)}
                placeholder="$0.00"
                autoFocus
              />
            ) : (
              currencyformatter.format(chargeData[column] || 0)
            )}
          </td>
        ))}
        {/* <td className="text-center p-2">{currencyformatter.format(charge.allowed || 0)}</td>
        <td className="text-center p-2">{currencyformatter.format(charge.deductible || 0)}</td>
        <td className="text-center p-2">{currencyformatter.format(charge.coinsurance || 0)}</td>
        <td className="text-center p-2">{currencyformatter.format(charge.copay || 0)}</td>
        <td className="text-center p-2">{currencyformatter.format(charge.adjustedAmount || 0)}</td>
        <td className="text-center p-2">{currencyformatter.format(charge.paid || 0)}</td> */}
      </tr>
    </>
  );
};

export default ChargeInfoRow;
