import { Button, Card, Col, Modal, Row, Table } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { t } from "stringConstants";
import { draggablePersonalizationLocalStorage } from "utils";
import { invoiceDocsSelector } from "store/features/invoiceDocuments/invoiceDocumentSelectors";
import { setInvoiceDocs } from "store/features/invoiceDocuments/invoiceDocumentSlice";
import { Storage } from "aws-amplify";
import { authSelector, selectLoginUser } from "store/features/authentication/authenticationSelectors";
import api from "api";
import { documentSelector } from "store/features/documents/documentSelectors";
import { fetchDocuemntAsync } from "store/features/documents/documentSlice";
import MainTable from "components/Table/MainTable";
import { DOCUMENT_PERSONALIZATION } from "constants/personalization";
import PersonalizationModal from "components/Modal/PersonalizationModal";
import InvoiceDocumentForm from "./InvoiceDocumentForm";
import Icon from "components/Icon";
import { saveAs } from "file-saver";
import { CONFIG } from "constant";
import { setLoader } from "store/features/general/generalSlice";
import { Link } from "react-router-dom";
import { TABLE_QUICK_TOOLS } from "constant";
import InvoiceDocumentList from "./InvoiceDocumentList";

const InvoiceDocumentModal = (props) => {
  const { handleClose, emp, cssClass } = props;
  const [personalize, setPersonalize] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [selectedDocument, setSelectedDocument] = useState({ isNew: true });
  const [documents, setDocuments] = useState([]);
  const [openPersonalizationModal, setOpenPersonalizationModal] = useState(false);

  const loginUser = useSelector(selectLoginUser);
  const invoiceDocs = useSelector(invoiceDocsSelector);
  const dispatch = useDispatch();

  console.log("invoiceDocs", invoiceDocs);

  const quickTools = [TABLE_QUICK_TOOLS.edit];
  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  useEffect(() => {
    if (emp?.id) {
      dispatch(fetchDocuemntAsync({ employeeID: emp.id }));
    }
  }, [emp]);

  const handleDownloadDoc = async (doc) => {
    try {
      dispatch(setLoader(true));
      const resut = await Storage.get(doc.fileName, {
        bucket: CONFIG.updateBucket,
      });
      const response = await fetch(resut);
      const blob = await response.blob();

      console.log("fileMetadata", blob.size);

      saveAs(blob, `${doc.fileName}`);
      dispatch(setLoader(false));
    } catch (err) {
      console.log("Error", err);
      dispatch(setLoader(false));
    }
  };

  return (
    <Modal
      show
      backdrop="static"
      animation={true}
      className={cssClass ?? ""}
      onHide={() => handleClose()}
      centered
      size={"lg"}
      keyboard={false}
    >
      <Modal.Header closeButton>
        {/* <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          Search Provider List
        </Modal.Title> */}
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col xs={6} md={6} lg={6}>
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                  }}
                >
                  Document Files List
                </Card.Title>
              </Card.Header>

              <Card.Body className="table-full-width desktop-noScroll">
                <InvoiceDocumentList
                  list={invoiceDocs}
                  handleEdit={(obj) => setSelectedDocument({ ...obj, isNew: false })}
                  handleDocDeleted={(obj) => {
                    dispatch(setInvoiceDocs(invoiceDocs.filter((f) => f.fileName !== obj.fileName)));
                    if (selectedDocument.fileName === obj.fileName) setSelectedDocument({ isNew: true });
                  }}
                />
              </Card.Body>
            </Card>
          </Col>
          <Col xs={6} md={6} lg={6}>
            <InvoiceDocumentForm
              emp={emp}
              document={selectedDocument}
              setDocuments={setDocuments}
              handleClose={handleClose}
            />
          </Col>
        </Row>
      </Modal.Body>
      {openPersonalizationModal && (
        <PersonalizationModal
          cssClass={cssClass}
          data={JSON.stringify(personalize)}
          handleChange={handlePersonalization}
          show={openPersonalizationModal}
          handleClose={() => setOpenPersonalizationModal(false)}
        />
      )}
    </Modal>
  );
};

export default InvoiceDocumentModal;
