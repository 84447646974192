import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import styles from 'components/InputField/InputStyle.module.css';

const InputSelectDropDown = (props) => {
  const {
    label,
    type = 'text',
    placeholder,
    handleChange,
    labelStyle,
    inputStyle,
    value,
    maxlength,
    groupWrapper,
    optionList,
    readOnly,
    setSelectedEmployee
  } = props;



  const [inputValue, setInputValue] = useState('');
  const [showOptions, setShowOptions] = useState(false); 

  
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleInputChange = (e) => {
    const val = e.target.value;
    setInputValue(val); 
    handleChange(val); 
    setShowOptions(true); 
  };

  const handleSelectInput = (option) => {
    const fullName = `${option.firstName} ${option.lastName}`;
    setInputValue(fullName); 
    handleChange(fullName); 
    setShowOptions(false);
    setSelectedEmployee(option)
  };

  const optionRow = (option, index) => {
    return (
      <li
        key={index}
        className="listOption"
        onClick={() => handleSelectInput(option)}
        title={`${option.firstName} ${option.lastName}`}
      >
        <span className="fw-bold optListContent">{`${option.firstName} ${option.lastName}`}</span>
      </li>
    );
  };

  return (
    <Form.Group className={`form-group-wrapper ${groupWrapper}`}>
      {label && <Form.Label className={`${styles.inputLabel} ${labelStyle}`}>{label}</Form.Label>}
      <div className="position-relative w-100">
        <Form.Control
          type={type}
          maxLength={maxlength}
          placeholder={placeholder}
          className={`${styles.inputStyling} ${inputStyle}`}
          value={inputValue}
          readOnly={readOnly}
          onChange={handleInputChange}
        />
        {inputValue && !readOnly && (
          <i
            className="fas fa-times cancelSearchInput"
            onClick={() => {
              setInputValue('');
              handleChange('');
              setShowOptions(false); // Optionally hide options on clear
            }}
          />
        )}
        {showOptions && optionList && !readOnly && optionList.length > 0 && (
          <ul className="optionListWrapper">
            {optionList.map((item, index) => optionRow(item, index))}
          </ul>
        )}
      </div>
    </Form.Group>
  );
};

export default InputSelectDropDown;