import React, { useState, useMemo, useEffect } from "react";
import { formatCurrency } from "utils";
import { CUSTOM_INS } from "constant";
import { CPT_STATUS_OPT } from "constant";
import DropdownMenu from "components/DropDownMenu";

const ChargeInfoRow = ({ chargeData, claimIndex, chargeIndex, handleChargeEdit, data, realCharge }) => {
  const [editable, setEditable] = useState({ row: null, column: null });

  const detailsColumns = ["allowed", "deductible", "co_insurance", "copay", "write_off", "paid"];

  const handleTabPress = (e, column) => {
    if (e.key === "Tab") {
      // Handle Tab press manually
      e.preventDefault();
      // Determine the direction of tabbing based on the Shift key
      const isShiftPressed = e.shiftKey;
      let step = isShiftPressed ? -1 : 1;

      // Set focus to the next (or previous) input field or perform other actions
      const currentTabIndex = parseInt(e.target.tabIndex);
      if (currentTabIndex % 10 === 6 && step > 0) {
        step = step + 4;
      }
      const nextTabIndex = currentTabIndex + step;

      const nextInput = document.querySelector(`[tabindex="${nextTabIndex}"]`);
      if (nextInput) {
        nextInput.focus();
        // handleEdit(chargeIndex, column);
      }
    }
  };

  const handleEdit = (row, column) => {
    if (column === "allowed") return;
    if (CUSTOM_INS.includes(data.payerid) && detailsColumns.filter((f) => f !== "write_off").includes(column)) return;
    setEditable({ row, column });
  };

  const handleInputChange = (column, inputValue) => {
    if (/^-?(?!0\d*$)\d*\.?\d*$/.test(inputValue) && column !== "allowed") {
      handleChargeEdit(claimIndex, chargeIndex, column, inputValue, chargeData?.id, chargeData);
    }
  };

  const handleSave = () => {
    setEditable({ row: null, column: null });
  };

  const columnsDisplay = useMemo(() => {
    let cloneColumnsDisp = [...detailsColumns];

    if (CUSTOM_INS.includes(data.payerid)) {
      cloneColumnsDisp.push("patient_amount");
    }
    return cloneColumnsDisp;
  }, [detailsColumns]);

  return (
    <tr key={chargeIndex} className="expended-row">
      <td></td>
      <td className="text-center ">{chargeData?.proc_code || ""}</td>
      <td className="text-center ellipsis" title={chargeData?.proc_name || ""}>
        {chargeData?.proc_name || ""}
      </td>
      <td className="text-center  custom-height">{formatCurrency(chargeData?.charge || 0)}</td>
      {columnsDisplay.map((column, index) => (
        <td
          className="text-center  custom-height"
          key={column}
          onClick={() => handleEdit(chargeIndex, column)}
          tabIndex={`${chargeIndex + 1}${index + 1}`}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleEdit(chargeIndex, column);
            }
          }}
          // style={{
          //   color: chargeData.error ? "red" : "",
          // }}
        >
          {editable.row === chargeIndex && editable.column === column ? (
            <input
              className="custom-input"
              type="text"
              value={chargeData[column] || ""}
              onChange={(e) => handleInputChange(column, e.target.value)}
              onBlur={handleSave}
              onKeyDown={(e) => handleTabPress(e, column)}
              placeholder="$0.00"
              autoFocus
              tabIndex={`${chargeIndex + 1}${index + 1}`}
            />
          ) : (
            <span>{formatCurrency(chargeData[column] || 0)}</span>
          )}
        </td>
      ))}
      {data.paid_insurance && <td className="text-center  custom-height">{formatCurrency(realCharge?.open || 0)}</td>}
      <td
        className="text-center  custom-height ellipsis"
        title={chargeData?.notes || ""}
        onClick={() => handleEdit(chargeIndex, "notes")}
      >
        {editable.row === chargeIndex && editable.column === "notes" ? (
          <input
            className="custom-input"
            type="text"
            value={chargeData?.notes || ""}
            onChange={(e) => handleChargeEdit(claimIndex, chargeIndex, "notes", e.target.value, chargeData?.id)}
            onBlur={handleSave}
            placeholder="note"
            autoFocus
          />
        ) : (
          chargeData.notes
        )}
      </td>
      <td style={{ borderBottom: "none", textAlign: "center" }} className="ellipsis">
        <select
          id={`${claimIndex}${chargeIndex + 1}`}
          className="claimStatusSelect statusPill custom-ellipsis"
          style={{
            border: "none",
            width: "135px",
            color: "black",
            textAlign: "center",
            padding: "5px",
          }}
          onChange={(e) => handleChargeEdit(claimIndex, chargeIndex, "status", e.target.value, chargeData?.id)}
          value={chargeData.status}
        >
          {CPT_STATUS_OPT.map(({ value, label }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </select>
      </td>
    </tr>
  );
};

export default ChargeInfoRow;
