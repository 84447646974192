import api from "api";
import Loader from "components/Loader/Loader";
import ErrorMessage from "components/Message/ErrorMessage";
import { INSURANCE_PROVIDER, MESSAGE_MODES } from "constant";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { setMessage } from "store/features/general/generalAction";
import { t } from "../../../stringConstants";

const ChangeOrderPayer = (props) => {
  const { handleClose, orders, appContext } = props;

  const [error, setError] = useState("");
  const [payer, setPayer] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleSave = async () => {
    try {
      if (!payer) {
        setError(t("insuranceProviderRequired"));
        return;
      }

      setLoading(true);
      await api.updateOrderInsurance(orders, payer, appContext.user);
      dispatch(setMessage("Update Data Successfully!", MESSAGE_MODES.error));
      handleClose(true);
      setLoading(false);
    } catch (err) {
      console.log("Error:-", err.message);
      dispatch(setMessage(err.message, MESSAGE_MODES.error));
      setLoading(false);
    }
  };

  return (
    <Modal
      show
      backdrop="static"
      // style={{ transform: "translate(0, -30%)" }}
      animation={true}
      onHide={() => {
        handleClose();
      }}
      centered
      size={"lg"}
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0 modal-title h4" id="contained-modal-title-vcenter">
          Change Order Payer
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="col-lg-12 col-12 mb-3">
          <label className="modalLineHeaders mt-0">{t("insuranceProvider")}:</label>
          <Select
            options={INSURANCE_PROVIDER}
            blurInputOnSelect={true}
            menuPlacement="auto"
            placeholder={t("selectinsuranceProvider")}
            className="w-100"
            onChange={(e) => setPayer(e)}
          />
        </div>
      </Modal.Body>
      {error && (
        <div className="ms-2">
          <ErrorMessage error={error} handleChange={() => setError("")} />
        </div>
      )}
      {loading && <Loader />}

      <Modal.Footer>
        <Button
          className="headerButton btn-fill"
          variant="primary"
          onClick={() => {
            handleClose(false);
          }}
        >
          Close
        </Button>
        <Button
          variant="secondary"
          className="headerButton btn-fill"
          onClick={() => {
            handleSave();
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangeOrderPayer;
