import { useState, useMemo, useEffect } from "react";
import { Button, Card, Table } from "react-bootstrap";
import ChargeInfoRow from "./ChargeInfoRow";
import { CLAIM_CODE_DETAIL_HEADER, CLAIM_SUBMIT_STATUS_LABELS, claimSubmitStatusColorOpts } from "constant";
import { formatDateMDY, tdRowDropdown, formatCurrency } from "utils";
import InputField from "components/InputField/InputField";
import { CUSTOM_INS } from "constant";
import { isAmountGreaterThanCharge } from "utils";
import { isAmountEqualToCharge } from "utils";
import HeaderItem from "components/Table/HeaderItem";
import { changeSort } from "utils";
import { INSURANCE_TYPES } from "constant";
import api from "api";
import Loader from "components/Loader/Loader";
import ProcedureInfoRow from "components/Modal/Order/components/ProcedureInfoSection/ProcedureInfoRow";
import { ERA_PROCEDURE_CODE_HEADER } from "constant";
import { processPaymentSelector } from "store/features/processPayment/processPaymentSelectors";
import { useDispatch, useSelector } from "react-redux";
import timesIcon from "assets/img/times-circle.svg";

const ClaimInfoRow = (props) => {
  const {
    data,
    claimIndex,
    handleClaimEdit,
    handleChargeEdit,
    checkAmountEquality,
    insuranceData,
    realClaim,
    handleSingleClaimPost,
    handleRemoveClaim,
  } = props;
  const [open, setOpen] = useState(true);
  const [sortBy, setSortBy] = useState("");
  const [eraList, setEraList] = useState([]);
  const [cptFilter, setCptFilter] = useState("");
  const [sortDescending, setSortDescending] = useState(true);
  const [filteredProcList, setFilteredProcList] = useState([]);
  const [editable, setEditable] = useState({ row: null, column: null });
  const [previousWorkingOpen, setPreviousWorkingOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { alreadyPostedClaim } = useSelector(processPaymentSelector);
  const [isClaimPosted, setIsClaimPosted] = useState(false);

  const handleEdit = (row, column) => {
    setEditable({ row, column });
  };

  useEffect(() => {
    // Restrict re Post Claim on same payment/EOB number
    setIsClaimPosted(alreadyPostedClaim.some((s) => s.claimID === data.id));
  }, [alreadyPostedClaim]);

  const handleSave = () => {
    setEditable({ row: null, column: null });
  };

  const getCliData = async (user) => {
    try {
      const era = await api.getClaimERA(user);
      // setEraList(formatEraListView(era, user));
      if (era.length > 0) {
        era.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
      }
      setEraList(era);
    } catch (error) {
      console.log("error in getCliData off", error.message);
    }
  };

  const sortUsers = (users, sortParam) => {
    if (sortDescending) {
      return [...users].sort((a, b) => (a[sortParam] < b[sortParam] ? 1 : b[sortParam] < a[sortParam] ? -1 : 0));
    } else {
      return [...users].sort((a, b) => (b[sortParam] < a[sortParam] ? 1 : a[sortParam] < b[sortParam] ? -1 : 0));
    }
  };

  const handleTabPress = (e, column) => {
    if (e.key === "Tab") {
      e.preventDefault();
      // Determine the direction of tabbing based on the Shift key
      const isShiftPressed = e.shiftKey;
      let step = isShiftPressed ? -1 : 1;

      // Set focus to the next (or previous) input field or perform other actions
      const currentTabIndex = parseInt(e.target.tabIndex);

      const nextTabIndex = currentTabIndex + step;

      const nextInput = document.querySelector(`[tabindex="${nextTabIndex}"]`);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  useEffect(() => {
    if (filteredProcList.length === 0) return;
    setFilteredProcList(sortUsers(filteredProcList, sortBy));
  }, [sortBy, sortDescending]);

  useEffect(() => {
    setFilteredProcList(
      data.proc_array.filter((prev) => (cptFilter ? prev.proc_code.includes(cptFilter) : prev.proc_code))
    );
  }, [cptFilter]);
  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  const totalOfAmounts = useMemo(() => {
    if (data.proc_array && data.proc_array.length > 0) {
      setFilteredProcList(
        sortUsers(
          data.proc_array.filter((prev) => (cptFilter ? prev.proc_code.includes(cptFilter) : prev.proc_code)),
          sortBy
        )
      );
      return data.proc_array.reduce((result, obj) => {
        let resObj = { ...result };

        resObj["total_Billed"] = (resObj["total_Billed"] || 0) + parseFloat(obj.charge || 0);
        resObj["total_allowed"] = (resObj["total_allowed"] || 0) + parseFloat(obj.allowed || 0);
        resObj["total_deductible"] = (resObj["total_deductible"] || 0) + parseFloat(obj.deductible || 0);
        resObj["total_co_insurance"] = (resObj["total_co_insurance"] || 0) + parseFloat(obj.co_insurance || 0);
        resObj["total_copay"] = (resObj["total_copay"] || 0) + parseFloat(obj.copay || 0);
        resObj["total_write_off"] = (resObj["total_write_off"] || 0) + parseFloat(obj.write_off || 0);

        resObj["chargeOfTotal"] = (resObj["chargeOfTotal"] || 0) + parseFloat(obj.paid || 0);

        if (CUSTOM_INS.includes(data.payerid)) {
          resObj["total_patient_amount"] = (resObj["total_patient_amount"] || 0) + parseFloat(obj.patient_amount || 0);
        }

        return resObj;
      }, {});
    }
  }, [data.proc_array]);

  const realAmounts = useMemo(() => {
    if (realClaim?.proc_array && realClaim.proc_array.length > 0) {
      return realClaim.proc_array.reduce((result, obj) => {
        let resObj = { ...result };

        resObj["total_Billed"] = (resObj["total_Billed"] || 0) + parseFloat(obj.charge || 0);
        resObj["total_allowed"] = (resObj["total_allowed"] || 0) + parseFloat(obj.allowed || 0);
        resObj["total_deductible"] = (resObj["total_deductible"] || 0) + parseFloat(obj.deductible || 0);
        resObj["total_co_insurance"] = (resObj["total_co_insurance"] || 0) + parseFloat(obj.co_insurance || 0);
        resObj["total_copay"] = (resObj["total_copay"] || 0) + parseFloat(obj.copay || 0);
        resObj["total_write_off"] = (resObj["total_write_off"] || 0) + parseFloat(obj.write_off || 0);
        resObj["total_mw_off"] = (resObj["total_mw_off"] || 0) + parseFloat(obj.mw_off || 0);
        resObj["chargeOfTotal"] = (resObj["chargeOfTotal"] || 0) + parseFloat(obj.paid || 0);
        resObj["total_open"] = (resObj["total_open"] || 0) + parseFloat(obj.open || 0);
        return resObj;
      }, {});
    }
  }, [realClaim?.proc_array]);

  const HEADER_STATUS = {
    Note: true,
    Status: true,
    CPT: true,
  };

  const balanceAmount = (totalAmount) => {
    if (!totalAmount) return 0;
    return (
      totalAmount.total_Billed -
      totalAmount.chargeOfTotal -
      totalAmount.total_write_off -
      (totalAmount.total_mw_off ? totalAmount.total_mw_off - totalAmount.total_write_off : 0)
    );
  };

  const childClaimHeader = useMemo(() => {
    let cloneHeader = [...CLAIM_CODE_DETAIL_HEADER];
    if (!realClaim?.paid_insurance) {
      cloneHeader = cloneHeader.filter((f) => f.itemKey !== "open");
    }
    if (CUSTOM_INS.includes(data.payerid)) {
      cloneHeader.splice(
        9,
        0,
        data.payerid === "00002"
          ? { itemKey: "patient_amount", title: "Client Paid" }
          : { itemKey: "patient_amount", title: "Patient Paid" }
      );
    }

    return cloneHeader;
  }, [CLAIM_CODE_DETAIL_HEADER, data]);
  return (
    <>
      <tr className={` ${open ? "border-bottom-0" : ""} position-relative`}>
        {tdRowDropdown(true, open, () => setOpen(!open), open ? "border-bottom-0" : "")}
        <td className={`text-center ${open ? "border-bottom-0" : ""}`} tabIndex={`1`}>
          {formatDateMDY(data.from_date_1)}
        </td>
        <td className={`text-center ${open ? "border-bottom-0" : ""}`} tabIndex={`2`}>
          {data.pat_name_f}
        </td>
        <td className={`text-center ${open ? "border-bottom-0" : ""}`} tabIndex={`3`}>
          {data.pat_name_l}
        </td>

        <td
          className={`text-center ${open ? "border-bottom-0" : ""}`}
          onClick={() => handleEdit(claimIndex, "claimNo")}
          tabIndex={`4`}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleEdit(claimIndex, "claimNo");
            }
          }}
        >
          {editable.row === claimIndex && editable.column === "claimNo" ? (
            <input
              className="custom-input"
              type="text"
              value={data.claimNo}
              onKeyDown={(e) => handleTabPress(e)}
              onChange={(e) => {
                const inputValue = e.target.value;

                handleClaimEdit(claimIndex, "claimNo", inputValue);
              }}
              onBlur={handleSave}
              maxLength={35}
              tabIndex={`4`}
              autoFocus
            />
          ) : (
            data.claimNo
          )}
        </td>
        <td
          className={`text-center ${open ? "border-bottom-0" : ""}`}
          onClick={() => handleEdit(claimIndex, "claim_received_date")}
          tabIndex={`5`}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleEdit(claimIndex, "claim_received_date");
            }
          }}
        >
          {editable.row === claimIndex && editable.column === "claim_received_date" ? (
            <input
              className="custom-input"
              type="date"
              onKeyDown={(e) => handleTabPress(e)}
              value={data.claim_received_date || ""}
              onChange={(e) => {
                const inputValue = e.target.value;

                handleClaimEdit(claimIndex, "claim_received_date", inputValue);
              }}
              onBlur={handleSave}
              tabIndex={`5`}
              max={insuranceData?.paid_date || new Date()}
              autoFocus
            />
          ) : (
            formatDateMDY(data.claim_received_date)
          )}
        </td>
        <td className={`text-center ${open ? "border-bottom-0" : ""}`} tabIndex={`6`}>
          <select
            className="statusPill claimStatusSelect custom-ellipsis"
            style={{
              border: "none",
              width: "135px",
              color: "black",
              textAlign: "center",
              padding: "5px",
            }}
            onBlur={handleSave}
            onChange={(e) => handleClaimEdit(claimIndex, "status_code", e.target.value)}
            value={data.status_code}
            tabIndex={`6`}
            onKeyDown={(e) => handleTabPress(e)}
          >
            {INSURANCE_TYPES.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>
        </td>
        <td className={`text-center ellipsis ${open ? "border-bottom-0" : ""}`} tabIndex={`7`}>
          {data?.payer_name}
        </td>
        <td className={`text-center ${open ? "border-bottom-0" : ""}`} tabIndex={`8`}>
          {formatCurrency(data?.total_charge)}
        </td>
        <td
          className={`text-center ${open ? "border-bottom-0" : ""}`}
          onClick={() => handleEdit(claimIndex, "total_paid")}
          style={{
            color: !isAmountGreaterThanCharge(
              realClaim?.paid_insurance ? balanceAmount(realAmounts) : data.total_charge,
              data.total_paid
            )
              ? "red"
              : "",
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleEdit(claimIndex, "total_paid");
            }
          }}
          tabIndex={`9`}
        >
          {editable.row === claimIndex && editable.column === "total_paid" ? (
            <input
              className="custom-input"
              type="text"
              placeholder="$0.00"
              onKeyDown={(e) => handleTabPress(e)}
              tabIndex={`9`}
              value={data.total_paid}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (/^-?(?!0\d*$)\d*\.?\d*$/.test(inputValue)) {
                  handleClaimEdit(claimIndex, "total_paid", inputValue);
                }
              }}
              onBlur={handleSave}
              autoFocus
            />
          ) : (
            formatCurrency(data.total_paid)
          )}
        </td>
        <td className={`text-center ellipsis ${open ? "border-bottom-0" : ""}`} tabIndex={`10`}>
          {formatCurrency(balanceAmount(realAmounts))}
        </td>
        <td style={{ borderBottom: "none", textAlign: "center" }} tabIndex={`11`} className="ellipsis">
          <select
            className="statusPill claimStatusSelect custom-ellipsis"
            tabIndex={`11`}
            onKeyDown={(e) => handleTabPress(e)}
            style={{
              border: "none",
              width: "100%",
              maxWidth: "100%",
              textAlign: "center",
              padding: "5px",
              backgroundColor: claimSubmitStatusColorOpts[data.status]
                ? claimSubmitStatusColorOpts[data.status]
                : "none",
            }}
            onChange={(e) => handleClaimEdit(claimIndex, "status", e.target.value)}
            value={data.status}
          >
            {Object.entries(CLAIM_SUBMIT_STATUS_LABELS).map(([key, value]) => (
              <option key={key} value={key}>
                {value}
              </option>
            ))}
          </select>
        </td>
        <td style={{ borderBottom: "none", textAlign: "center" }} tabIndex={`12`} className="ellipsis">
          <div className="cursor-pointer" onClick={() => handleRemoveClaim(data)}>
            <i className="fas fa-trash" />
          </div>
        </td>
      </tr>

      {open && (
        <tr>
          <td colSpan={"13"} className="expended-table-child">
            <Card className="striped-table-card charge-info-table">
              <Card.Body>
                <div className="form-group-wrapper d-block">
                  <div className="table-responsive pendingReleaseTable overFlow-y-hidden">
                    <Table className="table layout-fixed" tabIndex={"1"}>
                      <thead>
                        <tr className="tools-td">
                          <th></th>
                          {childClaimHeader.map((child, index) => (
                            <HeaderItem
                              key={index}
                              ItemKey={child.itemKey}
                              fieldEditableOnDoubleClick={child.filter && true}
                              value={child.filter && cptFilter}
                              onChange={(e) => setCptFilter(e?.target.value.toUpperCase() || "")}
                              onCancel={() => setCptFilter("")}
                              title={child.title}
                              flipSort={child.sort && flipSort}
                              sortBy={child.sort && sortBy}
                              sortDescending={child.sort && sortDescending}
                              aligned="centered"
                            />
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {filteredProcList.map((chargeData, i) => {
                          const realCharge = realClaim?.proc_array.find((f) => f.id === chargeData.id);
                          return (
                            <ChargeInfoRow
                              key={i}
                              chargeData={chargeData}
                              chargeIndex={i}
                              claimIndex={claimIndex}
                              handleChargeEdit={handleChargeEdit}
                              setCptFilter={setCptFilter}
                              cptFilter={cptFilter}
                              realCharge={realCharge}
                              data={data}
                            />
                          );
                        })}
                        <tr className="expended-row">
                          <td className="text-center p-2">
                            {tdRowDropdown(
                              !!realClaim?.paid_insurance,
                              previousWorkingOpen,
                              () => {
                                if (!previousWorkingOpen) {
                                  setPreviousWorkingOpen(true);
                                  getCliData(data);
                                } else {
                                  setPreviousWorkingOpen(false);
                                }
                              },
                              previousWorkingOpen ? "border-bottom-0" : ""
                            )}
                          </td>
                          <td className="text-center p-2"></td>
                          <td></td>
                          {totalOfAmounts &&
                            Object.keys(totalOfAmounts).length > 0 &&
                            Object.keys(totalOfAmounts).map((k) => (
                              <td className="fw-bold text-center" key={k}>
                                <span
                                  style={{
                                    flex: "1",
                                    color:
                                      ((k === "chargeOfTotal" && !CUSTOM_INS.includes(data.payerid)) ||
                                        (k === "total_patient_amount" && CUSTOM_INS.includes(data.payerid))) &&
                                      !isAmountEqualToCharge(data.total_paid, totalOfAmounts[k])
                                        ? "red"
                                        : "",
                                  }}
                                  className="fw-bold text-center"
                                >
                                  {formatCurrency(totalOfAmounts[k])}
                                </span>
                              </td>
                            ))}
                          {realClaim?.paid_insurance && (
                            <td className="fw-bold text-center">{formatCurrency(realAmounts.total_open || 0)}</td>
                          )}
                          <td></td>
                          <td></td>
                        </tr>
                        {previousWorkingOpen && eraList && eraList.length > 0 && (
                          <tr>
                            <td colSpan={CUSTOM_INS.includes(data.payerid) ? "14" : "13"}>
                              <Table className="table">
                                <thead>
                                  <tr className="data-listing-row">
                                    <th className={`centered w-10`} />
                                    {ERA_PROCEDURE_CODE_HEADER.map((m, index) => (
                                      <th key={index} className={`centered w-${m.width}`}>
                                        {m.title}
                                      </th>
                                    ))}
                                  </tr>
                                </thead>
                                <tbody>
                                  {eraList?.map((data, index) => (
                                    <ProcedureInfoRow
                                      key={index}
                                      data={data}
                                      index={index}
                                      handleOpenEraFile={() => console.log("")}
                                      handleChargeEdit={() => console.log("")}
                                      isViewAble={true}
                                      claim={realClaim}
                                    />
                                  ))}
                                </tbody>
                              </Table>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Card.Body>
              {!isClaimPosted && (
                <Button
                  variant="secondary"
                  className="modalButtons headerButton mb-2 btn-fill  ms-auto"
                  onClick={() => handleSingleClaimPost(data)}
                >
                  Post
                </Button>
              )}
            </Card>
          </td>
        </tr>
      )}
      {loading && <Loader />}
    </>
  );
};

export default ClaimInfoRow;
