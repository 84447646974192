import {
  fetchAllEmployeeClaimsAsync,
  createEmployeeClaimAsync,
  updateEmployeeClaimAsync,
  deleteEmployeeClaimAsync,
  acknowledgeClaimAsync,
  exportClaimDataToExcelAsync,
  updateSubmittedClaimAsync
} from "./employeeClaimsSlice";

export const createEmployeeClaim = (claimSubmission, seqNumber, claimStatus, claimtype) => async (dispatch) => {
  await dispatch(createEmployeeClaimAsync({ claimSubmission, seqNumber, claimStatus, claimtype }));
};

export const fetchAllEmployeeClaims =
  (dateFilter, page, filter, isNewCall = false, users) =>
  async (dispatch) => {
    await dispatch(fetchAllEmployeeClaimsAsync({ dateFilter, page, filter, isNewCall, users }));
  };

export const updateEmployeeClaim = (claimSubmission, claimStatus, claimtype, oldClaim) => async (dispatch) => {
  await dispatch(updateEmployeeClaimAsync({ claimSubmission, claimStatus, claimtype, oldClaim }));
};
export const updateSubmittedClaim = (claimSubmission) => async (dispatch) => {
  await dispatch(updateSubmittedClaimAsync({ claimSubmission }));
};
export const acknowledgeClaim = (data, loginUser) => async (dispatch) => {
  await dispatch(acknowledgeClaimAsync({ data, loginUser }));
};

export const deleteEmployeeClaim = (userToDelete, empsFilter) => async (dispatch) => {
  await dispatch(deleteEmployeeClaimAsync({ userToDelete, empsFilter }));
};


export const exportClaimDataToExcel = (selectedColumn) => async (dispatch) => {
  await dispatch(exportClaimDataToExcelAsync({ selectedColumn}));
};