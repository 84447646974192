import PersonalizationItem from "components/Personalization/PersonalizationItem";
import { useState, useEffect } from "react";
import { Button, Modal, Accordion, Card } from "react-bootstrap";
import { t } from "../../../stringConstants";
import PersonalizationModalAccordionButton from "components/Modal/Personalization/PersonalizationModalAccordionButton";
import { draggablePersonalizationLocalStorage } from "utils";
import { customIsEmpty } from "util/customLodash";
import { updateEmployeeClaimPersonalization } from "utils";
import { CLAIM_PERSONALIZATION } from "constants/claimPersonalization";
import TabPills from "components/Tabs/TabPills";
import TabItem from "components/Modal/Personalization/TabItem";

const PersonalizationModalEmployeeClaim = (props) => {
  const { show, handleClose, handleChange, cssClass } = props;
  const [personalize, setPersonalize] = useState({});

  const handleCheckBox = (event, claimStatus) => {
    let personalizedData = personalize[claimStatus];
    const index = personalizedData.findIndex((i) => i.itemKey === event.target.value);
    if (event.target.checked && index !== -1) {
      personalizedData[index].isCheck = true;
      return;
    }
    if (index !== -1) personalizedData[index].isCheck = false;
    setPersonalize({ ...personalize, [claimStatus]: personalizedData });
  };

  useEffect(() => {
    createEmployeeClaimPersonalizeObjectNew();
  }, []);

  const createEmployeeClaimPersonalizeObjectNew = () => {
    const employeePersonalizeObj = {
      draft: updateEmployeeClaimPersonalization(CLAIM_PERSONALIZATION, "draft"),
      submitted: updateEmployeeClaimPersonalization(CLAIM_PERSONALIZATION, "submitted"),
      rejected: updateEmployeeClaimPersonalization(CLAIM_PERSONALIZATION, "rejected"),
      paid: updateEmployeeClaimPersonalization(CLAIM_PERSONALIZATION, "paid"),
      denied: updateEmployeeClaimPersonalization(CLAIM_PERSONALIZATION, "denied"),
      cancelled: updateEmployeeClaimPersonalization(CLAIM_PERSONALIZATION, "cancelled"),
      patientClient: updateEmployeeClaimPersonalization(CLAIM_PERSONALIZATION, "patientClient"),
    };
    setPersonalize(employeePersonalizeObj);
  };

  const handleSubmit = () => handleChange(personalize);

  const tabPillsConstants = [
    {
      title: "Draft",
      component: <TabItem type="draft"  personalize={personalize} handleCheckBox={handleCheckBox} />,
    },
    {
      title: "Sent",
      component: <TabItem type="submitted" personalize={personalize} handleCheckBox={handleCheckBox} />,
    },
    {
      title: "Rejected",
      component: <TabItem type="rejected" personalize={personalize} handleCheckBox={handleCheckBox} />,
    },
    {
      title: "Paid",
      component: <TabItem type="paid" personalize={personalize} handleCheckBox={handleCheckBox} />,
    },
    {
      title: "Denied",
      component: <TabItem type="denied" personalize={personalize} handleCheckBox={handleCheckBox} />,
    },
    {
      title: "Cancelled",
      component: <TabItem type="cancelled" personalize={personalize} handleCheckBox={handleCheckBox} />,
    },
    {
      title: "Patient/Client",
      component: <TabItem type="patientClient" personalize={personalize} handleCheckBox={handleCheckBox} />,
    },
  ];

  return (
    <Modal
      show={show}
      backdrop="static"
      animation={true}
      onHide={() => handleClose()}
      centered
      size={"lg"}
      className={cssClass ?? "edit-modal-claims"}
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          {`${t("personalizeColumns")}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0 }}>
        <TabPills tabs={tabPillsConstants} />
      </Modal.Body>

      {/* <Modal.Body style={{ paddingTop: 0 }}>
        <Accordion defaultActiveKey={['0', '1', '2','3','4','5','6']}>
          <Card className="mb-2 column-card-wrapper">
            <Card.Header className="py-2 column-header-wrapper">
              <PersonalizationModalAccordionButton eventKey="0" isActiveInitially={true}>
                Draft
              </PersonalizationModalAccordionButton>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <div className="mb-3 d-flex flex-wrap">
                  {!customIsEmpty(personalize) &&
                    personalize["draft"].map((ek, index) => (
                      <PersonalizationItem
                        key={`draft_${index}`}
                        handleCheck={(event) => handleCheckBox(event, "draft")}
                        item={ek}
                        checkKey={`draft_${index}`}
                      />
                    ))}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-2 column-card-wrapper">
            <Card.Header className="py-2 column-header-wrapper">
              <PersonalizationModalAccordionButton eventKey="1">Sent</PersonalizationModalAccordionButton>
            </Card.Header>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                <div className="mb-3 d-flex flex-wrap">
                  {!customIsEmpty(personalize) &&
                    personalize["submitted"].map((ek, index) => (
                      <PersonalizationItem
                        key={`submitted_${index}`}
                        checkKey={`submitted_${index}`}
                        handleCheck={(event) => handleCheckBox(event, "submitted")}
                        item={ek}
                      />
                    ))}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-2 column-card-wrapper">
            <Card.Header className="py-2 column-header-wrapper">
              <PersonalizationModalAccordionButton eventKey="2">Rejected</PersonalizationModalAccordionButton>
            </Card.Header>
            <Accordion.Collapse eventKey="2">
              <Card.Body>
                <div className="mb-3 d-flex flex-wrap">
                  {!customIsEmpty(personalize) &&
                    personalize["rejected"].map((ek, index) => (
                      <PersonalizationItem
                        key={`rejected_${index}`}
                        checkKey={`rejected_${index}`}
                        handleCheck={(event) => handleCheckBox(event, "rejected")}
                        item={ek}
                      />
                    ))}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-2 column-card-wrapper">
            <Card.Header className="py-2 column-header-wrapper">
              <PersonalizationModalAccordionButton eventKey="3">Paid</PersonalizationModalAccordionButton>
            </Card.Header>
            <Accordion.Collapse eventKey="3">
              <Card.Body>
                <div className="mb-3 d-flex flex-wrap">
                  {!customIsEmpty(personalize) &&
                    personalize["paid"].map((ek, index) => (
                      <PersonalizationItem
                        key={`paid_${index}`}
                        checkKey={`paid_${index}`}
                        handleCheck={(event) => handleCheckBox(event, "paid")}
                        item={ek}
                      />
                    ))}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-2 column-card-wrapper">
            <Card.Header className="py-2 column-header-wrapper">
              <PersonalizationModalAccordionButton eventKey="4">Denied</PersonalizationModalAccordionButton>
            </Card.Header>
            <Accordion.Collapse eventKey="4">
              <Card.Body>
                <div className="mb-3 d-flex flex-wrap">
                  {!customIsEmpty(personalize) &&
                    personalize["denied"].map((ek, index) => (
                      <PersonalizationItem
                        key={`denied_${index}`}
                        checkKey={`denied_${index}`}
                        handleCheck={(event) => handleCheckBox(event, "denied")}
                        item={ek}
                      />
                    ))}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-2 column-card-wrapper">
            <Card.Header className="py-2 column-header-wrapper">
              <PersonalizationModalAccordionButton eventKey="5">Cancelled</PersonalizationModalAccordionButton>
            </Card.Header>
            <Accordion.Collapse eventKey="5">
              <Card.Body>
                <div className="mb-3 d-flex flex-wrap">
                  {!customIsEmpty(personalize) &&
                    personalize["cancelled"].map((ek, index) => (
                      <PersonalizationItem
                        key={`cancelled_${index}`}
                        checkKey={`cancelled_${index}`}
                        handleCheck={(event) => handleCheckBox(event, "cancelled")}
                        item={ek}
                      />
                    ))}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card className="mb-2 column-card-wrapper">
            <Card.Header className="py-2 column-header-wrapper">
              <PersonalizationModalAccordionButton eventKey="6">Patient/Client</PersonalizationModalAccordionButton>
            </Card.Header>
            <Accordion.Collapse eventKey="6">
              <Card.Body>
                <div className="mb-3 d-flex flex-wrap">
                  {!customIsEmpty(personalize) &&
                    personalize["patientClient"].map((ek, index) => (
                      <PersonalizationItem
                        key={`patientClient_${index}`}
                        checkKey={`patientClient_${index}`}
                        handleCheck={(event) => handleCheckBox(event, "patientClient")}
                        item={ek}
                      />
                    ))}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </Modal.Body> */}

      <Modal.Footer>
        <Button variant="primary" className="headerButton btn-fill" onClick={() => handleClose()}>
          Close
        </Button>
        <Button variant="secondary" className="headerButton btn-fill" onClick={handleSubmit}>
          Apply
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PersonalizationModalEmployeeClaim;
