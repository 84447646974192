import api from "api";
import Loader from "components/Loader/Loader";
import ErrorMessage from "components/Message/ErrorMessage";
import { MESSAGE_MODES, ORDER_QUANTITY } from "constant";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { setMessage as setMessageAction } from "store/features/general/generalAction";
import { userCompanyID } from "utils";
const ManualUpdateOrderQuantity = (props) => {
  const { handleConfirm, handleClose, orders, appContext } = props;
  const dispatch = useDispatch();

  const getSelectedCompany = () => {
    if (appContext.user?.isSubAgent()) {
      return appContext.subAgents.find((f) => f.id === userCompanyID.get());
    }
    return null;
  };
  const [selectedLocation, setSelectedLocation] = useState(getSelectedCompany());
  const [orderStatus, setOrderStatus] = useState("");
  const [error, setError] = useState("");
  const [orderMonth, setOrderMonth] = useState();
  const [message, setMessage] = useState("");
  const [ordQty, setOrdQty] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSave = async () => {
    if (ordQty === null && !selectedLocation) {
      setError("Please Select Order Quantity Or SubAgent");
      return;
    }
    try {
      setLoading(true);
      await api.updateOrdQtyManual(orders, ordQty, selectedLocation, appContext.user);
      dispatch(setMessageAction("Update Data Successfully", MESSAGE_MODES.success));
      handleClose(true);
      setLoading(false);
    } catch (err) {
      console.log("Error:-", err.message);
      dispatch(setMessageAction(err.message, MESSAGE_MODES.error));
      setLoading(false);
    }
  };

  console.log("orderMonth", orderMonth);
  return (
    <Modal
      show
      backdrop="static"
      // style={{ transform: "translate(0, -30%)" }}
      animation={true}
      onHide={() => {
        handleClose();
      }}
      centered
      size={"lg"}
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0 modal-title h4" id="contained-modal-title-vcenter">
          Update Order Quantity
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="form-group-wrapper">
          <label className="mb-1">Select Order Quantity:</label>
          <Select
            options={ORDER_QUANTITY}
            blurInputOnSelect={true}
            menuPlacement="auto"
            placeholder="Select Order Quantity"
            className="w-100"
            onChange={(e) => setOrdQty(e.value)}
          />
        </div>
        {(appContext.user?.isAdmin() || appContext.user?.isClient() || appContext.user?.isSite()) && (
          <div className="form-group-wrapper">
            <label className="modalLineHeaders mt-0">Sub Agents:</label>
            <Select
              options={appContext.subAgents.map((s) => {
                return { ...s, label: s.name, value: s.id };
              })}
              styles={{
                // Fixes the overlapping problem of the component
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
              }}
              blurInputOnSelect={true}
              menuPlacement="auto"
              placeholder="Select Sub Agent"
              className="w-100"
              onChange={(e) => setSelectedLocation(e)}
            />
          </div>
        )}
      </Modal.Body>
      {error && (
        <div className="ms-2">
          <ErrorMessage error={error} handleChange={() => setError("")} />
        </div>
      )}
      {loading && <Loader />}

      <Modal.Footer>
        <Button
          className="headerButton btn-fill"
          variant="primary"
          onClick={() => {
            handleClose();
          }}
        >
          Close
        </Button>
        <Button
          variant="secondary"
          className="headerButton btn-fill"
          onClick={() => {
            handleSave();
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ManualUpdateOrderQuantity;
