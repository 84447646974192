import InputField from "components/InputField/InputField";
import Loader from "components/Loader/Loader";
import ErrorMessage from "components/Message/ErrorMessage";
import { MESSAGE_MODES, MESSAGE_TEMPLATE_CATGORY } from "constant";
import { useEffect, useRef, useState, useMemo } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { selectLoginUser } from "store/features/authentication/authenticationSelectors";
import { setMessage } from "store/features/general/generalAction";
import { createNewMessageTemplate, updateMessageTemplate } from "store/features/messageTemplate/messageTemplateAction";
import { selectedMessageTemplateSelector } from "store/features/messageTemplate/messageTemplateSelectors";
import {
  setSelectedMessageTemplate,
  setShowMessageTemplateModal,
} from "store/features/messageTemplate/messageTemplateSlice";

const TEXT_FIELD_OPTIONS = [
  { label: "First Name", value: "{first_name}" },
  { label: "Last Name", value: "{last_name}" },
  { label: "Cpt Code", value: "{cpt_code}" },
  { label: "Cpt Code", value: "{cpt_code}" },
  { label: "Payer Name", value: "{payer_name}" },
  { label: "Payer Address", value: "{payer_address}" },
];

const DEFAULT_MESSAGE_TEMPLATE = {
  name: "",
  subject: "",
  category: "",
  message: "",
  isNew: true,
};

const MessageTemplateModal = (props) => {
  const { selectItem, cssClass } = props;

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const loginUser = useSelector(selectLoginUser);
  const selectedMessageTemplate = useSelector(selectedMessageTemplateSelector);

  const dispatch = useDispatch();
  const textAreaRef = useRef();

  const getObject = () => {
    return {
      ...(selectItem || DEFAULT_MESSAGE_TEMPLATE),
      updatedBy: loginUser.sub,
      updatedByName: loginUser.name,
    };
  };

  const [item, setItem] = useState(getObject());

  useEffect(() => {
    if (selectItem) setItem({ ...selectItem, ...item });
  }, [selectItem]);

  const insertAtCursor = (dropDownValue) => {
    textAreaRef.current.focus;
    if (textAreaRef.current) {
      let startPos = textAreaRef.current.selectionStart;
      let endPos = textAreaRef.current.selectionEnd;
      textAreaRef.current.value =
        textAreaRef.current.value.substring(0, startPos) +
        dropDownValue +
        textAreaRef.current.value.substring(endPos, textAreaRef.current.value.length);
      const newPos = endPos + dropDownValue.length;
      textAreaRef.current.setSelectionRange(newPos, newPos);
      textAreaRef.current.focus();
      setItem({ ...item, message: textAreaRef.current.value });
    }
  };
  const fromDisclaimerModal = useMemo(
    () => selectedMessageTemplate?.fromDisclaimer || selectedMessageTemplate?.fromDisclaimer === true,
    [selectedMessageTemplate]
  );

  const handleSaveChange = async () => {
    if (!item.name || !item.name.trim()) {
      setError("Name is required");
      return;
    }

    if (!fromDisclaimerModal && (!item.category || !item.category.trim())) {
      setError("Category is required");
      return;
    }

    if (!fromDisclaimerModal && (!item.subject || !item.subject.trim())) {
      setError("Subject is required");
      return;
    }

    if (!item.message || !item.message.trim()) {
      setError("Message is required");
      return;
    }

    setLoading(true);
    let updated = false;
    delete item["isNew"];
    delete item["fromDisclaimer"];

    const itemWithDefaultValues = {
      ...item,
      category: item.category ? item.category : "invoices",
      subject: item.subject ? item.subject : item.name,
    };

    if (selectItem.isNew) {
      dispatch(createNewMessageTemplate(itemWithDefaultValues));
      updated = true;
    } else {
      dispatch(updateMessageTemplate(itemWithDefaultValues));
      updated = true;
    }

    if (updated) {
      dispatch(setMessage("Record save sucessfully", MESSAGE_MODES.success));
    } else {
      dispatch(setMessage("Fail to save the Record", MESSAGE_MODES.error));
    }
    setLoading(false);
    dispatch(setSelectedMessageTemplate(null));
    dispatch(setShowMessageTemplateModal(false));
  };

  const handleClose = () => {
    dispatch(setSelectedMessageTemplate(null));
    dispatch(setShowMessageTemplateModal(false));
  };

  return (
    <Modal
      show
      backdrop="static"
      className={cssClass}
      animation={true}
      centered
      size={"lg"}
      onHide={() => {
        handleClose();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          {item.isNew ? " New Message Template" : "Edit Message Template"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="createClientsWrapper">
          {/* <div className="leftSide"> */}

          <div className="row mb-3 w-100">
            <InputField
              type="text"
              inputStyle="modalInput"
              labelStyle="text-capitalize"
              label="Name"
              value={item.name}
              index="name"
              placeholder="Name"
              handleChange={(e) =>
                setItem({
                  ...item,
                  name: e.target.value,
                })
              }
            />
            {!fromDisclaimerModal && (
              <>
                <Form.Group className="form-group-wrapper">
                  <label className="modalLineHeaders mt-0 text-capitalize">Category</label>
                  <Select
                    options={MESSAGE_TEMPLATE_CATGORY}
                    className="w-100"
                    value={MESSAGE_TEMPLATE_CATGORY.find((opts) => opts.value === item.category)}
                    menuPlacement="auto"
                    autoComplete="none"
                    onChange={(e) => setItem({ ...item, category: e.value })}
                  />
                </Form.Group>

                <InputField
                  type="text"
                  inputStyle="modalInput"
                  labelStyle="text-capitalize"
                  value={item.subject}
                  index="subject"
                  placeholder="Enter Subject"
                  label="subject"
                  handleChange={(e) => {
                    setItem({
                      ...item,
                      subject: e.target.value,
                    });
                  }}
                />

                <Form.Group className="form-group-wrapper">
                  <label className="modalLineHeaders mt-0 text-capitalize">Insert Field</label>
                  <Select
                    options={TEXT_FIELD_OPTIONS}
                    className="w-100"
                    value={{ label: "Insert field", value: "none" }}
                    menuPlacement="auto"
                    autoComplete="none"
                    onChange={(e) => insertAtCursor(e.value)}
                  />
                </Form.Group>
              </>
            )}

            <div className="form-group-wrapper">
              <textarea
                className="MessageInput w-100"
                placeholder="Message"
                ref={textAreaRef}
                value={item.message}
                onChange={(e) => setItem({ ...item, message: e.target.value })}
              />
            </div>
          </div>

          {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
        </div>
        {loading && <Loader />}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" className="headerButton btn-fill" onClick={() => handleClose()}>
          Close
        </Button>
        <div>
          <Button variant="secondary" className="headerButton btn-fill" onClick={handleSaveChange} disabled={loading}>
            {item.isNew ? "Create" : "Update"}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default MessageTemplateModal;
