import api from "api";
import Loader from "components/Loader/Loader";
import ErrorMessage from "components/Message/ErrorMessage";
import { useState } from "react";
import { Button, Card, Modal, Table } from "react-bootstrap";
import { formatDateOfBirthDOB, userCompanyID } from "utils";

const MergeProfileModal = (props) => {
  const { handleSave, handleClose, employees, userName } = props;
  const [selectedDpt, setSelectedDpt] = useState([]);
  const [error, setError] = useState("");
  const [primaryEmployee, setPrimaryEmployee] = useState([]);
  const [loading, setLoading] = useState(false);

  const onHandleSave = async () => {
    console.log("primaryEmployee", primaryEmployee);
    const deleteEmps = employees.filter((f) => primaryEmployee[0].id !== f.id);

    try {
      setLoading(true);

      const res = await api.mergeEmployeeLogs(
        primaryEmployee[0].id,
        deleteEmps.map((m) => m.id)
      );
      if (res.data) {
        for (let i = 0; i < deleteEmps.length; i++) {
          const emp = deleteEmps[i];
          await api.deleteEmployee(emp.id);
        }
        let logData = [];
        logData.push({
          schrID: primaryEmployee[0].id,
          auditType: "CombineProfile",
          action: "View Details",
          deletedIds: JSON.stringify(
            deleteEmps.map((m) => ({
              id: m.id,
              firstName: m.firstName,
              lastName: m.lastName,
              phoneNumber: m.phoneNumber,
            }))
          ),
          companyID: userCompanyID.get(),
          userName: userName,
        });
        await api.addlogs(logData);
      }
      setLoading(false);
      handleClose(true);
    } catch (err) {
      setLoading(false);
      console.log("Error:-", err);
    }
  };
  const TableRow = (props) => {
    const { item, handleCheck } = props;
    return (
      <tr>
        <td></td>
        <td>{item.firstName}</td>
        <td>{item.lastName}</td>
        <td>{item.phoneNumber}</td>
        <td>{item.email}</td>
        <td>{formatDateOfBirthDOB(item.dob)}</td>

        {handleCheck && (
          <td className="linkedText text-decoration-underline" onClick={() => handleCheck(item)}>
            Select
          </td>
        )}
      </tr>
    );
  };
  const handleCheckBox = (emp) => {
    setPrimaryEmployee([emp]);
  };
  return (
    <Modal show onHide={handleClose} animation={true} size={"xl"} scrollable keyboard={true}>
      <Modal.Header closeButton>
        <Modal.Title className="my-0">Combine Employee Profile</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card className="strpied-tabled-with-hover">
          <Card.Header>
            <Card.Title
              as="h5"
              style={{
                fontWeight: "bold",
              }}
            >
              Valid Profile
            </Card.Title>
          </Card.Header>
          <Card.Body className="table-full-width desktop-noScroll">
            <div
              className={
                employees.length > 0
                  ? "table-responsive pendingReleaseTable"
                  : "table-responsive pendingReleaseTable overFlow-y-hidden"
              }
            >
              <Table className="table">
                <thead>
                  <tr>
                    <th></th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Phone</th>
                    <th>Email</th>
                    <th>DOB</th>
                  </tr>
                </thead>
                <tbody>
                  {primaryEmployee.length > 0 ? (
                    primaryEmployee.map((dpt, index) => <TableRow key={index} item={dpt} />)
                  ) : (
                    <td colSpan={"6"} className="text-center p-3">
                      Select Valid Profile from below options
                    </td>
                  )}
                </tbody>
              </Table>
            </div>
          </Card.Body>
        </Card>
        <Card className="strpied-tabled-with-hover">
          <Card.Header>
            <Card.Title
              as="h5"
              style={{
                fontWeight: "bold",
              }}
            >
              Profile Options
            </Card.Title>
          </Card.Header>
          <Card.Body className="table-full-width desktop-noScroll">
            <div
              className={
                employees.length > 0
                  ? "table-responsive pendingReleaseTable"
                  : "table-responsive pendingReleaseTable overFlow-y-hidden"
              }
            >
              <Table className="table">
                <thead>
                  <tr>
                    <th></th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Phone</th>
                    <th>Email</th>
                    <th>DOB</th>
                    <th>Department</th>
                    <th>Zone</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {employees.map((dpt, index) => (
                    <TableRow key={index} handleCheck={handleCheckBox} item={dpt} />
                  ))}
                </tbody>
              </Table>
            </div>
          </Card.Body>
        </Card>
      </Modal.Body>
      <div className="px-4 pt-2" style={{ minHeight: "3vh" }}>
        {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
      </div>
      {loading && <Loader />}
      <Modal.Footer>
        <Button variant="primary" className="headerButton btn-fill" onClick={() => handleClose()}>
          Close
        </Button>
        <Button
          variant="secondary"
          disabled={primaryEmployee.length === 0}
          className="headerButton btn-fill"
          onClick={() => onHandleSave()}
        >
          Combine Profiles
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MergeProfileModal;
