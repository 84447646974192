import { Storage } from "aws-amplify";
import "bootstrap/dist/css/bootstrap.min.css";
import Loader from "components/Loader/Loader";
import { Audit_Entries, Audit_Types, CONFIG, MESSAGE_MODES } from "constant";
import { useEffect, useState } from "react";
import { Button, Card, Col, Modal, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { openEligibilityDetailModalAsync } from "store/features/checkEligibility/checkEligibilitySlice";
import { fetchApiDataAsync } from "store/features/employeeLogs/employeeLogsSlice";
import { setMessage } from "store/features/general/generalAction";
import AuditTrailEntries from "./AuditTrailEntries";

const EmployeeOrderHistory = ({ user, handleClose }) => {
  const dispatch = useDispatch();
  const { data, isLoading, error } = useSelector((state) => state.employeeLogs);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [entries, setEntries] = useState([]);
  const [eligibilityDetailsData, setEligibilityDetailsData] = useState(null);

  useEffect(() => {
    if (data && data.length > 0) loadData(data);
  }, [data]);

  useEffect(() => {
    dispatch(fetchApiDataAsync(user.id));
  }, [dispatch]);

  useEffect(() => {
    if (items != "") {
      const auditEntries = Audit_Entries.filter(
        (f) => items.findIndex((log) => Audit_Types[log.eventType] === f) !== -1
      );
      setEntries(auditEntries);
    }
  }, [items]);

  const loadData = (data) => {
    setLoading(isLoading);
    // const orders = await api.getOrderByEmployee(user.id);
    // const formatttedOrders = orders.map((o) => formatOrder(o));
    setItems(data);
    setLoading(false);
  };

  return (
    <Modal
      className="historyViewModal"
      show
      animation={true}
      onHide={() => handleClose()}
      backdrop="static"
      size={"xl"}
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          {`${user.firstName} ${user.lastName} Logs`}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="marginTop0">
        <>
          {!loading ? (
            <div style={{ flex: 1, marginTop: "10px" }}>
              <Row>
                <Col md="12">
                  <Card className="strpied-tabled-with-hover">
                    <Card.Body>
                      <div className="form-group-wrapper d-block">
                        <div className="table-responsive overFlow-y-hidden">
                          <Table className="table">
                            {/* <thead>
                            <tr>
                              <th></th>
                              <th style={{ width: "10%" }}>{`${t("srNo")}`}</th>
                              <th>Date Ordered</th>
                              <th className="border-0 centered">{`${t(
                                "qtyOrdered"
                              )}`}</th>
                              <th className="border-0 centered">Address</th>
                              <th className="border-0 centered">{`${t(
                                "orderStatus"
                              )}`}</th>
                              <th className="border-0 centered">orderId</th>
                              <th className="border-0 centered">
                                Requested By
                              </th>
                            </tr>
                          </thead> */}
                            <tbody>
                              {entries.length > 0 ? (
                                entries.map((item, i) => {
                                  return (
                                    <AuditTrailEntries
                                      key={i}
                                      index={i}
                                      item={item}
                                      handleOpenDetailModal={(item) =>
                                        dispatch(openEligibilityDetailModalAsync({ item }))
                                      }
                                      auditTrails={items}
                                      user={user}
                                    />
                                  );
                                })
                              ) : (
                                <tr>
                                  <td>
                                    <span>No Records Found!</span>
                                  </td>
                                </tr>
                              )}
                              {/* {items.length > 0 &&
                              items.map((item, i) => {
                                return (
                                  <TableRow key={i} index={i} log={item} />
                                );
                              })} */}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          ) : (
            <Loader />
          )}
        </>
      </Modal.Body>

      <Modal.Footer>
        <Button className="modalButtons headerButton btn-fill" variant="primary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EmployeeOrderHistory;
