import React, { useEffect, useState } from "react";
import deleteIcon from "assets/img/trash-icon.png";
import editIcon from "assets/img/edit-icon.png";
import { PARSE_ALPHA_INTO_NUM, currencyformatter, ERA_DENAIEL_REASONS } from "constant";
import { formatDateMDY, getTotalCharge } from "utils";
import Status from "components/Status";
import { isJSONString } from "utils";
import { getDiagonosisInternalCodeValue } from "utils";

const ProcedureListView = (props) => {
  const { data, isDisabled, newClaim, handleDelete, handleEdit, index, eraList, errorList } = props;
  const [reason, setReason] = useState("");

  useEffect(() => {
    if (eraList.length > 0) {
      let procData = null;
      eraList.forEach((f) => {
        const findProc = f.charge?.find((s) => s.proc_code === data.proc_code);
        if (findProc) {
          procData = findProc;
        }
      });
      if (procData) {
        const reasons = procData?.adjustment?.map((m) => ERA_DENAIEL_REASONS.find((f) => f.Code === m.code)?.Reason);
        setReason([...new Set(reasons)]);
      }
    }
  }, [eraList]);

  return (
    <tr key={index}>
      <td className={`table-list-data text-center`}>
        <div className="d-flex align-items-center justify-content-around cursor-pointer">
          <img src={editIcon} alt="edit" onClick={() => !isDisabled && handleEdit(data)} />
          {reason.length > 0 && (
            <div className="star-icon-wrapper" title={reason}>
              <Status type="circle" size="md" color="maroon" crossIcon />
            </div>
          )}
        </div>
      </td>
      <td className={`table-list-data text-center ${errorList?.includes("from_date") && "errorField"}`}>
        {formatDateMDY(data.from_date)}
      </td>
      <td
        className={`table-list-data text-center ${errorList?.includes("place_of_service") && "errorField"}`}
        title={data?.posDesc}
      >
        {data.place_of_service}
      </td>
      <td
        className={`table-list-data text-center ${errorList?.includes("proc_code") && "errorField"}`}
        title={data?.proc_desc}
      >
        {`${data?.internalCode || data?.proc_code}${
          data?.internalCode && data?.internalCode !== data?.proc_code ? ` - (${data?.proc_code})` : ""
        }`}
      </td>
      <td className={`table-list-data text-center ${errorList?.includes("mod1") && "errorField"}`}>{data?.mod1}</td>
      <td className={`table-list-data text-cente ${errorList?.includes("mod2") && "errorField"}`}>{data?.mod2}</td>
      <td className={`table-list-data text-center ${errorList?.includes("mod3") && "errorField"}`}>{data?.mod3}</td>
      <td className={`table-list-data text-center ${errorList?.includes("mod4") && "errorField"}`}>{data?.mod4}</td>
      <td
        className={`table-list-data text-center ${
          errorList?.includes(`diag_${PARSE_ALPHA_INTO_NUM[data?.diag_ref_1]}`) && "errorField"
        }`}
      >
        {getDiagonosisInternalCodeValue(newClaim[`diag_${PARSE_ALPHA_INTO_NUM[data?.diag_ref_1]}`])}
      </td>
      <td
        className={`table-list-data text-center ${
          errorList?.includes(`diag_${PARSE_ALPHA_INTO_NUM[data?.diag_ref_2]}`) && "errorField"
        }`}
      >
        {getDiagonosisInternalCodeValue(newClaim[`diag_${PARSE_ALPHA_INTO_NUM[data?.diag_ref_2]}`])}
      </td>
      <td
        className={`table-list-data text-center ${
          errorList?.includes(`diag_${PARSE_ALPHA_INTO_NUM[data?.diag_ref_3]}`) && "errorField"
        }`}
      >
        {getDiagonosisInternalCodeValue(newClaim[`diag_${PARSE_ALPHA_INTO_NUM[data?.diag_ref_3]}`])}
      </td>
      <td
        className={`table-list-data text-center ${
          errorList?.includes(`diag_${PARSE_ALPHA_INTO_NUM[data?.diag_ref_4]}`) && "errorField"
        }`}
      >
        {getDiagonosisInternalCodeValue(newClaim[`diag_${PARSE_ALPHA_INTO_NUM[data?.diag_ref_4]}`])}
      </td>
      <td className={`table-list-data text-center ${errorList?.includes("charge") && "errorField"}`}>
        {currencyformatter.format(getTotalCharge(data))}
      </td>
      <td className={`table-list-data text-center ${errorList?.includes("units") && "errorField"}`}>{data.units}</td>
      <td className={`table-list-data text-center`}>
        <img
          src={deleteIcon}
          alt="delete"
          className="cursor-pointer"
          onClick={() => !isDisabled && handleDelete(data)}
        />
      </td>
    </tr>
  );
};

export default ProcedureListView;
