import React, { useState, useEffect, useMemo } from "react";
import Select from "react-select";
import { getValidReg, checkValidity } from "../../utils";

const DropDown = (props) => {
  const { data, handleChange } = props;
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState();

  useEffect(() => {
    setOptions(data);
  }, [data]);

  const dropDownOptions = useMemo(() => {
    return options.map((opt) => {
      return { value: opt.value, label: opt.label };
    });
  }, [options]);

  const handleChanged = (e) => {
    handleChange(e.value, e);
    setSelectedValue(e);
  };

  //   useEffect(() => {
  //     if (dropDownOptions.length === 1 && !selectedOption) {
  //       const obj = {
  //         value: dropDownOptions[0].value,
  //         label: dropDownOptions[0].label,
  //       };
  //       setSelectedValue(obj);
  //       onOptionChange(obj);
  //     }
  //   }, [dropDownOptions]);
  //   let changeValue = true;
  //   const handleOnChange = (e) => {
  //     setSelectedValue(e);
  //     changeValue = false;
  //     if (onOptionChange) {
  //       onOptionChange(e);
  //     }
  //   };

  //   const handleChangeSelectInput = (e, r) => {
  //     const newValue = r.prevInputValue;
  //     if (r.action === "input-change") {
  //       changeValue = true;
  //     }
  //     if (r.action === "input-blur" && newValue && changeValue) {
  //       const val = getValidReg(newValue);
  //       let newOption = { value: val, label: val };
  //       let items = [...options];
  //       const findVal = items.findIndex(
  //         (c) => checkValidity(c.label) == checkValidity(newOption.label)
  //       );
  //       if (findVal !== -1) {
  //         newOption = items[findVal];
  //         setOptions(items);
  //       } else {
  //         setOptions([...options, newOption]);
  //       }
  //       handleOnChange({ value: newOption.value, label: newOption.value });
  //     }
  //   };
  return (
    <Select
      options={dropDownOptions}
      blurInputOnSelect={true}
      menuPlacement="auto"
      className="w-100"
      value={selectedValue}
      onChange={handleChanged}
    />
  );
};

export default DropDown;
