import { createSlice } from "@reduxjs/toolkit";
import { showConfirmationModal } from "./generalAction";
import CONSTANTS from "./generalConstant";

const generalSlice = createSlice({
  name: CONSTANTS.GENERAL,
  initialState: {
    loader: false,
    error: null,
    message: {
      text: "",
      mode: "",
    },
    confirmationModal: {
      showModal: false,
      message: "",
      title: "",
      onConfirm: null,
    },
  },
  reducers: {
    setLoader: (state, action) => {
      state.loader = Boolean(action.payload);
    },
    showMessage: (state, action) => {
      state.message = {
        text: action.payload?.text || "",
        mode: action.payload?.mode || "",
      };
    },
    setError: (state, action) => {
      state.error = action.payload || null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(showConfirmationModal.fulfilled, (state, action) => {
      state.confirmationModal = action.payload;
    });
  },
});

export const { setLoader, showMessage, setError } = generalSlice.actions;

export default generalSlice.reducer;
