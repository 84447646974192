import ViewCheckBox from "components/CheckBox/ViewCheckBox";
import AddGeneralSettingsModal from "components/GeneralSettings/AddGeneralSettingsModal";
import Icon from "components/Icon";
import Loader from "components/Loader/Loader";
import HeaderItem from "components/Table/HeaderItem";
import { useCallback, useEffect, useRef, useState } from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "../../../src/tableStyling.css";
import EditIcon from "../../assets/img/edit-icon.png";
import TrashIcon from "../../assets/img/trash-icon.png";
import {
  ICTCodeSelector,
  openCreateICTModalSelector,
  selectedICTItemSelector,
} from "../../store/features/cptCodes/cptCodeSelectors";
import { t } from "../../stringConstants";
import Filter from "components/Filter";
import { DIAGNOSIS_CODE_PERSONALIZE } from "constants/personalization";

import { calculateTdWidth, formatNumber } from "../../utils";
import {
  fetchICTCodeAsync,
  setOpenCreateICTModal,
  setDiagnosisList,
  setSelectedICTItem,
} from "../../store/features/cptCodes/cptCodeSlice";
import { debounce } from "debounce";
import AddDiagnosisCodeSettingsModal from "../../components/GeneralSettings/AddDiagnosisCodeSettingsModal";

const DiagnosisCodeSettings = (props) => {
  const [sortBy, setSortBy] = useState("");
  const diagnosisList = useSelector(ICTCodeSelector);
  const [sortDescending, setSortDescending] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const [width, setWidth] = useState(0);
  const mainWidth = useRef();
  const componentRef = useRef();
  const dispatch = useDispatch();
  const selectedICTItem = useSelector(selectedICTItemSelector);
  const openCreateICTModal = useSelector(openCreateICTModalSelector);

  const searchTerms = ["Diagnosis Code", "Alias", "Diagnosis Code Desc", "Active"];

  useEffect(() => {
    setWidth(componentRef.current.offsetWidth);
  }, [componentRef]);

  const handleResize = () => {
    if (componentRef.current) setWidth(componentRef.current.offsetWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => handleResize(), [mainWidth]);

  const tdWidth = calculateTdWidth(width - 160, 2);

  const sortUsers = (users, sortParam) => {
    if (sortDescending) {
      return [...users].sort((a, b) => (a[sortParam] < b[sortParam] ? 1 : b[sortParam] < a[sortParam] ? -1 : 0));
    } else {
      return [...users].sort((a, b) => (b[sortParam] < a[sortParam] ? 1 : a[sortParam] < b[sortParam] ? -1 : 0));
    }
  };

  const fetchFromApi = useCallback(
    debounce(async (filter, sortBy, sortDescending) => {
      dispatch(
        fetchICTCodeAsync({
          ...(Object.keys(filter).length > 0 ? filter : { code: "a" }),
          orderBy: sortBy,
          orderByDirection: sortDescending,
        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    fetchFromApi(filter, sortBy, sortDescending);
  }, [filter, sortBy, sortDescending]);

  const flipSort = (by) => {
    setSortDescending(sortBy === by ? !sortDescending : true);
    setSortBy(by);
  };

  const TableRow = ({ item, index }) => {
    return (
      <tr>
        <td className="icon" style={{ width: "45px", minWidth: "45px", maxWidth: "45px" }}>
          <img
            src={EditIcon}
            alt="edit icon"
            width="18"
            onMouseLeave={(e) => (e.target.style.color = "black")}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            aria-hidden="true"
            onClick={() => {
              dispatch(setSelectedICTItem({ item, index }));
              dispatch(setOpenCreateICTModal(true));
            }}
          />
        </td>
        <td className="ellipsis" title={item.code}>
          {item.code}
        </td>
        <td className="ellipsis" title={item.code}>
          {item.internalCode}
        </td>

        <td className="ellipsis" title={item.title}>
          {item.title}
        </td>

        <td className="ellipsis text-center" title={item.isActive}>
          {item.isActive ? <ViewCheckBox id="filter" bgColor="green" /> : "-"}
        </td>
        {/* <td
          className="icon"
          style={{
            textOverflow: "visible",
            width: "45px",
            minWidth: "45px",
            maxWidth: "45px",
          }}
        >
          <img
            src={TrashIcon}
            alt="trash icon"
            width="15"
            onMouseLeave={(e) => (e.target.style.color = "black")}
            onMouseOver={(e) => {
              e.target.style.cursor = "pointer";
            }}
            aria-hidden="true"
            onClick={() => {
              updateProcCodeData({ ...item, action: CLI_ACTIONS.delete }, index);
            }}
          />
        </td> */}
      </tr>
    );
  };

  return (
    <div style={{ flex: 1 }}>
      <>
        <Row>
          <Col>
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                  }}
                >
                  {t("diagnosisCodes")}
                </Card.Title>
                <div className="buttonHeader">
                  <Icon
                    handleClick={() => setShowFilter(!showFilter)}
                    title={"Filter"}
                    label={"Filter"}
                    iconType={"filter"}
                  />
                  <Icon
                    handleClick={() => dispatch(setOpenCreateICTModal(true))}
                    title={"Create Diagnosis"}
                    label={"Create"}
                    iconType={"addUserIcon"}
                  />
                </div>
                {showFilter && (
                  <Filter
                    filterTerms={searchTerms}
                    setFilter={setFilter}
                    filter={filter}
                    isUsersTable={true}
                    personalisationData={DIAGNOSIS_CODE_PERSONALIZE}
                  />
                )}
              </Card.Header>

              <Card.Body className="table-full-width desktop-noScroll">
                <div className="table-responsive pendingReleaseTable">
                  <Table>
                    <thead ref={componentRef}>
                      <tr>
                        <th />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey={"code"}
                          title={"Diagnosis Code"}
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned={"left"}
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey={"internalCode"}
                          title={"Alias"}
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned={"left"}
                        />
                        <HeaderItem
                          width={tdWidth}
                          ItemKey={"description"}
                          title={"Diagnosis Code Desc"}
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned={"left"}
                        />

                        <HeaderItem
                          width={tdWidth}
                          ItemKey={"isActive"}
                          title={"Active"}
                          flipSort={flipSort}
                          sortBy={sortBy}
                          sortDescending={sortDescending}
                          aligned={"centered"}
                        />
                      </tr>
                    </thead>
                    <tbody>
                      {diagnosisList &&
                        diagnosisList.map((item, i) => {
                          return <TableRow key={i} item={item} index={i} />;
                        })}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </Col>

          {openCreateICTModal && (
            <AddDiagnosisCodeSettingsModal
              handleClose={() => {
                dispatch(setOpenCreateICTModal(false));
                dispatch(setSelectedICTItem(null));
              }}
              selectedItem={selectedICTItem?.item || null}
            />
          )}
        </Row>
      </>
    </div>
  );
};

export default DiagnosisCodeSettings;
