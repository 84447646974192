import PersonalizationItem from "components/Personalization/PersonalizationItem";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { t } from "../../stringConstants";

const PersonalizationModal = (props) => {
  const { show, handleClose, handleChange, data, cssClass } = props;
  const [personalize, setPersonalize] = useState(JSON.parse(data));

  const handleCheckBox = (event) => {
    let personalizedData = [...personalize];
    const index = personalize.findIndex((i) => i.itemKey === event.target.value);
    if (event.target.checked && index !== -1) {
      personalizedData[index].isCheck = true;
      return;
    }
    if (index !== -1) personalizedData[index].isCheck = false;
    setPersonalize(personalizedData);
  };

  const handleSubmit = () => handleChange(personalize);

  return (
    <Modal
      show={show}
      backdrop="static"
      animation={true}
      onHide={() => handleClose()}
      centered
      size={"lg"}
      className={cssClass ?? ""}
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          {`${t("personalizeColumns")}`}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="mb-3">
          {personalize.map((ek, index) => (
            <PersonalizationItem key={index} handleCheck={handleCheckBox} item={ek} />
          ))}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" className="headerButton btn-fill" onClick={() => handleClose()}>
          Close
        </Button>
        <Button variant="secondary" className="headerButton btn-fill" onClick={handleSubmit}>
          Apply
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PersonalizationModal;
