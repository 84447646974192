import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../api";
import { formatLocations } from "../../../utils";
import { LOCATION, LOCATION_FECTH, USER_LOCATION_FECTH, IMPORT_LCOATION } from "./locationsConstants";
// Async thunk to fetch all locations
export const fetchAllLocations = createAsyncThunk(LOCATION_FECTH, async (param, { getState }) => {
  const clients = getState().clients.clients;

  const models = await api.getLocations(param);
  return formatLocations(models, clients);
});

//Async thunk to Fetch user location
export const fetchUserLocation = createAsyncThunk(USER_LOCATION_FECTH, async (param, { getState }) => {
  const location = await api.getUserSite();
  return location;
});

// Async thunk to create a location
export const createLocationAsync = createAsyncThunk("location/createLocation", async (location, { getState }) => {
  const loginUser = getState().auth.user;
  // const response = await api.newLocations(location, loginUser);
  const response = await api.createLocations(location, loginUser);
  return response;
});
export const createLabClient = createAsyncThunk("location/createLabClient", async (location, { getState }) => {
  const loginUser = getState().auth.user;
  // const response = await api.newLocations(location, loginUser);
  const response = await api.createLabClient(location, loginUser);
  return response;
});

export const importLocationAsync = createAsyncThunk(IMPORT_LCOATION, async (location, { getState }) => {
  const loginUser = getState().auth.user;
  const params = location.map((loc) => ({
    ...loc,
    createdBy: loginUser.sub,
    createdByName: loginUser.name,
  }));
  const response = await api.importLabClient(params);
  return response;
});

// Async thunk to update a location
export const updateLocationAsync = createAsyncThunk(
  "location/updateLocation",
  async ({ locationId, updatedlocation }) => {
    const response = await api.updateLocation(locationId, updatedlocation);
    return response;
  }
);
export const updateLabClient = createAsyncThunk(
  "location/updateLabClient",
  async (data, { getState, rejectWithValue }) => {
    try {
      const loginUser = getState().auth.user;
      const response = await api.updateLabClient(data, loginUser);
      return response;
    } catch (error) {
      console.error("Error in updateLabClient:", error);
      return rejectWithValue(error.response?.data || "Unexpected error occurred");
    }
  }
);
export const updateLabClientNotes = createAsyncThunk("location/updateLabClientNotes", async (data, { getState }) => {
  const loginUser = getState().auth.user;
  const response = await api.updateLabClientNotes(data);
  return response;
});

// Async thunk to delete a location
export const deleteLocationAsync = createAsyncThunk("location/deleteLocation", async (locationId) => {
  const response = await api.deleteLocation(locationId);
  return response;
});

const locationSlice = createSlice({
  name: LOCATION,
  initialState: {
    locations: [],
    userLocation: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllLocations.fulfilled, (state, action) => {
        state.locations = action.payload;
      })
      .addCase(createLocationAsync.fulfilled, (state, action) => {
        state.locations.push(action.payload);
      })
      .addCase(createLabClient.fulfilled, (state, action) => {
        state.locations.push(action.payload);
      })
      .addCase(updateLocationAsync.fulfilled, (state, action) => {
        const updatedlocation = action.payload;
        const existinglocationIndex = state.locations.findIndex((location) => location.id === updatedlocation.id);
        if (existinglocationIndex !== -1) {
          state.locations[existinglocationIndex] = updatedlocation;
        }
      })
      .addCase(updateLabClient.fulfilled, (state, action) => {
        const updatedlocation = action.payload;
        const existinglocationIndex = state.locations.findIndex((location) => location.id === updatedlocation.id);
        if (existinglocationIndex !== -1) {
          state.locations[existinglocationIndex] = updatedlocation;
        }
      })
      .addCase(updateLabClientNotes.fulfilled, (state, action) => {
        const updatedlocation = action.payload;
        const existinglocationIndex = state.locations.findIndex((location) => location.id === updatedlocation.id);
        if (existinglocationIndex !== -1) {
          state.locations[existinglocationIndex] = updatedlocation;
        }
      })
      .addCase(deleteLocationAsync.fulfilled, (state, action) => {
        const deletedlocationId = action.payload;
        state.locations = state.locations.filter((location) => location.id !== deletedlocationId);
      })
      .addCase(fetchUserLocation.fulfilled, (state, action) => {
        state.locations = [action.payload];
        state.userLocation = action.payload;
      });
  },
});

export default locationSlice.reducer;
