import React, { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import ErrorMessage from "components/Message/ErrorMessage";
import { ERA_PROCEDURE_CODE_DETAIL_HEADER, countryListAllIsoData, CUSTOM_INS } from "constant";
import ProcedureTable from "./ProcedureTable";
import { getValidSpaces, getStateLength } from "utils";
import MainTable from "components/Table/MainTable";
import { INVOICE_SETTING_PERSONALISATION } from "constants/personalization";
import {
  selectedCompanyAlertSetting,
  selectedCompanySetting,
} from "store/features/companySetting/companySettingSelectors";
import { updateCompanyAlertSetting } from "store/features/companySetting/companySettingSlice";

import InputField from "components/InputField/InputField";
import moment from "moment";

const InvoiceSettingModal = ({ handleClose }) => {
  const dispatch = useDispatch();
  const alertSetting = useSelector(selectedCompanyAlertSetting);
  const companySetting = useSelector(selectedCompanySetting);

  const handelRemoveRow = (row, index) => {
    let newList = [...alertSetting];
    newList.splice(index, 1);
    dispatch(updateCompanyAlertSetting({ invoices: newList }));
  };

  const addInvoiceAttemp = () => {
    let newList = [...alertSetting];
    newList.push({ attemptNo: newList.length + 1, noOfDays: 15 });
    dispatch(updateCompanyAlertSetting({ invoices: newList }));
  };

  const renderTd = (item, row, index) => {
    if (item.itemKey === "action" && index + 1 === alertSetting.length) {
      return (
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }} key={item.id}>
          <td
            className="ellipsis"
            style={{
              textAlign: item.textAlign,
              textOverflow: item.textOverflow,
            }}
            onClick={() => handelRemoveRow(row, index)}
          >
            <label style={{ color: "red", cursor: "pointer" }}>Remove</label>
          </td>
        </div>
      );
    }
    return (
      <td
        key={item.id}
        className={`ellipsis ${item.cssClass ?? ""}`}
        style={{
          textAlign: item.textAlign,
          textOverflow: item.textOverflow,
          color: `${item.colorObj ? item.colorObj[row[item.itemKey]] : ""}`,
          ...item.style,
        }}
        title={row[item.itemKey]}
      >
        {row[item.itemKey]}
      </td>
    );
  };

  return (
    <Modal show backdrop="static" animation={true} onHide={() => handleClose()} centered size={"xl"} keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title className="my-0 text-capitalize" id="contained-modal-title-vcenter">
          Invoice Send Setting
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="plus-icon">
          <i className="fas fa-plus-circle fa-lg cursor-pointer w-auto" onClick={() => addInvoiceAttemp()} />
        </div>
        <MainTable
          individualRowCssClass={(row) => row.isError && "isClaimFalse"}
          cssClass={"overFlow-y-hidden"}
          columns={INVOICE_SETTING_PERSONALISATION}
          rows={alertSetting}
          personalisationKey={"document"}
          widthToSkip={80}
          customColumnCellRenderer={renderTd}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button
          style={{ marginBottom: 10 }}
          variant="primary"
          className="modalButtons headerButton btn-fill"
          onClick={() => {
            handleClose();
          }}
        >
          Close
        </Button>

        <Button style={{ marginBottom: 10 }} variant="secondary" className="modalButtons headerButton btn-fill">
          {"Update"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InvoiceSettingModal;
