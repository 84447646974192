import React, { useState, useContext } from "react";

// react-bootstrap components
import { Card, InputGroup, FormControl } from "react-bootstrap";
import ColorLabel from "components/ColorLabel";
import Select from "react-select";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { selectLoginUser } from "store/features/authentication/authenticationSelectors";
import {
  STATUS_OPTIONS,
  VACCINATED_OPTIONS,
  QA_OPTIONS,
  TEST_OPTIONS,
  weekDaysOptions,
  CHECKIN_OPTIONS,
  ZONE_STATUS_OPTIONS,
  COLOR_OPT,
  SENT_INVOICE_OPT,
  EMPLOYEE_STATUS,
} from "constant";
import { WEEK_DAYS } from "constant";
import { AppContext } from "../context/app-context";
import {
  INSURANCE_PROVIDER,
  NOTE_FILTER,
  ELIGIBLE_STATUS,
  CLAIM_SUBMIT,
  CLAIM_STATUS,
  INSURANCE_STATUS,
  AUTO_SHIP_OPTIONS,
  ELIGIBILITY_STATUS,
} from "constant";
import { useSelector } from "react-redux";
import { ORDER_STATUS_OPT } from "constant";
import { CONFIG } from "constant";
import { EMP_CLAIM_PERSONALIZE } from "constants/personalization";
import { convertToDropDownOption } from "utils";
import CheckBoxButton from "./CheckBoxButton/CheckBoxButton";
import { ADMIN_USERS } from "constant";
import SingleDatePicker from "./Date/SingleDatePicker";
import moment from "moment";
import { selectedCompanySetting } from "store/features/companySetting/companySettingSelectors";
import { CLAIM_INVOICE_PERSONALIZE } from "constants/personalization";
import SwitchToggle from "./SwitchToggle/SwitchToggle";
import { RESUBMIT_CLAIM_OPT, INVOICE_STATUS_OPT, CLAIM_STATUS_OPT } from "constant";

const Filter = ({
  filterTerms,
  setFilter,
  filter,
  isUsersTable,
  triggerFilter,
  setTriggerFilter,
  handleClearFilter,
  isCheckEligibility,
  member,
  employeeClaim,
  claimInvoice,
  personalisationData,
  setShowFilter,
}) => {
  const [clearFilter, setClearFilter] = useState(false);
  const appContext = useContext(AppContext);
  const setting = useSelector(selectedCompanySetting);
  const loginUser = useSelector(selectLoginUser);

  const colorOptions = () => {
    const unique = [...new Set(appContext.programs.map((item) => item.color))];

    return unique.map((p) => {
      return { value: p, label: <ColorLabel color={p} /> };
    });
  };

  const facilityOption = () => {
    return appContext.locations
      .filter((f) => f.id !== CONFIG.generalFacility)
      .map((m) => ({ value: m.id, label: `${m.practiceID}-${m.name}` }));
  };
  const subAgentOption = () => {
    return appContext.subAgents
      .filter((f) => f.id !== CONFIG.generalSubAgent)
      .map((m) => ({ value: m.id, label: m.name }));
  };
  const clientOption = () => {
    return appContext.companies
      .filter((f) => f.id !== CONFIG.generalClient)
      .map((m) => ({ value: m.id, label: m.name }));
  };

  const insurnaceCompanyOpt = () => {
    let value = setting?.otherNPI
      ? setting?.otherNPI?.map((m) => ({ ...m, value: m?.npi, label: m.name }))
      : setting
      ? [{ ...setting, value: setting.npi, label: setting.name }]
      : [];
    value = [{ value: "", label: "All" }, ...value];
    // value = [{ value: "", label: "All" }, ...value];

    return value;
  };

  const usersOption = (user) => {
    if (user) {
      return appContext.users.find((f) => f.phone_number === user.phone_number)?.id;
    }
    return appContext.users
      .filter((f) => !ADMIN_USERS.includes(f.phone_number))
      .map((m) => ({ value: m.id, label: `${m.firstName} ${m.lastName}` }));
  };

  const userNameOption = (user) => {
    return appContext.users
      .filter((f) => !ADMIN_USERS.includes(f.phone_number))
      .map((m) => ({ value: `${m.firstName} ${m.lastName}`, label: `${m.firstName} ${m.lastName}` }));
  };

  const filterAlias = (term) => {
    // Claim Filter keys

    if (personalisationData) {
      return personalisationData.find((f) => f.title === term)?.itemKey;
    }

    if (employeeClaim) {
      switch (term) {
        case "LTCS ID":
          return "pcn";
        case "ID":
          return "id";
        case "Blank":
          return "Blank";
        case "Processed By Secondary Ins":
          return "status_code";
        case "Processed By":
          return "updatedByName";
        case "Resubmitted Claims":
          return "reSubmissionDate";
        case "Crossover Claims":
          return "crossover_carrier";
        case "Assigned To":
          return "assignTo";
        case "Assign To Me":
          return "assignToMe";
        case "Billing Company":
          return "bill_npi";
        case "Submission Date":
          return "submissionDate";
        case "Claim Note":
          return "note";
        case "Claim Reason":
          return "message";
        case "Acc. No":
          return "accNo";
        case "Invoice No":
          return "invoiceID";
        case "By Client":
          return "locationID";
        case "Check No":
          return "paymentCheckNo";
      }
      return EMP_CLAIM_PERSONALIZE.find((f) => f.title === term)?.itemKey;
    }
    if (claimInvoice) {
      switch (term) {
        case "Due Date":
          return "dueDate";
        case "Paid Date":
          return "paidDate";
        case "Invoice ID":
          return "invoiceNo";
      }
      return CLAIM_INVOICE_PERSONALIZE.find((f) => f.title === term)?.itemKey;
    }

    // Except Claim Tab mean Other tab filter keys
    switch (term) {
      case "Crew ID":
        return "id";
      case "Order Date":
        return "orderDate";
      case "Claim Submit Date":
        return "claimSubmitDate";
      case "Claim Submit":
        return "claim";
      case "Name":
        return "name";
      case "First Name":
        return "firstName";
      case "Last Name":
        return "lastName";
      case "Phone":
        if (isUsersTable) {
          return "phone_number";
        } else {
          return "phoneNumber";
        }
      // return "phoneNumber";
      case "Email":
        return "email";
      case "Barcode":
        return "barcode";
      case "Associated":
        return "note";
      case "Status":
        return "status";
      case "Employee Status":
        return "employeeStatus";
      case "Result":
        return "result";
      // case "Lab":
      //   return "lab";
      // case "Show":
      //   return "site_name";
      case "Role":
        return "userRole";
      case "Insurance Status":
        return "InsuranceStatus";
      case "Claim Status":
        return "claimStatus";
      case "Claim Amount":
        return "claimAmount";
      case "Claim Paid":
        return "amountRecieved";
      case "Amount Received Date":
        return "amountRecievedDate";
      case "Contact Name":
        return "contact_name";
      case "Eligibility":
      case "Eligibility Status":
        return "eligibilityStatus";
      case "Contact Phone":
        return "phone_number";
      case "Contact Email":
        return "contact_email";
      case "Test Type":
        return "test_type";
      case "Gender":
        return "gender";
      case "Auto Ship":
        return "autoShipment";
      case "isVaccinated":
        return "isVaccinated";
      case "LTCS ID":
        return "schrID";
      case "Order ID":
        return "orderId";
      case "DOS":
        return "from_date_1";
      case "Insurance Number":
      case "ID Number":
      case "Medicaid Number":
      case "Primary Insurance Number":
        return "medicalNo";
      case "Medicare Number":
        return "medicareNo";
      case "Test Ordered":
        return "testOrdered";
      case "Order Qty":
        return "testQty";
      case "Test Available":
        return "testAvailable";
      case "Updated At":
        return "updatedAt";
      case "Date":
        return "createdAt";
      case "Order Status":
        return "orderStatus";
      case "Claim Status":
        return "claimStatus";
      case "Ordered By":
        return "requestedByName";
      case "Facility":
        return "locationID";
      case "Sub Agent":
        return "subAgentID";
      case "Group ID":
        return "groupId";
      case "Insurance Provider":
      case "Primary Insurance":
        return "payerId";
      case "Secondary Insurance":
        return "secondaryInsurance";
      case "Secondary Insurance Number":
        return "secondaryInsNumber";
      case "Client":
        return "clientID";
      case "Claim ID":
        return "employeeClaimID";
      case "Order Note":
        return "note";
      case "Note":
        return "note";
      case "Tracking Number":
        return "trackingNumber";
      case "B-ID":
        return "remote_claimid";
      case "Paid By":
        return "paid_ins_processed";
      case "Procedure Code":
      case "Diagnosis Code":
        return "code";
      case "Alias":
        return "internalCode";
      case "Procedure Code Desc":
      case "Diagnosis Code Desc":
        return "title";
      case "Charge":
        return "charges";
      case "Ins Name":
        return "Name";
      case "Ins Alias":
        return "shortName";
      case "Electronic Claims":
        return "eClaim";
      case "Electronic Eligibility":
        return "eEligibility";
      case "Electronic ERA":
        return "ERA";
      case "NPI":
      case "Insurance Company":
        return "npi";

      default:
        return term;
    }
  };

  const getDropDown = (data, filterTerm) => {
    const prevData = filter[filterAlias(filterTerm)];

    const prevSelectedVal = prevData
      ? data.filter((d) => d.value === prevData || d.value.indexOf(prevData) !== -1)
      : null;
    return (
      <Select
        options={data}
        blurInputOnSelect={true}
        className="filterSelector"
        defaultValue={null}
        menuPlacement="auto"
        value={prevSelectedVal && prevSelectedVal.length > 0 ? prevSelectedVal[0] : null}
        onChange={(e) => {
          setFilter({
            ...filter,
            [filterAlias(filterTerm)]: e.value,
          });
        }}
      />
    );
  };
  const getMultiDropDown = (data, filterTerm) => {
    const prevData = filter[filterAlias(filterTerm)];
    const prevSelectedVal = prevData ? data.filter((d) => prevData.indexOf(d.value) !== -1) : null;
    return (
      <Select
        options={data}
        blurInputOnSelect={true}
        className="filterSelector"
        defaultValue={null}
        menuPlacement="auto"
        isMulti
        value={prevSelectedVal}
        onChange={(e) => {
          setFilter({
            ...filter,
            [filterAlias(filterTerm)]: e.map((t) => t.value),
          });
        }}
      />
    );
  };

  const getCheckBox = (filterTerm) => (
    <div className="filterSwitchButton">
      <label className="m-0">{filterTerm}:</label>
      <SwitchToggle
        checked={filter[filterAlias(filterTerm)]}
        handleChange={(e) =>
          setFilter({
            ...filter,
            [filterAlias(filterTerm)]: e.target.checked,
          })
        }
      />
    </div>
    // <CheckBoxButton
    //   id={filterTerm}
    //   value={filter[filterAlias(filterTerm)]}
    //   name={filterTerm}
    //   checked={filter[filterAlias(filterTerm)]}
    //   label={filterTerm}
    //   handleChange={(e) =>
    //     setFilter({
    //       ...filter,
    //       [filterAlias(filterTerm)]: e.target.checked,
    //     })
    //   }
    // />
  );
  const lowerCaseKeys = (obj) => {
    const keys = Object.keys(obj);
    const newObj = {};
    for (const key of keys) {
      newObj[key.toLowerCase()] = obj[key];
    }
    return newObj;
  };

  const dateSelection = (filterTerm) => (
    <SingleDatePicker
      onApply={(event, picker) => {
        const date = moment(picker.startDate);
        if (date.isValid()) {
          setFilter({
            ...filter,
            [filterAlias(filterTerm)]: date.format("YYYY-MM-DD"),
          });
        }
      }}
      value={filter[filterAlias(filterTerm)]}
      currentDate={true}
    />
  );

  const dateRangeSelection = (filterTerm) => (
    <DateRangePicker
      onApply={(event, picker) => {
        picker.element.val(picker.startDate.format("MM/DD/YYYY") + " - " + picker.endDate.format("MM/DD/YYYY"));
        setFilter({
          ...filter,
          [filterAlias(filterTerm)]: {
            startDate: picker.startDate,
            endDate: picker.endDate,
          },
        });
      }}
      onCancel={(event, picker) => {
        picker.element.val("");
        setFilter({
          ...filter,
          [filterAlias(filterTerm)]: {},
        });
      }}
      initialSettings={
        filter[filterAlias(filterTerm)]
          ? filter[filterAlias(filterTerm)]
          : {
              drops: "auto",
              autoUpdateInput: false,
              locale: {
                cancelLabel: "Clear",
              },
            }
      }
    >
      <input type="text" placeholder="Select Date Range" readOnly className="dateRangeFilter" defaultValue="" />
    </DateRangePicker>
  );
  const filterContent = (filterTerm) => {
    switch (filterTerm) {
      case "isVaccinated":
        return getDropDown(VACCINATED_OPTIONS, filterTerm);
      case "Active":
        return getDropDown(EMPLOYEE_STATUS, filterTerm);
      case "Status":
      case "Employee Status":
        if (isCheckEligibility) return getDropDown(ELIGIBLE_STATUS, filterTerm);
        if (employeeClaim) return getDropDown(CLAIM_STATUS_OPT, filterTerm);
        if (claimInvoice) return getDropDown(INVOICE_STATUS_OPT, filterTerm);
        return getDropDown(STATUS_OPTIONS, filterTerm);
      case "Sent":
        return getDropDown(SENT_INVOICE_OPT, filterTerm);
      case "Claim Submit":
        return getDropDown(CLAIM_SUBMIT, filterTerm);
      case "Eligibility":
        return getDropDown(ELIGIBILITY_STATUS, filterTerm);
      case "Claim Status":
        return getDropDown(CLAIM_STATUS, filterTerm);
      case "Invoice Status":
        return getDropDown(INVOICE_STATUS_OPT, filterTerm);
      case "Order Status":
        return getDropDown(ORDER_STATUS_OPT, filterTerm);
      case "Insurance Status":
        return getDropDown(INSURANCE_STATUS, filterTerm);
      case "Note":
      case "Order Note":
        return getDropDown(NOTE_FILTER, filterTerm);
      case "Auto Ship":
        return getDropDown(AUTO_SHIP_OPTIONS, filterTerm);
      case "Resubmitted Claims":
        return getDropDown(RESUBMIT_CLAIM_OPT, filterTerm);
      case "By Client":
      case "Facility":
        return getDropDown(facilityOption(), filterTerm);
      case "Sub Agent":
        return getDropDown(subAgentOption(), filterTerm);
      case "Client":
        return getDropDown(clientOption(), filterTerm);
      case "Processed By":
        return getDropDown(userNameOption(), filterTerm);
      case "Assigned To":
        return getDropDown(usersOption(), filterTerm);
      case "Billing Company":
        return getDropDown(insurnaceCompanyOpt(), filterTerm);
      case "Insurance Provider":
      case "Secondary Insurance":
      case "Primary Insurance":
        return getMultiDropDown(
          INSURANCE_PROVIDER.map((ins) => {
            return { value: ins.payerid, label: ins.label };
          }),
          filterTerm
        );
      case "Date":
      case "Order Date":
      case "Updated At":
      case "Claim Submit Date":
      case "Amount Received Date":
      case "DOS":
      case "Paid Date":
      case "Submission Date":
        return dateRangeSelection(filterTerm);
      case "Date of Birth":
      case "DOB":
      case "Due Date":
        return dateSelection(filterTerm);
      case "Processed By Secondary Ins":
      case "Assign To Me":
      case "Crossover Claims":
      case "Electronic Claims":
      case "Electronic Eligibility":
      case "Electronic ERA":
        return getCheckBox(filterTerm);
      case "Blank":
        return getMultiDropDown(convertToDropDownOption(filterTerms, employeeClaim), filterTerm);
      default:
        return (
          <FormControl
            onChange={(e) => {
              setFilter({
                ...filter,
                [filterAlias(filterTerm)]: e.target.value.toLowerCase(),
              });
            }}
            placeholder={`Enter ${filterTerm}`}
            value={filter[filterAlias(filterTerm)]}
          />
        );
    }
  };
  const renderFilters = (filters) => {
    return filters.map((filterTerm) => {
      return (
        <InputGroup key={filterTerm} className="">
          {triggerFilter && (setFilter({ ...filter }), setTriggerFilter(false))}
          <InputGroup.Text
            style={{
              width: "185px",
            }}
          >
            {filterTerm}
          </InputGroup.Text>
          {filterContent(filterTerm)}
        </InputGroup>
      );
    });
  };

  return (
    <div>
      <div className="filterResetBtn">
        {setShowFilter && (
          <span onClick={() => setShowFilter(false)} className="linkedText textUnderLine mr-4">
            Hide Filter
          </span>
        )}
        <span
          onClick={() => {
            if (Object.keys(filter).length === 0) return;
            Array.from(document.querySelectorAll("input")).forEach((input) => (input.value = ""));

            if (filter.hasOwnProperty("orderBy")) {
              setFilter({ orderBy: filter.orderBy, orderByDirection: filter.orderByDirection });
            } else {
              setFilter({});
            }

            if (handleClearFilter) handleClearFilter();
          }}
          className="linkedText textUnderLine"
        >
          Clear Filter
        </span>
      </div>
      <div className="mt-t custom-filters-wrapper">{renderFilters(filterTerms)}</div>
    </div>
  );
};

export default Filter;
