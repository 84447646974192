import React, { useState, useEffect } from "react";
import InputField from "components/InputField/InputField";
import FileInput from "components/Input/FileInput";
import { useSelector } from "react-redux";
import { documentSelector } from "store/features/documents/documentSelectors";
import { Button, Modal } from "react-bootstrap";
import { Storage } from "aws-amplify";

import { CONFIG } from "constant";
import { createUniqueAppealFileName } from "utils";

const UploadAppealDocuments = ({ handleClose, appealId, clientId , setUploadedFilesName}) => {
  const [selectedDocumentType, setSelectedDocumentType] = useState({});

  const [selectedFileName, setSelectedFileName] = useState("");

  const [documentType, setDocumentType] = useState([]);

  const [file, setFile] = useState("");
  const [fileType, setFileType] = useState("");
  const [fileSize, setFileSize] = useState("");

  const handelDocumentChange = (e) => {
    setSelectedDocumentType(e);
  };

  const { documentTypes } = useSelector(documentSelector);

  useEffect(() => {
    const doucumentsTypes = documentTypes.map((item) => {
      return {
        label: item.title,
        value: item.title,
      };
    });
    setDocumentType(doucumentsTypes);
  }, [documentTypes]);

  const handelImageUpload = async () => {
    try {
      const fileName = createUniqueAppealFileName();
      const fileExtension = file.name.split(".").pop();
      const fileNameWithExtension = `${fileName}.${fileExtension}`

      const filePath = `appeal_files/${clientId}/${appealId}/${fileNameWithExtension}`;

      await Storage.put(filePath, file, { bucket: CONFIG.eligibilityBucket });


      setUploadedFilesName({fileName:fileNameWithExtension, docType:selectedDocumentType.value})


    } catch (error) {
      console.log("Error", error);
    }
  };





  return (
    <Modal show backdrop="static" animation={true} onHide={() => handleClose()} centered size={"md"} keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title className="my-0 text-capitalize " id="contained-modal-title-vcenter" style={{}}>
          Upload Appeal Documents
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="table-full-width desktop-noScroll">
        <div className="col-sm-12 createClientsWrapper ">
          <div className="form-group-wrapper">
            <InputField
              type="dropDown"
              groupWrapper="form-group-wrapper me-2 "
              inputStyle="w-100"
              labelStyle="mt-0 text-capitalize"
              label="Document Type:"
              options={documentType}
              placeholder="Select Document Type"
              value={selectedDocumentType}
              handleChange={(e) => handelDocumentChange(e)}
            />
          </div>
        </div>

        <div className="col-sm-12 createClientsWrapper">
          <div className="form-group-wrapper">
            <label className="modalLineHeaders">Document File: </label>
            <FileInput
              setFileType={setFileType}
              setFile={setFile}
              setSelectedFileName={setSelectedFileName}
              selectedFileName={selectedFileName}
              setFileSize={setFileSize}
            />
          </div>
        </div>
        <div className="col-sm-12 createClientsWrapper" style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button variant="secondary" className="modalButtons headerButton btn-fill mb-2" onClick={handelImageUpload}>
            Upload
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UploadAppealDocuments;
