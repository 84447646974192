import axios from "axios";
import { API, Auth } from "aws-amplify";
import api from "../api";
import { userCompanyID } from "utils";

const apiName = "apif79b844d";
const publicApiName = "api4af2df69";

const fetchInsurances = async (param) => {
  let path = "/insurances/list";
  let myInit = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      clientID: userCompanyID.get(),
      ...param,
    },
  };

  try {
    const models = await API.post(apiName, path, myInit);

    return models.rows || [];
  } catch (e) {
    console.log("Error getting users:", e);
    return [];
  }
};

const createInsurances = async (params) => {
  let path = "/insurances";
  let myInit = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      ...params,
    },
  };

  try {
    const models = await API.post(apiName, path, myInit);

    return models;
  } catch (e) {
    console.log("Error getting users:", e);
    return null;
  }
};

const updateInsurances = async (params) => {
  let path = "/insurances";
  let myInit = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
    body: {
      ...params,
    },
  };

  try {
    const models = await API.put(apiName, path, myInit);

    return models;
  } catch (e) {
    console.log("Error getting users:", e);
    return null;
  }
};

export default {
  fetchInsurances,
  createInsurances,
  updateInsurances,
};
