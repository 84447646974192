import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { EMP_CLAIM_PERSONALIZE } from "constants/personalization";
import moment from "moment";
import api, { exportClamDataToExcel } from "../../../api";
import {
  CLAIMS_KEYS,
  CLAIM_EDIT_STATUS,
  CLAIM_SUBMIT_STATUS,
  CONFIG,
  DIAGNOSIS_ITEMS,
  EMP_CLAIM_BLANK_OPTS,
  MEDICARE_ID,
  MESSAGE_MODES,
  ON_HOLD_CPT,
  PARSE_ALPHA_INTO_NUM,
  STATS_OBJECT,
  STATUS_KEY,
  CUSTOM_INS,
} from "../../../constant";
import graphqlApi from "../../../graphqlApi";
import {
  downloadFileFromS3,
  formatClaims,
  formatDateMDY,
  formatEmployeeClaim,
  getDiagonosisCode,
  getFloatVal,
  getIntVal,
  isJSONString,
  removeKeysFromObject,
  removeSpaceIns,
  userCompanyID,
  SYSTEM_TIME_ZONE
} from "../../../utils";
import { setMessage } from "../general/generalAction";
import {
  ASSIGN_CLAIM,
  EMPLOYEE_CLAIM,
  EMPLOYEE_CLAIM_ACKNOWLEGDE,
  EMPLOYEE_CLAIM_CREATE,
  EMPLOYEE_CLAIM_DELETE,
  EMPLOYEE_CLAIM_FETCH,
  EMPLOYEE_CLAIM_UPDATE,
  EMPLOYEE_CLAIM_UPDATE_STATUS,
  EMPLOYEE_CLAIM_CANCEL,
  GET_CLAIM_STATS,
  EMPLOYEE_CLAIM_EXPORT_EXCEL,
} from "./employeeClaimsConstant";
import insServices from "services/InsuranceService";
import { isAllDiagAndProcCodeValidation } from "views/EmployeeClaims/claimFuntions/formatClaim";

const sortUsers = (users) => {
  return [...users].sort((a, b) =>
    `${b["pat_name_f"]}${b["pat_name_l"]}` < `${a["pat_name_f"]}${a["pat_name_l"]}`
      ? 1
      : `${a["pat_name_f"]}${a["pat_name_l"]}` < `${b["pat_name_f"]}${b["pat_name_l"]}`
      ? -1
      : 0
  );
};
// Async thunk to fetch all employeeClaims

export const fetchAllEmployeeClaimsAsync = createAsyncThunk(
  EMPLOYEE_CLAIM_FETCH,
  async ({ dateFilter, page, filter, isNewCall, users }, { getState, dispatch }) => {
    const params = { ...filter, page, timezone: SYSTEM_TIME_ZONE };
    const loginUser = getState().auth.user;

    if (CONFIG.isLabType) {
      Object.assign(params, { clientID: userCompanyID.get() });
    }

    if (dateFilter) {
      const { start, end } = dateFilter;
      if (start) Object.assign(params, { startDate: moment(start.format("YYYY-MM-DD")).startOf("day").toISOString() });
      if (end) Object.assign(params, { endDate: moment(end.format("YYYY-MM-DD")).endOf("day").toISOString() });
    }

    if (filter.submissionDate) {
      const { startDate, endDate } = filter.submissionDate;
      if (startDate)
        Object.assign(params, {
          submissionStartDate: moment(startDate.format("YYYY-MM-DD")).startOf("day").toISOString(),
        });
      if (endDate)
        Object.assign(params, { submissionEndDate: moment(endDate.format("YYYY-MM-DD")).endOf("day").toISOString() });
      delete params.submissionDate;
    }

    if (filter.Blank || filter.Blank?.length > 0) {
      delete params.Blank;
      Object.assign(params, { blanks: filter.Blank.map((element) => EMP_CLAIM_BLANK_OPTS[element]) });
    }

    if (Object.keys(filter).includes("assignToMe")) {
      const user = users.find((f) => f.phone_number === loginUser.phone_number);
      Object.assign(params, { assignTo: filter.assignToMe ? user.id : "" });
    }

    // Compare Params
    if (JSON.stringify(getState().employeeClaims.apiParams) === JSON.stringify(params)) {
      return null;
    }

    const prevAssignToMe = getState().employeeClaims?.apiParams?.assignToMe;
    const newAssignToMe = params?.assignToMe;

    if (prevAssignToMe && newAssignToMe) {
      const prevFetchedList = getState().employeeClaims.employeeClaims || [];
      if (prevFetchedList.length > 0) {
        const newFilteredList = nestedFilter(prevFetchedList, getState().employeeClaims.claimsFilter);
        dispatch(employeeClaimSlice.actions.setFilteredEmployeeClaims(newFilteredList));
        dispatch(employeeClaimSlice.actions.setStatsObj(updateStatsLocally(newFilteredList)));
        return;
      }
    }

    if (isNewCall) {
      Object.assign(params, { page: 1 });
      dispatch(employeeClaimSlice.actions.setPageNo(1));
      dispatch(employeeClaimSlice.actions.setCurrentPage(1));
    }

    let objFilter = { ...filter };

    const res = await api.getClaimList(params);
    if (filter.assignToMe === false) {
      delete objFilter.assignToMe;
      dispatch(employeeClaimSlice.actions.setClaimsFilter(objFilter));
    }

    dispatch(employeeClaimSlice.actions.setAPIParams(params));

    return res;
  }
);

const createChangeObject = (oldObj, newObj) => {
  const changeObject = { oldRecord: {}, newRecord: {} };

  const getSubKeyInfo = (key, value) => {
    return [key, value];
  };

  const processKey = async (key, sourceObj, targetObj) => {
    if (Object.prototype.hasOwnProperty.call(sourceObj, key)) {
      const [newKey, newValue] = getSubKeyInfo(key, sourceObj[key]);
      targetObj[newKey] = newValue;
    }
  };

  // Process keys where values are different or present in newObj but not in oldObj
  const allKeys = new Set([...Object.keys(oldObj), ...Object.keys(newObj)]);

  for (const key of allKeys) {
    if (
      !Object.prototype.hasOwnProperty.call(newObj, key) ||
      JSON.stringify(newObj[key]) !== JSON.stringify(oldObj[key])
    ) {
      processKey(key, newObj, changeObject.newRecord);
      processKey(key, oldObj, changeObject.oldRecord);
    }
  }

  return changeObject;
};

const updateStatsLocally = (claims) => {
  const resultSummary = {
    draft: 0,
    draftAmount: 0,
    submitted: 0,
    submittedAmount: 0,
    rejected: 0,
    rejectedAmount: 0,
    approved: 0,
    approvedAmount: 0,
    denied: 0,
    deniedAmount: 0,
    paid: 0,
    paidAmount: 0,
    partial: 0,
    partialAmount: 0,
    cancelled: 0,
    cancelledAmount: 0,
  };
  for (const item of claims) {
    const { status, paidAmount, total_charge } = item;
    resultSummary[status] = parseInt(resultSummary[status]) + 1;
    resultSummary[`${status}Amount`] =
      resultSummary[`${status}Amount`] + (status === "paid" ? paidAmount : total_charge);
  }

  return resultSummary;
};

// Apply Local Filter For Client Side Action Performs
const nestedFilter = (targetArray, filters) => {
  if (Object.keys(filters).length === 0) return targetArray;
  const filterKeys = Object.keys(filters).filter((f) => !["orderBy", "orderByDirection"].includes(f));
  //filters main array of objects
  const models = targetArray.filter((obj) => {
    //goes through each key being filtered for
    return filterKeys.every((key) => {
      if (filters[key] == "" && !Object.keys(filters[key]).length) {
        return true;
      }

      if (key === "from_date_1") {
        return Object.keys(filters[key]).length > 0
          ? new Date(obj.from_date_1) >= filters[key].startDate?._d &&
              new Date(obj.from_date_1) <= filters[key].endDate?._d
          : obj.from_date_1;
      }
      if (key === "paidAmount" || key === "adjustedAmount") {
        return obj[key] && obj[key].toString().includes(filters[key]);
      }
      if (key === "pat_dob") {
        return obj[key] && formatDateMDY(obj[key]).includes(filters[key]);
      }
      if (key === "Blank" && filters[key].length > 0) {
        return filters[key].some((f) => !obj[EMP_CLAIM_PERSONALIZE.find((t) => t.title === f)?.itemKey]);
      }
      if (key === "status_code") {
        return filters[key] && obj[key] === 2;
      }
      if (key === "reSubmissionDate") {
        return filters[key] && obj[key];
      }
      if (key === "crossover_carrier") {
        return filters[key] && obj[key];
      }
      if (key === "crossover_carrier") {
        return filters[key] && obj[key];
      }
      if (key === "payer_name") {

        return filters[key] && obj[key];
      }
      if (key === "status") {
        const keyData = obj[key];

        if (filters[key] === "patientClient") {
          return CUSTOM_INS.includes(obj.payerid);
        }

        return (
          keyData &&
          CLAIM_EDIT_STATUS[keyData] &&
          filters[key] &&
          typeof CLAIM_EDIT_STATUS[keyData] === "string" &&
          CLAIM_EDIT_STATUS[keyData].toLowerCase().includes(filters[key]?.toLowerCase())
        );
      }
      // return obj[key] && obj[key].toLowerCase().includes(filters[key].toLowerCase());


      if (typeof obj[key] === "string" && typeof filters[key] === "string") {
        return obj[key].toLowerCase().includes(filters[key]?.toLowerCase());
      }
      return false;
    });

  });
  return models;
};

// Parse Diagnosis Code JSON
const parseDiagonosisCode = (obj) => {
  for (const item of DIAGNOSIS_ITEMS) {
    obj[`diag_${PARSE_ALPHA_INTO_NUM[item]}`] = getDiagonosisCode(obj[`diag_${PARSE_ALPHA_INTO_NUM[item]}`]);
  }
  return obj;
};

//  get Stats Summmary Data From API
export const getStatsSummaryClaimAsync = createAsyncThunk(GET_CLAIM_STATS, async ({ users }, { getState }) => {
  const claimFilter = getState().employeeClaims.claimsFilter;
  const params = { ...claimFilter, page: 1, summaryOnly: "y" };
  const loginUser = getState().auth.user;
  if (CONFIG.isLabType) {
    Object.assign(params, { clientID: userCompanyID.get() });
  }

  if (claimFilter.Blank || claimFilter.Blank?.length > 0) {
    delete params.Blank;
    Object.assign(params, { blanks: claimFilter.Blank.map((element) => EMP_CLAIM_BLANK_OPTS[element]) });
  }

  if (Object.keys(claimFilter).includes("assignToMe")) {
    const user = users ? users.find((f) => f.phone_number === loginUser.phone_number) : {};

    Object.assign(params, { assignTo: claimFilter.assignToMe ? user?.id : "" });
  }

  const res = await api.getClaimList(params);

  const stats = {
    ...res.summary,
    rejectted: res.summary.rejected,
    rejecttedAmount: res.summary.rejectedAmount,
    partialProcessed: res.summary.partial,
    partialProcessedAmount: res.summary.partialAmount,
  };

  return stats;
});

// Submit Claim to Claim MD
const claimSubmitToClaimMD = async (claimSubmission, claimtype, res) => {
  const fileName = `${claimtype}/${claimtype}_${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}.csv`;
  let claims = removeKeysFromObject(claimSubmission, CLAIMS_KEYS);
  if (MEDICARE_ID.includes(claims.payerid)) {
    Object.assign(claims, { prov_npi: "", prov_name_f: "", prov_name_l: "", prov_name_m: "", prov_taxid: "" });
  }
  claims = parseDiagonosisCode(claims);
  await api.saveFileOnBucket([{ ...claims, id: res.id, prov_id: "", ref_id: "", ord_prov_id: "" }], fileName);
};

// Async thunk to create a employeeClaim
export const createEmployeeClaimAsync = createAsyncThunk(
  EMPLOYEE_CLAIM_CREATE,
  async ({ claimSubmission, seqNumber, claimStatus, claimtype }, { dispatch, getState }) => {
    // if claim  does'nt have pcn number then assign the pcn number hardly
    if (!claimSubmission.pcn && claimSubmission.employeeID) {
      const employees = getState().employees.employees;
      const emp = employees.find((f) => f.id === claimSubmission.employeeID);
      Object.assign(claimSubmission, { pcn: emp.schrID });
    }
    let res = await graphqlApi.createClaimGQL(claimSubmission, seqNumber, claimStatus);
    if (res) {
      if (claimStatus === CLAIM_SUBMIT_STATUS.submitted) {
        //  Claim Submitted to Claim MD
        await claimSubmitToClaimMD(claimSubmission, claimtype, res);
        dispatch(setMessage("Claim Sent Successfully", MESSAGE_MODES.success));
      } else {
        dispatch(setMessage("Claim Created Successfully", MESSAGE_MODES.success));
      }
    }
    return res;
  }
);

// Async thunk to update a employeeClaim
export const updateEmployeeClaimAsync = createAsyncThunk(
  EMPLOYEE_CLAIM_UPDATE,
  async ({ claimSubmission, claimStatus, claimtype, oldClaim }, { getState, dispatch }) => {
    const stats = getState().employeeClaims.statsObj;
    const loginUser = getState().auth.user;
    const updatedObject = {
      ...claimSubmission,
      updatedBy: loginUser?.sub,
      updatedByName: loginUser?.name,
      ...(claimStatus === "submitted" && {
        submittedBy: loginUser?.sub,
        submittedByName: loginUser?.name,
      }),
    };
    const res = await graphqlApi.updateEmployeeClaimsGQL(updatedObject);
    if (res.newImage && claimStatus === CLAIM_SUBMIT_STATUS.submitted) {
      //  Claim Submitted to Claim MD
      await claimSubmitToClaimMD(updatedObject, claimtype, res.newImage);
      dispatch(setMessage("Claim Sent Successfully", MESSAGE_MODES.success));
    } else {
      if (oldClaim !== "noAlert") {
        dispatch(setMessage("Claim Updated Successfully", MESSAGE_MODES.success));
      }
    }

    dispatch(employeeClaimSlice.actions.setStatsObj(updateStats(stats, res.newImage, res.oldImage, "MODIFY", false)));

    return [res.newImage];
  }
);

export const updateSubmittedClaimAsync = createAsyncThunk(
  EMPLOYEE_CLAIM_UPDATE,
  async ({ claimSubmission }, { getState, dispatch }) => {
    const stats = getState().employeeClaims.statsObj;
    const loginUser = getState().auth.user;
    const updatedObject = {
      ...claimSubmission,
      updatedBy: loginUser?.sub,
      updatedByName: loginUser?.name,
    };
    const res = await graphqlApi.updateSubmittedClaimsGQL(updatedObject);
    dispatch(setMessage("Claim Updated Successfully", MESSAGE_MODES.success));
    dispatch(employeeClaimSlice.actions.setStatsObj(updateStats(stats, res.newImage, res.oldImage, "MODIFY", false)));
    return [res.newImage];
  }
);

// fetch inusruance modal
const fetchInsuranceWithPayerID = async (ids) => {
  const formattedIds = ids.map((id) => `'${id}'`).join(", ");
  let payerList = await insServices.fetchInsurances({ payerids: formattedIds });
  const payerMap = new Map(payerList.map((payer) => [payer.payerId, payer]));
  const settingObj = {};

  for (const id of ids) {
    const payerRecord = payerMap.get(id);
    let objToSet = {
      bill_npi: CONFIG.DEFAULT_NPI,
      eClaim: "yes",
      eEligibility: true,
    };
    if (payerRecord) {
      objToSet = {
        bill_npi: payerRecord.npi,
        eClaim: payerRecord.eClaim ? "yes" : "no",
        eEligibility: payerRecord.eEligibility,
      };
    }
    settingObj[id] = objToSet;
  }
  return settingObj;
};

// Async thunk to Bulk Submit  employeeClaims
export const updateBulkSubmitEmployeeClaimAsync = createAsyncThunk(
  EMPLOYEE_CLAIM_UPDATE,
  async ({ claimSubmissions, claimStatus, claimtype }, { getState, dispatch }) => {
    let stats = getState().employeeClaims.statsObj;
    let user = getState().auth.user;
    let providers = getState().providers.providers;
    const setting = await api.getCompanySetting();

    if (claimStatus === CLAIM_SUBMIT_STATUS.submitted) {
      let claimSubmitted = [];
      let notSubmitClaim = [];

      const uniqueIds = [...new Set(claimSubmissions.map((item) => item.payerid))];

      // fetch insurance Modal and Check e-claim and e-eligibility
      const payerList = await fetchInsuranceWithPayerID(uniqueIds);
      let ErrorList = [];
      for (const subClaim of claimSubmissions) {
        const claim = { ...subClaim, ...payerList[subClaim.payerid] };

        if (CONFIG.isBioLab) {
          const isOnHoldCPT = claim.proc_array.some((s) => removeSpaceIns(s.proc_code).includes(ON_HOLD_CPT));
          let isOnHoldICT = null;
          if (isOnHoldCPT || isOnHoldICT) {
            notSubmitClaim.push({ ...claim, error: "CPT on Hold" });
            continue;
          }
        }

        if (claim.prov_id && (!claim.provider || !claim.provider?.prov_id)) {
          const findProvider = providers.find((f) => f.id === claim.prov_id);
          if (findProvider) {
            claim.provider = {
              ...(claim.provider && {}),
              prov_npi: findProvider.npi,
              prov_name_f: findProvider.firstName,
              prov_name_l: findProvider.lastName,
              prov_name_m: findProvider.middleName,
              prov_taxid: findProvider.taxid,
            };
          }
        }
        // Check Validation
        const getAnyError = isAllDiagAndProcCodeValidation(claim, "submitted");
        if (getAnyError) {
          notSubmitClaim.push({ ...claim, error: getAnyError });
          continue;
        }

        // Format Claims For Claim MD format
        const formatedClaim = formatEmployeeClaim(claim, setting, true);

        const newClaim = {
          ...formatedClaim,
          status: "submitted",
          prov_id: "",
          ref_id: "",
          ord_prov_id: "",
          status: claimStatus,
          isUpdate: true,
          submittedBy: user.sub,
          submittedByName: user.name,
        };

        if (MEDICARE_ID.includes(newClaim.payerid)) {
          Object.assign(newClaim, { prov_npi: "", prov_name_f: "", prov_name_l: "", prov_name_m: "", prov_taxid: "" });
        }

        let claimObj = removeKeysFromObject(newClaim, CLAIMS_KEYS);
        claimObj = parseDiagonosisCode(claimObj);
        claimSubmitted.push(claimObj);
        stats = updateStats(stats, newClaim, claim, "MODIFY", false);
      }

      const fileName = `${claimtype}/${claimtype}_multi_${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}.csv`;
      if (claimSubmitted.length > 0) {
        await api.saveFileOnBucket(claimSubmitted, fileName);
        dispatch(
          setMessage(
            `${claimSubmitted.length} Claim(s) Sent Successfully${
              notSubmitClaim.length > 0 ? `and ${notSubmitClaim.length} claim(s) having an issue` : ""
            }`,
            MESSAGE_MODES.success
          )
        );
        dispatch(employeeClaimSlice.actions.setStatsObj(stats));
      } else {
        dispatch(setMessage(`All ${notSubmitClaim.length} claim(s) having an issue`, MESSAGE_MODES.error));
      }
      return claimSubmissions;
    }
  }
);

// Async Thunk to Acknowledge Claim
export const acknowledgeClaimAsync = createAsyncThunk(EMPLOYEE_CLAIM_ACKNOWLEGDE, async ({ data, loginUser }) => {
  const res = await graphqlApi.acknowledgeClaimGQL(data, loginUser);
  return [res];
});

// Async thunk to update a employee Claim Status
export const updateStatusEmployeeClaimAsync = createAsyncThunk(
  EMPLOYEE_CLAIM_UPDATE_STATUS,
  async ({ claimsList, newDate, newStatus, reason, filter, timeFilter, eraCancel }, { getState, dispatch }) => {
    if (eraCancel) {
      await api.cancelClaimERA({ params: { ...timeFilter, ...filter, ids: eraCancel } });

      return [];
    } else {
      if (claimsList.length === 0) return;

      const loginUser = getState().auth.user;

      let stats = getState().employeeClaims.statsObj;

      const updatedClaims = [];

      for (let i = 0; i < claimsList.length; i++) {
        const claimToBeUpdated = { ...claimsList[i] };
        claimToBeUpdated.status = newStatus.value;
        claimToBeUpdated.message = reason?.replace(/'/g, "") || "";

        const res = await graphqlApi.updateEmployeeClaimStatusWithLogsGQL(claimToBeUpdated, newDate, loginUser);
        stats = updateStats(stats, res.newImage, res.oldImage, "MODIFY", false);
        updatedClaims.push(res.newImage);
      }
      dispatch(employeeClaimSlice.actions.setStatsObj(stats));
      dispatch(setMessage("Claim Status Updated Successfully!", MESSAGE_MODES.success));

      return updatedClaims;
    }
  }
);

// Async thunk to update a employeeClaim
export const assignEmployeeClaimAsync = createAsyncThunk(ASSIGN_CLAIM, async (claimsArr, { dispatch, getState }) => {
  if (!claimsArr || claimsArr?.items?.length === 0) return;

  await api.removeAssignTo(claimsArr);

  const msg =
    "Hello, claims have been assigned to you. Please log into the MedFlow Revenue Management portal to find out more information";
  await api.sendSMSNotification(claimsArr[0]?.assignUserNumber, msg);

  dispatch(
    setMessage(
      `${claimsArr?.items?.length} Claim${claimsArr?.items?.length === 1 ? "" : "s"} Assigned Successfully!`,
      MESSAGE_MODES.success
    )
  );
  return [];
});

// Async thunk to delete a employeeClaim
export const deleteEmployeeClaimAsync = createAsyncThunk(
  EMPLOYEE_CLAIM_DELETE,
  async ({ userToDelete, empsFilter }, { dispatch }) => {
    const deletedClaimIDs = [];
    const ttl = empsFilter.length;
    if (userToDelete || ttl > 0) {
      if (userToDelete) {
        const res = await graphqlApi.deleteEmployeeClaimGQL(userToDelete.id);
        deletedClaimIDs.push(res);
      } else if (ttl > 0) {
        for (let i = 0; i < ttl; i++) {
          const res = await graphqlApi.deleteEmployeeClaimGQL(empsFilter[i].id);
          deletedClaimIDs.push(res);
        }
      }
    }
    dispatch(setMessage("Claim Deleted Successfully", MESSAGE_MODES.success));
    return deletedClaimIDs;
  }
);

const updateStats = (stats, newImage, oldImage, eventName, isDeleted) => {
  let companyStats = { ...stats };
  try {
    const keyName = STATUS_KEY[newImage.status];
    const amountKey = `${keyName}Amount`;

    const value = getIntVal(companyStats[keyName]);
    const amount = getFloatVal(companyStats[amountKey]);

    // new record inserted then increment the number and amount
    if (eventName === "INSERT") {
      companyStats[keyName] = value + 1;
      companyStats[amountKey] = amount + getFloatVal(newImage.total_charge);
    }

    // when record is deleted then decrement the number and subtract the amount
    else if (isDeleted && value > 0) {
      companyStats[keyName] = value - 1;
      if (amount >= getFloatVal(newImage?.total_charge)) {
        companyStats[amountKey] = amount - getFloatVal(newImage?.total_charge);
      }
    }

    if (eventName === "MODIFY" && !isDeleted && oldImage) {
      const oldKey = STATUS_KEY[oldImage.status];
      const oldAmountKey = `${oldKey}Amount`;

      const oldValue = getIntVal(companyStats[oldKey]);
      const oldAmount = getFloatVal(companyStats[oldAmountKey]);
      let oldClaimAmount = getFloatVal(oldImage.total_charge);
      if (newImage.status === "paid" || newImage.status === "partial") {
        oldClaimAmount = getFloatVal(newImage.paidAmount);
      }
      if (oldValue > 0) {
        companyStats[oldKey] = oldValue - 1;
      }
      if (oldAmount >= oldClaimAmount) {
        companyStats[oldAmountKey] = oldAmount - oldClaimAmount;
      }
    }

    if (eventName === "MODIFY" && !isDeleted && newImage) {
      const newKey = STATUS_KEY[newImage.status];

      const newAmountKey = `${newKey}Amount`;

      const newValue = getIntVal(companyStats[newKey]);
      const newAmount = getFloatVal(companyStats[newAmountKey]);
      let newClaimAmount = getFloatVal(newImage.total_charge);

      if (newImage.status === "paid" || newImage.status === "partial") {
        newClaimAmount = getFloatVal(newImage.paidAmount);
      }

      companyStats[newKey] = newValue + 1;
      companyStats[newAmountKey] = newAmount + newClaimAmount;
    }
  } catch (e) {
    console.log("[updateStats]", e);
  }
  return companyStats;
};

export const exportClaimDataToExcelAsync = createAsyncThunk(
  EMPLOYEE_CLAIM_EXPORT_EXCEL,
  async ({ selectedColumn }, { getState }) => {
    const claimParams = {
      params: {
        selectedColumn,
        ...getState().employeeClaims.apiParams,
      },
    };
    const result = await exportClamDataToExcel(claimParams);
    await downloadFileFromS3(result.fileName, result.fileName, CONFIG.eligibilityBucket);
  }
);

export const cancelEmployeeClaimAsync = createAsyncThunk(
  EMPLOYEE_CLAIM_CANCEL,
  async ({ claimsList, newDate, newStatus, reason, filter, timeFilter }, { getState, dispatch }) => {
    const response = await api.cancelClaimERA({ params: { ...timeFilter, ...filter, ids: claimsList } });
  }
);

const employeeClaimSlice = createSlice({
  name: EMPLOYEE_CLAIM,
  initialState: {
    employeeClaims: [],
    filteredEmployeeClaims: [],
    claimsFilter: {},
    claimTimeFilter: {},
    apiParams: {},
    statsObj: STATS_OBJECT,
    totalRecord: 0,
    currentPage: 1,
    pageNo: 1,
    claimReSubmissionModal: null,
  },
  reducers: {
    setFilteredEmployeeClaims: (state, action) => {
      state.filteredEmployeeClaims = action.payload;
    },
    setPageNo: (state, action) => {
      state.pageNo = action.payload;
    },
    setStatsObj: (state, action) => {
      state.statsObj = action.payload;
    },
    setClaimsFilter: (state, action) => {
      state.claimsFilter = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setClaimTimeFilter: (state, action) => {
      state.claimTimeFilter = action.payload;
    },
    setAPIParams: (state, action) => {
      state.apiParams = action.payload;
    },
    setClaimReSubmissionModal: (state, action) => {
      state.claimReSubmissionModal = action.payload;
    },
    updateNotes: (state, action) => {
      const data = action.payload;
      const emp = state.employeeClaims.find((f) => f.id === data.id);
      emp["note"] = data.note;

      const existingemployeeIndex = state.employeeClaims.findIndex((employee) => employee.id === emp.id);
      const existingFilteremployeeIndex = state.filteredEmployeeClaims.findIndex((employee) => employee.id === emp.id);
      if (existingemployeeIndex !== -1) {
        state.employeeClaims[existingemployeeIndex] = emp;
      }
      if (existingFilteremployeeIndex !== -1) {
        state.filteredEmployeeClaims[existingFilteremployeeIndex] = emp;
      }
    },
    updateClaimSubs: (state, action) => {
      let existingClaimIndex = state.employeeClaims.findIndex((f) => f.id === action.payload.id);
      // console.log('existingClaimIndex',existingClaimIndex)
      const claim = formatClaims([action.payload]);

      const isClaimInFilter = nestedFilter(claim, state.claimsFilter);

      if ((existingClaimIndex !== -1)  ) {
        // console.log('I am here inside if')
        // console.log('isClaimInFilter',isClaimInFilter)
        if (isClaimInFilter.length > 0) {
          state.employeeClaims[existingClaimIndex] = claim[0];
        } else {
          state.employeeClaims = state.employeeClaims.filter((item) => item.id !== claim[0].id);
        }


        const statsValue = updateStats(
          { ...state.statsObj },
          claim[0],
          state.employeeClaims[existingClaimIndex],
          "MODIFY",
          false
        );
        state.statsObj = statsValue;
      }

      let existingFilteredClaimIndex = state.filteredEmployeeClaims.findIndex((f) => f.id === action.payload.id);

      if (existingFilteredClaimIndex !== -1 ) {
        if (isClaimInFilter.length > 0) {
          state.filteredEmployeeClaims[existingFilteredClaimIndex] = claim[0];
        } else {
          state.filteredEmployeeClaims = state.filteredEmployeeClaims.filter((item) => item.id !== claim[0].id);
        }
      }
    },
    createClaimSubs: (state, action) => {
      if (state.employeeClaims.findIndex((f) => f.id === action.payload.id) === -1) {
        const claim = formatClaims([action.payload]);
        const isClaimInFilter = nestedFilter(claim, state.claimsFilter);
        if (isClaimInFilter.length > 0) {
          state.statsObj = updateStats({ ...state.statsObj }, isClaimInFilter[0], null, "INSERT", false);
        }
        state.employeeClaims.unshift(claim[0]);
        state.filteredEmployeeClaims.unshift(claim[0]);
        state.filteredEmployeeClaims = nestedFilter(state.filteredEmployeeClaims, state.claimsFilter);
        state.totalRecord = state.filteredEmployeeClaims.find((f) => f.id === claim[0].id)
          ? parseInt(state.totalRecord)
          : parseInt(state.totalRecord) + 1;
      }
    },
    deleteClaimSubs: (state, action) => {
      if (state.employeeClaims.findIndex((f) => f.id === action.payload.id) !== -1) {
        const deletedClaim = action.payload;
        state.employeeClaims = state.employeeClaims.filter((employeeClaim) => employeeClaim.id !== deletedClaim.id);
        state.filteredEmployeeClaims = state.filteredEmployeeClaims.filter(
          (employeeClaim) => employeeClaim.id !== deletedClaim.id
        );
        state.statsObj = updateStats({ ...state.statsObj }, deletedClaim, null, "", true);
        state.totalRecord = parseInt(state.totalRecord) - 1;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllEmployeeClaimsAsync.fulfilled, (state, action) => {
        const res = action.payload;
        if (!res) return;
        // const claims = sortUsers(formatClaims(res.rows));
        // const models = sortUsers([...state.employeeClaims, ...claims]);
        const claims = formatClaims(res.rows);
        const models = [...state.employeeClaims, ...claims];
        if (state.pageNo === 1) {
          state.statsObj = {
            ...res.summary,
            rejectted: res.summary.rejected,
            rejecttedAmount: res.summary.rejectedAmount,
            partialProcessed: res.summary.partial,
            partialProcessedAmount: res.summary.partialAmount,
          };
          state.totalRecord = parseInt(res.total);
          state.employeeClaims = claims;
          state.filteredEmployeeClaims = claims;
        } else {
          state.employeeClaims = models;
          state.filteredEmployeeClaims = models;
        }

        // setFilteredEmployeeClaims(sortUsers(filterDates(nestedFilter(models, filter), timeFilter), sortBy));
      })
      .addCase(getStatsSummaryClaimAsync.fulfilled, (state, action) => {
        state.statsObj = action.payload;
      })
      .addCase(createEmployeeClaimAsync.fulfilled, (state, action) => {
        if (state.employeeClaims.findIndex((f) => f.id === action.payload.id) === -1) {
          const claim = formatClaims([action.payload]);
          state.employeeClaims.unshift(claim[0]);
          state.filteredEmployeeClaims.unshift(claim[0]);
          state.statsObj = updateStats({ ...state.statsObj }, claim[0], null, "INSERT", false);
          state.filteredEmployeeClaims = nestedFilter(state.filteredEmployeeClaims, state.claimsFilter);
        }
      })

      .addCase(deleteEmployeeClaimAsync.fulfilled, (state, action) => {
        const deletedClaims = action.payload;
        const deletedemployeeClaimIds = deletedClaims.map((m) => m.id);
        state.employeeClaims = state.employeeClaims.filter(
          (employeeClaim) => !deletedemployeeClaimIds.includes(employeeClaim.id)
        );
        state.filteredEmployeeClaims = state.filteredEmployeeClaims.filter(
          (employeeClaim) => !deletedemployeeClaimIds.includes(employeeClaim.id)
        );
        for (let i = 0; i < deletedClaims.length; i++) {
          const claim = deletedClaims[i];
          state.statsObj = updateStats({ ...state.statsObj }, claim, null, "", true);
        }
      })
      .addMatcher(
        (action) =>
          [
            updateEmployeeClaimAsync,
            acknowledgeClaimAsync,
            updateStatusEmployeeClaimAsync,
            updateBulkSubmitEmployeeClaimAsync,
          ].some((thunk) => action.type.startsWith(thunk.fulfilled.type)),
        (state, action) => {
          const claims = formatClaims(action.payload);

          for (let i = 0; i < claims.length; i++) {
            const updatedemployeeClaim = { ...claims[i] };
            const existingemployeeClaimIndex = state.employeeClaims.findIndex(
              (employeeClaim) => employeeClaim.id === updatedemployeeClaim.id
            );
            if (existingemployeeClaimIndex !== -1) {
              state.employeeClaims[existingemployeeClaimIndex] = updatedemployeeClaim;
            }
            const existingfilteredEmployeeClaimIndex = state.filteredEmployeeClaims.findIndex(
              (employeeClaim) => employeeClaim.id === updatedemployeeClaim.id
            );
            if (existingfilteredEmployeeClaimIndex !== -1) {
              state.filteredEmployeeClaims[existingfilteredEmployeeClaimIndex] = updatedemployeeClaim;
            }
          }
          state.filteredEmployeeClaims = nestedFilter(state.filteredEmployeeClaims, state.claimsFilter);
        }
      );
  },
});

export const {
  setPageNo,
  setFilteredEmployeeClaims,
  updateNotes,
  setStatsObj,
  updateClaimSubs,
  createClaimSubs,
  deleteClaimSubs,
  setClaimReSubmissionModal,
  setClaimsFilter,
  setClaimTimeFilter,
  setCurrentPage,
} = employeeClaimSlice.actions;

export default employeeClaimSlice.reducer;
