import { fetchAllCompanies, sigInUserAsync, getCurrentUserAsync, getListenerDataAsync } from "./authenticationSlice";

export const getAllCompanies = () => (dispatch) => {
  dispatch(fetchAllCompanies());
};

export const signInUser = (username, password) => (dispatch) => {
  return dispatch(sigInUserAsync({ username, password }));
};
export const getCurrentUser = () => async (dispatch) => {
  await dispatch(getCurrentUserAsync());
};

export const getListenerData = (param) => (dispatch) => {
  dispatch(getListenerDataAsync(param));
};
