import React, { useMemo, useState } from "react";
import { Card, Table, Button } from "react-bootstrap";
import { setMessage } from "store/features/general/generalAction";
import ChargeInfoRow from "./ChargeInfoRow";
import { ERA_PROCEDURE_CODE_DETAIL_HEADER, MESSAGE_MODES } from "constant";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginUser } from "store/features/authentication/authenticationSelectors";
import { formatDateMDY, tdRowDropdown, getFloatVal } from "utils";
import DraftInvoiceModal from "./DraftInvoiceModal/DraftInvoiceModal";
import { STATUS_CODE_METHOD } from "constant";
import { CUSTOM_INS } from "constant";
import { formatCurrency } from "utils";
import api from "api";
import { CONFIRMATION_TYPE } from "constant";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import Loader from "components/Loader/Loader";

const ProcedureInfoRow = (props) => {
  const {
    data,
    index,
    handleOpenEraFile,
    claim,
    handleShowLogs,
    isViewAble,
    setEraList,
    isNotEditAble,
    setSelectedERA,
  } = props;
  const [open, setOpen] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState({ isShow: false });
  const [openDraftModal, setOpenDraftModal] = useState(false);
  const [filteredData, setFilteredData] = useState(data);
  const [updatedChargeArray, setUpdatedChargeArray] = useState([]);
  const [checkboxes, setCheckboxes] = useState([]);
  const [loading, setLoading] = useState(false);
  const loginUser = useSelector(selectLoginUser);

  const dispatch = useDispatch();
  const paidBy = `${data.payer_name}
  ${data.status_code === "1" ? " as Primary" : data.status_code === "2" ? " as Secondary" : ""}`;

  const eraStatus = () => {
    if (data.total_paid > 0) return "Paid";
    //if (getFloatVal(data.total_allowed) > 0) return "Paid";
    if (data.total_paid < 0) return "Refund";
    return "Denied";
  };

  const autoUpdateAllowed = (obj, key) => {
    if (key === "allowed") return "";
    return getFloatVal(obj.deductible) + getFloatVal(obj.copay) + getFloatVal(obj.co_insurance) + getFloatVal(obj.paid);
  };

  const handleResetEra = () => {
    setUpdatedChargeArray([]);
    setFilteredData(data);
  };

  const claimProcCodes = useMemo(()=>{
    return claim.proc_array.map((p)=> p.proc_code).join(',');
  },[]);

  const handleCheckboxSelect = (event, chgid) => {
    let processCheckbox = [...checkboxes];
    if (event.target.checked) {
      processCheckbox.push(chgid);
    } else {
      processCheckbox = processCheckbox.filter((f) => f !== chgid);
    }
    setSelectedERA((prev) => {
      let prevSelectedERA = [...prev];

      if (event.target.checked) {
        prevSelectedERA.push(chgid);
      } else {
        const indexToRemove = prevSelectedERA.indexOf(chgid);

        if (indexToRemove !== -1) {
          prevSelectedERA.splice(indexToRemove, 1);
        }
      }
      return [...prevSelectedERA];
    });
    setCheckboxes(processCheckbox);
  };

  const generateDifferenceOfChargeAmount = (claim, newCharge) => {
    let differenceArray = [];
    for (const item of claim.charge) {
      const newChargeIndex = newCharge.findIndex((f) => f.chgid === item.chgid);
      if (newChargeIndex === -1) continue;
      differenceArray.push({
        proc_code: item.proc_code,
        chgid: item.chgid,
        charge: item.charge,
        paid: newCharge[newChargeIndex].paid - getFloatVal(item.paid),
        co_insurance: newCharge[newChargeIndex].co_insurance - getFloatVal(item.co_insurance),
        allowed: newCharge[newChargeIndex].allowed - getFloatVal(item.allowed),
        copay: newCharge[newChargeIndex].copay - getFloatVal(item.copay),
        deductible: newCharge[newChargeIndex].deductible - getFloatVal(item.deductible),
        write_off: newCharge[newChargeIndex].write_off - getFloatVal(item.write_off),
      });
    }
    return differenceArray;
  };

  const convertChangedFieldIntoNumber = (obj) => {
    let processData = { ...obj };
    const detailsColumns = ["allowed", "deductible", "co_insurance", "copay", "write_off", "paid"];
    detailsColumns.forEach((field) => {
      processData[field] = getFloatVal(processData[field]);
    });
    return processData;
  };

  const handleUpdateCharge = (updatedCharge, procArray, updatedChargeIndex) => {
    const updatedChargeArrayCopy = [...updatedChargeArray];
    const existingIndex = updatedChargeArrayCopy.findIndex((charge) => charge.uuid === updatedCharge.uuid);

    if (
      JSON.stringify(convertChangedFieldIntoNumber(updatedCharge)) !==
      JSON.stringify(convertChangedFieldIntoNumber(procArray[updatedChargeIndex]))
    ) {
      if (existingIndex !== -1) {
        updatedChargeArrayCopy[existingIndex] = convertChangedFieldIntoNumber(updatedCharge);
      } else {
        updatedChargeArrayCopy.push(convertChangedFieldIntoNumber(updatedCharge));
      }
    } else if (existingIndex !== -1) {
      updatedChargeArrayCopy.splice(existingIndex, 1);
    }

    setUpdatedChargeArray(updatedChargeArrayCopy);
  };

  const getChangeObjectIndex = (items, editedObject) => {
    const index = items?.findIndex((f) => {
      const isUuidMatch = f.uuid && f.uuid === editedObject.uuid;
      const isChgidMatch = f.chgid !== "NULL" && f.chgid === editedObject.proc_id;
      return isUuidMatch || isChgidMatch;
    });
    return index;
  };

  const handleChargeEdit = (claimIndex, chargeIndex, fieldName, newValue, proc_id, editedObject) => {
    
    const chargeUpdateFunc = (prev) => {
      const proc_array = [...prev.charge];
      const realChargeArr = [...data.charge];

      const realChargeIndex = getChangeObjectIndex(realChargeArr, editedObject);
      const updatedChargeIndex = getChangeObjectIndex(proc_array, editedObject);

      if (updatedChargeIndex === -1) return prev;

      const updatedCharge = { ...proc_array[updatedChargeIndex] };
      Object.assign(updatedCharge, { [fieldName]: newValue ?? null });

      let allowedAmount = autoUpdateAllowed(updatedCharge, fieldName);

      if (allowedAmount !== "") {
        Object.assign(updatedCharge, { allowed: allowedAmount });
      }

      handleUpdateCharge(updatedCharge, realChargeArr, realChargeIndex);

      proc_array[updatedChargeIndex] = updatedCharge;

      return { ...prev, charge: proc_array };
    };
    if (!isViewAble && !isNotEditAble) {
      setFilteredData(chargeUpdateFunc(filteredData));
    }
  };

  const handleConfirm = async (isConfirm, type) => {
    setOpenConfirmation({ isShow: false });
    if (!isConfirm) return;
    switch (type) {
      case CONFIRMATION_TYPE.updateERA:
        handleEditERA();
        break;
    }
  };

  const totalOfAmounts = useMemo(() => {
    if (filteredData.charge && filteredData.charge.length > 0) {
      return filteredData.charge.reduce((result, obj) => {
        let resObj = { ...result };

        resObj["total_Billed"] = (resObj["total_Billed"] || 0) + getFloatVal(obj.charge);
        resObj["total_allowed"] = (resObj["total_allowed"] || 0) + getFloatVal(obj.allowed);
        resObj["total_deductible"] = (resObj["total_deductible"] || 0) + getFloatVal(obj.deductible);
        resObj["total_co_insurance"] = (resObj["total_co_insurance"] || 0) + getFloatVal(obj.co_insurance);
        resObj["total_copay"] = (resObj["total_copay"] || 0) + getFloatVal(obj.copay || 0);
        resObj["total_write_off"] = (resObj["total_write_off"] || 0) + getFloatVal(obj.write_off);

        resObj["chargeOfTotal"] = (resObj["chargeOfTotal"] || 0) + getFloatVal(obj.paid || 0);

        return resObj;
      }, {});
    }
  }, [filteredData.charge]);

  const handleEditERA = async () => {
    try {
      const params = {
        charge: updatedChargeArray,
        claimID: claim.id,
        id: data.id,
        total_paid: totalOfAmounts.chargeOfTotal,
        total_allowed: totalOfAmounts.total_allowed,
        total_copay: totalOfAmounts.total_copay,
        total_deductible: totalOfAmounts.total_deductible,
        total_write_off: totalOfAmounts.total_write_off,
        total_co_insurance: totalOfAmounts.total_co_insurance,
        diff_value: generateDifferenceOfChargeAmount(data, updatedChargeArray),
        userName: loginUser.name,
        userID: loginUser.sub,
        payer_icn: filteredData.payer_icn,
      };
      setLoading(true);

      const res = await api.updateEraApi(params);

      if (res) setUpdatedChargeArray([]);

      setEraList((prev) => {
        return prev.map((m) => {
          if (m.id === filteredData.id) return filteredData;
          return m;
        });
      });
      dispatch(setMessage("Successfully Update ERA", MESSAGE_MODES.success));
      setLoading(false);
    } catch (err) {
      console.log("Error:-", err.message);
      dispatch(setMessage(err.message, MESSAGE_MODES.error));
      setLoading(false);
    }
  };

  return (
    <>
      <tr index={index} className={open ? "border-bottom-0" : ""}>
        {tdRowDropdown(true, open, () => setOpen(!open), open ? "border-bottom-0" : "")}
        <td className={`text-center ${open ? "border-bottom-0" : ""}`}>{formatDateMDY(data.paid_date)}</td>
        <td className={`text-center ${open ? "border-bottom-0" : ""}`}>{data.payer_icn}</td>
        <td className={`text-center ${open ? "border-bottom-0" : ""} ellipsis`}>{data.prov_name}</td>
        <td className={`text-center ${open ? "border-bottom-0" : ""}`}>{STATUS_CODE_METHOD[data.status_code]}</td>
        <td className={`text-center ${open ? "border-bottom-0" : ""}`}>{data.check_number}</td>
        <td className={`text-center ${open ? "border-bottom-0" : ""} text-capitalize`}>{eraStatus()}</td>
        <td className={`text-center ${open ? "border-bottom-0" : ""} ellipsis`} title={paidBy}>
          {paidBy}
        </td>
        <td className={`text-center ${open ? "border-bottom-0" : ""} ellipsis`} title={formatCurrency(data.total_paid)}>
          {formatCurrency(data.total_paid)}
        </td>
        {!isViewAble && (
          <>
            <td
              className={`table-column ellipsis linkedImage ${open ? "border-bottom-0" : ""}`}
              onClick={() => handleOpenEraFile && handleOpenEraFile(data)}
            >
              <i className="fas fa-image" />
            </td>

            <td
              className={`text-center linkedText ${open ? "border-bottom-0" : ""}`}
              onClick={() => handleShowLogs && handleShowLogs(data)}
            >
              {"View"}
            </td>
          </>
        )}
      </tr>
      {open && (
        <tr>
          <td colSpan={"12"} className="expended-table-child">
            <Card className="striped-table-card charge-info-table">
              <Card.Body>
                <div className="form-group-wrapper d-block">
                  <div className="table-responsive overFlow-y-hidden">
                    <Table className="table layout-fixed">
                      <thead>
                        <tr>
                          <th className="centered p-2 w-5"></th>
                          {ERA_PROCEDURE_CODE_DETAIL_HEADER.map((title, i) => (
                            <th className={`centered p-2 w-${title.width}`} key={i}>
                              {title.item}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {filteredData?.charge?.map((chargeData, i) => {
                          return (
                            <ChargeInfoRow
                              key={i}
                              chargeData={chargeData}
                              index={index}
                              checkboxes={checkboxes}
                              chargeIndex={i}
                              handleCheckboxes={handleCheckboxSelect}
                              handleChargeEdit={handleChargeEdit}
                              isClaimCpt = {claimProcCodes.includes(chargeData.proc_code) }
                            />
                          );
                        })}
                        {totalOfAmounts && Object.keys(totalOfAmounts).length > 0 && (
                          <tr className="expended-row">
                            <td></td>
                            <td></td>
                            <td className="text-center p-2"></td>
                            <td></td>
                            {Object.keys(totalOfAmounts).map((k) => (
                              <td className="fw-bold text-center">
                                <span className="fw-bold text-center">{formatCurrency(totalOfAmounts[k])}</span>
                              </td>
                            ))}
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Card.Body>
              {updatedChargeArray.length > 0 && (
                <div className="d-flex align-items-center flex-right flex-row-reverse">
                  <Button
                    variant="secondary"
                    className="modalButtons headerButton mb-2 btn-fill mx-3"
                    onClick={() =>
                      setOpenConfirmation({
                        isShow: true,
                        message: `Are you sure, you want to update this ERA?`,
                        actionType: CONFIRMATION_TYPE.updateERA,
                        title: "Update ERA",
                      })
                    }
                  >
                    Update ERA
                  </Button>
                  <i class="fas fa-undo mb-2 cursor-pointer" onClick={handleResetEra}></i>
                </div>
              )}
            </Card>
          </td>
        </tr>
      )}
      {openDraftModal && (
        <DraftInvoiceModal
          handleClose={() => setOpenDraftModal(false)}
          cssClass="seperateModal"
          data={{ ...claim, ...data }}
        />
      )}
      {loading && <Loader />}
      {openConfirmation.isShow && (
        <ConfirmationModal
          show={openConfirmation.isShow}
          actionType={openConfirmation.actionType}
          title={openConfirmation.title}
          message={openConfirmation.message}
          note={openConfirmation.note}
          handleConfirm={handleConfirm}
          cssClass="seperateModal"
        />
      )}
    </>
  );
};

export default ProcedureInfoRow;
