import saleApi from "api/saleApi";
import { MESSAGE_MODES } from "constant";
import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { setMessage } from "store/features/general/generalAction";
import { locationsSelectors } from "store/features/locations/locationsSelectors";
import { setSalesPersonAssignDetails } from "store/features/salePerson/salePersonAction";
import { salesPersonAssigningSelector, salesPersonsListSelector } from "store/features/salePerson/salePersonSelector";

const AssignSalesPerson = () => {
  const [error, setError] = useState("");
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [commission, setCommission] = useState(0);
  const [selectedSalesPersonList, setSelectedSalesPersonList] = useState([]);
  const dispatch = useDispatch();
  const clientsList = useSelector(locationsSelectors);
  const salesPersonList = useSelector(salesPersonsListSelector);
  const { salesPersonListToAssign, clientId } = useSelector(salesPersonAssigningSelector);

  useEffect(() => {
    setSelectedClientId(clientId);
  }, [clientId]);

  useEffect(() => {
    setSelectedSalesPersonList(salesPersonListToAssign);
  }, [salesPersonListToAssign]);

  const onHandleSave = async () => {
    if (!selectedClientId) {
      setError("No Client Selected.");
      return;
    }

    if (selectedSalesPersonList.length === 0) {
      setError("No Sales Person Selected.");
      return;
    }

    if (!commission) {
      setError("Please set commission");
      return;
    }

    const salePersons = selectedSalesPersonList.map(({ id }) => {
      return { id, commission: commission.toString() };
    });
    const res = await saleApi.linkSalesPerson({ siteID: selectedClientId, salePersons });
    if (res) {
      dispatch(setMessage("Sales Agent Assigned Successfully!", MESSAGE_MODES.success));
      handleClose();
    } else {
      dispatch(setMessage(error.message, MESSAGE_MODES.error));
    }
  };

  const handleClose = () => {
    dispatch(setSalesPersonAssignDetails({ showAssignModal: false }));
  };

  const clientsDropdownOptions = clientsList.map(({ id, name }) => {
    return { label: name, value: id };
  });

  const salesAgentDropdownOptions = salesPersonList.map(({ id, firstName, middleName, lastName }) => {
    return { label: firstName + " " + middleName + " " + lastName, value: id };
  });

  return (
    <>
      <Modal show backdrop="static" animation={true} onHide={handleClose} centered size={"md"}>
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            Assign Sales Person
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: 0 }}>
          {salesPersonListToAssign.length > 0 && (
            <Form.Group className="form-group-wrapper">
              <label className="modalLineHeaders mt-0 text-capitalize">Select Client</label>
              <Select
                options={clientsDropdownOptions}
                className="w-100"
                value={clientsDropdownOptions.find((opts) => opts.value === selectedClientId)}
                menuPlacement="auto"
                autoComplete="none"
                onChange={(e) => setSelectedClientId(e.value)}
              />
            </Form.Group>
          )}
          {clientId && (
            <Form.Group className="form-group-wrapper">
              <label className="modalLineHeaders mt-0 text-capitalize">Select Sales Person</label>
              <Select
                options={salesAgentDropdownOptions}
                className="w-100"
                value={salesAgentDropdownOptions.find((opts) => opts.value === selectedSalesPersonList?.[0])}
                menuPlacement="auto"
                autoComplete="none"
                onChange={(e) => setSelectedSalesPersonList([e.value])}
              />
            </Form.Group>
          )}
          <Form.Group className="form-group-wrapper">
            <label className="modalLineHeaders mt-0 text-capitalize">Commission %:</label>
            <input
              type="number"
              value={commission}
              className={`modalInput`}
              placeholder="%"
              style={{
                width: "100%",
                flexShrink: "initial",
              }}
              onChange={(e) => {
                let val = Number(e.target.value);
                if (val < 0 || val > 100) {
                  return;
                }
                setCommission(val);
              }}
            />
          </Form.Group>
        </Modal.Body>
        <div className="px-4 my-2" style={{ height: "2vh" }}>
          {error && <strong style={{ color: "Red" }}>{error}</strong>}
        </div>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="primary"
            className="modalButtons headerButton btn-fill"
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            style={{ marginBottom: 10 }}
            variant="secondary"
            className="modalButtons headerButton btn-fill"
            onClick={onHandleSave}
          >
            Assign
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AssignSalesPerson;
