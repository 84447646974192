import React, { useState } from "react";
import SwitchToggle from "components/SwitchToggle/SwitchToggle";

const UserPermissions = (props) => {
  const { permission, setPermission, value, label, advanceOptions } = props;
  return (
    <>
      <div className="userPermission profile-detail mb-3">
        <div className="d-flex align-items-center justify-content-between">
          <span className="mx-1">{label}</span>
          <SwitchToggle
            checked={permission[value]?.access}
            handleChange={(e) =>
              setPermission({
                ...permission,
                [value]: { ...permission[value], access: e.target.checked ? true : false },
              })
            }
          />
        </div>
        {advanceOptions && (
          <>
            <div className="d-flex mx-2 align-items-center column-gap-10">
              <span className="mx-1">Write</span>
              <input
                type="checkbox"
                checked={permission[value]?.write}
                disabled={!permission[value]?.access}
                onChange={(e) =>
                  setPermission({
                    ...permission,
                    [value]: { ...permission[value], write: e.target.checked ? true : false },
                  })
                }
              />
            </div>
            <div className="d-flex mx-2 align-items-center column-gap-10">
              <span className="mx-1">Delete</span>
              <input
                type="checkbox"
                checked={permission[value]?.delete}
                disabled={!permission[value]?.access}
                onChange={(e) =>
                  setPermission({
                    ...permission,
                    [value]: { ...permission[value], delete: e.target.checked ? true : false },
                  })
                }
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default UserPermissions;
