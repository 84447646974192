import { useSelector } from "react-redux";
import { selectedHospiceDetailsSelector } from "store/features/checkEligibility/checkEligibilitySelectors";
import {
  messageTemplateModalSelector,
  selectedMessageTemplateSelector,
} from "store/features/messageTemplate/messageTemplateSelectors";
import { salesPersonAssigningSelector } from "store/features/salePerson/salePersonSelector";
import MessageTemplateModal from "views/MessageTemplate/MessageTemplateModal";
import AssignSalesPerson from "./Modal/AssignSalesPerson/AssignSalesPerson";
import HospiceDetailsModal from "./Modal/CheckEligibility/HospiceDetailsModal";

const GlobalActionsComponent = () => {
  const salesPersonAssigningDetails = useSelector(salesPersonAssigningSelector);
  const selectedHospiceDetails = useSelector(selectedHospiceDetailsSelector);
  const messageTemplateModal = useSelector(messageTemplateModalSelector);
  const selectedMessageTemplate = useSelector(selectedMessageTemplateSelector);
  return (
    <>
      {salesPersonAssigningDetails.showAssignModal && <AssignSalesPerson />}

      {selectedHospiceDetails?.showModal && <HospiceDetailsModal cssClass="seperateModal" />}

      {messageTemplateModal && <MessageTemplateModal cssClass="seperateModal" selectItem={selectedMessageTemplate} />}
    </>
  );
};

export default GlobalActionsComponent;
