import MainTable from "components/Table/MainTable";
import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { APPEAL_MODAL_VIEW_TABLE } from "constants/personalization";
import InputField from "components/InputField/InputField";
import { useSelector } from "react-redux";
import { documentSelector } from "store/features/documents/documentSelectors";
import DocumentList from "components/PatientDocument/DocumentList";

import { listTemplate } from "api/messageTemplateApi";
import { replacePlaceholders } from "utils";
import UploadAppealDocuments from "./UploadAppealDocuments";
import api from "api";
import { selectedCompanySetting } from "store/features/companySetting/companySettingSelectors";
import { Storage } from "aws-amplify";
import { CONFIG } from "constant";
import { selectLoginUser } from "store/features/authentication/authenticationSelectors";

const AppealModal = ({ handleClose, cssClass, appealData, claimData, chargeAmount }) => {
  const [selectedText, setSelectedText] = useState({});

  const [defaultTemplates, setSefaultTemplates] = useState([]);

  const [showUploadDocumentModal, setShowUploadDocumentModal] = useState(false);

  const [appealId, setAppealId] = useState(null);

  const [uploadedFilesList, setUploadedFilesList] = useState([]);

  const [selectedDocument, setSelectedDocument] = useState({ isNew: true });

  const setting = useSelector(selectedCompanySetting);

  const loginUser = useSelector(selectLoginUser);
  
  const getMessageTemplate = async () => {
    try {
      const templates = await listTemplate();
      if (templates) {
        const tempResult = templates.map((item) => {
          return { label: item.name, value: item.message };
        });
        setSefaultTemplates(tempResult);
      }
    } catch (error) {
      console.log("🚀 ~ getMessageTemplate ~ error:", error);
    }
  };

  useEffect(() => {
    getMessageTemplate();
  }, []);
  useEffect(() => {
    getAppealId();
  }, [setting]);

  const handelDefaultMessageChange = (e) => {
    const placeholderObj = {
      first_name: claimData.pat_name_f,
      cpt_code: appealData.map((item) => item.proc_code).join(", "),
      payer_name: appealData[0].payer_name,
      payer_address: " ",
    };

    const resTemp = replacePlaceholders(e.value, placeholderObj);
    setSelectedText({
      ...e,
      value: resTemp,
    });
  };



  const handelSaveAppealData = async () => {
    const appealObj = {
      appealNo: appealId,
      claimID: claimData.id,
      clientID: claimData.clientID,
      appealDate: new Date().toISOString().substring(0, 10),
      proc_array: JSON.stringify(appealData),
      files: JSON.stringify(uploadedFilesList),
      message: selectedText.value,
      appealAmount: chargeAmount,
      status: "Pending",
      updatedByName:loginUser.name,
      updatedBy:loginUser.sub,
      mailStatus:null,
      payer_name: appealData[0].payer_name,
      payerid:appealData[0].payerid,
      employeeID:claimData.employeeID,
      setID:setting.id,
    };

    try {
      const result = await api.createAppeal(appealObj);
      console.log("Result", result);
    } catch (error) {
      console.log("Error", error);
    }
  };

  const getAppealId = async () => {
    try {
      const appealID = await api.getEmployeeNo("a", setting.id);
      if (!appealId) {
        setAppealId(appealID);
      }
    } catch (error) {}
  };

  const getAppealFiles = async () => {
    const filesPath = `appeal_files/${claimData.clientID}/${appealId}`;
    const res = await Storage.get(filesPath, {
      bucket: CONFIG.eligibilityBucket,
    });
    console.log("Res", res);
  };

  const addFilesName = ({ fileName, docType }) => {
    const fileObj = {
      fileName: fileName,
      fileType: "application/pdf",
      docType: docType,
    };

    setUploadedFilesList([...uploadedFilesList, fileObj]);
    setShowUploadDocumentModal(false);
  };

  const renderTd = (item, row) => {
    if (item.itemKey === "view") {
      return (
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }} key={item.id}>
          <td
            className="ellipsis"
            style={{
              textAlign: item.textAlign,
              textOverflow: item.textOverflow,
            }}
            onClick={() => handelViewAppeal(row)}
          >
            <label style={{ color: "red", cursor: "pointer" }}>View</label>
          </td>
        </div>
      );
    }
  };

  const handelViewAppeal = (row) =>{
    console.log('I ma here')
  }

  return (
    <Modal
      show
      centered
      size={"lg"}
      className={cssClass}
      backdrop="static"
      onHide={() => {
        handleClose();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0 text-capitalize " id="contained-modal-title-vcenter" style={{}}>
          Appeal Modal
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0 }}>
        {appealData && <MainTable columns={APPEAL_MODAL_VIEW_TABLE} rows={appealData} 
        
        customColumnCellRenderer={renderTd}
        
        />}

        <div className="col-sm-12 createClientsWrapper mt-1">
          <div className="form-group-wrapper">
            <InputField
              type="dropDown"
              groupWrapper="form-group-wrapper me-2 mt-3"
              inputStyle="w-100"
              labelStyle="mt-0 text-capitalize"
              label="Default Message:"
              options={defaultTemplates}
              placeholder="Select Default Message"
              value={selectedText}
              handleChange={(e) => handelDefaultMessageChange(e)}
            />
          </div>
        </div>

        <div className="col-sm-12 createClientsWrapper">
          <div className="form-group-wrapper">
            <label htmlFor="appealMessage">Appeal Message</label>
            <textarea
              id="appealMessage"
              className="form-control"
              style={{ minHeight: "90px" }}
              value={selectedText?.value || ""}
            ></textarea>
          </div>
        </div>

        <div className="justify-content-between d-flex align-items-center mb-0 m-0 secondary-insurance-head">
          <h4 className="text-decoration-underline mt-0 section-title">Documents</h4>
          <div className="plus-icon" onClick={() => setShowUploadDocumentModal(true)}>
            <i className="fas fa-plus-circle fa-lg cursor-pointer w-auto" />
          </div>
        </div>

        {true && (
          <DocumentList
            list={uploadedFilesList}
            handleEdit={(item) => !editButton && setSelectedDocument({ ...item, isNew: false })}
            headerLabel={"Documents"}
            handleDocDeleted={(doc) => dispatch(deleteDcoumentAsync(doc))}
          />
        )}

        {showUploadDocumentModal && (
          <UploadAppealDocuments
            handleClose={() => setShowUploadDocumentModal(false)}
            appealId={appealId}
            clientId={claimData.clientID}
            setUploadedFilesName={(item) => addFilesName(item)}
          />
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="primary"
          className="modalButtons headerButton btn-fill mb-2"
          onClick={() => {
            handleClose();
          }}
        >
          Close
        </Button>
        <Button variant="secondary" className="modalButtons headerButton btn-fill mb-2" onClick={handelSaveAppealData}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AppealModal;
