import MoneyInput from "components/MoneyInput/MoneyInput";
import React, { useState } from "react";
import { formatCurrency } from "utils";
import DiscountInput from "./DiscountInput";
import OverallDiscountInput from "./OverallDiscountInput";

const containerStyle = {
  width: "100%",
  height: "100px",
  paddingRight: "74px",
  alignItems: "center",
  display: "flex",
  justifyContent: "flex-end",
};

const flexStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

const discountTypeButtonStyle = {
  marginLeft: "0px",
  background: "#31A8E0",
  borderRadius: "5px 0px 0px 5px",
  cursor: "pointer",
  width: "50px",
  height: "100%",
  padding: "4px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const contentStyle = { width: "160px" };
const textStyle = { fontSize: 14, marginBottom: "10px", color: "#000000", fontWeight: 600 };

const BillingInformation = ({
  billedToPlan,
  discountAmount,
  overAllDiscount,
  handleDiscountChange,
  changeDiscountType,
  invoiceDiscountType,
}) => {
  return (
    <div style={containerStyle}>
      <div style={flexStyle}>
        <div className="content" style={contentStyle}>
          <p style={textStyle}>Total Bill:</p>
          <p style={textStyle}>Total Invoice Discount:</p>
          <p style={textStyle}>Total Due Charges:</p>
        </div>
        <div className="content" style={{ ...contentStyle, width: "180px" }}>
          <MoneyInput
            customSign="edit-input-total"
            moneyInputClass="mb-1"
            readOnly={true}
            value={formatCurrency(billedToPlan)}
          />
          <div style={{ display: "flex", position: "relative" }}>
            <MoneyInput
              customSign="edit-input-total"
              moneyInputClass="mb-1"
              onChange={handleDiscountChange}
              value={discountAmount}
              customStyle="rounded-2"
            />
            <span
              style={{
                position: "absolute",
                left: "15px",
                background: "#31A8E0",
                borderRadius: "6px 0 0 6px",
                cursor: "pointer",
                width: "25px",
                height: "85%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                fontWeight: "bold",
              }}
              onClick={changeDiscountType}
            >
              {invoiceDiscountType === "percentage" ? "%" : "$"}
            </span>
          </div>
          {/* <div style={{ display: "flex" }}>
            <OverallDiscountInput />
          </div> */}
          <MoneyInput customSign="edit-input-total" value={formatCurrency(overAllDiscount)} readOnly={true} />
        </div>
      </div>
    </div>
  );
};

export default BillingInformation;
