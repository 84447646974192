import { CUSTOM_INS } from "constant";
import moment from "moment";
import { Button, Card, Modal, Table } from "react-bootstrap";

const EligibilityCheckListViewModal = (props) => {
  const { handleClose, lists, setEligibilityDetailsData } = props;

  const TableRow = ({ item }) => {
    const { emp, res } = item;
    return (
      <tr>
        <td></td>
        <td>{emp.firstName}</td>
        <td>{emp.lastName}</td>
        <td>{emp.insuranceCompanyCode}</td>
        <td
          className="ellipsis text-center"
          onDoubleClick={() =>
            !CUSTOM_INS.includes(emp.insuranceCompany) &&
            setEligibilityDetailsData({
              ...res,
              employee: emp,
              ins_name_f: emp.firstName,
              ins_name_l: emp.lastName,
              employeeID: emp.id,
              ins_dob: moment(emp.dob).format("YYYYMMDD"),
              ins_number: emp.medicalNo,
              ins_sex: emp.gender || emp.sex,
              payerId: emp.insuranceCompany,
            })
          }
        >
          <div
            className="statusPill custom-ellipsis d-block text-center p-0"
            style={{
              backgroundColor: res ? "green" : "red",
            }}
          >
            {res ? "Pass" : "Fail"}
          </div>
        </td>
        <td className="text-center ellipsis w-50" title={emp.eligibilityStatus?.message || ""}>
          {emp.eligibilityStatus?.message || ""}
        </td>
      </tr>
    );
  };

  return (
    <Modal show onHide={handleClose} animation={true} size={"xl"} scrollable>
      <Modal.Header closeButton>
        <Modal.Title className="my-0">Eligibility View</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card className="strpied-tabled-with-hover">
          <Card.Body className="table-full-width desktop-noScroll">
            <div
              className={
                lists.length > 0
                  ? "table-responsive pendingReleaseTable"
                  : "table-responsive pendingReleaseTable overFlow-y-hidden"
              }
            >
              <Table className="table">
                <thead>
                  <tr>
                    <th></th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Insurance</th>
                    <th className="centered">Status</th>
                    <th className="centered">Reason</th>
                  </tr>
                </thead>
                <tbody>
                  {lists.map((elig, index) => (
                    <TableRow key={index} item={elig} />
                  ))}
                </tbody>
              </Table>
            </div>
          </Card.Body>
        </Card>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" className="headerButton btn-fill" onClick={() => handleClose()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EligibilityCheckListViewModal;
