import api from "api";
import ProcedureCodeInput from "components/Claim/ProcedureCodeInput";
import Loader from "components/Loader/Loader";
import ErrorMessage from "components/Message/ErrorMessage";
import { DEFAULT_MEDICARE_PRO_CODE, DIAG_REF_CODE, INSURANCE_PROVIDER, MESSAGE_MODES } from "constant";
import { AppContext } from "context/app-context";
import moment from "moment";
import { useContext, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import DatePicker from "react-date-picker";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { setMessage } from "store/features/general/generalAction";
import { t } from "../../../stringConstants";
import { formatPhoneNumber } from "../../../utils.js";

const NewClaimSubmissionModal = (props) => {
  const { orders, employees, handleClose, fileDir } = props;
  const appContext = useContext(AppContext);
  const [payer, setPayer] = useState(null);
  const [proCode, setProCode] = useState(DEFAULT_MEDICARE_PRO_CODE);
  const [claimAmount, setClaimAmount] = useState(200);
  const [quantity, setQuantity] = useState(8);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const [proCodeIndex, setProCodeIndex] = useState(-1);
  const [dateOfService, setDateOfService] = useState(null);
  const dispatch = useDispatch();

  const getDateOfService = (orderDate) => {
    if (dateOfService) {
      return moment(dateOfService, "YYYY-MM-DD").format("MM/DD/YYYY");
    }

    let dos = null;
    switch (orderDate) {
      case "20220901":
        dos = "09/01/2022";
        break;
      case "20230101":
        dos = "01/10/2023";
        break;
      case "20221201":
        dos = "12/10/2022";
        break;
      case "20221101":
        dos = "11/10/2022";
        break;
      case "20221001":
        dos = "10/10/2022";
        break;
      case "20230201":
        dos = "02/06/2023";
        break;
    }
    return dos || moment(orderDate, "YYYYMMDD").format("MM/DD/YYYY");
  };
  const onHandleSave = async (isConfirm, newUser) => {
    if (!payer) {
      setError(t("insuranceProviderRequired"));
      return;
    }

    if (!proCode) {
      setError(t("procedureCodeRequired"));
      return;
    }

    if (confirmText !== "confirm") {
      setError(t("typeConfirm"));
      return;
    }
    try {
      setLoading(true);
      const csvData = [];
      orders.forEach((d) => {
        const emp = employees.find((e) => e.id === d.employeeID);
        let phNo = formatPhoneNumber(emp?.phoneNumber);
        phNo = phNo.replace("+1", "");
        const dos = getDateOfService(d.orderDate);
        for (let i = 0; i < proCode.length; i++) {
          let currentProCode = proCode[i];
          const payerID = payer.payerid.toString().padStart(5, 0);
          const obj = {
            accept_assign: "Y",
            amount_paid: 0,
            auto_accident: "",
            auto_accident_state: "",
            bill_addr_1: "2268 LINCOLN AVE",
            bill_addr_2: "",
            bill_city: "ALTADENA",
            bill_id: "291U00000X",
            bill_name: "ALTA DIAGNOSTIC LABORATORIES, INC",
            bill_npi: "1063180354",
            bill_phone: "8185730434",
            bill_state: "CA",
            bill_taxid: "871701212",
            bill_taxid_type: "E",
            bill_taxonomy: "",
            bill_zip: "910015432",
            clia_number: "",
            employment_related: "N",
            facility_addr_1: "2268 LINCOLN AVE",
            facility_addr_2: "",
            facility_city: "ALTADENA",
            facility_id: "",
            facility_name: "ALTA DIAGNOSTIC LABORATORIES, INC",
            facility_npi: "1063180354",
            facility_state: "CA",
            facility_zip: "910015432",
            ins_addr_1: d.street,
            ins_addr_2: d.street2,
            ins_city: d.city,
            ins_dob: moment(d.dob).format("MM/DD/YYYY"),
            ins_employer: "",
            ins_group: "",
            ins_name_f: d.firstName,
            ins_name_l: d.lastName,
            ins_name_m: "",
            ins_number: emp?.medicareNo || d.medicalNo,
            ins_phone: phNo,
            ins_plan: "",
            ins_sex: emp?.sex || d.sex || d.gender,
            ins_state: d.state,
            ins_zip: d.zip,
            narrative: "",
            ord_prov_name_f: "",
            ord_prov_name_l: "",
            ord_prov_name_m: "",
            ord_prov_npi: "",
            other_ins_dob: "",
            other_ins_group: "",
            other_ins_medicare_code: "",
            other_ins_name_f: "",
            other_ins_name_l: "",
            other_ins_name_m: "",
            other_ins_number: "",
            other_ins_payment_date: "",
            other_ins_plan: "",
            other_ins_sex: "",
            other_pat_rel: "",
            other_payer_addr_1: "",
            other_payer_addr_2: "",
            other_payer_city: "",
            other_payer_name: "",
            other_payer_phone: "",
            other_payer_state: "",
            other_payer_typecode: "",
            other_payer_zip: "",
            other_payerid: "",
            pat_addr_1: d.street,
            pat_addr_2: d.street2,
            pat_city: d.city,
            pat_country: "",
            pat_dob: moment(d.dob).format("MM/DD/YYYY"),
            pat_name_f: d.firstName,
            pat_name_l: d.lastName,
            pat_name_m: "",
            pat_phone: formatPhoneNumber(d.phoneNumber),
            pat_rel: "18",
            pat_sex: emp?.sex || d.sex || d.gender,
            pat_ssn: "",
            pat_state: d.state,
            pat_zip: d.zip || emp.zip,
            pay_addr_1: "",
            pay_addr_2: "",
            pay_city: "",
            pay_state: "",
            pay_zip: "",
            payer_addr_1: "",
            payer_addr_2: "",
            payer_city: "",
            payer_name: payerID === "01192" ? "CA Medicare South" : payer.payer_name,
            payer_order: "Primary",
            payer_state: "",
            payer_zip: "",
            payerid: payerID,
            pcn: emp?.schrID,
            prior_auth: "",
            prov_id: "",
            prov_name_f: "",
            prov_name_l: "",
            prov_name_m: "",
            prov_npi: "",
            prov_taxid: "",
            prov_taxid_type: "",
            prov_taxonomy: "",
            ref_name_f: "",
            ref_name_l: "",
            ref_name_m: "",
            ref_npi: "",
            remote_batchid: "",
            remote_claimid: d.orderId,
            remote_fileid: "",
            total_charge: claimAmount,
            charge_1: claimAmount,
            diag_ref_1: "A",
            from_date_1: dos,
            mod1_1: "",
            mod2_1: "",
            mod3_1: "",
            mod4_1: "",
            narrative_1: "",
            place_of_service_1: "81",
            proc_code_1: currentProCode.label,
            remote_chgid_1: `1-${d.orderId}`,
            thru_date_1: dos,
            units_1: "8",
            diag_ref_2: "",
            from_date_2: "",
            mod1_2: "",
            mod2_2: "",
            mod3_2: "",
            mod4_2: "",
            narrative_2: "",
            place_of_service_2: "",
            proc_code_2: "",
            remote_chgid_2: "",
            thru_date_2: "",
            units_2: "",
            primaryInsurance: payer.value,
            localOrderId: d.id,
          };
          const ttlDiagnosis = currentProCode.diagnosis.length;
          for (let j = 0; j < ttlDiagnosis; j++) {
            obj["diag_" + (j + 1)] = currentProCode.diagnosis[j];
          }
          let diagRef = [];
          for (let j = 0; j < ttlDiagnosis; j++) {
            diagRef.push(DIAG_REF_CODE[j]);
          }
          obj["diag_ref_1"] = diagRef.join("");
          csvData.push(obj);
        }
      });

      const fileName = `${fileDir}/${fileDir}_${moment().format("DD_MM_YYYY_HH_mm_ss.SSS")}.csv`;
      console.log("Data", csvData);
      await api.saveFileOnBucket(csvData, fileName);
      dispatch(
        setMessage(
          `Submitted Claim Successfully for ${orders.length} order${orders.length > 1 && "s"}!`,
          MESSAGE_MODES.success
        )
      );
      setLoading(false);
      handleClose(true);
    } catch (err) {
      setLoading(false);
      console.log(err);
      dispatch(setMessage(err.message, MESSAGE_MODES.error));
    }
  };

  const handleChangeList = (newList) => {
    setProCode(
      newList.map((e) => {
        const item = proCode.find((p) => p.label === e);
        if (item) {
          return { ...item };
        }
        return { label: e, value: e, diagnosis: [], modifire: [] };
      })
    );
  };

  const handleChangeDiagnosis = (newList, itemKey) => {
    if (proCodeIndex === -1) return;
    const currentItem = proCode[proCodeIndex];
    const newProCode = [...proCode];
    currentItem[itemKey] = newList;
    newProCode.splice(proCodeIndex, 1, currentItem);
    setProCode(newProCode);
  };

  return (
    <>
      <Modal
        show
        animation={true}
        size="md"
        onHide={() => {
          handleClose();
        }}
        backdrop="static"
        style={{ paddingLeft: "0" }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            {t("submitClaim")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingTop: 0,
          }}
        >
          <div className="createClientsWrapper">
            <div className="centerWrap">
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Date of Service: </label>
                <DatePicker
                  className="w-100 orderTestDob"
                  openCalendarOnFocus={false}
                  dayPlaceholder="dd"
                  monthPlaceholder="mm"
                  yearPlaceholder="yyyy"
                  format="MM/dd/y"
                  onChange={(e) => setDateOfService(moment(e).format("YYYY-MM-DD"))}
                  value={
                    dateOfService && new Date(dateOfService) != "Invalid Date"
                      ? moment(dateOfService, "YYYY-MM-DD").toDate()
                      : null
                  }
                  maxDate={new Date()}
                  minDate={new Date("1900-01-01")}
                />
              </div>
              <div className="col-lg-12 col-12 mb-3">
                <label className="modalLineHeaders mt-0">{t("insuranceProvider")}:</label>
                <Select
                  options={INSURANCE_PROVIDER}
                  blurInputOnSelect={true}
                  menuPlacement="auto"
                  placeholder={t("selectinsuranceProvider")}
                  className="w-100"
                  onChange={(e) => setPayer(e)}
                />
              </div>
              <div className={"mt-4"}>
                <div className="form-group-wrapper me-2">
                  <label className="modalLineHeaders">{t("claimAmount")}:</label>
                  <input
                    value={claimAmount}
                    className="modalInput"
                    maxLength={9}
                    placeholder={t("claimAmount")}
                    style={{
                      width: "100%",
                    }}
                    onChange={(e) => setClaimAmount(e.target.value)}
                  />
                </div>
              </div>
              <div className={"mt-4"}>
                <div className="form-group-wrapper me-2">
                  <label className="modalLineHeaders">{t("quanity")}:</label>
                  <input
                    value={quantity}
                    className="modalInput"
                    maxLength={3}
                    placeholder={t("quanity")}
                    style={{
                      width: "100%",
                    }}
                    onChange={(e) => setQuantity(e.target.value)}
                  />
                </div>
              </div>

              <ProcedureCodeInput
                items={proCode.map((e) => e.label)}
                maxLength={2}
                label={t("procedureCode")}
                onChangeList={handleChangeList}
              />

              <div className="col-lg-12 col-12 mb-3">
                <label className="modalLineHeaders mt-0">{t("procedureCode")}:</label>
                <Select
                  options={proCode.map((e, index) => {
                    return { value: index, label: e.label };
                  })}
                  blurInputOnSelect={true}
                  menuPlacement="auto"
                  placeholder={t("procedureCode")}
                  className="w-100"
                  onChange={(e) => setProCodeIndex(e.value)}
                />
              </div>

              {proCodeIndex !== -1 && (
                <>
                  <ProcedureCodeInput
                    items={proCode[proCodeIndex].diagnosis}
                    label={t("diagnosisCode")}
                    onChangeList={handleChangeDiagnosis}
                    maxLength={8}
                    itemKey="diagnosis"
                  />
                  <ProcedureCodeInput
                    items={proCode[proCodeIndex].modifire}
                    label={t("modifireCode")}
                    onChangeList={handleChangeDiagnosis}
                    maxLength={8}
                    itemKey="modifire"
                  />
                </>
              )}

              <div className="form-group-wrapper mt-4">
                <label className="modalLineHeaders">Type confirm: </label>
                <input
                  value={confirmText}
                  className="modalInput"
                  placeholder="confirm"
                  style={{
                    width: "100%",
                  }}
                  onChange={(e) => setConfirmText(e.target.value)}
                />
              </div>

              {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="primary"
            className="modalButtons headerButton btn-fill"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>

          <Button
            style={{ marginBottom: 10 }}
            variant="secondary"
            className="modalButtons headerButton btn-fill"
            onClick={() => onHandleSave(true)}
          >
            Submit
          </Button>
        </Modal.Footer>
        {loading && <Loader />}
      </Modal>
    </>
  );
};

export default NewClaimSubmissionModal;
