import React from "react";
import { Bounce } from "react-activity";
import "react-activity/dist/Bounce.css";

const Loader = () => {
  return (
    <>
      {" "}
      <div
        style={{
          width: "100vw",
          height: "100vh",
          position: "fixed",
          top: "0",
          left: "0",
          zIndex: "1100000",
        }}
      />
      <Bounce
        size={35}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          right: "50%",
          zIndex: "1100000",
        }}
        color={"#a82632"}
      />
    </>
  );
};
export default Loader;
