import { COLUMNS } from "constant";
import React, { useState, useEffect } from "react";
import TrashIcon from "assets/img/trash-icon.png";
import { Card, CloseButton, Col, Row } from "react-bootstrap";
import SwitchToggle from "../SwitchToggle/SwitchToggle";
import EditIcon from "assets/img/edit-icon.png";

const InsuranceViewCard = (props) => {
  const {
    insurance,
    handleUpdateIns,
    index,
    handleEdit,
    cssClass,
    handleRemove,
    handleDragStart,
    handleDragOver,
    handleDragEnd,
  } = props;
  const [insObj, setInsObj] = useState(insurance);

  useEffect(() => {
    setInsObj(insurance);
  }, [insurance]);
  return (
    <Card className={cssClass || ""} key={index}>
      <Card.Body>
        <Row className="align-items-center">
          <Col md={6}>
            <span>{`${COLUMNS[index] || ""} Inusrnace`}</span>
          </Col>
          <Col md={2} className="text-right">
            <SwitchToggle
              checked={insObj.isActive}
              handleChange={(e) => handleUpdateIns({ ...insObj, isActive: e.target.checked })}
              color={"blue"}
            />
          </Col>
          <Col md={2} className="text-right">
            <img
              src={EditIcon}
              width="18"
              alt="edit icon"
              onMouseLeave={(e) => (e.target.style.color = "black")}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              aria-hidden="true"
              className="mb-1"
              onClick={(e) => handleEdit(insObj)}
            />
          </Col>
          <Col md={2} className="text-right">
            <img
              src={TrashIcon}
              width="18"
              alt="Trash icon"
              onMouseLeave={(e) => (e.target.style.color = "black")}
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              aria-hidden="true"
              onClick={(e) => handleRemove(insObj)}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <span className="fw-bold fs-6">{insObj.insuranceCompanyCode}</span>
          </Col>
        </Row>
        <Row>
          <Col>
            <span>{insObj.medicalNo}</span>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer>
        <Col md={6}>
          <span></span>
        </Col>
        <Col md={6}>
          <span></span>
        </Col>
      </Card.Footer>
    </Card>
  );
};

export default InsuranceViewCard;
