import { Button, Card, Col, Modal, Row, Badge } from "react-bootstrap";
import { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { t } from "stringConstants";
import { invoiceDocsSelector } from "store/features/invoiceDocuments/invoiceDocumentSelectors";
import { setInvoiceDocs } from "store/features/invoiceDocuments/invoiceDocumentSlice";
import { Storage } from "aws-amplify";
import { authSelector, selectLoginUser } from "store/features/authentication/authenticationSelectors";
import api from "api";
import { documentSelector } from "store/features/documents/documentSelectors";
import { fetchDocuemntAsync } from "store/features/documents/documentSlice";
import { setLoader } from "store/features/general/generalSlice";
import InputField from "components/InputField/InputField";
import { employeesSelector } from "store/features/employees/employeesSelectors";


import { selectedCompanySetting } from "store/features/companySetting/companySettingSelectors";
import RadioButtonOptions from "components/RadioButton/RadioButtonOptions";
import { DRAFT_INVOICE_VIEW_AS } from "constant";
import { parseInsDetails } from "utils";
import InvoicePreviewModal from "views/EmployeeClaims/InvoicePreview/InvoicePreviewModal";

const DraftInvoiceModal = (props) => {
  const { handleClose, emp, cssClass, data } = props;
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [invoiceData, setInvoiceData] = useState({});
  const [viewAs, setViewAs] = useState(DRAFT_INVOICE_VIEW_AS[0].value);

  const loginUser = useSelector(selectLoginUser);
  const invoiceDocs = useSelector(invoiceDocsSelector);
  const { employees } = useSelector(employeesSelector);
  const setting = useSelector(selectedCompanySetting);
  const dispatch = useDispatch();

  const calulateAmount = useMemo(() => {
    let total_paid = data.charge.reduce((total, item) => total + parseFloat(item.paid), 0);
    let balance = (parseFloat(data.total_charge) - total_paid).toFixed(2);
    return {
      total_paid,
      balance,
    };
  }, [data]);
  let foundEmployee = employees.find((e) => e.id === data.employeeID);
  let insuranceOptions = parseInsDetails(foundEmployee?.insuranceDetails, foundEmployee)
    ?.slice(1)
    ?.map((item) => ({
      label: item.insuranceCompanyCode,
      value: item.id,
    }));
  return (
    <Modal
      show
      backdrop="static"
      animation={true}
      className={cssClass ?? ""}
      onHide={() => handleClose()}
      centered
      size={"lg"}
      keyboard={false}
    >
      <Modal.Header closeButton>
        {/* <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          Search Provider List
        </Modal.Title> */}
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col xs={12} md={12} lg={12}>
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                {/* <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                  }}
                >
                  Draft Invoice
                </Card.Title> */}
                <div className="d-flex justify-content-between align-items-center">
                  <label bg="primary">Total Charge: ${parseFloat(data.total_charge).toFixed(2)}</label>
                  <br />
                  <label bg="primary">Total Paid: ${parseFloat(calulateAmount.total_paid).toFixed(2)}</label>
                  <br />
                  <label bg="primary">Balance: ${parseFloat(calulateAmount.balance).toFixed(2)}</label>
                </div>
              </Card.Header>

              <Card.Body className="table-full-width desktop-noScroll">
                <div style={{ paddingLeft: "10px" }}>
                  <RadioButtonOptions
                    cssClass={""}
                    label={"Action:"}
                    options={DRAFT_INVOICE_VIEW_AS}
                    name={`DRAFT_INVOICE_VIEW_AS`}
                    checkedVal={viewAs}
                    handleChange={(e, obj) => e.target.checked && setViewAs(obj.value)}
                  />
                </div>
                {viewAs == DRAFT_INVOICE_VIEW_AS[2].value && (
                  <InputField
                    type="dropDown"
                    labelStyle="mt-0 modalLineHeaders text-capitalize"
                    inputStyle="w-50"
                    label="Secondary Insurance:"
                    options={insuranceOptions}
                    // value={
                    //   newUser.country
                    //     ? {
                    //         label: newUser.country,
                    //         value: newUser.country,
                    //       }
                    //     : null
                    // }
                    index="insurance"
                    maxLength={20}
                    placeholder="Select Insurance"
                    handleChange={(e) => {
                      console.log(e);
                    }}
                  />
                )}
              </Card.Body>
            </Card>
          </Col>
          <Col xs={6} md={6} lg={6}>
            {/* <InvoiceDocumentForm emp={emp} document={selectedDocument} setDocuments={setDocuments} /> */}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" className="modalButtons headerButton btn-fill" onClick={handleClose}>
          Close
        </Button>
        {viewAs !== DRAFT_INVOICE_VIEW_AS[2].value && (
          <Button
            variant="secondary"
            className="modalButtons headerButton btn-fill"
            onClick={async () => {
              setInvoiceData({
                ...data,
                proc_array: data.charge,
                pat_addr_1: foundEmployee?.street,
                pat_state: foundEmployee?.state,
                pat_zip: foundEmployee?.zip,
                pat_city: foundEmployee?.city,
                pat_country: foundEmployee?.country,
                pat_email: foundEmployee?.email,
                totalPaid: calulateAmount.total_paid,
              });
              setOpenPreviewModal(true);
            }}
          >
            Generate Invoice
          </Button>
        )}
      </Modal.Footer>
      {openPreviewModal && <InvoicePreviewModal close={() => setOpenPreviewModal(false)} data1={invoiceData} />}
    </Modal>
  );
};

export default DraftInvoiceModal;
