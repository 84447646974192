import api from "api";
import Loader from "components/Loader/Loader";
import ErrorMessage from "components/Message/ErrorMessage";
import RadioButton from "components/RadioButton/RadioButton";
import { MESSAGE_MODES } from "constant";
import moment from "moment";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setMessage as setMessageAction } from "store/features/general/generalAction";
import { isValidDobField } from "utils";
const ManualOrderStatusModal = (props) => {
  const { handleConfirm, handleClose, orders, appContext } = props;

  const [orderStatus, setOrderStatus] = useState("");
  const [error, setError] = useState("");
  const [orderMonth, setOrderMonth] = useState();
  const [message, setMessage] = useState("");
  const [trackingNumber, setTrackingNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleSave = async (orderStatus) => {
    if (!orderStatus) {
      setError("Please Select Order Status");
      return;
    }
    if (!orderMonth) {
      setError("Please Select Date and Time");
      return;
    }
    if (!isValidDobField(orderMonth, "YYYY-MM-DDTHH:mm")) {
      setError("Invalid Order Date and Time");
      return;
    }
    if (orderStatus === "Sent" && (!trackingNumber || !trackingNumber.trim())) {
      setError("Please Add Tracking Number");
      return;
    }
    if (!message || !message.trim()) {
      setError("Please Add Message");
      return;
    }
    try {
      setLoading(true);
      await api.updateOrderStatus(orders, orderStatus, trackingNumber, orderMonth, message, appContext.user);
      dispatch(setMessageAction("Update Order Status Successfully", MESSAGE_MODES.success));
      handleClose(true);
      setLoading(false);
    } catch (err) {
      console.log("Error:-", err.message);
      dispatch(setMessageAction(err.message, MESSAGE_MODES.error));
      setLoading(false);
    }
    console.log({ orderStatus, orderMonth, message, trackingNumber });
  };

  console.log("orderMonth", orderMonth);
  return (
    <Modal
      show
      backdrop="static"
      // style={{ transform: "translate(0, -30%)" }}
      animation={true}
      onHide={() => {
        handleClose();
      }}
      centered
      size={"lg"}
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0 modal-title h4" id="contained-modal-title-vcenter">
          Update Order Status
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="form-group-wrapper">
          <label className="my-0 modalLineHeaders">Select Order Status:</label>
          <div className="d-flex justify-content-between w-100 flex-wrap">
            <RadioButton
              name="orderStatus"
              id="sent"
              label="Sent"
              checked={orderStatus === "Sent" ? true : false}
              handleChange={(e) => setOrderStatus("Sent")}
            />
            <RadioButton
              name="orderStatus"
              id="delivered"
              label="Delivered"
              checked={orderStatus === "Delivered" ? true : false}
              handleChange={(e) => setOrderStatus("Delivered")}
            />
            <RadioButton
              name="orderStatus"
              id="returned"
              label="Returned"
              checked={orderStatus === "Returned" ? true : false}
              handleChange={(e) => setOrderStatus("Returned")}
            />
          </div>
        </div>
        {orderStatus === "Sent" && (
          <div className="form-group-wrapper">
            <label className="modalLineHeaders">Add Tracking Number:</label>
            <input
              className="modalInput"
              placeholder="Enter Tracking Number"
              value={trackingNumber}
              onChange={(e) => setTrackingNumber(e.target.value)}
            />
          </div>
        )}
        <div className="form-group-wrapper">
          <label className="modalLineHeaders">Select Date:</label>
          <input
            className="modalInput"
            type="datetime-local"
            value={orderMonth}
            style={{ width: "100%", marginBottom: "10px" }}
            onChange={(e) => setOrderMonth(e.target.value)}
            max={moment().format("YYYY-MM-DDTHH:mm")}
          />
        </div>
        <div className="form-group-wrapper">
          <label className="modalLineHeaders">Message: </label>

          <textarea className="modalInput w-100" onChange={(e) => setMessage(e.target.value)} />
        </div>
      </Modal.Body>
      {error && (
        <div className="ms-2">
          <ErrorMessage error={error} handleChange={() => setError("")} />
        </div>
      )}
      {loading && <Loader />}

      <Modal.Footer>
        <Button
          className="headerButton btn-fill"
          variant="primary"
          onClick={() => {
            handleClose();
          }}
        >
          Close
        </Button>
        <Button
          variant="secondary"
          className="headerButton btn-fill"
          onClick={() => {
            handleSave(orderStatus);
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ManualOrderStatusModal;
