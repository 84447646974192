import React from 'react'

const HeaderRendererEditable = ({ label, align = "left", showClearIcon = false, onClear }) => (
    <div
      style={{
        textAlign: align,
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: align === "left" ? "flex-start" : align === "center" ? "center" : "flex-end",
      }}
    >
      {label}
      {showClearIcon && (
        <div
          style={{
            marginLeft: "10px",
            background: "#BF1E2E",
            borderRadius: "5px",
            cursor: "pointer",
            width: "30px",
            height: "80%",
            padding: "4px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={onClear}
        >
          <span style={{ color: "white", fontWeight: "bold" }}>X</span>
        </div>
      )}
    </div>
  );

export default HeaderRendererEditable