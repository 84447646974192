import SwitchToggle from "components/SwitchToggle/SwitchToggle";
import { CLI_ACTIONS } from "constant";
import React, { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { t } from "stringConstants";
import { createICTCodeAsync, updateICTCodeAsync } from "../../store/features/cptCodes/cptCodeSlice";
import { RemoveSpaceAndChar } from "../../utils";
import InputSelectDropDown from "../InputField/InputSelectDropDown";
const AddDiagnosisCodeSettingsModal = (props) => {
  const { handleClose, selectedItem, handleSave } = props;
  const [newItem, setNewItem] = useState(
    selectedItem ? { ...selectedItem, action: CLI_ACTIONS.update } : { action: CLI_ACTIONS.add }
  );
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  console.log("newItem", newItem);
  const onHandleSave = async (obj) => {
    if (!obj.code || !obj.code.trim()) {
      setError("Please fill Diagnosis Code field!");
      return;
    }
    if (!obj.title || !obj.title.trim()) {
      setError("ICT code title is required");
      return;
    }

    if (!obj.internalCode || !obj.internalCode.trim()) {
      Object.assign(obj, { internalCode: obj.code });
    }
    try {
      if (newItem.action === CLI_ACTIONS.add) {
        dispatch(createICTCodeAsync(obj));
      } else if (newItem.action === CLI_ACTIONS.update) {
        dispatch(updateICTCodeAsync(obj));
      }
      handleClose();
    } catch (err) {
      console.log("Error in ICT Modal", err);
    }
  };

  return (
    <>
      <Modal show backdrop="static" animation={true} onHide={() => handleClose()} centered size={"lg"}>
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            {newItem.action === CLI_ACTIONS.add ? `${t("create")}` : `${t("update")}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: 0 }}>
          {/* <div className="d-flex justify-content-around">
            
          </div> */}
          <div className="crew-form-list crew-profile-again flex-direction row11">
            <div className="w-100 createClientsWrapper">
              <Form.Group className="form-group-wrapper w-100">
                <Form.Label className="mt-0 text-capitalize">ICT Code:</Form.Label>
                <Form.Control
                  className={`modalInput`}
                  minLength={5}
                  onChange={(e) => setNewItem({ ...newItem, code: RemoveSpaceAndChar(e.target.value).toUpperCase() })}
                  value={newItem?.code}
                  placeholder="Enter ICT Code"
                  type="text"
                />
              </Form.Group>
            </div>
            <div className="w-100 createClientsWrapper">
              <Form.Group className="form-group-wrapper w-100">
                <Form.Label className="mt-0 text-capitalize">Alias:</Form.Label>
                <Form.Control
                  className={`modalInput`}
                  minLength={5}
                  onChange={(e) => setNewItem({ ...newItem, internalCode: e.target.value.toUpperCase() })}
                  value={newItem?.internalCode}
                  placeholder="Enter Alias"
                  type="text"
                />
              </Form.Group>
            </div>
            <div className="w-100 createClientsWrapper">
              <Form.Group className="form-group-wrapper w-100">
                <Form.Label className="mt-0 text-capitalize">title</Form.Label>
                <Form.Control
                  className={`modalInput`}
                  maxLength={65}
                  onChange={(e) => setNewItem({ ...newItem, title: e.target.value })}
                  value={newItem?.title}
                  placeholder="Enter ICT Title"
                  type="text"
                />
              </Form.Group>
            </div>
          </div>
          <div
            className="d-flex align-items-center column-gap-10 profile-
                          detail mb-3"
          >
            <span className="section-title">Is Active: </span>
            <SwitchToggle
              checked={newItem.isActive}
              handleChange={(e) =>
                setNewItem({
                  ...newItem,
                  isActive: e.target.checked,
                })
              }
            />
          </div>
        </Modal.Body>
        <div className="px-4 my-2" style={{ height: "2vh" }}>
          {error && <strong style={{ color: "Red" }}>{error}</strong>}
        </div>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="primary"
            className="modalButtons headerButton btn-fill"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
          <Button
            style={{ marginBottom: 10 }}
            variant="secondary"
            className="modalButtons headerButton btn-fill"
            onClick={() => onHandleSave(newItem)}
          >
            {newItem.action === CLI_ACTIONS.add ? "Create" : "Update"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddDiagnosisCodeSettingsModal;
