import api from "api";
import InputCPTCodeDropDown from "components/InputField/InputCPTCodeDropDown";
import InputField from "components/InputField/InputField";
import Loader from "components/Loader/Loader";
import ErrorMessage from "components/Message/ErrorMessage";
import { TEST_TYPE_OPT } from "constant";
import { PROCEDURE_CODE_STATES } from "constant";
import { useEffect, useState } from "react";
import { Button, Card, Form, Modal, Table } from "react-bootstrap";
import { formatOrderDate } from "utils";
import { parseStringIntoFloat } from "utils";

const DefaultClientSettingModal = (props) => {
  const { handleClose, data, cssClass } = props;
  const [obj, setObj] = useState({});
  const [settingList, setSettingList] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchClientSetting = async (id) => {
    try {
      setLoading(true);
      const res = await api.getClientTestSettingByCompanyID(id);
      setSettingList(res);
      setLoading(false);
    } catch (err) {
      console.log("Error:-", err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClientSetting(data.id);
  }, [data]);

  const handleSubmit = (actionState) => {
    if (!obj.test_type) {
      setError("Test Type is required");
      return;
    }
    if (!obj.proc_code || !obj.proc_code.trim()) {
      setError("Procedure Code is required");
      return;
    }
    if (!obj.diag_1) {
      setError(" Diagnosis Code  is required");
      return;
    }
  };
  const handleTrashSetting = async (id) => {
    try {
      setLoading(true);
      const res = await api.deleteClientTestSetting(id);
      setSettingList((prev) => prev.filter((f) => f.id !== id));
      setLoading(false);
    } catch (err) {
      console.log("Error:-", err.message);
      setLoading(false);
    }
  };



  const TableRow = ({ item, sr }) => {
    return (
      <>
        <tr>
          <td className="icon" style={{ width: "45px", minWidth: "45px", maxWidth: "45px" }}>
            {item.length - sr}
          </td>

          <td className={`ellipsis `}>{item.test}</td>
          <td className={`ellipsis`}>{item.defaultCPT[0].proc_code}</td>
          <td className={`ellipsis`}> {item.defaultICT[0].diag_1}</td>
          <td onClick={() => handleTrashSetting(item)}>
            <i className="fas fa-trash" />
          </td>
        </tr>
      </>
    );
  };

  return (
    <>
      {loading && <Loader />}
      <Modal
        show={true}
        backdrop="static"
        className={`${cssClass}`}
        animation={true}
        onHide={() => handleClose()}
        centered
        size={"xl"}
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            Default Procedure Code
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ paddingTop: 0 }}>
          <div className="crew-form-list crew-profile-again flex-direction row11">
            <div className="createClientsWrapper w-100">
              <InputField
                type="dropDown"
                options={TEST_TYPE_OPT}
                labelStyle="text-capitalize"
                label="Tests"
                placeholder="Select Test Types"
                index={"test_type"}
                optionList={[]}
                value={obj.test_type ? TEST_TYPE_OPT.find((f) => f.value === obj.test_type) : null}
                handleChange={(opt) => {
                  if (opt) {
                    setObj({
                      ...obj,
                      test_type: opt.value,
                    });
                  } else {
                    setObj({ ...obj, test_type: "" });
                  }
                }}
                required
              />
              <div className="w-100 createClientsWrapper">
                <InputCPTCodeDropDown
                  type="text"
                  inputStyle="modalInput"
                  labelStyle=" text-capitalize"
                  label={"PROCEDURE"}
                  placeholder={"PROCEDURE"}
                  optionList={[]}
                  value={obj.internalCode || obj.proc_code || ""}
                  handleChange={(opt) =>
                    setObj({
                      ...obj,
                      proc_code: opt.value,
                      charge: parseStringIntoFloat(opt.charges),
                      internalCode: opt.internalCode,
                    })
                  }
                  searchtype="cpt"
                />
              </div>

              <div className="w-100 createClientsWrapper">
                <InputCPTCodeDropDown
                  type="text"
                  inputStyle="modalInput"
                  labelStyle="text-capitalize"
                  groupWrapper="mb-0 form-group-wrapper"
                  label={"Diagnosis"}
                  selectedDiagnosis={[...(obj.diag_1 ? [obj.diag_1] : [])]}
                  placeholder={"Search Code"}
                  optionList={[]}
                  value={obj.diag_1 || obj.diag_1_internal || ""}
                  handleChange={(opt) =>
                    setObj({
                      ...obj,
                      [`diag_1`]: opt?.value,
                      diag_1_internal: opt?.internalCode,
                    })
                  }
                  searchtype="ict"
                />
              </div>
            </div>
          </div>
          {settingList && settingList.length > 0 && (
            <Card>
              <Card.Body>
                <Table className="table">
                  <thead>
                    <tr>
                      <th
                        className={`ellipsis border-0`}
                        style={{
                          color: "grey",
                          fontWeight: "bold",
                        }}
                      >
                        Sr#
                      </th>
                      <th
                        className={`ellipsis border-0`}
                        style={{
                          color: "grey",
                          fontWeight: "bold",
                        }}
                      >
                        <div>
                          <span title="Order Status">Tests</span>
                        </div>
                      </th>
                      <th
                        className={`ellipsis border-0`}
                        style={{
                          color: "grey",
                          fontWeight: "bold",
                        }}
                      >
                        <div>
                          <span title="Order Status">Procedure Code</span>
                        </div>
                      </th>
                      <th
                        className={`ellipsis border-0`}
                        style={{
                          color: "grey",
                          fontWeight: "bold",
                        }}
                      >
                        <div>
                          <span title="Order Status">Diagnosis Code</span>
                        </div>
                      </th>
                      <th
                        className={`ellipsis border-0`}
                        style={{
                          color: "grey",
                          fontWeight: "bold",
                        }}
                      >
                        <div>
                          <span title="Order Status"></span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {settingList.map((o, index) => {
                      return <TableRow key={index} item={o} sr={index} />;
                    })}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          )}
        </Modal.Body>
        <div className="px-4 my-2" style={{ height: "2vh" }}>
          {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
        </div>

        <Modal.Footer>
          <Button variant="primary" className="headerButton btn-fill" onClick={() => handleClose()}>
            Close
          </Button>
          <Button
            variant="secondary"
            className="headerButton btn-fill mx-3 ms-auto"
            onClick={() => handleSubmit(PROCEDURE_CODE_STATES.PLUS)}
          >
            Add
          </Button>
          {/* {Object.keys(data).length === 0 ? (
          <Button
            variant="secondary"
            className="headerButton btn-fill"
            onClick={() => handleSubmit(PROCEDURE_CODE_STATES.ADD)}
          >
            Add
          </Button>
        ) : (
          <Button
            variant="secondary"
            className="headerButton btn-fill"
            onClick={() => handleSubmit(PROCEDURE_CODE_STATES.UPDATE)}
          >
            Update
          </Button>
        )} */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DefaultClientSettingModal;
