import saleApi from "api/saleApi";
import AutoCompleteAddress from "components/AutoCompleteAddress";
import InputField from "components/InputField/InputField";
import Loader from "components/Loader/Loader";
import ErrorMessage from "components/Message/ErrorMessage";
import PhoneNoInput from "components/Phone/PhoneNoInput";
import RadioButtonOptions from "components/RadioButton/RadioButtonOptions";
import SwitchToggle from "components/SwitchToggle/SwitchToggle";
import { GENDER_OPTIONS, MESSAGE_MODES, countryListAllIsoData } from "constant";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginUser } from "store/features/authentication/authenticationSelectors";
import { setMessage } from "store/features/general/generalAction";
import { setSelectedSalePerson, updateSalesPersonInList } from "store/features/salePerson/salePersonAction";
import { selectedClientIdSelector } from "store/features/salePerson/salePersonSelector";
import { formatZip, getValidSpaces, isValidPhoneWithCode } from "utils";
import moment from "moment";
import Select from "react-select";
import { locationsSelectors } from "store/features/locations/locationsSelectors";

const SalePersonModal = (props) => {
  const { selectItem, cssClass } = props;

  const loginUser = useSelector(selectLoginUser);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const selectedClientId = useSelector(selectedClientIdSelector);
  const clientsList = useSelector(locationsSelectors);

  const getObject = () => {
    const country = countryListAllIsoData.find((f) => f.name === selectItem.country) || { code: "US" };

    return {
      ...selectItem,
      isoCode: country.code,
      updatedBy: loginUser.sub,
      updatedByName: loginUser.name,
    };
  };
  const [item, setItem] = useState(getObject());

  const clientsDropdownOptions = clientsList.map(({ id, name }) => {
    return { label: name, value: id };
  });

  const handleSaveChange = async () => {
    if (!item.firstName || !item.firstName.trim()) {
      setError("Sale person first name required");
      return;
    }

    if (!item.lastName || !item.lastName.trim()) {
      setError("Sale person first name required");
      return;
    }

    // if (!item.phoneNumber) {
    //   setError("Phone Number is required");
    //   return;
    // }

    // if (!isValidPhoneWithCode(item.phoneNumber)) {
    //   setError("Invalid Phone Number");
    //   return;
    // }

    // if (!selectedClientId && !item.siteID) {
    //   setError("Client is required");
    //   return;
    // }

    setLoading(true);
    delete item["isNew"];
    let updated = false;
    if (selectItem.isNew) {
      const newSalesPersonRes = await saleApi.createSalePerson(item);
      dispatch(updateSalesPersonInList({ updatedSalesPerson: newSalesPersonRes.rows[0], isNew: true }));
      updated = true;
    } else {
      updated = await saleApi.updateSalePerson(item);
      dispatch(updateSalesPersonInList({ updatedSalesPerson: item, isNew: false }));
    }

    if (updated) {
      dispatch(setMessage("Record save sucessfully", MESSAGE_MODES.success));
    } else {
      dispatch(setMessage("Fail to save the Record", MESSAGE_MODES.error));
    }
    setLoading(false);
    dispatch(setSelectedSalePerson(null));
  };

  const handleUpdateAddress = (val, flag) => {
    if (!flag) {
      setItem({ ...item, street: val });
    } else {
      const address = {
        street: val.street,
        country: val.country,
        city: val.city,
        zip: val.zipcode,
        state: val.state,
        isoCode: val.isoCode,
      };
      setItem({ ...item, ...address });
    }
  };

  const handleClose = () => {
    dispatch(setSelectedSalePerson(null));
  };

  return (
    <Modal show backdrop="static" className={cssClass} animation={true} centered size={"lg"}>
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          {item.isNew ? " New Sale Representative" : "Edit Sale Representative"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="createClientsWrapper">
          <div className="leftSide">
            <div className="row mb-3">
              <InputField
                type="text"
                inputStyle="modalInput"
                labelStyle="text-capitalize"
                label="First Name"
                value={item.firstName}
                index="firstName"
                placeholder="First Name"
                handleChange={(e) =>
                  setItem({
                    ...item,
                    firstName: e.target.value,
                  })
                }
                required
              />
              <InputField
                type="text"
                inputStyle="modalInput"
                labelStyle="text-capitalize"
                label="Last Name"
                value={item.lastName}
                index="lastName"
                placeholder="Last Name"
                handleChange={(e) =>
                  setItem({
                    ...item,
                    lastName: e.target.value,
                  })
                }
                required
              />

              <div className="form-group-wrapper">
                <RadioButtonOptions
                  label={"Gender:"}
                  options={GENDER_OPTIONS}
                  name={`GENDER_OPTIONS`}
                  checkedVal={item?.gender}
                  handleChange={(e, obj) => e.target.checked && setItem({ ...item, gender: obj.value })}
                />
              </div>

              <InputField
                type="datePicker"
                inputStyle="w-100 orderTestDob"
                labelStyle="modalLineHeaders mt-0 text-capitalize"
                label="Date of Birth"
                value={
                  item.dob && new Date(item.dob) != "Invalid Date" ? moment(item.dob, "YYYY-MM-DD").toDate() : null
                }
                index="dob"
                placeholder="Date of Birth"
                handleChange={(e) =>
                  setItem({
                    ...item,
                    dob: moment(e).format("YYYY-MM-DD") != "Invalid date" ? moment(e).format("YYYY-MM-DD") : "",
                  })
                }
                minDate={new Date("1900-01-01")}
                maxDate={new Date()}
              />

              <div className="form-group-wrapper phoneInput position-relative">
                <label className="modalLineHeaders">Phone Number:</label>
                <PhoneNoInput
                  handleChange={(e) =>
                    setItem({
                      ...item,
                      phoneNumber: e,
                    })
                  }
                  value={item?.phoneNumber}
                />
              </div>

              <InputField
                type="text"
                inputStyle="modalInput"
                labelStyle="text-capitalize"
                label="Email"
                value={item.email}
                index="firstName"
                placeholder="Email"
                handleChange={(e) =>
                  setItem({
                    ...item,
                    email: e.target.value,
                  })
                }
              />

              <div className="form-group-wrapper">
                <label className="modalLineHeaders">ID#</label>
                <input
                  type="number"
                  value={item.practiceID}
                  className={`modalInput`}
                  placeholder="Lab Specific ID"
                  style={{
                    width: "100%",
                    flexShrink: "initial",
                  }}
                  onChange={(e) => {
                    let val = Number(e.target.value);
                    setItem({
                      ...item,
                      practiceID: val,
                    });
                  }}
                />
              </div>

              <div
                className="d-flex align-items-center justify-content-between profile-
                          detail mb-3"
              >
                <span>Active/Inactive:</span>
                <SwitchToggle
                  checked={item?.isActive}
                  handleChange={(e) =>
                    setItem({
                      ...item,
                      isActive: e.target.checked,
                    })
                  }
                />
              </div>
            </div>

            {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
          </div>
          <div className="rightSide">
            <div className="form-group-wrapper">
              <label className="modalLineHeaders mt-0">Address:</label>
              <AutoCompleteAddress cssClass="modalInput" value={item.street} handleChange={handleUpdateAddress} />
            </div>
            <InputField
              type="text"
              inputStyle="modalInput"
              labelStyle="text-capitalize"
              label="City"
              value={item.city}
              index="firstName"
              placeholder="City"
              handleChange={(e) =>
                setItem({
                  ...item,
                  city: e.target.value,
                })
              }
            />

            <InputField
              type="text"
              inputStyle="modalInput"
              labelStyle="text-capitalize"
              value={item.state}
              index="state"
              placeholder="Enter State"
              maxLength={10}
              label="State"
              handleChange={(e) => {
                setItem({
                  ...item,
                  state: e.target.value,
                });
              }}
            />
            <InputField
              type="text"
              inputStyle="modalInput"
              labelStyle="text-capitalize"
              value={item.isoCode === "US" ? item.zip : item.zip}
              placeholder="Enter Zip Code"
              index="zip"
              maxLength={10}
              label="Zip"
              handleChange={(e) => {
                let val = e.target.value;
                if (val) val = val.toUpperCase();
                setItem({
                  ...item,
                  zip: item.isoCode === "US" ? formatZip(val) : getValidSpaces(val),
                });
              }}
            />

            <InputField
              type="text"
              inputStyle="modalInput"
              labelStyle="text-capitalize"
              label="Country"
              value={item.country}
              index="country"
              placeholder="Country"
              handleChange={(e) =>
                setItem({
                  ...item,
                  country: e.target.value,
                })
              }
            />
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Commission %:</label>
              <input
                type="number"
                value={item.commission}
                className={`modalInput`}
                placeholder="%"
                style={{
                  width: "100%",
                  flexShrink: "initial",
                }}
                onChange={(e) => {
                  let val = Number(e.target.value);

                  if (val < 0 || val > 100) {
                    return;
                  }

                  setItem({
                    ...item,
                    commission: val,
                  });
                }}
              />
            </div>
            {!selectedClientId && (
              <Form.Group className="form-group-wrapper">
                <label className="modalLineHeaders mt-0 text-capitalize">Select Client</label>
                <Select
                  options={clientsDropdownOptions}
                  className="w-100"
                  value={clientsDropdownOptions.find((opts) => opts.value === item.siteID)}
                  menuPlacement="auto"
                  autoComplete="none"
                  onChange={(e) => setItem({ ...item, siteID: e.value })}
                />
              </Form.Group>
            )}
          </div>
          {loading && <Loader />}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" className="headerButton btn-fill" onClick={() => handleClose()}>
          Close
        </Button>
        <div>
          <Button variant="secondary" className="headerButton btn-fill" onClick={handleSaveChange} disabled={loading}>
            {item.isNew ? "Create" : "Update"}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default SalePersonModal;
