import React from "react";
import inputStyle from "./MoneyInput.module.css";

const MoneyInput = ({
  onChange,
  value,
  onBlur,
  onFocus,
  customSign,
  moneyInputClass,
  customStyle,
  readOnly = false,
}) => {
  return (
    <div className={`${inputStyle.moneyInputContainer} ${moneyInputClass} `}>
      <span className={`${inputStyle.dollarSign} ${customSign}`}>$</span>
      <input
        type="text"
        maxLength="10"
        placeholder="0"
        className={`${inputStyle.dollarInput} ${customStyle && customStyle}`}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        readOnly={readOnly}
      />
    </div>
  );
};

export default MoneyInput;
