import React from "react";
const RadioButton = (props) => {
  const { id, label, name, checked, handleChange, value, cssClass, disabled } = props;
  return (
    <div className={`radio-button-wrapper ${cssClass ?? ""}`}>
      <input
        value={value}
        type="radio"
        disabled={disabled}
        name={name}
        id={id}
        checked={checked}
        onChange={handleChange}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

export default RadioButton;
