import React, { useState, useRef } from "react";
import { Overlay, Popover } from "react-bootstrap";
import { Card, Table, Row, Col, Button } from "react-bootstrap";

const PopoverStick = (props) => {
  const { item, columns, values, cssClass } = props;
  const [showPopover, setShowPopover] = useState(false);
  const childNode = useRef();
  let setTimeoutConst = null;
  const handleMouseEnter = () => {
    setTimeoutConst = setTimeout(() => {
      setShowPopover(true);
    }, 1000);
  };
  const handleMouseLeave = () => {
    clearTimeout(setTimeoutConst);
    setShowPopover(false);
  };

  const TableRow = ({ rowItem, index }) => {
    return (
      <tr key={index}>
        {columns.map((m) => (
          <td className="popOver-centered border-0">{rowItem[m]}</td>
        ))}
      </tr>
    );
  };

  return (
    <>
      <td
        className={cssClass}
        ref={childNode}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={() => setShowPopover(true)}
        style={{
          cursor: "pointer",
        }}
      >
        {item}
      </td>
      {values?.length > 0 && showPopover && (
        <Overlay show={showPopover} placement="auto" flip target={childNode.current}>
          <Popover
            onMouseEnter={() => {
              setShowPopover(true);
            }}
            onMouseLeave={handleMouseLeave}
            id="popover"
            className="popOverSchedule"
          >
            <Popover.Header className="fw-bold"></Popover.Header>
            <Card className="striped-table-card programPopOverCard">
              <Card.Body>
                <div className="form-group-wrapper d-block">
                  <div className="table-responsive overFlow-y-hidden">
                    <Table className="table">
                      <thead>
                        <tr>
                          {columns.map((m) => (
                            <th className="popOver-centered border-0">{m}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {values.map((val, i) => {
                          return <TableRow key={i} index={i} rowItem={val} />;
                        })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Popover>
        </Overlay>
      )}
    </>
  );
};

export default PopoverStick;
