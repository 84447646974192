import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchMessageTemplateLists } from "store/features/messageTemplate/messageTemplateAction";
import { messageTemplateListSelector } from "store/features/messageTemplate/messageTemplateSelectors";
import Select from "react-select";
import {
  ReactSelectCustomContainer,
  Option,
  customStyles,
} from "components/CustomReactSelect/ReactSelectCustomOptionContainer";
import createTemplateIcon from "../../assets/img/create.svg";
import { setSelectedMessageTemplate } from "store/features/messageTemplate/messageTemplateSlice";
import { setShowMessageTemplateModal } from "store/features/messageTemplate/messageTemplateSlice";

const MessageSubjectNoteModel = (props) => {
  const { handleClose, show, handleSave, existingData } = props;

  const initialEmailMessageState = { note: "", errors: {} };

  const [emailMessage, setEmailMessage] = useState(initialEmailMessageState);

  const dispatch = useDispatch();

  const messageTemplatesList = useSelector(messageTemplateListSelector);

  const messageList = useMemo(
    () =>
      messageTemplatesList.map((item) => ({
        label: item.subject,
        value: item.message,
      })),
    [messageTemplatesList]
  );

  const handleTemplateSelect = useCallback(
    (event) => {
      const { value } = event;
      const formattedMessage = formatMessage(value, existingData);
      setEmailMessage({ note: formattedMessage, errors: {} });
    },
    [existingData]
  );

  const handelChange = useCallback((e) => {
    const { name, value } = e.target;
    if (name === "note" && value.length > 220) {
      return;
    }
    setEmailMessage((prevState) => ({ ...prevState, [name]: value }));
  }, []);

  useEffect(() => {
    dispatch(fetchMessageTemplateLists());
    if (existingData) {
      setEmailMessage((prevState) => ({ ...prevState, note: existingData.message }));
    }
  }, [dispatch, existingData]);

  const formatMessage = (template, data) => {
    const replacePlaceholder = (template, key, value) => {
      const regex = new RegExp(`{${key}}`, "g");
      return template.replace(regex, value || "");
    };

    let message = template;
    message = replacePlaceholder(message, "first_name", data?.firstName || "");
    message = replacePlaceholder(message, "last_name", data?.lastName || "");
    message = replacePlaceholder(message, "payer_name", data?.payer_name || "");
    message = replacePlaceholder(message, "payer_address", data?.payer_address || "");

    if (data.details && Array.isArray(data.details)) {
      const cptCodes = data.details
        .map((detail) => detail.proc_code)
        .filter((code) => code)
        .join(", ");
      message = replacePlaceholder(message, "cpt_code", cptCodes);
    } else {
      message = replacePlaceholder(message, "cpt_code", "");
    }

    return message;
  };

  const validateForm = () => {
    let isValid = true;
    let errors = {};

    if (!emailMessage.note.trim()) {
      errors.note = "Note is required";
      isValid = false;
    }

    setEmailMessage({ ...emailMessage, errors: errors });
    return isValid;
  };

  const handleSaveData = () => {
    if (validateForm()) {
      setEmailMessage(initialEmailMessageState);
      handleSave(emailMessage);
    }
  };

  const handleCreateMessageTemplate = () => {
    dispatch(setSelectedMessageTemplate({ isNew: true, fromDisclaimer:true }));
    dispatch(setShowMessageTemplateModal(true));
  };

  return (
    <Modal
      show={show}
      backdrop="static"
      onHide={handleClose}
      animation={true}
      size={"lg"}
      scrollable
      centered
      className="seperateModal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0">Disclaimer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{display:"flex", width:"100%"}}>
          <Form.Group className="form-group-wrapper" style={{ flexGrow: 1, marginRight: "8px" }}>
            <Select
              options={messageList}
              className="w-100"
              menuPlacement="auto"
              menuPortalTarget={document.body}
              styles={{ ...customStyles, menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              autoComplete="none"
              onChange={handleTemplateSelect}
              components={{ Option, ValueContainer: ReactSelectCustomContainer }}
            />
          </Form.Group>

            <div
              style={{cursor: "pointer" }}
              onClick={handleCreateMessageTemplate}
            >
              <img src={createTemplateIcon} className="custom-filter-red" />
            </div>
        
        </div>

        <div className="row textAreaMessageInput">
          <div className="col-12 mb-3">
            <textarea
              placeholder={"Note!"}
              name="note"
              value={emailMessage.note}
              className={`MessageInput ${emailMessage.errors.note ? "error-message-box" : ""}`}
              onChange={handelChange}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ marginBottom: 10 }}
          variant="primary"
          className="modalButtons headerButton btn-fill"
          onClick={handleClose}
        >
          Close
        </Button>
        <Button
          style={{ marginBottom: 10 }}
          variant="primary"
          className="modalButtons headerButton btn-fill"
          onClick={handleSaveData}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MessageSubjectNoteModel;
