import api from "api";
import Loader from "components/Loader/Loader";
import { INSURANCE_PROVIDER, MEDICATRE_IDS, MESSAGE_MODES, PAYER_NETWORK_OPTIONS } from "constant";
import moment from "moment";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Modal } from "react-bootstrap";
import DatePicker from "react-date-picker";
import Select from "react-select";
import { setMessage } from "store/features/general/generalAction";
import { CONFIG } from "../../constant";
import { t } from "../../stringConstants";
import { convertToUpper, getValidDep, isValidIDNumber, isValidName, validatePhone } from "../../utils";
import RadioButton from "../RadioButton/RadioButton";
import { insurnaceSelector, selectedInsurnaceSelector } from "../../store/features/insurances/insuranceSelectors";
import DropDown from "../Modal/DropDown";
import AutoCompleteAddress from "../AutoCompleteAddress";
import PhoneNoInput from "components/Phone/PhoneNoInput";
import SwitchToggle from "components/SwitchToggle/SwitchToggle";
import { createInsuranceAsync, updateInsuranceAsync } from "../../store/features/insurances/insuranceSlice";
import InputField from "components/InputField/InputField";
import InputGrid from "components/Input/InputGrid";
import { selectedCompanySetting } from "../../store/features/companySetting/companySettingSelectors";

const options = {
  componentRestrictions: { country: "us" },
  fields: ["address_components", "name", "formatted_address"],
  strictBounds: false,
  types: [],
};
const InsuranceModal = (props) => {
  const { handleClose, cssClass } = props;
  const { selectedInsurance } = useSelector(insurnaceSelector);
  const [newInsurance, setNewInsurnace] = useState(selectedInsurance);
  const [address, setAddress] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const firstNameRef = useRef();
  const dispatch = useDispatch();

  const { otherNPI, cliaNumber } = useSelector(selectedCompanySetting);

  useEffect(() => {
    setTimeout(() => {
      setError("");
    }, 4000);
  }, [error]);

  useEffect(() => {
    if (firstNameRef.current) {
      firstNameRef.current.focus();
    }

    if (newInsurance.npi) {
      const data = otherNPI.find((f) => f.npi === newInsurance.npi);
      if (data) {
        setNewInsurnace({
          ...newInsurance,
          npiName: data.name,
        });
      }
    }
  }, []);

  const checkValidation = (newInsurance) => {
    if (!newInsurance.Name || !newInsurance.Name.trim()) {
      setError("Insurance name is required");
      return;
    }

    if (!newInsurance.shortName || !newInsurance.shortName.trim()) {
      setError("Lab Alias is required");
      return;
    }

    // if (!newInsurance.phone || !newInsurance.phone.trim()) {
    //   setError("Phone Number is required");
    //   return;
    // }
    // if (!validatePhone(newInsurance.phone)) {
    //   setError("Invalid Phone Number");
    //   return;
    // }
    // if (!newInsurance.fax || !newInsurance.fax.trim()) {
    //   setError("Fax is required");
    //   return;
    // }
    // if (!validatePhone(newInsurance.fax)) {
    //   setError("Invalid Fax");
    //   return;
    // }
    // if (!newInsurance.eClaim) {
    //   if (!newInsurance.manualTerm || !newInsurance.manualTerm.trim()) {
    //     setError("Term is required");
    //     return;
    //   }
    //   if (!newInsurance.eFax || !newInsurance.eFax.trim()) {
    //     setError("eFax is required");
    //     return;
    //   }
    //   if (!validatePhone(newInsurance.eFax)) {
    //     setError("Invalid eFax");
    //     return;
    //   }
    //   if (!newInsurance.manualMail || !newInsurance.manualMail.trim()) {
    //     setError("Mail is required");
    //     return;
    //   }
    // }

    // if (!newInsurance.ERA) {
    //   if (!newInsurance.eraMail || !newInsurance.eraMail.trim()) {
    //     setError("Era Mail is required");
    //     return;
    //   }
    // }
    // if (!newInsurance.claimPhone || !newInsurance.claimPhone.trim()) {
    //   setError("Claim Phone is required");
    //   return;
    // }
    // if (!validatePhone(newInsurance.claimPhone)) {
    //   setError("Invalid Claim Phone");
    //   return;
    // }

    // if (!newInsurance.claimFax || !newInsurance.claimFax.trim()) {
    //   setError("Claim Fax is required");
    //   return;
    // }
    // if (!validatePhone(newInsurance.claimFax)) {
    //   setError("Invalid Claim Fax");
    //   return;
    // }(

    if (!newInsurance.npi || !newInsurance.npi.trim()) {
      setError("NPI is Required");
      return;
    }

    return true;
  };

  const onHandleSave = async () => {
    const isValidation = checkValidation(newInsurance);
    if (!isValidation) return;

    const data = { ...newInsurance };

    if (newInsurance.eClaim) {
      Object.assign(data, { manualAmount: 0, manualTerm: "", manualMail: "", eFax: "" });
    }
    if (newInsurance.ERA) {
      Object.assign(data, { eraMail: false });
    }

    try {
      if (newInsurance.isNew) {
        await dispatch(createInsuranceAsync(data));
      } else {
        await dispatch(updateInsuranceAsync(data));
      }
      handleClose();
    } catch (err) {
      console.log(err);
      dispatch(setMessage(err.message, MESSAGE_MODES.error));
    }
  };

  const handlePhoneNo = (e) => {
    setNewInsurnace({
      ...newInsurance,
      phoneNumber: e,
    });
  };
  const handleUpdateAddress = (val, flag) => {
    const addressObj = {
      address: val.street,
      city: val.city,
      zip: val.zipcode,
      state: val.state,
    };
    setNewInsurnace({ ...newInsurance, ...addressObj });
  };

  const handleUpdateClaimAddress = (val, flag) => {
    const addressObj = {
      claimAddress: val.street,
      claimCity: val.city,
      claimZip: val.zipcode,
      claimState: val.state,
    };
    setNewInsurnace({ ...newInsurance, ...addressObj });
  };

  return (
    <>
      <Modal
        show
        animation={true}
        size="xl"
        onHide={() => {
          handleClose();
        }}
        backdrop="static"
        className={cssClass ?? ""}
        style={{ paddingLeft: "0" }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            Add Insurance Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingTop: 0,
          }}
        >
          <div className="createClientsWrapper">
            <div className="leftSide">
              <div className="row mb-3">
                <InputField
                  type="dropDown"
                  groupWrapper="w-100"
                  options={INSURANCE_PROVIDER}
                  labelStyle="text-capitalize"
                  label="Select Insurance:"
                  value={newInsurance.payerId ? INSURANCE_PROVIDER.find((f) => f.value === newInsurance.payerId) : null}
                  index="Name"
                  isClearable={true}
                  placeholder="Select Insurance"
                  handleChange={(e) => {
                    if (e) {
                      setNewInsurnace({ ...newInsurance, Name: e.label, payerId: e.value });
                    } else {
                      setNewInsurnace({ ...newInsurance, Name: "", payerId: "" });
                    }
                  }}
                />
                {/* <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Select Insurnace: </label>
                  <DropDown
                    data={INSURANCE_PROVIDER}
                    handleChange={(val, e) => {
                      console.log("Val", e);
                      setNewInsurnace({
                        ...newInsurance,
                        Name: e.label,
                        payerId: e.value,
                      });
                    }}
                  />
                </div> */}
                <div className="form-group-wrapper mt-3">
                  <label className="modalLineHeaders">Alias: </label>
                  <input
                    ref={firstNameRef}
                    value={newInsurance.shortName}
                    className="modalInput"
                    placeholder="Alias"
                    style={{
                      width: "100%",
                    }}
                    onChange={(e) =>
                      setNewInsurnace({
                        ...newInsurance,
                        shortName: getValidDep(e.target.value),
                      })
                    }
                  />
                </div>
                <div className="form-group-wrapper">
                  <label className="mb-1">Address:</label>
                  <AutoCompleteAddress
                    value={newInsurance.address}
                    handleChange={handleUpdateAddress}
                    cssClass={"modalInput"}
                  />
                </div>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders mt-0">City:</label>
                  <input
                    type="text"
                    className="modalInput"
                    value={newInsurance.city}
                    placeholder="City"
                    onChange={(e) => setNewInsurnace({ ...newInsurance, city: e.target.value })}
                  />
                </div>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders mt-0">State:</label>
                  <input
                    type="text"
                    className="modalInput"
                    value={newInsurance.state}
                    placeholder="State"
                    onChange={(e) => setNewInsurnace({ ...newInsurance, city: e.target.value })}
                  />
                </div>
                <div className="form-group-wrapper">
                  <Form.Group className="form-group-wrapper">
                    <label className="modalLineHeaders mt-0 text-capitalize">Phone Number</label>
                    <PhoneNoInput
                      cssClass={`employee-phone-input`}
                      handleChange={(e) =>
                        setNewInsurnace({
                          ...newInsurance,
                          phone: e,
                        })
                      }
                      value={newInsurance.phone}
                    />
                  </Form.Group>
                </div>
                <div className="form-group-wrapper">
                  <Form.Group className="form-group-wrapper">
                    <label className="modalLineHeaders mt-0 text-capitalize">Fax</label>
                    <PhoneNoInput
                      cssClass={`employee-phone-input`}
                      handleChange={(e) =>
                        setNewInsurnace({
                          ...newInsurance,
                          fax: e,
                        })
                      }
                      value={newInsurance.fax}
                    />
                  </Form.Group>
                </div>
                <InputField
                  type="dropDown"
                  groupWrapper="w-100"
                  options={otherNPI?.map((m) => ({ ...m, value: m.npi, label: m.npi })) || []}
                  labelStyle="text-capitalize"
                  label="Select Insurance NPI:"
                  value={newInsurance.npi ? { value: newInsurance.npi, label: newInsurance.npi } : null}
                  index="Name"
                  isClearable={true}
                  placeholder="Select Insurance NPI"
                  handleChange={(e) => {
                    if (e) {
                      setNewInsurnace({
                        ...newInsurance,
                        npi: e.value,
                        npiName: e.name,
                      });
                    } else {
                      setNewInsurnace({ ...newInsurance, npi: "" });
                    }
                  }}
                />
                {newInsurance.npi && (
                  <InputGrid
                    cssClass="mt-3"
                    headerLabel={"Lab Insurance Details"}
                    list={[
                      { label: "Name", value: newInsurance.npiName },
                      {
                        label: "Address",
                        value: newInsurance.address,
                      },
                    ]}
                  />
                )}
                <InputField
                  type="dropDown"
                  groupWrapper="w-100"
                  options={cliaNumber.map((m) => ({ value: m.cliCode, label: m.cliCode }))}
                  labelStyle="text-capitalize"
                  label="Select CLIA Number:"
                  value={
                    newInsurance.cliaNumber ? { value: newInsurance.cliaNumber, label: newInsurance.cliaNumber } : null
                  }
                  index="Name"
                  isClearable={true}
                  placeholder="Select CLIA Number"
                  handleChange={(e) => {
                    if (e) {
                      setNewInsurnace({ ...newInsurance, cliaNumber: e.value });
                    } else {
                      setNewInsurnace({ ...newInsurance, cliaNumber: "" });
                    }
                  }}
                />
              </div>
            </div>
            <div className="leftSide">
              <div className="row mb-3">
                <div
                  className="d-flex align-items-center justify-content-between profile-
                          detail mb-3"
                >
                  <span>Electronic Claims:</span>
                  <SwitchToggle
                    checked={newInsurance.eClaim}
                    handleChange={(e) =>
                      setNewInsurnace({
                        ...newInsurance,
                        eClaim: e.target.checked,
                      })
                    }
                  />
                </div>
                {!newInsurance.eClaim && (
                  <>
                    <div className="form-group-wrapper">
                      <label className="modalLineHeaders mt-0">Term:</label>
                      <input
                        type="text"
                        className="modalInput"
                        value={newInsurance.manualTerm}
                        placeholder="Term"
                        onChange={(e) => setNewInsurnace({ ...newInsurance, manualTerm: e.target.value })}
                      />
                    </div>
                    <div className="form-group-wrapper">
                      <label className="modalLineHeaders mt-0">Amount:</label>
                      <input
                        type="number"
                        className="modalInput"
                        value={newInsurance.manualAmount}
                        placeholder="Amount"
                        onChange={(e) => setNewInsurnace({ ...newInsurance, manualAmount: e.target.value })}
                      />
                    </div>
                    <Form.Group className="form-group-wrapper">
                      <label className="modalLineHeaders mt-0 text-capitalize">eFax</label>
                      <PhoneNoInput
                        cssClass={`employee-phone-input`}
                        handleChange={(e) =>
                          setNewInsurnace({
                            ...newInsurance,
                            eFax: e || "",
                          })
                        }
                        value={newInsurance.eFax}
                      />
                    </Form.Group>
                    <div className="form-group-wrapper">
                      <label className="modalLineHeaders mt-0">Mail:</label>
                      <input
                        type="email"
                        className="modalInput"
                        value={newInsurance.manualMail}
                        placeholder="Mail"
                        onChange={(e) => setNewInsurnace({ ...newInsurance, manualMail: e.target.value })}
                      />
                    </div>
                  </>
                )}
                <div
                  className="d-flex align-items-center justify-content-between profile-
                          detail mb-3"
                >
                  <span>Electronic Eligibility:</span>
                  <SwitchToggle
                    checked={newInsurance.eEligibility}
                    handleChange={(e) =>
                      setNewInsurnace({
                        ...newInsurance,
                        eEligibility: e.target.checked,
                      })
                    }
                  />
                </div>
                <div
                  className="d-flex align-items-center justify-content-between profile-
                          detail mb-3"
                >
                  <span>Electronic EDI:</span>
                  <SwitchToggle
                    checked={newInsurance.EDI}
                    handleChange={(e) =>
                      setNewInsurnace({
                        ...newInsurance,
                        EDI: e.target.checked,
                      })
                    }
                  />
                </div>
                <div
                  className="d-flex align-items-center justify-content-between profile-
                          detail mb-3"
                >
                  <span>Electronic ERA:</span>
                  <SwitchToggle
                    checked={newInsurance.ERA}
                    handleChange={(e) =>
                      setNewInsurnace({
                        ...newInsurance,
                        ERA: e.target.checked,
                      })
                    }
                  />
                </div>

                {!newInsurance.ERA && (
                  <div
                    className="d-flex align-items-center justify-content-between profile-
                          detail mb-3"
                  >
                    <span>ERA Mail:</span>
                    <SwitchToggle
                      checked={newInsurance.eraMail}
                      handleChange={(e) =>
                        setNewInsurnace({
                          ...newInsurance,
                          eraMail: e.target.checked,
                        })
                      }
                    />
                  </div>
                )}
                <div className="form-group-wrapper">
                  <label className="mb-1">Claim Address:</label>
                  <AutoCompleteAddress
                    value={newInsurance.claimAddress}
                    handleChange={handleUpdateClaimAddress}
                    cssClass={"modalInput"}
                  />
                </div>

                <div className="form-group-wrapper">
                  <label className="modalLineHeaders mt-0">Claim City:</label>
                  <input
                    type="text"
                    className="modalInput"
                    value={newInsurance.claimCity}
                    placeholder="City"
                    onChange={(e) => setNewInsurnace({ ...newInsurance, claimCity: e.target.value })}
                  />
                </div>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders mt-0">Claim State:</label>
                  <input
                    type="text"
                    className="modalInput"
                    value={newInsurance.claimState}
                    placeholder="State"
                    onChange={(e) => setNewInsurnace({ ...newInsurance, claimState: e.target.value })}
                  />
                </div>
                <div className="form-group-wrapper">
                  <Form.Group className="form-group-wrapper">
                    <label className="modalLineHeaders mt-0 text-capitalize">Claim Phone Number</label>
                    <PhoneNoInput
                      cssClass={`employee-phone-input`}
                      handleChange={(e) =>
                        setNewInsurnace({
                          ...newInsurance,
                          claimPhone: e,
                        })
                      }
                      value={newInsurance.claimPhone}
                    />
                  </Form.Group>
                </div>
                <div className="form-group-wrapper">
                  <Form.Group className="form-group-wrapper">
                    <label className="modalLineHeaders mt-0 text-capitalize">Claim Fax</label>
                    <PhoneNoInput
                      cssClass={`employee-phone-input`}
                      handleChange={(e) =>
                        setNewInsurnace({
                          ...newInsurance,
                          claimFax: e,
                        })
                      }
                      value={newInsurance.claimFax}
                    />
                  </Form.Group>
                </div>
                <InputField
                  type="dropDown"
                  groupWrapper="w-100"
                  options={PAYER_NETWORK_OPTIONS}
                  labelStyle="text-capitalize"
                  label="Network:"
                  value={newInsurance.network ? { value: newInsurance.network, label: newInsurance.network } : null}
                  index="Name"
                  isClearable={true}
                  placeholder="Select"
                  handleChange={(e) => {
                    setNewInsurnace({
                      ...newInsurance,
                      network: e.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <div className="px-4 my-2" style={{ height: "2vh" }}>
          {" "}
          {error.length > 0 && <strong style={{ color: "Red" }}>{error}</strong>}
        </div>
        {!loading ? (
          <Modal.Footer>
            <Button
              style={{ marginBottom: 10 }}
              variant="primary"
              className="modalButtons headerButton btn-fill"
              onClick={() => {
                handleClose();
              }}
            >
              Close
            </Button>

            <Button
              style={{ marginBottom: 10 }}
              variant="secondary"
              className="modalButtons headerButton btn-fill"
              onClick={() => onHandleSave()}
            >
              {newInsurance.isNew ? "Save" : "Update"}
            </Button>
          </Modal.Footer>
        ) : (
          <Loader />
        )}
      </Modal>
    </>
  );
};

export default InsuranceModal;
