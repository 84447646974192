import FullImageView from "components/Image/FullPageImageView";
import React, { useEffect, useRef, useState } from "react";
import { isValidFile } from "../../utils";
import CameraModal from "./CameraModal";

const ImageInput = (props) => {
  const { selectedImage, setSelectedImage, type, title, cssClass, showCropDialog, disabled, setCopyImage, imageCss, buttonText="Upload Image" } =
    props;
  const [image, setImage] = useState(selectedImage);
  const [fullView, setFullView] = useState("");
  const [captureImage, setCaptureImage] = useState(false);
  useEffect(() => {
    if (selectedImage instanceof Object) {
      setImage(URL.createObjectURL(selectedImage));
    } else {
      setImage(selectedImage);
    }
  }, [selectedImage]);

  const inputFile = useRef();

  const handleImageClick = () => {
    if (inputFile.current && title !== "profile") {
      inputFile.current.click();
    }
  };

  const onChangeImage = async (event) => {
    if (event.currentTarget.files && event.currentTarget.files.length > 0) {
      const file = event.currentTarget.files[0];
      //   const isValid = await isValidImageHeightWidth(file);
      //   if (!isValid) {
      //     alert("File resolution should be upto 150px*150px");
      //     return;
      //   }
      const message = isValidFile(file);
      if (message.length > 0) {
        alert(message);
        return;
      }
      setCopyImage ? setCopyImage(file) : setSelectedImage(file);
      //setImage(URL.createObjectURL(file));
      if (showCropDialog) showCropDialog(true);
      event.target.value = null;
    }
  };
  const handleClick = (image) => {
    setFullView(image);
  };

  if (type === "profile") {
    return (
      <>
        <FullImageView image={fullView} handleClose={() => setFullView("")} />
        <div className="inputFileWrapper positionRelative">
          {/* <i className="fas fa-times-circle"></i> */}
          {image && (
            <img
              src={image}
              onClick={() => handleClick(image instanceof Object ? URL.createObjectURL(image) : image)}
            />
          )}
          <div className="profileEditImage">
            <i
              className="fas fa-edit"
              onMouseOver={(e) => {
                e.target.style.cursor = "pointer";
              }}
              aria-hidden="true"
              onClick={handleImageClick}
            />
          </div>

          <input
            type="file"
            ref={inputFile}
            accept="image/*"
            onChange={(e) => onChangeImage(e)}
            style={{ display: "none" }}
          />
        </div>
      </>
    );
  }

  if (type === "Camera") {
    return (
      <>
        <FullImageView image={fullView} handleClose={() => setFullView("")} />
        <div className="icon-btn-wrapper w-100 d-flex justify-content-evenly align-items-center">
          <div className="image-capture-wrapper cursor-pointer" onClick={() => !disabled && setCaptureImage(true)}>
            <i style={{ lineHeight: "normal" }} className="email-attachment-btn nc-icon nc-camera-20 fw-bold fs-5" />
          </div>

          <div className={`inputFileWrapperFull d-block ${cssClass}`}>
            <input
              type="file"
              ref={inputFile}
              accept="image/*"
              disabled={disabled || false}
              onChange={(e) => onChangeImage(e)}
              style={{ display: "none" }}
            />
          </div>
          <div className="uploadImage" onClick={handleImageClick}>
            Upload Image
          </div>
          {image && (
            <img
              src={image}
              className={`${imageCss}`}
              onClick={() => handleClick(image instanceof Object ? URL.createObjectURL(image) : image)}
            />
          )}
        </div>
        {captureImage && (
          <CameraModal setImage={setSelectedImage} setCaptureImage={setCaptureImage} cssClass={"seperateModal"} />
        )}
      </>
    );
  }

  return (
    <>
      <FullImageView image={fullView} handleClose={() => setFullView("")} />
      <div>
        {title && <label className="modalLineHeaders">{`${title}:`}</label>}
        <div className={`inputFileWrapperFull d-block ${cssClass}`}>
          {image && (
            <img
              src={image}
              className={`${imageCss}`}
              onClick={() => handleClick(image instanceof Object ? URL.createObjectURL(image) : image)}
            />
          )}
          <input
            type="file"
            ref={inputFile}
            accept="image/*"
            disabled={disabled || false}
            onChange={(e) => onChangeImage(e)}
            style={{ display: "none" }}
          />
        </div>
        <div className="uploadImage" onClick={handleImageClick}>
          {buttonText}
        </div>
      </div>
    </>
  );
};

export default ImageInput;
