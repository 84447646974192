import React from "react";

import  { components, StylesConfig } from 'react-select';




export const ReactSelectCustomOption = (props) => {
  const isSelected = props.isSelected;
  const customStyle = isSelected ? { backgroundColor: "blue", color: "white" } : {};

  return (
    <components.Option {...props} style={customStyle}>
      {props.children}
    </components.Option>
  );
};


export const ReactSelectCustomContainer = ({ children, ...props }) => {
  let [values, input] = children;

  if (Array.isArray(values)) {
    let label = values.length > 1 ? `${values.length} selected` : values[0].props.children;
    values = <components.MultiValueContainer {...props}>{label}</components.MultiValueContainer>;
  }

  return (
    <components.ValueContainer {...props}>
      {values}
      {input}
    </components.ValueContainer>
  );
};



export const Option = props => {
  return (
    <components.Option {...props}>
      <input
        type="checkbox"
        checked={props.isSelected}
        onChange={(e) => e.preventDefault()}
        style={{ marginRight: '10px', cursor: 'pointer', }}
      />
      {props.data.label}
    </components.Option>
  );
};

export const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "white" : provided.backgroundColor,
    color: state.isSelected ? "black" : provided.color,
    ':active': {
      ...provided[':active'],
      backgroundColor: state.isSelected ? provided.backgroundColor : provided[':active'].backgroundColor,
    },
    ':hover': {
      ...provided[':hover'],
      backgroundColor: "#cfe2ff",
      color: "black", 
    }
  }),
};