import React, { useState } from "react";
import { Table, Card } from "react-bootstrap";
import { convertYYYYMMDDToDate, formatCurrency } from "../../../utils";

const EligibitlityDetailsCollapsableTable = (props) => {
  const { columns, rows, globalSearch } = props;

  const CollapsableRow = ({ user }) => {
    const additionalPayorInfo = JSON.stringify(user).includes("Additional Payer");
    return (
      <>
        <Card className="p-3 testAdmin createClientsWrapper">
          <div>
            <label className="fw-bold">Insurance Type: </label>
            <div className="form-group-eligibity-wrapper crew-profile-row4">
              {user?.insurance_type_code && (
                <div className="form-group-eligibity-wrapper w-100">
                  <label className="modalLineHeaders fw-bold">Code: </label>
                  <span>{user.insurance_type_code}</span>
                </div>
              )}
              {user?.insurance_type_description && (
                <div className="form-group-eligibity-wrapper w-100">
                  <label className="modalLineHeaders fw-bold">Description: </label>
                  <span>{user.insurance_type_description}</span>
                </div>
              )}
              {user.benefit && (
                <div className="form-group-eligibity-wrapper w-100">
                  <label className="modalLineHeaders fw-bold">Coords of Beneifts: </label>
                  <span>{convertYYYYMMDDToDate(user.benefit)}</span>
                </div>
              )}
            </div>
            <hr />
          </div>
          {(user.plan_date || user.plan_number || user.mco_number) && (
            <div>
              <label className="fw-bold">Plan: </label>
              <div className="form-group-eligibity-wrapper crew-profile-row4">
                {user?.plan_date && (
                  <div className="form-group-eligibity-wrapper w-100">
                    <label className="modalLineHeaders fw-bold">Date: </label>
                    <span>{convertYYYYMMDDToDate(user.plan_date)}</span>
                  </div>
                )}
                {user.plan_number && (
                  <div className="form-group-eligibity-wrapper w-100">
                    <label className="modalLineHeaders fw-bold">Number: </label>
                    <span>{user.plan_number}</span>
                  </div>
                )}
                {user.mco_number && (
                  <div className="form-group-eligibity-wrapper w-100">
                    <label className="modalLineHeaders fw-bold">Netwrok Id: </label>
                    <span>{user.mco_number}</span>
                  </div>
                )}
              </div>

              <hr />
            </div>
          )}
          {user?.entity_name?.join(",") && (
            <div>
              <label className="modalLineHeaders fw-bold">Additional Payer: </label>
              <div className="form-group-eligibity-wrapper crew-profile-row4">
                {user.entity_name?.length > 0 && (
                  <div className="form-group-eligibity-wrapper w-100">
                    <label className="modalLineHeaders fw-bold">Payer: </label>
                    <span>{user.entity_name[0]}</span>
                  </div>
                )}
                {user.entity_addr_1?.length > 0 && (
                  <div className="form-group-eligibity-wrapper w-100">
                    <label className="modalLineHeaders fw-bold">Address: </label>
                    <span>{user.entity_addr_1 && user.entity_addr_1[0]}</span>
                  </div>
                )}
                {(user.entity_city || user.entity_state) && (
                  <div className="form-group-eligibity-wrapper w-100">
                    <label className="modalLineHeaders fw-bold">City: </label>
                    <span>
                      {user.entity_city && user.entity_city[0]}, {user.entity_state && user.entity_state[0]}
                    </span>
                  </div>
                )}
              </div>
              <div className="form-group-eligibity-wrapper crew-profile-row4">
                {user.entity_zip?.length > 0 && (
                  <div className="form-group-eligibity-wrapper w-100">
                    <label className="modalLineHeaders fw-bold">Zip: </label>
                    <span>{user.entity_zip[0]}</span>
                  </div>
                )}
                {user.entity_phone?.length > 0 && (
                  <div className="form-group-eligibity-wrapper w-100">
                    <label className="modalLineHeaders fw-bold">Phone: </label>
                    <span>{user.entity_phone[0]}</span>
                  </div>
                )}
                {user.entity_website?.length > 0 && (
                  <div className="form-group-eligibity-wrapper w-100">
                    <label className="modalLineHeaders fw-bold">Website: </label>
                    <span className="custom-ellipsis d-block" title={user.entity_website[0]}>
                      {user.entity_website[0]}
                    </span>
                  </div>
                )}
              </div>
              <hr />
            </div>
          )}

          {user?.benefit_notes && (
            <div className="form-group-eligibity-wrapper crew-profile-column-reverse6">
              <label className="fw-bold d-block w-30">Notes: </label>
              <span>{user?.benefit_notes}</span>
            </div>
          )}
        </Card>
      </>
    );
  };

  const TableRow = ({ user, index }) => {
    if (!JSON.stringify(user).toLowerCase().includes(globalSearch.toLowerCase())) return <></>;
    const [open, setOpen] = useState(false);
    return (
      <>
        <tr key={index} className="cursor-pointer" onClick={() => setOpen(!open)}>
          <td style={{ width: "5%" }}>{index + 1}</td>
          {/* <td className="ellipsis" style={{ textAlign: "center" }}>
            {user.benefit_code}
          </td> */}
          <td className="ellipsis" style={{ textAlign: "center" }}>
            {user.benefit_description}
          </td>
          <td className="ellipsis" style={{ textAlign: "center" }}>
            {user.benefit_coverage_description}
          </td>
          <td className="ellipsis" style={{ textAlign: "center" }}>
            {user.plan_date ? convertYYYYMMDDToDate(user.plan_date) : "-"}
          </td>
          <td className="ellipsis" style={{ textAlign: "center" }}>
            {`${formatCurrency(user.benefit_amount || 0)}`}
          </td>
        </tr>
        {open && (
          <tr className="additionalInfo-row">
            <td colSpan={6}>
              <CollapsableRow user={user} />
            </td>
          </tr>
        )}
      </>
    );
  };
  return (
    <div className="table-responsive table-wrapper">
      <Table className="table">
        <thead>
          <tr>
            <th style={{ width: "5%" }}>#</th>

            {columns.length > 0 &&
              columns.map((m) => (
                <th className="ellipsis centered" style={{ textAlign: m.textAlign }}>
                  {m.title}
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {rows.length > 0 && rows.map((user, index) => <TableRow user={user} index={index} key={index} />)}
        </tbody>
      </Table>
    </div>
  );
};

export default EligibitlityDetailsCollapsableTable;
