import React from "react";
import EditIcon from "assets/img/edit-icon.png";
import TrashIcon from "assets/img/trash-icon.png";
import customFileIcon from "assets/img/file-icon.svg";
import { FILE_TYPES } from "constant";
import { Storage } from "aws-amplify";
import { saveAs } from "file-saver";
import { useSelector, useDispatch } from "react-redux";
import { setLoader } from "store/features/general/generalSlice";
import { CONFIG } from "constant";

const InvoiceDocumentList = (props) => {
  const { headerLabel, list, handleEdit, handleAdd, handleDocDeleted } = props;

  const dispatch = useDispatch();

  const handleDownloadDoc = async (doc, name) => {
    try {
      dispatch(setLoader(true));
      const resut = await Storage.get(doc.fileName, {
        bucket: CONFIG.documentBucket,
      });
      const response = await fetch(resut);
      const blob = await response.blob();

      console.log("fileMetadata", blob.size);

      saveAs(blob, `${name}`);
      dispatch(setLoader(false));
    } catch (err) {
      console.log("Error", err);
      dispatch(setLoader(false));
    }
  };

  return (
    <>
      <div className="mb-4 scrollable-div p-2 w-100 invoiceDocListWrapper">
        {list &&
          list.map((item) => {
            const parts = item.fileName.split("$");

            const slicedString = parts[parts.length - 1];

            return (
              item && (
                <div className="secondary-insurance-change d-flex justify-content-between">
                  <div className="w-75 flex-column d-flex" title={item.fileName}>
                    <span className="fw-bold">{item.docType}</span>
                    <span>{item.docLabel || ""}</span>
                    <span>{slicedString || ""}</span>
                  </div>
                  <div className="d-flex flex-column w-25 justify-content-around align-items-center">
                    <div className="d-flex w-100 justify-content-between align-items-center">
                      <img
                        src={EditIcon}
                        width="18"
                        alt="edit icon"
                        onMouseLeave={(e) => (e.target.style.color = "black")}
                        onMouseOver={(e) => {
                          e.target.style.cursor = "pointer";
                        }}
                        aria-hidden="true"
                        onClick={(e) => handleEdit(item)}
                      />

                      {/* <i
                        className={`fas fa-download fs-4 mx-2 cursor-pointer`}
                        style={{ color: "grey" }}
                        onClick={() => handleDownloadDoc(item, slicedString)}
                      /> */}
                      <img
                        src={TrashIcon}
                        width="16"
                        alt="Trash icon"
                        onMouseLeave={(e) => (e.target.style.color = "black")}
                        onMouseOver={(e) => {
                          e.target.style.cursor = "pointer";
                        }}
                        aria-hidden="true"
                        onClick={(e) => handleDocDeleted(item)}
                      />
                    </div>
                  </div>
                </div>
              )
            );
          })}
      </div>
    </>
  );
};

export default InvoiceDocumentList;
