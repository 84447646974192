import { AWS_API_METHODS, callAmplifyAPI } from "./defaultApi";

const getClaimById = async (id) => {
  try {
    return await callAmplifyAPI("/claim", { id: id }, AWS_API_METHODS.post);
  } catch (err) {
    console.error("Error getClaimById:", err);
    return null;
  }
};

export default {
  getClaimById,
};
