import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setShowSalesPersonModal } from "store/features/salePerson/salePersonAction";
import SalesPersonView from "views/SalesPersonView";

const SalesPersonModal = (props) => {
  const { clientId, cssClass } = props;
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setShowSalesPersonModal(false));
  };

  return (
    <>
      <Modal
        show={true}
        backdrop="static"
        className={`${cssClass}`}
        animation={true}
        onHide={() => handleClose()}
        centered
        size={"2x2"}
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            Sales Persons
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ paddingTop: 0 }}>
          <SalesPersonView clientId={clientId} />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" className="headerButton btn-fill" onClick={() => handleClose()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SalesPersonModal;
