import { API, graphqlOperation } from "aws-amplify";
import { userCompanyID } from "utils";
import { createMessageTemplate, deleteMessageTemplate, updateMessageTemplate } from "../graphql/mutations";
import * as queries from "../graphql/queries";

export const listTemplate = async (filter) => {
  try {
    let model = [];
    let pageNextToken = null;
    
    const params = {
      filter: { clientID: { eq: userCompanyID.get() } },
      limit: 3000,
    };

    do {
      if (pageNextToken) {
        Object.assign(params, { nextToken: pageNextToken });
      }
      const graphqlData = await API.graphql(graphqlOperation(queries.listMessageTemplates, params));
      model = model.concat(graphqlData.data.listMessageTemplates.items);
      pageNextToken = graphqlData.data.listMessageTemplates.nextToken;
    } while (pageNextToken);

    return model;
  } catch (err) {
    console.error("Error listing message Template:", err);
    return err;
  }
};

export const createMessageTemplateGQL = async (msgTemplate) => {
  try {
    const newMsgTemplate = {
      ...msgTemplate,
      clientID: userCompanyID.get(),
    };
    const result = await API.graphql(graphqlOperation(createMessageTemplate, { input: newMsgTemplate }));
    return result.data?.createMessageTemplate;
  } catch (error) {
    console.error("Error creating message Template:", error);
    return error;
  }
};

export const updateMessageTemplateGQL = async (updatedMessageTemplateData) => {
  try {
    // Creating a new object with only the fields allowed in UpdateMessageTemplateInput
    const input = {
      id: updatedMessageTemplateData?.id,
      clientID: updatedMessageTemplateData?.clientID,
      companyID: updatedMessageTemplateData?.companyID,
      subAgentID: updatedMessageTemplateData?.subAgentID,
      name: updatedMessageTemplateData?.name,
      category: updatedMessageTemplateData?.category,
      subject: updatedMessageTemplateData?.subject,
      message: updatedMessageTemplateData?.message,
      isActive: updatedMessageTemplateData?.isActive,
      criteria: updatedMessageTemplateData?.criteria,
      updatedBy: updatedMessageTemplateData?.updatedBy,
      updatedByName: updatedMessageTemplateData?.updatedByName,
      _version: updatedMessageTemplateData._version,
      // Do not include createdBy, createdByName, createdAt, updatedAt, _deleted, and _lastChangedAt
    };
    const result = await API.graphql(graphqlOperation(updateMessageTemplate, { input }));
    console.log({ result, input });
    return result.data.updateMessageTemplate;
  } catch (error) {
    console.error("Error updating message Template:", error);
    throw error; // Rethrow the error for handling it in the calling code
  }
};

export const deleteMessageTemplateGQL = async (messageTemplate) => {
  try {
    const input = {
      id: messageTemplate.id,
      _version: messageTemplate._version, // Include the version number here
    };
    console.log("Deleting message template with ID:", messageTemplate);

    const result = await API.graphql(graphqlOperation(deleteMessageTemplate, { input }));
    console.log("Deletion result:", result);
    return result.data.deleteMessageTemplate;
  } catch (error) {
    console.error("Error deleting message Template:", error);
    throw error; // Rethrow the error for handling it in the calling code
  }
};
