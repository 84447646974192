import MainTable from "components/Table/MainTable";
import { HOSPICE_DETAILS_PERSONALIZE } from "constants/personalization";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { selectedHospiceDetailsSelector } from "store/features/checkEligibility/checkEligibilitySelectors";
import { setHospiceDetailsData } from "store/features/checkEligibility/checkEligibilitySlice";

const HospiceDetailsModal = (props) => {
  const { cssClass } = props;
  const dispatch = useDispatch();
  const { modalTitle, detailsList } = useSelector(selectedHospiceDetailsSelector);

  const handleClose = () => {
    dispatch(setHospiceDetailsData(null));
  };

  const rows = detailsList;
  console.log({ rows });

  return (
    <>
      <Modal
        show={true}
        backdrop="static"
        className={`${cssClass}`}
        animation={true}
        onHide={handleClose}
        centered
        size={"lg"}
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            {modalTitle}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ paddingTop: 0 }}>
          <MainTable columns={HOSPICE_DETAILS_PERSONALIZE} rows={rows} widthToSkip={160} />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" className="headerButton btn-fill" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default HospiceDetailsModal;
