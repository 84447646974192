import { EMPLOYEE_CLAIM } from "constant";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import Select from "react-select";
import { usersList } from "store/features/users/userSelectors";

const AssignClaimModal = (props) => {
  const { handleClose, handleSave } = props;
  const [error, setError] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [message, setMessage] = useState("");
  const users = useSelector(usersList);

  const dropdownOptions = users
    .filter((f) => f.userPermission[EMPLOYEE_CLAIM]?.access)
    .map((user) => {
      return { label: `${user.firstName} ${user.lastName}`, value: user.id };
    });

  const onHandleSave = () => {
    if (!selectedUser) {
      setError("Please select any one user!");
      return;
    }
    if (!message) {
      setError("Add a message!");
      return;
    }

    const assignedUser = users.find((user) => user.id === selectedUser.value);
    handleSave(assignedUser, message);
  };

  return (
    <>
      <Modal show backdrop="static" animation={true} onHide={handleClose} centered size={"lg"}>
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            Assign Claim
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: 0 }}>
          <Form.Group className="form-group-wrapper">
            <label className="modalLineHeaders mt-0 text-capitalize">Select User</label>
            <Select
              options={dropdownOptions}
              className="w-100"
              value={selectedUser}
              isClearable
              menuPlacement="auto"
              autoComplete="none"
              onChange={(e) => setSelectedUser(e)}
            />
          </Form.Group>
          <Form.Group className="form-group-wrapper">
            <label className="modalLineHeaders mt-0 text-capitalize">Message</label>
            <textarea
              className="form-control assignMessage"
              value={message}
              onChange={(e) => setMessage(e.target.value.replace(/'/g, ""))}
            />
          </Form.Group>
        </Modal.Body>
        <div className="px-4 my-2" style={{ height: "2vh" }}>
          {error && <strong style={{ color: "Red" }}>{error}</strong>}
        </div>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="primary"
            className="modalButtons headerButton btn-fill"
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            style={{ marginBottom: 10 }}
            variant="secondary"
            className="modalButtons headerButton btn-fill"
            onClick={onHandleSave}
          >
            Assign
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AssignClaimModal;
