import React from "react";
import { Button } from "react-bootstrap";

const containerStyle = {
  display: "flex",
  alignItems: "center",
  width: "550px",
  position: "relative",
  top: "-30px",
  marginLeft: "auto",
  marginRight: "40px",
  justifyContent: "flex-end",
};

const labelStyle = { marginBottom: "0" };

const inputGroupStyle = {
  display: "flex",
  alignItems: "center",
  marginRight: "10px",
  marginLeft: "10px",
  border: "1px solid rgb(221, 221, 221)",
  borderRadius: "6px",
  padding: "0",
  paddingRight: "10px",
};

const discountTypeButtonStyle = {
  marginLeft: "0px",
  background: "#31A8E0",
  borderRadius: "5px 0px 0px 5px",
  cursor: "pointer",
  width: "50px",
  height: "100%",
  padding: "4px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const inputStyle = {
  maxWidth: "202px",
  border: "none",
  textAlign: "right",
  padding: "0px",
};

const DiscountInput = ({
  label,
  discountType,
  discountAmount,
  onDiscountTypeChange,
  onDiscountAmountChange,
  onUpdateDiscount,
}) => {
  return (
    <div style={containerStyle} className="edit-modal-option-custom">
      <p style={labelStyle}>{label}</p>
      <div style={inputGroupStyle}>
        <div style={discountTypeButtonStyle} onClick={onDiscountTypeChange}>
          <span style={{ color: "white", fontWeight: "bold" }}>{discountType ? "%" : "$"}</span>
        </div>
        <input
          type="number"
          className="editable-cell-invoice"
          style={inputStyle}
          value={discountAmount}
          onChange={onDiscountAmountChange}
        />
      </div>

      <Button variant="secondary" className="modalButtons headerButton btn-fill me-0" onClick={onUpdateDiscount}>
        Apply
      </Button>
    </div>
  );
};

export default DiscountInput;
