import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { ERA_PROCEDURE_CODE_HEADER } from "constant";
import ProcedureInfoRow from "./ProcedureInfoRow";

const ProcedureInfoSection = (props) => {
  const { eraList, handleOpenEraFile, claim, handleShowLogs, setEraList, isNotEditAble, setSelectedERA } = props;
  // const [claims, setClaims] = useState(dataList || []);

  return (
    <>
      <div className="personal-info-wrapper pt-3 px-3">
        {/* <div className="d-flex justify-content-between font-bold">
          <div className="d-flex flex-column">
            <span>{newUser.bill_name || ""}</span>
            <span>{newUser.bill_addr_1 || ""}</span>
            <span>{newUser.bill_addr_2 || ""}</span>
            <span>
              {newUser.bill_city || ""}, {newUser.bill_state || ""} {newUser.bill_zip || ""}
            </span>
          </div>
          <div className="d-flex flex-column">
            <span>NPI#: {newUser.bill_npi || ""}</span>
            <span>Date#: {formatDateMDY(newUser.from_date)}</span>
            <span>EFT#: 0</span>
            <span>Tax ID#: {newUser.bill_taxid || ""}</span>
          </div>
        </div> */}
        <div className="d-flex align-items-center justify-content-between">
          <h4 className="text-decoration-underline text-black mt-0 section-title">ERA Detail</h4>
        </div>
        <div className="testAdmin createClientsWrapper resubmissionFormWrapper">
          <div className="data-listing-form">
            <div className="table-wrapper">
              <Table className="table">
                <thead>
                  <tr className="data-listing-row">
                    <th className={`centered w-10`} />
                    {ERA_PROCEDURE_CODE_HEADER.map((m, index) => (
                      <th key={index} className={`centered w-${m.width}`}>
                        {m.title}
                      </th>
                    ))}
                    <th className={`centered w-10`}>Download</th>
                    <th className={`centered w-10`}>Audit Trail</th>
                  </tr>
                </thead>
                <tbody>
                  {eraList?.map((data, index) => (
                    <ProcedureInfoRow
                      key={index}
                      data={data}
                      isNotEditAble={isNotEditAble}
                      setEraList={setEraList}
                      setSelectedERA={setSelectedERA}
                      index={index}
                      handleOpenEraFile={handleOpenEraFile}
                      claim={claim}
                      handleShowLogs={handleShowLogs}
                    />
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProcedureInfoSection;
