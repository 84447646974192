import { CLAIM_CODE_HEADER } from "constant";
import { useState } from "react";
import { Table } from "react-bootstrap";
import ClaimInfoRow from "./ClaimInfoRow";

const ClaimInfoModal = (props) => {
  const {
    claims,
    handleClaimEdit,
    handleChargeEdit,
    setCalculationError,
    insuranceData,
    realClaims,
    handleSingleClaimPost,
    handleRemoveClaim,
  } = props;
  return (
    <>
      <div className="table-responsive overFlow-y-hidden">
        <div className="personal-info-wrapper pt-3 px-3">
          <div className="testAdmin createClientsWrapper resubmissionFormWrapper">
            <div className="data-listing-form" style={{ marginBottom: "0px" }}>
              <div className="table-wrapper">
                <Table className="table layout-fixed" tabIndex={"1"}>
                  <thead>
                    <tr className="data-listing-row">
                      <th />
                      {CLAIM_CODE_HEADER.map((m, index) => (
                        <th key={index} className={`centered w-${m.width}`}>
                          {m.title}
                        </th>
                      ))}
                      <th className="centered w-5"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {claims &&
                      claims.map((item, i) => {
                        const realClaim = realClaims?.find((f) => f.id === item.id);
                        return (
                          <ClaimInfoRow
                            data={item}
                            key={i}
                            realClaim={realClaim}
                            claimIndex={i}
                            handleChargeEdit={handleChargeEdit}
                            handleClaimEdit={handleClaimEdit}
                            insuranceData={insuranceData}
                            handleSingleClaimPost={handleSingleClaimPost}
                            handleRemoveClaim={handleRemoveClaim}
                          />
                        );
                      })}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClaimInfoModal;
