import { createSlice } from "@reduxjs/toolkit";
import { INVOICE_PREVIEW } from "./invoicePreviewConstant";
import { addSelectedInvoice, resetInvoice,updateInvoiceDetails } from "./invoicePreviewAction";
import { updateClaimMessage } from "../bulkInvoices/bulkInvoicesAction";

const invoicePreviewSlice = createSlice({
  name: INVOICE_PREVIEW,
  initialState: {
    selectedInvoice: {},
    isBulk: false,
    viewOnly: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addSelectedInvoice.fulfilled, (state, action) => {
      state.selectedInvoice = action.payload.selectedInvoice;
      state.isBulk = action?.payload?.isBulk || false;
      state.viewOnly = action?.payload?.viewOnly || false;
    });
    builder.addCase(resetInvoice.fulfilled, (state) => {
      state.selectedInvoice = {};
      state.isBulk = false;
      state.viewOnly = false;
    });
    builder.addCase(updateInvoiceDetails.fulfilled, (state,action) => {
      const { details, discount, discountType,totalBilled } = action.payload;
      state.selectedInvoice = {
        ...state.selectedInvoice,
        ...(details  && { details }),
        ...(discount  && { discount }),
        ...(discountType !== undefined  && { discountType }),
        ...(totalBilled  && { totalBilled }),
      };
    });
    builder.addCase(updateClaimMessage.fulfilled, (state, action) => {
      const { id, message, discount,minimumAmount } = action.payload;
      if (state.selectedInvoice && state.selectedInvoice.claimID === id) {
        state.selectedInvoice = {
          ...state.selectedInvoice,
          ...(message  && { message }),
          ...(discount  && { discount }),
          ...(minimumAmount  && { minAmount:minimumAmount }),
        };
      }
    });
    
  },
});

export default invoicePreviewSlice.reducer;
