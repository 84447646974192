export const cptCodeSelector = (state) => state.cptCode.procedureList;

export const openCreateCPTModalSelector = (state) => state.cptCode.openCreateCPTModal;

export const selectedCPTItemSelector = (state) => state.cptCode.selectedCPTItem;

export const ICTCodeSelector = (state) => state.cptCode.diagnosisList;

export const openCreateICTModalSelector = (state) => state.cptCode.openCreateICTModal;

export const selectedICTItemSelector = (state) => state.cptCode.selectedICTItem;
