import PopoverStick from "components/PopOverStick";
import { claimSubmitStatusColorOpts } from "constant";
import { ProcTooltipHeader } from "constant";
import { CLAIM_STATUS_LABELS } from "constant";
import { currencyformatter } from "constant";
import React from "react";
import { Form } from "react-bootstrap";
import { CapsFirstLetter } from "utils";
import { formatDateMDY } from "utils";

const EmpPrevClaimRow = ({ index, claim, selectedClaim, setSelectedClaim, multiSelected, setMultiSelected }) => {
  const prov_name =
    claim.provider?.prov_name ||
    `${claim.provider?.prov_name_f} ${claim.provider?.prov_name_m || ""} ${claim.provider?.prov_name_l || ""}`;
  const ProcCode = claim.proc_array.length === 1 ? claim.proc_array[0].proc_name : "Multi";
  return (
    <>
      <tr key={index}>
        <td className="table-list-data text-center">
          <Form.Check>
            <Form.Check.Input
              type="checkbox"
              checked={selectedClaim?.id === claim.id || multiSelected?.includes(claim.id) || false}
              value={claim.id}
              onChange={(e) =>
                setMultiSelected
                  ? e.target.checked
                    ? setMultiSelected((prev) => [...prev, claim.id])
                    : setMultiSelected(multiSelected.filter((f) => f !== claim.id))
                  : e.target.checked
                  ? setSelectedClaim(claim)
                  : setSelectedClaim(null)
              }
            />
          </Form.Check>
        </td>
        <td className="table-list-data text-center ellipsis" title={formatDateMDY(claim.from_date_1)}>
          {formatDateMDY(claim.proc_array[0].from_date)}
        </td>
        <PopoverStick
          cssClass="table-list-data text-center ellipsis"
          item={`${ProcCode || ""}`}
          columns={ProcTooltipHeader}
          values={claim.proc_array.map((proc) => ({ Code: proc.proc_code, Description: proc.proc_name })) || []}
        />

        <td className="table-list-data text-center ellipsis" title={claim.payer_name || ""}>
          {claim.payer_name || ""}
        </td>

        <td className="table-list-data text-center ellipsis" title={currencyformatter.format(claim.total_charge || 0)}>
          {currencyformatter.format(claim.total_charge || 0)}
        </td>

        <td className="table-list-data text-center ellipsis" title={CapsFirstLetter(prov_name)}>
          {CapsFirstLetter(prov_name)}
        </td>
        <td
          className="table-list-data text-center ellipsis"
          title={CapsFirstLetter(CLAIM_STATUS_LABELS[claim.status])}
          style={{ color: claimSubmitStatusColorOpts[claim.status] }}
        >
          {CapsFirstLetter(CLAIM_STATUS_LABELS[claim.status])}
        </td>
      </tr>
    </>
  );
};

export default EmpPrevClaimRow;
