import React, { useState } from "react";
import MoneyInput from "components/MoneyInput/MoneyInput";
import { toTitleCase } from "utils";
import deleteIcon from "assets/img/trash-icon.png";
import { formatCurrency } from "utils";

export const CurrencyEditableCell = ({
  cellData,
  columnData,
  dataKey,
  rowData,
  rowIndex,
  updateData,
  readOnly = false,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = (e) => {
    updateData(rowIndex, e.target.value, dataKey);
  };
  const handleFocus = readOnly ? undefined : () => setIsFocused(true);
  const handleBlur = readOnly ? undefined : () => setIsFocused(false);
  const displayValue = readOnly ? formatCurrency(cellData).slice(1) : (isFocused ? cellData : formatCurrency(cellData).slice(1));

  return (
    <MoneyInput
      onChange={handleChange}
      value={displayValue}
      onBlur={handleBlur}
      onFocus={handleFocus}
      autoFocus={!readOnly}
      readOnly={readOnly}
    />
  );
};

export const renderEditableCell = ({ cellData, columnData, dataKey, rowData, rowIndex }) => {
  const centerAlignedColumns = ["units"];

  const isCenterAligned = centerAlignedColumns.includes(dataKey);

  return (
    <input
      className={`editable-cell-invoice ${isCenterAligned ? "center-aligned" : ""}`}
      type="number"
      value={cellData}
      onChange={(e) => handleDetailChange(rowIndex, e.target.value, dataKey)}
      style={{ textAlign: isCenterAligned ? "center" : "left" }}
    />
  );
};

export const renderActionCell = ({ cellData, columnData, dataKey, rowData, rowIndex, onClick }) => (
  <div style={{ textAlign: "right" }}>
    <img src={deleteIcon} alt="delete icon" style={{ cursor: "pointer" }} onClick={() => onClick(rowData)} />
  </div>
);

export const nameCellRenderer = ({ rowData }) => <div>{`${rowData.first_name} ${rowData.last_name}`}</div>;
export const refferingCellRenderer = ({ rowData }) => <div>{`${toTitleCase(rowData.referring_provider)}`}</div>;
