import React, { useEffect, useMemo } from "react";
import { formatCurrency } from "../../../utils";
import { getElgibilityLineItem } from "./common";
import { formatPhoneNumber } from "../../../utils";
import { Button } from "react-bootstrap";

const PayerInformation = (props) => {
  const { data, label, handleAddToProfile, loading, employee } = props;

  const formatAdditionalPayer = () => {
    if (!data) return;
    const additionalPayerName = data;
    const addressParts = [];
    if (additionalPayerName.address) addressParts.push(additionalPayerName.address);
    if (additionalPayerName.city || additionalPayerName.state || additionalPayerName.zip) {
      const locationParts = [];
      if (additionalPayerName.city) locationParts.push(additionalPayerName.city);
      if (additionalPayerName.state) locationParts.push(additionalPayerName.state);
      if (additionalPayerName.zip) locationParts.push(additionalPayerName.zip);
      addressParts.push(locationParts.join(", "));
    }

    return addressParts.join("\n");
  };

  return (
    <>
      <div className="form-group-eligibity-wrapper w-50">
        <div className="form-group-eligibity-wrapper">
          <div className="d-grid w-100">
            <span className="fw-bold">{label}</span>
            <span className="error-text">{data?.title}</span>
            {/* <span>{formatAdditionalPayer()}</span>
            {data?.phoneNumber && <span>{`Phone: ${formatPhoneNumber(`+1${data?.phoneNumber}`)}`}</span>} */}
            {data?.planStartDate && <span>{`${data?.planStartDate}- ${data?.planEndDate}`}</span>}
            {data?.additionalPayerId && (
              <div className="d-flex  w-100">
                {employee && (
                  <Button
                    style={{ marginBottom: 10 }}
                    variant="secondary"
                    className="modalButtons headerButton btn-fill noPrint"
                    disabled={loading}
                    onClick={() => {
                      handleAddToProfile(true, data);
                    }}
                  >
                    Add to Profile
                  </Button>
                )}

                <Button
                  style={{ marginBottom: 10 }}
                  variant="primary"
                  className="modalButtons headerButton btn-fill noPrint"
                  disabled={loading}
                  onClick={() => {
                    handleAddToProfile(false, data);
                  }}
                >
                  Check Insurance
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PayerInformation;
