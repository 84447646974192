import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { EMP_CLAIM_PERSONALIZE } from "constants/personalization";
import moment from "moment";
import api from "../../../api";
import {
  CLAIMS_KEYS,
  CLAIM_SUBMIT_STATUS,
  CONFIG,
  DIAGNOSIS_ITEMS,
  EMP_CLAIM_BLANK_OPTS,
  MEDICARE_ID,
  MESSAGE_MODES,
  ON_HOLD_CPT,
  PARSE_ALPHA_INTO_NUM,
  STATS_OBJECT,
  STATUS_KEY,
} from "../../../constant";
import graphqlApi from "../../../graphqlApi";
import {
  formatClaims,
  formatDateMDY,
  formatEmployeeClaim,
  formatInvoice,
  getDiagonosisCode,
  getFloatVal,
  getIntVal,
  isJSONString,
  removeKeysFromObject,
  removeSpaceIns,
  userCompanyID,
} from "../../../utils";
import { setMessage } from "../general/generalAction";
import {
  CLAIM_INVOICE,
  CLAIM_INVOICE_FETCH,
  CLAIM_INVOICE_UPDATE,
  CLAIM_INVOICE_CANCELLED,
} from "./claimInvoiceConstants";
import insServices from "services/InsuranceService";
import { CLAIM_INVOICE_PERSONALIZE } from "constants/personalization";

// Async thunk to fetch all employeeClaims

const nestedFilter = (targetArray, filters) => {
  if (Object.keys(filters).length === 0) return targetArray;
  const filterKeys = Object.keys(filters);
  //filters main array of objects
  const models = targetArray.filter((obj) => {
    //goes through each key being filtered for
    return filterKeys.every((key) => {
      if (filters[key] == "" && !Object.keys(filters[key]).length) {
        return true;
      }
      if (key === "paidAmount" || key === "adjustedAmount") {
        return obj[key] && obj[key].toString().includes(filters[key]);
      }
      if (key === "invoiceAmount") {
        return obj[key] && obj[key].toString().includes(filters[key]);
      }
      if (key === "discount") {
        return obj[key] && obj[key].toString().includes(filters[key]);
      }
      if (key === "dueDate") {
        return obj[key] && obj[key].toString().includes(filters[key]);
      }
      if (key === "Blank" && filters[key].length > 0) {
        return filters[key].some((f) => !obj[CLAIM_INVOICE_PERSONALIZE.find((t) => t.title === f)?.itemKey]);
      }
      if (key === "status_code") {
        return filters[key] && obj[key] === 2;
      }
      return obj[key] && obj[key].toLowerCase().includes(filters[key].toLowerCase());
    });
  });
  return models;
};

export const fetchAllClaimInvoiceAsync = createAsyncThunk(
  CLAIM_INVOICE_FETCH,
  async ({ timeFilter, pageNo, filter, isNewCall, sortBy, orderByDirection }, { getState }) => {
    const params = { ...filter, page: pageNo, orderByDirection, orderBy: sortBy };

    if (timeFilter) {
      const { start, end } = timeFilter;
      if (start) Object.assign(params, { startDate: moment(start.format("YYYY-MM-DD")).startOf("day").toISOString() });
      if (end) Object.assign(params, { endDate: moment(end.format("YYYY-MM-DD")).endOf("day").toISOString() });
    }

    if (filter?.Blank || filter?.Blank?.length > 0) {
      delete params.Blank;
      Object.assign(params, { blanks: filter.Blank.map((element) => EMP_CLAIM_BLANK_OPTS[element]) });
    }

    if (isNewCall) {
      Object.assign(params, { page: 1 });
    }
    const res = await api.getInvoice(params);

    return res;
  }
);

export const claimInvoiceUpdateAsync = createAsyncThunk(CLAIM_INVOICE_UPDATE, async (data, { getState, dispatch }) => {
  const dbInvoice = await graphqlApi.getClaimInvoiceByIdGQl(data.id);
  console.log("dbInvoice", dbInvoice);
  const correctedDate = data.dueDate.replace(/T00:00:00Z$/, "");
  const params = {
    id: dbInvoice.id,
    toData: JSON.stringify(data.toData),
    paidAmount: data.paidAmount,
    invoiceAmount: data.invoiceAmount,
    dueDate: moment(correctedDate).utc().format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"),
    note: data.note,
    details: JSON.stringify(data.details),
    minAmount: data.minAmount,
    _version: dbInvoice._version,
  };

  const res = await graphqlApi.updateClaimInvoiceGQL(params);
  if (Object.keys(res).length > 0) {
    dispatch(setMessage("Invoice updated successfully !!", MESSAGE_MODES.success));
  }
  return res;
});

export const claimInvoiceCancelledAsync = createAsyncThunk(
  CLAIM_INVOICE_CANCELLED,
  async ({ invoices }, { getState, dispatch }) => {
    const loginUser = getState().auth.user;

    const params = {
      ids: invoices.map((m) => m.id),
      userName: loginUser.name,
      userID: loginUser.sub,
    };

    const res = await api.cancelInvoices(params);

    if (res)
      dispatch(
        setMessage(
          `${res.success} Invoice(s) Cancelled Successfully ${res.fail > 0 ? `${res.fail} Invoice(s) Failed` : ""}`,
          MESSAGE_MODES.success
        )
      );
    return res;
  }
);

const claimInvoiceSlice = createSlice({
  name: CLAIM_INVOICE,
  initialState: {
    claimInvoice: [],
    filteredClaimInvoice: [],
    invoicesFilter: {},
    totalRecord: 0,
    currentPage: 1,
    pageNo: 1,
  },
  reducers: {
    setFilteredClaimInvoice: (state, action) => {
      state.filteredClaimInvoice = action.payload;
    },
    setPageNo: (state, action) => {
      state.pageNo = action.payload;
    },
    setInvoicesFilter: (state, action) => {
      state.invoicesFilter = action.payload;
      state.filteredClaimInvoice = nestedFilter(state.filteredClaimInvoice, state.invoicesFilter);
      state.totalRecord = state.filteredClaimInvoice.length;
    },
    updateNotes: (state, action) => {
      const data = action.payload;
      const emp = state.filteredClaimInvoice.find((f) => f.id === data.id);
      emp["note"] = data.note;

      const existingInvoiceIndex = state.claimInvoice.findIndex((inv) => inv.id === emp.id);
      const existingFilterInvoiceIndex = state.filteredClaimInvoice.findIndex((inv) => inv.id === emp.id);
      if (existingInvoiceIndex !== -1) {
        state.claimInvoice[existingInvoiceIndex] = emp;
      }
      if (existingFilterInvoiceIndex !== -1) {
        state.filteredClaimInvoice[existingFilterInvoiceIndex] = emp;
      }
    },
    updateInvoiceSubs: (state, action) => {
      const dbInvoice = formatInvoice([action.payload])[0];

      const existingIndex = state.claimInvoice.findIndex((invoice) => invoice.id === dbInvoice.id);
      const existingFilterIndex = state.filteredClaimInvoice.findIndex((invoice) => invoice.id === dbInvoice.id);
      if (existingIndex !== -1) {
        state.claimInvoice[existingIndex] = dbInvoice;
      }
      if (existingFilterIndex !== -1) {
        state.filteredClaimInvoice[existingFilterIndex] = dbInvoice;
        const filteredClaimInvoice = nestedFilter(state.filteredClaimInvoice, state.invoicesFilter);
        // Update Total Record According to Filter
        state.totalRecord = state.totalRecord - (state.filteredClaimInvoice.length - filteredClaimInvoice.length);
        state.filteredClaimInvoice = filteredClaimInvoice;
      }
    },
    createInvoiceSubs: (state, action) => {
      if (state.claimInvoice.findIndex((f) => f.id === action.payload.id) === -1) {
        const dbInvoice = formatInvoice([action.payload]);
        state.claimInvoice.unshift(dbInvoice[0]);
        state.filteredClaimInvoice.unshift(dbInvoice[0]);
        state.claimInvoice = nestedFilter(state.filteredClaimInvoice, state.invoicesFilter);
        state.totalRecord = state.totalRecord + 1;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllClaimInvoiceAsync.fulfilled, (state, action) => {
      const res = action.payload;
      if (!res) return;
      let record = formatInvoice(res.rows);
      const models = [...state.claimInvoice, ...record];
      if (state.pageNo === 1) {
        state.totalRecord = res.total;
        state.claimInvoice = record;
        state.filteredClaimInvoice = record;
      } else {
        state.claimInvoice = models;
        state.filteredClaimInvoice = models;
      }
    });
  },
});

export const {
  setPageNo,
  setFilteredClaimInvoice,
  setInvoicesFilter,
  updateNotes,
  createInvoiceSubs,
  updateInvoiceSubs,
} = claimInvoiceSlice.actions;

export default claimInvoiceSlice.reducer;
