import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../../api";
import { CONFIG, MESSAGE_MODES } from "../../../constant";
import { userCompanyID } from "../../../utils";
import { CHECK_ELIGIBILITY, CHECK_ELIGIBILITY_FETCH } from "./checkEligibilityConstant";
import { Storage } from "aws-amplify";

// Async thunk to fetch all eligibilities

export const fetchAllEligibilitiesAsync = createAsyncThunk(
  CHECK_ELIGIBILITY_FETCH,
  async ({ page, filter, isNewCall }, { getState }) => {
    const user = getState().auth.user;
    const params = { ...filter, page };

    if (CONFIG.isLabType) {
      Object.assign(params, { clientID: userCompanyID.get() });
    } else if (user?.isClient()) {
      Object.assign(params, { clientID: userCompanyID.get() });
    } else if (user?.isSite()) {
      Object.assign(params, { locationID: userCompanyID.get() });
    } else if (user?.isSubAgent()) {
      Object.assign(params, { subAgentID: userCompanyID.get() });
    }
    if (isNewCall) {
      Object.assign(params, { page: 1 });
    }

    let res = await api.getEligibilityList(params);

    return res;
  }
);

// Async thunk to create a eligibility
export const createeligibilityAsync = createAsyncThunk("eligibility/careateeligibility", async (eligibility) => {
  const response = await api.geteligibilities(eligibility);
  return response;
});

// Async thunk to update a eligibility
export const updateeligibilityAsync = createAsyncThunk(
  "eligibility/updateeligibility",
  async ({ eligibilityId, updatedeligibility }) => {
    const response = await api.updateeligibility(eligibilityId, updatedeligibility);
    return response;
  }
);

// Async thunk to delete a eligibility
export const deleteeligibilityAsync = createAsyncThunk("eligibility/deleteeligibility", async (eligibilityId) => {
  const response = await api.deleteeligibility(eligibilityId);
  return response;
});

// Async thunk Open Eligibility Details Modal
export const openEligibilityDetailModalAsync = createAsyncThunk(
  "eligibility/openDetailsModal",
  async ({ item, employee }, { dispatch }) => {
    if (item.apiData) {
      const data = JSON.parse(item.apiData);
    
      if (data.fileName) {
        try {
          let res = await Storage.get(`${data.fileName}`, {
            bucket: CONFIG.eligibilityBucket,
            download: true,
          });
          let eligibile = null;
          await res.Body.text().then((string) => {
            // handle the String data return String
            const apidata = JSON.parse(string);
            eligibile = {
              ...item,
              error: apidata?.error,
              ...apidata?.elig,
              ...(employee && { payerId: employee.insuranceCompany, employee }),
            };
          });
          return eligibile;
        } catch (err) {
          console.log("Error:-", err.message);
          dispatch(`${err.message}`, MESSAGE_MODES.error);
          return null;
        }
      } else {
        return { ...item, ...data?.elig, ...(employee && { payerId: employee.insuranceCompany, employee }) };
      }
    } else {
      return { ...item, ...(employee && { payerId: employee.insuranceCompany, employee }) };
    }
  }
);

const eligibilitieslice = createSlice({
  name: CHECK_ELIGIBILITY,
  initialState: {
    eligibilities: [],
    filteredEligibilities: [],
    totalRecord: 0,
    currentPage: 1,
    pageNo: 1,
    openCheckEligibilityModal: false,
    eligibilityDetailsData: null,
    hospiceDetailsData: {
      showModal: false,
    },
  },
  reducers: {
    setFilteredEligibilities: (state, action) => {
      state.filteredEligibilities = action.payload;
    },
    setPageNo: (state, action) => {
      state.pageNo = action.payload;
    },
    createEligibility: (state, action) => {
      state.eligibilities.unshift(action.payload);
      state.filteredEligibilities.unshift(action.payload);
    },
    setOpenCheckEligibilityModal: (state, action) => {
      state.openCheckEligibilityModal = action.payload;
    },
    setEligibilityDetailsData: (state, action) => {
      state.eligibilityDetailsData = action.payload;
    },
    setHospiceDetailsData: (state, action) => {
      state.hospiceDetailsData = { ...action.payload, showModal: action.payload?.showModal || false };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllEligibilitiesAsync.fulfilled, (state, action) => {
        const res = action.payload;
        const eligibilityLists = res.rows.map((r) => ({ ...r, view: "view" }));
        const models = [...state.eligibilities, ...eligibilityLists];
        if (state.pageNo === 1) {
          state.totalRecord = res.total;
          state.eligibilities = eligibilityLists;
          state.filteredEligibilities = eligibilityLists;
        } else {
          state.eligibilities = models;
          state.filteredEligibilities = models;
        }

        // setFilteredEligibilities(sortUsers(filterDates(nestedFilter(models, filter), timeFilter), sortBy));
      })
      .addCase(createeligibilityAsync.fulfilled, (state, action) => {
        state.eligibilities.push(action.payload);
      })
      .addCase(openEligibilityDetailModalAsync.fulfilled, (state, action) => {
        state.eligibilityDetailsData = action.payload;
      })
      .addCase(updateeligibilityAsync.fulfilled, (state, action) => {
        const updatedeligibility = action.payload;
        const existingeligibilityIndex = state.eligibilities.findIndex(
          (eligibility) => eligibility.id === updatedeligibility.id
        );
        if (existingeligibilityIndex !== -1) {
          state.eligibilities[existingeligibilityIndex] = updatedeligibility;
        }
      })
      .addCase(deleteeligibilityAsync.fulfilled, (state, action) => {
        const deletedeligibilityId = action.payload;
        state.eligibilities = state.eligibilities.filter((eligibility) => eligibility.id !== deletedeligibilityId);
      });
  },
});

export const {
  setPageNo,
  setFilteredEligibilities,
  createEligibility,
  setOpenCheckEligibilityModal,
  setEligibilityDetailsData,
  setHospiceDetailsData,
} = eligibilitieslice.actions;

export default eligibilitieslice.reducer;
