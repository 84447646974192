// import moment from "moment";
import { formatCurrency, toTitleCase, getFormatedDate, formatPhoneForCalRedie } from "utils";
import { invoiceHtmlTemplate } from "templates/invoiceTemplates";
import bwipjs from "bwip-js";
import qrcode from "qrcode";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { CONFIG } from "constant";

import moment from "moment-timezone";

export const generateInvoiceDataBillPatient = (data) => {
  let totalBilled = 0;
  let coveredPlan = 0;
  let paidByYou = 0;
  const tableRows = data
    ?.map((item, index) => {
      totalBilled += Number(item.charge || 0);
      coveredPlan += Number(item?.paid || 0);

      const backgroundColor = index % 2 === 0 ? "#F0F2F5" : "";
      return `
        <tr style="background-color: ${backgroundColor};">
        <td style="padding: 8px; text-align: left; font-size: 11px; width:5%">${index + 1}</td>
        <td style="padding: 8px; text-align: left; font-size: 11px; width:8%">${
          getFormatedDate(item?.from_date) || ""
        }</td>
          <td style="padding: 8px; text-align: left; font-size: 11px; width:16%">${
            toTitleCase(item?.referring_provider) || ""
          }</td>
          <td style="padding: 8px; text-align: left; font-size: 11px; width:10%">${item?.accession_no || ""}</td>
          <td style="padding: 8px; text-align: left; font-size: 11px; width:7%">${item?.proc_code || ""}</td>
          <td style="padding: 8px; text-align: center; font-size: 11px; width:4%;">${item?.units || ""}</td>
          <td style="padding: 8px; text-align: left; font-size: 11px; width: 38%; ">${
            item.proc_desc
              ? item.proc_desc.length > 50
                ? item.proc_desc.substring(0, 50) + "..."
                : item.proc_desc
              : ""
          }</td>

          <td style="padding: 8px; text-align: left; font-size: 11px; width: 12%;" >
            <div style="display: flex; justify-content: space-around; width:80%; margin: 0 0 0 auto;">
              <span>$</span>
              <span style="text-align: right; flex-grow: 1;">${formatCurrency(parseFloat(item.charge)).slice(1)}</span>
            </div>
          </td>
        </tr>
      `;
    })
    .join("");

  return { totalBilled, coveredPlan, paidByYou, tableRows };
};

export const generateInvoiceDataClientDetail = (data) => {
  let totalBilled = 0;
  let coveredPlan = 0;
  let paidByYou = 0;
  const tableRows = data
    ?.map((item, index) => {
      totalBilled += Number(item.total || 0);
      coveredPlan += Number(item?.paid || 0);

      let backgroundColor = index % 2 == 0 ? "#F0F2F5" : "";
      if (!item.isSameRecord || index === 0) {
        backgroundColor = "rgb(207, 214, 221)";
      }
      if (item.hasOwnProperty("total")) {
        return `
        <tr style="background: rgb(170, 219, 246);">
          <td style="padding: 8px; text-align: left; font-size: 11px; font-weight:bold;">${item.patient_name}</td>
          <td style="padding: 8px; text-align: left; font-size: 11px;"></td>
          <td style="padding: 8px; text-align: left; font-size: 11px;"></td>
          <td style="padding: 8px; text-align: left; font-size: 11px;"></td>
          <td style="padding: 8px; text-align: left; font-size: 11px;"></td>
          <td style="padding: 8px; text-align: left; font-size: 11px;"></td>
          <td style="padding: 8px; text-align: left; font-size: 11px;font-weight:bold;">Total</td>
          <td style="padding: 8px; text-align: left; font-size: 11px; width: 120px; font-weight:bold;" >
              <div style="display: flex; justify-content: space-around; width:80%; margin: 0 0 0 auto;">
              <span>$</span>
              <span style="text-align: right; flex-grow: 1;">${formatCurrency(parseFloat(item.total)).slice(1)}</span>
              </div>
          </td>
        </tr>
        <tr><td style="height: 10px; background-color: transparent; border: none;" colspan="8"></td></tr>
        `;
      }

      return `
      <tr style="background-color: ${backgroundColor};">
        <td style="padding: 8px; text-align: left; font-size: 11px;">${
          !item.isSameRecord || index === 0 ? toTitleCase(item?.patient_name) || "" : ""
        }</td>
        <td style="padding: 8px; text-align: left; font-size: 11px;">${toTitleCase(item?.referring_provider) || ""}</td>
        <td style="padding: 8px; text-align: left; font-size: 11px;">${getFormatedDate(item?.from_date) || ""}</td>
        <td style="padding: 8px; text-align: left; font-size: 11px;">${item?.accession_no || ""}</td>
        <td style="padding: 8px; text-align: left; font-size: 11px;">${item?.cpt || ""}</td>
        <td style="padding: 8px; text-align: center; font-size: 11px;">${item?.units || ""}</td>
        <td style="padding: 8px; text-align: left; font-size: 11px; ">${
          item.proc_desc ? (item.proc_desc.length > 30 ? item.proc_desc.substring(0, 30) + "..." : item.proc_desc) : ""
        }</td>
        <td style="padding: 8px; text-align: left; font-size: 11px; width: 120px;" >
            <div style="display: flex; justify-content: space-around; width:80%; margin: 0 0 0 auto;">
            <span>$</span>
            <span style="text-align: right; flex-grow: 1;">${formatCurrency(parseFloat(item.charge)).slice(1)}</span>
            </div>
        </td>
      </tr>    `;
    })
    .join("");

  return { totalBilled, coveredPlan, paidByYou, tableRows };
};

export const generateInvoiceDataClientSummary = (data) => {
  let totalBilled = 0;
  let coveredPlan = 0;
  let paidByYou = 0;
  const tableRows = data
    ?.map((item, index) => {
      totalBilled += Number(item.charge || 0);
      coveredPlan += Number(item?.paid || 0);

      let backgroundColor = index % 2 == 0 ? "#F0F2F5" : "";

      return `
      <tr style="background-color: ${backgroundColor};">
      <td style="padding: 8px; text-align: left; font-size: 11px;">${toTitleCase(item?.patient_name) || ""}</td>
        <td style="padding: 8px; text-align: left; font-size: 11px;">${toTitleCase(item?.referring_provider) || ""}</td>
        <td style="padding: 8px; text-align: left; font-size: 11px;">${getFormatedDate(item?.from_date) || ""}</td>
        <td style="padding: 8px; text-align: left; font-size: 11px;">${item?.accession_no || ""}</td>
        <td style="padding: 8px; text-align: left; font-size: 11px; width: 120px;" >
            <div style="display: flex; justify-content: space-around; width:80%; margin: 0 0 0 auto;">
            <span>$</span>
            <span style="text-align: right; flex-grow: 1;">${formatCurrency(parseFloat(item.charge)).slice(1)}</span>
            </div>
        </td>
      </tr>    `;
    })
    .join("");

  return { totalBilled, coveredPlan, paidByYou, tableRows };
};

export const formatDataClientDetailView = (data) => {
  if (data.length === 0) return;

  const sortedData = [...data].sort((a, b) => {
    const aName = `${a.first_name} ${a.last_name}`;
    const bName = `${b.first_name} ${b.last_name}`;
    return aName.localeCompare(bName);
  });

  let lastPatientName = sortedData[0];
  let totalDiscountedCharge = 0;
  const tableData = [];

  sortedData.forEach((item, index) => {
    const { employee_id } = item;
    const isSameRecord = employee_id === lastPatientName.employee_id;
    const patientName = `${item.first_name} ${item.last_name}`;

    const discountedCharge = parseFloat(item.charge) - parseFloat(item.discount);

    const tableRow = {
      referring_provider: item.referring_provider,
      patient_name: patientName,
      from_date: item.from_date,
      accession_no: item.accession_no,
      cpt: item.proc_code,
      units: item.units,
      proc_desc: item.proc_desc,
      charge: discountedCharge.toFixed(2),
      discount: item.discount,
      isSameRecord,
    };

    if (!isSameRecord) {
      tableData.push({
        total: totalDiscountedCharge,
        referring_provider_name: lastPatientName.referring_provider,
        patient_name: `${lastPatientName.first_name} ${lastPatientName.last_name}`,
      });
      totalDiscountedCharge = 0;
      lastPatientName = item;
    }
    tableData.push(tableRow);

    totalDiscountedCharge += discountedCharge;

    if (index === sortedData.length - 1) {
      tableData.push({
        total: totalDiscountedCharge,
        referring_provider_name: item.referring_provider,
        patient_name: patientName,
      });
    }
  });

  return tableData;
};

export const transformDataBillPatientAndClientSummary = (data) => {
  const sortedData = [...data].sort((a, b) => {
    const aName = `${a.first_name} ${a.last_name}`;
    const bName = `${b.first_name} ${b.last_name}`;
    return aName.localeCompare(bName);
  });
  const result = sortedData.map((item) => {
    const { charge, discount = 0 } = item;
    const discountedCharge = parseFloat(charge) - parseFloat(discount);
    return {
      ...item,
      charge: discountedCharge,
      originalCharge: charge,
      patient_name: `${item.first_name} ${item.last_name}`,
    };
  });
  return result;
};

export const fillInvoiceTemplates = async (
  data,
  message,
  invoiceData,
  discountAmount,
  invoicePreviewType,
  barCodeState,
  qrCodeState
) => {
  const phone = formatPhoneForCalRedie(data?.fromData?.phone);

  const dueCharges = formatCurrency(
    parseFloat(invoiceData.totalBilled || 0) +
      parseFloat(data?.toData?.outStandingBalance || 0) -
      parseFloat(discountAmount)
  );

  let filledTemplate = invoiceHtmlTemplate({ ...data, invoicePreviewType }, CONFIG)
    .replace(/{barcode}/g, barCodeState)
    .replace(/{scanner}/g, qrCodeState)
    .replace(/{dueCharges}/g, `${dueCharges}`)
    .replace(/{dueCharges2}/g, `${dueCharges}`)
    .replace(/{dueCharges3}/g, `${dueCharges}`)
    .replace(/{timezone}/g, `${data.timezone}`)

    .replace(/{discount_amount}/g, `${formatCurrency(discountAmount).slice(1)}`)

    .replace(/{companyName}/g, data?.fromData?.companyName)
    .replace(/{companyStreet}/g, data?.fromData?.street)
    .replace(/{companyState}/g, data?.fromData?.state)
    .replace(/{companyZip}/g, data?.fromData?.zip)
    .replace(/{pat_name_f}/g, data?.toData?.firstName)
    .replace(/{pat_name_l}/g, data?.toData?.lastName)
    .replace(/{message}/g, message || "")
    .replace(/{invoiceSettingPhone}/g, phone)
    .replace(/{helpPhone}/g, phone)
    .replace(/{pat_addr_1}/g, data?.toData?.street)
    .replace(/{pat_state}/g, data?.toData?.state)
    .replace(/{pat_zip}/g, data?.toData?.zip)
    .replace(/{pcn}/g, data.toData.pcn)
    .replace(/{firstName}/g, data?.toData?.firstName)
    .replace(/{lastName}/g, data?.toData?.lastName)
    .replace(/{billedPlan}/g, `${formatCurrency(parseFloat(invoiceData.totalBilled)).slice(1)}`)
    .replace(/{past_charges}/g, `${formatCurrency(data.toData.outStandingBalance).slice(1)}`)
    .replace(/{coveredPlan}/g, `${formatCurrency(parseFloat(invoiceData.coveredPlan)).slice(1)}`)

    .replace(/{paid}/g, `${formatCurrency(invoiceData?.paidByYou).slice(1)}`)

    .replace(/{invoiceNo}/g, data.invoiceNo)
    .replace(/{invoiceNo1}/g, data.invoiceNo)
    .replace(/{tableRows}/g, invoiceData.tableRows)
    .replace(/{billingDate}/g, getFormatedDate(data.invoiceDate) || getFormatedDate(new Date()))
    .replace(/{billingDate1}/g, getFormatedDate(data.invoiceDate) || getFormatedDate(new Date()))
    .replace(
      /{dueDate}/g,
      getFormatedDate(data.dueDate) ||
        getFormatedDate(moment.utc().add(1, "days").format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"))
    )
    .replace(
      /{dueDate1}/g,
      getFormatedDate(data.dueDate) ||
        getFormatedDate(moment.utc().add(1, "days").format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"))
    );

  return { filledTemplate };
};

export const generateBarCode = async (id) => {
  let barcodeImage;
  const canvas = document.createElement("canvas");
  await bwipjs.toCanvas(canvas, {
    bcid: "code128",
    text: id || "00000",
    scale: 3,
    height: 10,
    textxalign: "center",
  });
  barcodeImage = canvas.toDataURL("image/png");
  return barcodeImage;
};

export const generateQRCodeImage = (data) => {
  return new Promise((resolve, reject) => {
    qrcode.toDataURL(data, (err, url) => {
      if (err) {
        reject(err);
      } else {
        resolve(url);
      }
    });
  });
};

export const getQRCode = (employeeID) => {
  let url = `https://www.provider.medflowllc.com/${employeeID}`;
  return generateQRCodeImage(url)
    .then((dataURL) => {
      return dataURL;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const addPrefixToIds = (html, prefix, data) => {
  if (!html) return "";

  const parser = new DOMParser();

  const phone = formatPhoneForCalRedie(data?.fromData?.phone);
  const formattedHTML = html
    .replace(/{companyName}/g, data?.fromData?.companyName)
    .replace(/{invoiceSettingPhone}/g, phone)
    .replace(/{companyZip}/g, data?.fromData?.zip);

  const doc = parser.parseFromString(formattedHTML, "text/html");
  const elementsWithId = doc.querySelectorAll("[id]");
  elementsWithId.forEach((element) => {
    element.id = `${prefix}${element.id}`;
  });
  return new XMLSerializer().serializeToString(doc);
};

export const handleConvertToPdf = async (templates, data) => {
  try {
    const contentElement1 = document.getElementById("template1_contentWrapper");
    const pdfWidth = 215.9;
    const pdfHeight = 279.4;
    const scale = 1.5;
    const options = {
      scale: scale,
      logging: false,
    };
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: [pdfWidth, pdfHeight],
    });
    const canvas1 = await html2canvas(contentElement1, options);
    const imgData1 = canvas1.toDataURL("image/png");
    pdf.addImage(imgData1, "PNG", 0, 4, pdfWidth, pdfHeight);
    for (let index = 0; index < templates.filledTemplate1.length; index++) {
      const contentElement = document.getElementById(`template${index + 2}_contentWrapper`);
      if (contentElement) {
        pdf.addPage();
        const canvas = await html2canvas(contentElement, options);
        const imgData = canvas.toDataURL("image/png");
        const yPos = index === 0 ? 4 : 10;
        pdf.addImage(imgData, "PNG", 0, yPos, pdfWidth, 272);
      }
    }
    pdf.save(`${data?.toData?.firstName}${data?.toData?.lastName ? `_${data?.toData?.lastName}` : ""}.pdf`);
  } catch (error) {
    console.log("Error in generating pdf", error);
  }
};
