import SwitchToggle from "components/SwitchToggle/SwitchToggle";
import { CLI_ACTIONS } from "constant";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { t } from "stringConstants";
import { createCPTCodeAsync, updateCPTCodeAsync } from "../../store/features/cptCodes/cptCodeSlice";
import { RemoveSpaceAndChar, parseStringIntoFloat } from "../../utils";
const AddProcedureCodeSettingsModal = (props) => {
  const { handleClose, selectedItem, handleSave } = props;
  const [newItem, setNewItem] = useState(
    selectedItem ? { ...selectedItem, action: CLI_ACTIONS.update } : { action: CLI_ACTIONS.add }
  );
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  const onHandleSave = async (obj) => {
    if (!obj.code || !obj.code.trim()) {
      setError("Please fill Procedure Code field!");
      return;
    }
    if (!obj.description || !obj.description.trim()) {
      setError("CPT code title is required");
      return;
    }

    if (!obj.charges || !obj.charges.trim()) {
      setError("Charge is required");
      return;
    }

    if (!obj.internalCode || !obj.internalCode.trim()) {
      Object.assign(obj, { internalCode: obj.code });
    }

    try {
      if (newItem.action === CLI_ACTIONS.add) {
        dispatch(createCPTCodeAsync(obj));
      } else if (newItem.action === CLI_ACTIONS.update) {
        dispatch(updateCPTCodeAsync(obj));
      }
      handleClose();
    } catch (err) {
      console.log("Error in CPT Modal", err);
    }
  };

  return (
    <>
      <Modal show backdrop="static" animation={true} onHide={() => handleClose()} centered size={"md"}>
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            {newItem.action === CLI_ACTIONS.add ? `${t("create")}` : `${t("update")}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: 0 }}>
          <div className="crew-form-list crew-profile-again flex-direction row11">
            <div className="w-100 createClientsWrapper">
              <Form.Group className="form-group-wrapper w-100">
                <Form.Label className="mt-0 text-capitalize">CPT Code:</Form.Label>
                <Form.Control
                  className={`modalInput`}
                  minLength={5}
                  onChange={(e) => setNewItem({ ...newItem, code: RemoveSpaceAndChar(e.target.value).toUpperCase() })}
                  value={newItem?.code}
                  placeholder="Enter CPT Code"
                  type="text"
                />
              </Form.Group>
            </div>
            <div className="w-100 createClientsWrapper">
              <Form.Group className="form-group-wrapper w-100">
                <Form.Label className="mt-0 text-capitalize">Alias:</Form.Label>
                <Form.Control
                  className={`modalInput`}
                  minLength={5}
                  onChange={(e) => setNewItem({ ...newItem, internalCode: e.target.value.toUpperCase() })}
                  value={newItem?.internalCode}
                  placeholder="Enter Alias"
                  type="text"
                />
              </Form.Group>
            </div>
            <div className="w-100 createClientsWrapper">
              <Form.Group className="form-group-wrapper w-100">
                <Form.Label className="mt-0 text-capitalize">title</Form.Label>
                <Form.Control
                  className={`modalInput`}
                  maxLength={65}
                  onChange={(e) => setNewItem({ ...newItem, description: e.target.value })}
                  value={newItem?.description}
                  placeholder="Enter CPT Title"
                  type="text"
                />
              </Form.Group>
            </div>
            <div className="w-100 createClientsWrapper">
              <Form.Group className="form-group-wrapper w-100">
                <Form.Label className="mt-0 text-capitalize">Charges</Form.Label>
                <Form.Control
                  className={`modalInput`}
                  maxLength={65}
                  onChange={(e) => setNewItem({ ...newItem, charges: parseStringIntoFloat(e.target.value) })}
                  value={newItem?.charges}
                  placeholder="Enter Charges"
                  type="text"
                />
              </Form.Group>
            </div>
            <div className="w-100 createClientsWrapper">
              <Form.Group className="form-group-wrapper w-100">
                <Form.Label className="mt-0 text-capitalize">Reference Charge</Form.Label>
                <Form.Control
                  className={`modalInput`}
                  maxLength={65}
                  onChange={(e) => setNewItem({ ...newItem, referenceCharge: parseStringIntoFloat(e.target.value) })}
                  value={newItem?.referenceCharge}
                  placeholder="Enter Reference Charge"
                  type="text"
                />
              </Form.Group>
            </div>
          </div>
          <div
            className="d-flex align-items-center column-gap-10 profile-
                          detail mb-3"
          >
            <span className="section-title">Is Active: </span>
            <SwitchToggle
              checked={newItem.isActive}
              handleChange={(e) =>
                setNewItem({
                  ...newItem,
                  isActive: e.target.checked,
                })
              }
            />
          </div>
        </Modal.Body>
        <div className="px-4 my-2" style={{ height: "2vh" }}>
          {error && <strong style={{ color: "Red" }}>{error}</strong>}
        </div>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="primary"
            className="modalButtons headerButton btn-fill"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
          <Button
            style={{ marginBottom: 10 }}
            variant="secondary"
            className="modalButtons headerButton btn-fill"
            onClick={() => onHandleSave(newItem)}
          >
            {newItem.action === CLI_ACTIONS.add ? "Create" : "Update"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddProcedureCodeSettingsModal;
