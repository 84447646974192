import api from "api";
import logoImage from "../../assets/img/login_logo.png";
import { createRef, useEffect, useRef, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { setIsAuthorized } from "store/features/authentication/authenticationSlice";

const TwoFa = () => {
  const inputRefs = useRef([createRef(), createRef(), createRef(), createRef(), createRef(), createRef()]);

  const [digits, setDigits] = useState(Array(6).fill(""));

  const [twoFaError, setTwoFaError] = useState(null);

  const dispatch = useDispatch();

  let history = useHistory();
  let url = window.location.hostname;

  const handleChange = (value, index) => {
    if (value !== "" && !/^\d$/.test(value)) {
      return;
    }
    const newDigits = [...digits];
    newDigits[index] = value;
    setDigits(newDigits);

    if (value) {
      if (index < 5) {
        inputRefs.current[index + 1].focus();
      }
    } else if (index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleKeyUp = (e, index) => {
    if (e.key === "Backspace" && !digits[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  useEffect(() => {
    inputRefs.current[0].focus();
  }, []);

  const handelOtpVerification = async () => {
    const code = digits.join("");
    try {
      const result = await api.verifyOtp(code);
      if (result?.verified) {
        dispatch(setIsAuthorized(true));
        localStorage.setItem("isAuthorized", true)
        if (history) history.push("/admin");
      }
    } catch (error) {
      console.log("Error", { error });
      setTwoFaError(error?.response?.data?.error);
    }
  };

  const handleResendOtp = async () => {
    try {
      await api.resendOtp();
    } catch (error) {
      setTwoFaError(error?.response?.data?.error);
    }
  };

  return (
    <div className="loginWrapper">
      <div className="login-left-side">
        <div className="text-center">{<img src={logoImage} alt="logo" className="logo-width" />}</div>
      </div>

      <SweetAlert show={twoFaError ? true : false} error title="Error" onConfirm={() => setTwoFaError(null)}>
        {twoFaError}
      </SweetAlert>
      <div className="login-right-side">
        <div className="registerFormWrapper">
          <div className="registerWrapper">
            <div className="codeScannerWrapper codeScannerPhoneInput">
              <div className="registered-successful">
                <h3 className="w-100 text-center mt-2">
                  <b>Authenticate Your Account</b>
                </h3>
                <p style={{ fontSize: "17px" }}>
                  Please confirm your account by entering the authorization code sent to your email address.
                </p>
                <div className="custom-radio-btn-wrapper">
                  {Array.from({ length: 6 }).map((_, index) => (
                    <input
                      key={index}
                      ref={(el) => (inputRefs.current[index] = el)}
                      type="text"
                      maxLength="1"
                      value={digits[index]}
                      onChange={(e) => handleChange(e.target.value, index)}
                      onKeyUp={(e) => handleKeyUp(e, index)}
                      style={{ width: "40px", textAlign: "center", margin: "0 5px" }}
                    />
                  ))}
                </div>
                <div className="mt-4">
                  <button
                    type="submit"
                    disabled={digits.join("").length !== 6}
                    className="text-uppercase w-50 btn signin-btn"
                    onClick={handelOtpVerification}
                  >
                    Verify
                  </button>
                  <p className="mt-2" style={{ cursor: "pointer", fontSize: "15px" }} onClick={handleResendOtp}>
                    Resend Code?
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwoFa;
