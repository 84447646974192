import Loader from "components/Loader/Loader";
import ErrorMessage from "components/Message/ErrorMessage";
import { PREV_CLAIM_TABLE } from "constant";
import { AppContext } from "context/app-context";
import graphqlApi from "graphqlApi";
import { useContext, useState } from "react";
import { Button, Card, Modal, Table } from "react-bootstrap";
import EmpPrevClaimRow from "./EmpPrevClaimRow";

const ViewClaimList = (props) => {
  const { empClaimsList, handleClose, handleSaveClaim, cssClass, patient, user } = props;
  const [selectedClaim, setSelectedClaim] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const appContext = useContext(AppContext);

  const handleProceed = async () => {
    const { provider, refProvider, ordProvider } = patient;
    if (selectedClaim.length === 0) {
      setError("Please Select any claim to update");
      return;
    }
    try {
      setLoading(true);
      const updatedUser = patient.user || patient.updatedUser;

      // const res = await api.updateClaims(patient.user, user, provider, refProvider, ordProvider, selectedClaim);
      const res = await graphqlApi.updateClaimsGQL(
        updatedUser,
        user,
        provider,
        refProvider,
        ordProvider,
        selectedClaim
      );
      
      handleClose(true);
      // appContext.resetEmployees();
      setLoading(false);
    } catch (err) {
      console.log("Error:-", err);
      setLoading(false);
    }
  };

  return (
    <Modal
      show
      backdrop="static"
      animation={true}
      onHide={() => handleClose()}
      className={`${cssClass}`}
      centered
      size={"xl"}
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0 text-capitalize" id="contained-modal-title-vcenter">
          Patient Claim List
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="crew-member-profile">
          <h6>Please select claim for update patient details:</h6>
          <Card className="strpied-tabled-with-hover">
            <Card.Body>
              <div>
                <Table>
                  <thead>
                    <tr className="data-listing-row">
                      <th />
                      {PREV_CLAIM_TABLE.map((title) => (
                        <th className="centered">{title}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {empClaimsList.map((claim, index) => (
                      <EmpPrevClaimRow
                        claim={claim}
                        index={index}
                        multiSelected={selectedClaim}
                        setMultiSelected={setSelectedClaim}
                      />
                    ))}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Modal.Body>
      {loading && <Loader />}

      <div className="px-4 my-2" style={{ height: "2vh" }}>
        {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
      </div>

      <Modal.Footer>
        <Button
          style={{ marginBottom: 10 }}
          variant="primary"
          className="modalButtons headerButton btn-fill"
          onClick={() => {
            handleClose();
          }}
        >
          Close
        </Button>
        <Button
          style={{ marginBottom: 10 }}
          variant="secondary"
          className="modalButtons headerButton btn-fill"
          onClick={() => handleProceed(selectedClaim)}
        >
          Proceed
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewClaimList;
