import {
  createEmployeeAsync,
  updateEmployeeAsync,
  deleteEmployeeAsync,
  fetchAllEmployeesApiAsync,
} from "./employeesSlice";

export const createEmployee = (employee) => async (dispatch) => {
  await dispatch(createEmployeeAsync(employee));
};

export const updateEmployee = (employee) => async (dispatch) => {
  await dispatch(updateEmployeeAsync(employee));
};

export const deleteEmployee = (employeeId) => async (dispatch) => {
  await dispatch(deleteEmployeeAsync(employeeId));
};

export const fetchAllEmployeesApi =
  (dateFilter = null, page = 1, filter = {}, isNewCall = false) =>
  async (dispatch) => {
    await dispatch(fetchAllEmployeesApiAsync({ dateFilter, page, filter, isNewCall }));
  };
