import React from "react";
import { Button, Modal } from "react-bootstrap";
import InputCPTCodeDropDown from "components/InputField/InputCPTCodeDropDown";
import { clientPcsSelector } from "store/features/clientPcs/clientPcsSelector";
import MainTable from "components/Table/MainTable";
import { CLIENT_PCS_PERSONALIZE } from "constants/personalization";
import { useDispatch, useSelector } from "react-redux";
import {
  addSelectedPcs,
  removeSelectedPcs,
  showHideClientPcsModal,
  resetClientPcsState,
} from "store/features/clientPcs/clientPcsAction";
import graphqlApi from "graphqlApi";

const ClientPcsModal = () => {
  const dispatch = useDispatch();
  const { clientPcsList, showClientPcsModal, clientId } = useSelector(clientPcsSelector);
  const handleClose = () => {
    dispatch(showHideClientPcsModal(false));
  };

  const handelRemoveRow = (row) => {
    dispatch(removeSelectedPcs(row));
  };

  const handelCptChange = (cpt) => {
    dispatch(addSelectedPcs(cpt));
  };

  const renderTd = (item, row) => {
    if (item.itemKey === "action") {
      return (
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }} key={item.id}>
          <td
            className="ellipsis"
            style={{
              textAlign: item.textAlign,
              textOverflow: item.textOverflow,
            }}
            onClick={() => handelRemoveRow(row)}
          >
            <label style={{ color: "red", cursor: "pointer" }}>Remove</label>
          </td>
        </div>
      );
    }
  };

  const handelUpdateClientCpt = async () => {
    try {
      const cptCodes = clientPcsList.map((item) => ({ code: item.code }));

      const result = await graphqlApi.updateExcludedCpt({ id: clientId, cptCodes: { excluded: cptCodes } });
      if (result.data) {
        dispatch(resetClientPcsState());
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  return (
    <Modal
      show={showClientPcsModal}
      backdrop="static"
      onHide={handleClose}
      animation={true}
      size={"lg"}
      scrollable
      centered
      className="seperateModal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0">Excluded Procedure Code</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="w-100 createClientsWrapper">
          <InputCPTCodeDropDown
            type="text"
            inputStyle="modalInput"
            labelStyle=" text-capitalize"
            groupWrapper="mb-0 form-group-wrapper"
            label={"PROCEDURE"}
            placeholder={"PROCEDURE"}
            optionList={[]}
            handleChange={handelCptChange}
            searchtype="cpt"
            value=""
          />
        </div>

        <MainTable
          cssClass={" mt-5"}
          height={300}
          columns={CLIENT_PCS_PERSONALIZE}
          rows={clientPcsList}
          personalisationKey={"document"}
          widthToSkip={80}
          customColumnCellRenderer={renderTd}
        />
      </Modal.Body>
      <Modal.Footer className="mt-2">
        <Button
          style={{ marginBottom: 10 }}
          variant="primary"
          className="modalButtons headerButton btn-fill"
          onClick={handleClose}
        >
          Close
        </Button>
        <Button
          style={{ marginBottom: 10 }}
          variant="secondary"
          className="modalButtons headerButton btn-fill"
          onClick={handelUpdateClientCpt}
        >
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ClientPcsModal;
