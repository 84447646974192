import api from "api";
import Default_Profile from "assets/img/default_profile.png";
import { Storage } from "aws-amplify";
import ImageCropModal from "components/Input/ImageCropModal";
import ImageInput from "components/Input/ImageInput";
import Loader from "components/Loader/Loader";
import PhoneNoInput from "components/Phone/PhoneNoInput";
import QrCodeCard from "components/QrCard/QrCodeCard";
import RadioButton from "components/RadioButton/RadioButton";
import {
  INSURANCE_PROVIDER,
  MEDICARE_ID,
  MEDICATRE_IDS,
  MESSAGE_MODES,
  PROFILE_IMAGE_PATH,
  countryListAllIsoData,
} from "constant";
import { AppContext } from "context/app-context";
import countriesOption from "countries";
import moment from "moment";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import DatePicker from "react-date-picker";
import { usePlacesWidget } from "react-google-autocomplete";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { setMessage } from "store/features/general/generalAction";
import {
  convertToLower,
  convertToUpper,
  formatZip,
  getStateLength,
  getValidDep,
  getValidSpaces,
  isValidEmail,
  isValidIDNumber,
  isValidName,
  isValidZipCode,
  patientDataInLocalStorage,
  validatePhone,
} from "utils";
const options = {
  componentRestrictions: { country: "us" },
  fields: ["address_components", "name", "formatted_address"],
  strictBounds: false,
  types: [],
};
const Profile = (props) => {
  const { handleSave, handleClose } = props;
  const appContext = useContext(AppContext);
  const [newUser, setNewUser] = useState(patientDataInLocalStorage.get() || {});
  const [address, setAddress] = useState(null);
  const [editButton, setEditButton] = useState(false);
  const [show, setShow] = useState("");
  const [profileImage, setProfileImage] = useState(Default_Profile);
  const [copyImage, setCopyImage] = useState(Default_Profile);
  const [showImageCroper, setShowImageCropper] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [insuranceCardFront, setInsuranceCardFront] = useState("");
  const [insuranceCardBack, setInsuranceCardBack] = useState("");
  const childNode = useRef();
  const dispatch = useDispatch();

  useEffect(async () => {
    if (!appContext.employeeRecord) {
      return;
    }
    setNewUser({
      ...appContext.employeeRecord,
      phoneNumber: `${appContext.employeeRecord.countryCode}${appContext.employeeRecord.phoneNumber}`,
      subAgentID: appContext.employeeRecord.subAgent,
      ...(appContext.employeeRecord.medicareNo &&
        !MEDICARE_ID.includes( appContext.employeeRecord.insuranceCompany) && {
          isMedicare: "Y",
        }),
      ...(!appContext.employeeRecord.medicareNo && { isMedicare: "N" }),
      ...(appContext.employeeRecord.insuranceDetails && { isSecondary: "Y" }),
      insuranceDetails: {
        ...appContext.employeeRecord.insuranceDetails,
        ...(appContext.employeeRecord.insuranceDetails?.medicareNo &&
          appContext.employeeRecord.insuranceDetails?.insuranceGroupId && {
            isMedicare: "Y",
          }),
      },
      isoCode: countryListAllIsoData.find((f) => f.name === appContext.employeeRecord.country)?.code || "US",
    });
    if (appContext.employeeRecord?.picture) {
      setProfileImage(`${PROFILE_IMAGE_PATH}${appContext.employeeRecord.picture}`);
    }
    if (appContext.employeeRecord?.insuranceCardFront) {
      await getFileFromStorage(appContext.employeeRecord.insuranceCardFront, setInsuranceCardFront);
    }
    if (appContext.employeeRecord?.insuranceCardBack) {
      await getFileFromStorage(appContext.employeeRecord.insuranceCardBack, setInsuranceCardBack);
    }
  }, [appContext.employeeRecord]);

  useEffect(() => {
    setTimeout(() => {
      setShow("");
    }, 4000);
  }, [show]);

  useEffect(() => {
    if (newUser.status === "active") {
      setEditButton(false);
    } else {
      setEditButton(true);
    }
  }, [newUser.status]);

  useEffect(() => {
    if (address) {
      setNewUser({ ...newUser, ...address });
    }
  }, [address]);

  const handleEditInfo = async (newUser) => {
    if (!newUser.firstName || newUser.firstName === " ") {
      setShow("First name is required");
      return;
    }

    if (!isValidName(newUser.firstName)) {
      setShow("Name is invalid must contains at least 2 letters and Name should be realistic");
      return;
    }

    if (!newUser.lastName || newUser.lastName === " ") {
      setShow("Last name is required");
      return;
    }

    if (!isValidName(newUser.lastName)) {
      setShow("Name is invalid must contains at least 2 letters and Name should be realistic");
      return;
    }

    if (!newUser.dob) {
      setShow("Date of Birth is required");
      return;
    }
    if (!newUser.phoneNumber || newUser.phoneNumber === " ") {
      setShow("Phone number is required");
      return;
    }
    if (!validatePhone(newUser.phoneNumber)) {
      setShow("Invalid Phone Number");
      return;
    }

    if (!newUser.email || newUser.email === " ") {
      setShow("Email name is required");
      return;
    }

    if (!isValidEmail(newUser.email)) {
      setShow("Invalid Email");
      return;
    }

    if (!newUser.sex) {
      setShow("Required gender");
      return;
    }

    if (!newUser.insuranceCompany) {
      setShow("Insurance Provider is required");
      return;
    }
    if (
      newUser.insuranceCompany &&
      MEDICATRE_IDS.indexOf(newUser.insuranceCompany) === -1 &&
      !newUser.insuranceGroupId.trim()
    ) {
      setShow("Group ID is Required");
      return;
    }

    if (!newUser.medicalNo.trim()) {
      setShow(`${newUser.insuranceGroupId ? "ID Number" : "Medicare Number"}  is required`);
      return;
    }

    if (!isValidIDNumber(newUser.medicalNo)) {
      setShow(`${newUser.insuranceGroupId ? "ID Number" : "Medicare Number"}  is invalid`);
      return;
    }
    setLoading(true);
    const isRecordExists = await api.getUserValidate(newUser);

    if (isRecordExists.error) {
      setShow(isRecordExists.error);
      return;
    }

    if (profileImage instanceof Object) {
      Object.assign(newUser, { ...newUser, profileImage: profileImage });
    }

    if (newUser.isMedicare === "N" && newUser.insuranceGroupId && !newUser.insuranceDetails?.medicareNo) {
      Object.assign(newUser, { ...newUser, medicareNo: "" });
    }
    if (!newUser.isSecondary || newUser.isSecondary === "N") {
      Object.assign(newUser, { ...newUser, insuranceDetails: null });
    }

    if (newUser.insuranceDetails?.isMedicare === "N" && newUser.insuranceDetails?.insuranceGroupId) {
      Object.assign(newUser, {
        ...newUser,
        insuranceDetails: { ...newUser.insuranceDetails, medicareNo: "" },
      });
    }

    let insuranceCardFrontFileName = "";
    let insuranceCardBackFileName = "";
    if (insuranceCardFront instanceof Object) {
      insuranceCardFrontFileName = await api.saveFileStorage(
        newUser.medicalNo,
        IMAGE_UPLOAD_TYPE.frontCard,
        insuranceCardFront
      );
    }

    if (insuranceCardBack instanceof Object) {
      insuranceCardBackFileName = await api.saveFileStorage(
        newUser.medicalNo,
        IMAGE_UPLOAD_TYPE.backCard,
        insuranceCardBack
      );
    }

    if (insuranceCardFrontFileName) {
      Object.assign(newUser, {
        insuranceCardFront: insuranceCardFrontFileName,
      });
    }
    if (insuranceCardBackFileName) {
      Object.assign(newUser, { insuranceCardBack: insuranceCardBackFileName });
    }

    try {
      await api.updateEmployee(newUser);
      dispatch(setMessage("Profile Updated Successfully", MESSAGE_MODES.success));
      appContext.resetEmployeeRecord();
      setLoading(false);
    } catch (err) {
      console.log("Error:-", err.message);
      dispatch(setMessage(err.message, MESSAGE_MODES.error));
      setLoading(false);
    }
  };

  const getFileFromStorage = async (file, setImage) => {
    if (file) {
      try {
        const path = await Storage.get(`VaccinationImage/${file}`);
        setImage(path);
        console.log("path", path);
        return true;
      } catch {
        console.log("Image loading fail");
      }
    } else {
      return false;
    }
  };

  const handleAddressUpdate = (place) => {
    if (!place || Object.keys(place).length === 1) return;

    const obj = {};
    let address1 = "";
    let postcode = "";
    for (const curr of place.address_components) {
      const type = curr.types[0];

      switch (type) {
        case "street_number": {
          address1 = `${curr.long_name}`;
          break;
        }
        case "route": {
          address1 += ` ${curr.long_name}`;
          break;
        }
        case "postal_code": {
          postcode = `${curr.long_name}`;
          break;
        }
        case "locality": {
          Object.assign(obj, { city: curr.long_name });
          break;
        }
        case "administrative_area_level_1": {
          Object.assign(obj, { state: curr.short_name });
        }
        case "administrative_area_level_2": {
          Object.assign(obj, { county: curr.long_name });
        }
        case "country": {
          Object.assign(obj, { country: curr.long_name, isoCode: curr.short_name });
          break;
        }
      }
    }
    Object.assign(obj, { street: address1.trim() || place.name, zipcode: postcode });
    setAddress({ ...obj });
  };

  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyABX4LTqTLQGg_b3jFOH8Z6_H5CDqn8tbc",
    onPlaceSelected: (place) => {
      handleAddressUpdate(place);
    },
    options,
  });

  const onHandleSave = async (isConfirm, newUser) => {
    if (!newUser.firstName || !newUser.firstName.trim()) {
      setShow("First name is required");
      return;
    }

    if (!isValidName(newUser.firstName)) {
      setShow("Name is invalid must contains at least 2 letters and Name should be realistic");
      return;
    }

    if (!newUser.lastName || !newUser.lastName.trim()) {
      setShow("Last name is required");
      return;
    }

    if (!isValidName(newUser.lastName)) {
      setShow("Name is invalid must contains at least 2 letters and Name should be realistic");
      return;
    }
    if (!newUser.email || newUser.email === " ") {
      setShow("Email name is required");
      return;
    }

    if (!isValidEmail(newUser.email)) {
      setShow("Invalid Email");
      return;
    }

    if (!newUser.phoneNumber || newUser.phoneNumber === " ") {
      setShow("Phone number is required");
      return;
    }
    if (!validatePhone(newUser.phoneNumber)) {
      setShow("Invalid Phone Number");
      return;
    }
    if (!newUser.street || !newUser.street.trim()) {
      setShow("Address is required");
      return;
    }

    if (!newUser.city || !newUser.city.trim()) {
      setShow("City is required");
      return;
    }

    if (!newUser.state || !newUser.state.trim()) {
      setShow("State is required");
      return;
    }
    if (newUser.state.length > getStateLength(newUser)) {
      setShow("State must be 2 characters");
      return;
    }
    if (!newUser.zip) {
      setShow("Zip Code is required");
      return;
    }
    if (!isValidZipCode(newUser.isoCode || "US", newUser.zip)) {
      setShow("Zip Code is Invalid");
      return;
    }
    if (!newUser.country) {
      setShow("Country is required");
      return;
    }
    if (!newUser.sex) {
      setShow("Required gender");
      return;
    }

    if (!newUser.insuranceCompany) {
      setShow("Insurance Provider is required");
      return;
    }
    if (
      newUser.insuranceCompany &&
      MEDICATRE_IDS.indexOf(newUser.insuranceCompany) === -1 &&
      !newUser.insuranceGroupId.trim()
    ) {
      setShow("Group ID is Required");
      return;
    }

    if (!newUser.medicalNo.trim()) {
      setShow(`${newUser.insuranceGroupId ? "Insurance Number" : "Medicare Number"}  is required`);
      return;
    }

    if (!isValidIDNumber(newUser.medicalNo)) {
      setShow(`${newUser.insuranceGroupId ? "Insurance Number" : "Medicare Number"}  is invalid`);
      return;
    }

    if (newUser.isMedicare === "Y" && !newUser.medicareNo) {
      setShow("Medicare Number Not be Empty");
      return;
    }
    if (newUser.isMedicare === "Y" && !isValidIDNumber(newUser.medicareNo)) {
      setShow("Medicare Number is invalid");
      return;
    }

    if (newUser.isSecondary === "Y") {
      if (!newUser.insuranceDetails?.insuranceCompany) {
        setShow("Secondary Insurance Provider is required");
        return;
      }
      if (
        newUser.insuranceDetails?.insuranceCompany &&
        MEDICATRE_IDS.indexOf(newUser.insuranceDetails?.insuranceCompany) === -1 &&
        !newUser.insuranceDetails?.insuranceGroupId.trim()
      ) {
        setShow("Secondary Group ID is Required");
        return;
      }
      if (!newUser.insuranceDetails?.medicalNo || !newUser.insuranceDetails?.medicalNo.trim()) {
        setShow(
          `${
            newUser.insuranceDetails?.insuranceGroupId ? "Secondary Insurance Number" : "Secondary Medicare Number "
          } is Required`
        );
        return;
      }
      if (!isValidIDNumber(newUser.insuranceDetails?.medicalNo)) {
        setShow(`${newUser.insuranceDetails?.insuranceGroupId ? "Insurance Number" : "Medicare Number"}  is invalid`);
        return;
      }
      if (newUser.insuranceDetails?.isMedicare === "Y" && !newUser.insuranceDetails?.medicareNo) {
        setShow("Secondary Medicare Number Not be Empty");
        return;
      }
      if (newUser.insuranceDetails?.isMedicare === "Y" && !isValidIDNumber(newUser.insuranceDetails?.medicareNo)) {
        setShow("Secondary Medicare Number is invalid");
        return;
      }
    }

    const findVal = await appContext.employees.find((curr) => curr.phoneNumber === newUser.phoneNumber);

    if (profileImage instanceof Object) {
      Object.assign(newUser, { ...newUser, profileImage: profileImage });
    }
    handleSave(newUser);
    handleClose();
  };
  const handleCropDone = (isConfirm, fileData) => {
    setShowImageCropper(false);
    if (isConfirm && fileData) {
      setProfileImage(new File([fileData], "profileImage.jpeg"));
    } else {
      setProfileImage(profileImage);
    }
  };

  const handleConfirm = async (isConfirm) => {
    setOpenConfirmation(false);
    if (!isConfirm) {
      const obj = { ...newUser, callTime: user.callTime, onAlert: true };
      onHandleSave(false, obj);
    } else {
      onHandleSave(false, newUser);
    }
  };
  const handlePhoneNo = (e) => {
    setNewUser({
      ...newUser,
      phoneNumber: e,
    });
  };
  const insuranceObject = useMemo(() => {
    if (newUser.insuranceCompany) {
      const item = INSURANCE_PROVIDER.find((i) => i.value === newUser.insuranceCompany);
      if (item) return item;
    }
    return "";
  }, [newUser]);

  const secondaryInsuranceObject = useMemo(() => {
    if (newUser.insuranceDetails?.insuranceCompany) {
      const item = INSURANCE_PROVIDER.find((i) => i.value === newUser.insuranceDetails.insuranceCompany);
      if (item) return item;
    }
    return "";
  }, [newUser]);

  return (
    <div style={{ flex: 1 }}>
      <Row>
        <Col md="12">
          {!loading ? (
            <Card className="strpied-tabled-with-hover">
              <Card.Header></Card.Header>
              <Card.Body className="table-full-width desktop-noScroll">
                <div className="createClientsWrapper">
                  <div className="leftSide">
                    {/* <div className="upload-file-section d-flex align-items-center justify-content-between">
                      <ImageInput
                        type="profile"
                        setSelectedImage={setProfileImage}
                        selectedImage={profileImage}
                        showCropDialog={setShowImageCropper}
                        setCopyImage={setCopyImage}
                      />
                    </div> */}
                    <div className="first-last-name-wrapper">
                      <div className="form-group-wrapper">
                        <label className="modalLineHeaders">First Name: </label>
                        <input
                          value={newUser?.firstName}
                          readOnly={editButton}
                          className="modalInput"
                          placeholder="First Name"
                          style={{
                            width: "100%",
                          }}
                          onChange={(e) =>
                            setNewUser({
                              ...newUser,
                              firstName: getValidDep(e.target.value),
                            })
                          }
                        />
                      </div>
                      <div className="form-group-wrapper">
                        <label className="modalLineHeaders">Last Name: </label>
                        <input
                          value={newUser?.lastName}
                          readOnly={editButton}
                          className="modalInput"
                          placeholder="Last Name"
                          onChange={(e) =>
                            setNewUser({
                              ...newUser,
                              lastName: getValidDep(e.target.value),
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="form-group-wrapper">
                      <label className="modalLineHeaders">DOB: </label>
                      <DatePicker
                        className="w-100 orderTestDob"
                        openCalendarOnFocus={false}
                        disabled={editButton}
                        dayPlaceholder="dd"
                        monthPlaceholder="mm"
                        yearPlaceholder="yyyy"
                        format="MM/dd/y"
                        onChange={(e) =>
                          setNewUser({
                            ...newUser,
                            dob: moment(e).format("YYYY-MM-DD") != "Invalid date" ? moment(e).format("YYYY-MM-DD") : "",
                          })
                        }
                        value={
                          newUser?.dob && new Date(newUser.dob) != "Invalid Date"
                            ? moment(newUser.dob, "YYYY-MM-DD").toDate()
                            : null
                        }
                        maxDate={new Date()}
                        minDate={new Date("1900-01-01")}
                      />
                    </div>
                    <div className="form-group-wrapper position-relative">
                      <label className="modalLineHeaders">Email: </label>
                      <input
                        value={newUser?.email}
                        readOnly={editButton}
                        className={`modalInput `}
                        placeholder="Email"
                        style={{
                          width: "100%",
                          flexShrink: "initial",
                        }}
                        onChange={(e) =>
                          setNewUser({
                            ...newUser,
                            email: convertToLower(e.target.value),
                          })
                        }
                      />
                    </div>

                    <div className="form-group-wrapper phoneInput position-relative">
                      <label className="modalLineHeaders">Phone #: </label>
                      <PhoneNoInput handleChange={handlePhoneNo} value={newUser?.phoneNumber} disabled={editButton} />
                    </div>
                    <div className="form-group-wrapper">
                      <label className="modalLineHeaders mt-0 text-capitalize">Primary Insurance</label>
                      <Select
                        options={INSURANCE_PROVIDER}
                        blurInputOnSelect={true}
                        isDisabled={editButton}
                        menuPlacement="auto"
                        placeholder="Select Primary Insurance"
                        value={insuranceObject}
                        onChange={(e) =>
                          setNewUser({
                            ...newUser,
                            insuranceCompany: e.value,
                            insuranceCompanyCode: e.label,
                            insuranceGroupId: "",
                            medicalNo: "",
                            medicareNo: "",
                            isMedicare: "N",
                            isSecondary: "N",
                            insuranceDetails: {
                              ...newUser.insuranceDetails,
                              isMedicare: "N",
                              insuranceCompany:
                                newUser.insuranceDetails?.insuranceCompany !== e.value
                                  ? newUser.insuranceDetails?.insuranceCompany
                                  : null,
                            },
                          })
                        }
                      />
                    </div>

                    {newUser.insuranceCompany && MEDICATRE_IDS.indexOf(newUser.insuranceCompany) === -1 && (
                      <>
                        <div className={!newUser.isNew ? "first-last-name-wrapper" : ""}>
                          <div className="form-group-wrapper">
                            <label className="mb-1">Group ID:</label>
                            <input
                              value={newUser.insuranceGroupId}
                              readOnly={editButton}
                              className="modalInput"
                              maxLength={16}
                              placeholder="Enter Group ID"
                              style={{
                                width: "100%",
                              }}
                              onChange={(e) =>
                                setNewUser({
                                  ...newUser,
                                  insuranceGroupId: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="form-group-wrapper">
                            <label className="mb-1">Insurance Number</label>
                            <input
                              value={newUser.medicalNo}
                              readOnly={editButton}
                              className="modalInput"
                              maxLength={16}
                              placeholder="Enter Insurance Number"
                              style={{
                                width: "100%",
                              }}
                              onChange={(e) =>
                                setNewUser({
                                  ...newUser,
                                  medicalNo: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </>
                    )}

                    {newUser.insuranceCompany && MEDICATRE_IDS.indexOf(newUser.insuranceCompany) !== -1 && (
                      <div className="form-group-wrapper">
                        <label className="modalLineHeaders">Medicare Number:</label>
                        <input
                          value={newUser.medicalNo}
                          readOnly={editButton}
                          className="modalInput"
                          maxLength={16}
                          placeholder={
                            MEDICATRE_IDS.includes(newUser.insuranceCompany) ? "Medicare Number" : "ID Number"
                          }
                          style={{
                            width: "100%",
                          }}
                          onChange={(e) =>
                            setNewUser({
                              ...newUser,
                              medicalNo: e.target.value,
                              medicareNo: e.target.value,
                            })
                          }
                        />
                      </div>
                    )}
                    {newUser.insuranceCompany && MEDICATRE_IDS.indexOf(newUser.insuranceCompany) === -1 && (
                      <div className="form-group-wrapper">
                        <label className="modalLineHeaders mt-0 text-capitalize">is this Medicare Advantage?</label>
                        <div className="d-flex justify-content-between w-100 flex-wrap">
                          <RadioButton
                            name="medicare"
                            id="yes"
                            label="Yes"
                            disabled={editButton}
                            checked={newUser?.isMedicare === "Y" ? true : false}
                            handleChange={(e) =>
                              setNewUser({
                                ...newUser,
                                isMedicare: e.target.checked ? "Y" : "",
                              })
                            }
                          />
                          <RadioButton
                            name="medicare"
                            id="no"
                            checked={newUser?.isMedicare === "N" ? true : false}
                            disabled={editButton}
                            label="No"
                            handleChange={(e) =>
                              setNewUser({
                                ...newUser,
                                isMedicare: e.target.checked ? "N" : "",
                                medicareNo: "",
                              })
                            }
                          />
                        </div>
                      </div>
                    )}
                    {newUser.insuranceCompany && newUser.isMedicare === "Y" && (
                      <div className="form-group-wrapper">
                        <label className="modalLineHeaders mt-0 text-capitalize">
                          {MEDICATRE_IDS.includes(newUser.insuranceCompany) ? "Medicare Number" : "Medicare Number"}
                        </label>
                        <input
                          className={"modalInput"}
                          readOnly={editButton}
                          maxLength={16}
                          onChange={(e) =>
                            setNewUser({
                              ...newUser,
                              medicareNo: convertToUpper(e.target.value),
                            })
                          }
                          value={newUser.medicareNo}
                          placeholder={
                            MEDICATRE_IDS.includes(newUser.insuranceCompany) ? "Medicare Number" : "Medicare Number"
                          }
                          type="text"
                        />
                      </div>
                    )}
                    {newUser.insuranceCompany && (
                      <div className="form-group-wrapper">
                        <label className="modalLineHeaders mt-0 text-capitalize">
                          Do you have Secondary Insurance?
                        </label>
                        <div className="d-flex justify-content-between w-100 flex-wrap">
                          <RadioButton
                            name="secondary"
                            id="y"
                            label="Yes"
                            disabled={editButton}
                            checked={newUser?.isSecondary === "Y" ? true : false}
                            handleChange={(e) =>
                              setNewUser({
                                ...newUser,
                                isSecondary: e.target.checked ? "Y" : "",
                              })
                            }
                          />
                          <RadioButton
                            name="secondary"
                            id="n"
                            disabled={editButton}
                            checked={newUser?.isSecondary === "N" ? true : false}
                            label="No"
                            handleChange={(e) =>
                              setNewUser({
                                ...newUser,
                                isSecondary: e.target.checked ? "N" : "",
                              })
                            }
                          />
                        </div>
                      </div>
                    )}
                    {newUser.insuranceCompany && newUser.isSecondary === "Y" && (
                      <div className="form-group-wrapper">
                        <label className="modalLineHeaders mt-0 text-capitalize">Secondary Insurance</label>
                        <Select
                          options={INSURANCE_PROVIDER.filter((f) => f.value !== newUser.insuranceCompany)}
                          blurInputOnSelect={true}
                          isDisabled={editButton}
                          menuPlacement="auto"
                          placeholder="Select Secondary Insurance"
                          value={secondaryInsuranceObject}
                          onChange={(e) =>
                            setNewUser({
                              ...newUser,
                              insuranceDetails: {
                                ...newUser.insuranceDetails,
                                insuranceCompany: e.value,
                                insuranceCompanyCode: e.label,
                                insuranceGroupId: "",
                                isMedicare: "N",
                                isSecondary: "N",
                              },
                            })
                          }
                        />
                      </div>
                    )}
                    {newUser.insuranceDetails?.insuranceCompany &&
                      newUser.isSecondary === "Y" &&
                      MEDICATRE_IDS.indexOf(newUser.insuranceDetails?.insuranceCompany) === -1 && (
                        <>
                          <div className="form-group-wrapper">
                            <label className="modalLineHeaders mt-0 text-capitalize">Secondary Group ID</label>
                            <input
                              className={"modalInput"}
                              readOnly={editButton}
                              maxLength={16}
                              onChange={(e) =>
                                setNewUser({
                                  ...newUser,
                                  insuranceDetails: {
                                    ...newUser.insuranceDetails,
                                    insuranceGroupId: convertToUpper(e.target.value),
                                  },
                                })
                              }
                              value={newUser.insuranceDetails.insuranceGroupId}
                              placeholder="Enter Insurance GroupID"
                              type="text"
                            />
                          </div>
                          <div className="form-group-wrapper">
                            <label className="modalLineHeaders mt-0 text-capitalize">Secondary Insurance Number</label>
                            <input
                              className={"modalInput"}
                              readOnly={editButton}
                              maxLength={16}
                              onChange={(e) =>
                                setNewUser({
                                  ...newUser,
                                  insuranceDetails: {
                                    ...newUser.insuranceDetails,
                                    medicalNo: convertToUpper(e.target.value),
                                  },
                                })
                              }
                              value={newUser.insuranceDetails.medicalNo}
                              placeholder="Enter Insurance Number"
                              type="text"
                            />
                          </div>
                        </>
                      )}
                    {newUser.insuranceDetails?.insuranceCompany &&
                      newUser.isSecondary === "Y" &&
                      newUser.isMedicare === "N" &&
                      MEDICATRE_IDS.indexOf(newUser.insuranceDetails?.insuranceCompany) === -1 &&
                      MEDICATRE_IDS.indexOf(newUser.insuranceCompany) === -1 && (
                        <div className="form-group-wrapper">
                          <label className="modalLineHeaders mt-0 text-capitalize">is this Medicare Advantage?</label>
                          <div className="d-flex justify-content-between w-100 flex-wrap">
                            <RadioButton
                              name="secondaryMedicare"
                              id="syes"
                              label="Yes"
                              disabled={editButton}
                              checked={newUser.insuranceDetails.isMedicare === "Y" ? true : false}
                              handleChange={(e) =>
                                setNewUser({
                                  ...newUser,
                                  insuranceDetails: {
                                    ...newUser.insuranceDetails,
                                    isMedicare: e.target.checked ? "Y" : "",
                                  },
                                })
                              }
                            />
                            <RadioButton
                              name="secondaryMedicare"
                              id="sno"
                              disabled={editButton}
                              checked={newUser?.insuranceDetails.isMedicare === "N" ? true : false}
                              label="No"
                              handleChange={(e) =>
                                setNewUser({
                                  ...newUser,
                                  insuranceDetails: {
                                    ...newUser.insuranceDetails,
                                    isMedicare: e.target.checked ? "N" : "",
                                  },
                                })
                              }
                            />
                          </div>
                        </div>
                      )}
                    {newUser.insuranceDetails?.insuranceCompany &&
                      newUser.isSecondary === "Y" &&
                      MEDICATRE_IDS.indexOf(newUser.insuranceDetails?.insuranceCompany) !== -1 && (
                        <div className="form-group-wrapper">
                          <label className="modalLineHeaders mt-0 text-capitalize">Secondary Medicare Number</label>
                          <input
                            className={"modalInput"}
                            readOnly={editButton}
                            maxLength={16}
                            onChange={(e) =>
                              setNewUser({
                                ...newUser,
                                insuranceDetails: {
                                  ...newUser.insuranceDetails,
                                  medicalNo: convertToUpper(e.target.value),
                                  medicareNo: convertToUpper(e.target.value),
                                },
                                ...(newUser.isMedicare === "N" &&
                                  !newUser.medicareNo && {
                                    medicareNo: convertToUpper(e.target.value),
                                  }),
                              })
                            }
                            value={newUser.insuranceDetails.medicareNo}
                            placeholder={"Secondary Medicare Number"}
                            type="text"
                          />
                        </div>
                      )}
                    {newUser.insuranceDetails?.insuranceCompany &&
                      newUser.isSecondary === "Y" &&
                      newUser.insuranceDetails.isMedicare === "Y" && (
                        <div className="form-group-wrapper">
                          <label className="modalLineHeaders mt-0 text-capitalize">Medicare Number</label>
                          <input
                            className={"modalInput"}
                            readOnly={editButton}
                            maxLength={16}
                            onChange={(e) =>
                              setNewUser({
                                ...newUser,
                                insuranceDetails: {
                                  ...newUser.insuranceDetails,
                                  medicareNo: convertToUpper(e.target.value),
                                },
                                medicareNo: convertToUpper(e.target.value),
                              })
                            }
                            value={newUser.insuranceDetails.medicareNo}
                            placeholder={"Medicare Number"}
                            type="text"
                          />
                        </div>
                      )}
                  </div>
                  <div className="rightSide">
                    <div className="first-last-name-wrapper">
                      <div className="form-group-wrapper">
                        <label className="modalLineHeaders">Address: </label>
                        <input
                          ref={ref}
                          value={newUser.street}
                          readOnly={editButton}
                          placeholder="Enter Address"
                          className="modalInput"
                          onChange={(e) =>
                            setNewUser({
                              ...newUser,
                              street: getValidDep(e.target.value),
                            })
                          }
                        />
                      </div>
                      <div className="form-group-wrapper">
                        <label className="modalLineHeaders">Apartment#:</label>
                        <input
                          value={newUser.street2}
                          className="modalInput"
                          readOnly={editButton}
                          maxLength={16}
                          placeholder="Apartment#"
                          style={{
                            width: "100%",
                          }}
                          onChange={(e) => setNewUser({ ...newUser, street2: e.target.value })}
                        />
                      </div>
                    </div>
                    <div className="first-last-name-wrapper">
                      <div className="form-group-wrapper">
                        <label className="modalLineHeaders">City:</label>
                        <input
                          type="text"
                          className="modalInput"
                          readOnly={editButton}
                          value={newUser.city}
                          placeholder="City"
                          maxLength={65}
                          onChange={(e) => setNewUser({ ...newUser, city: e.target.value })}
                        />
                      </div>

                      <div className="form-group-wrapper">
                        <label className="modalLineHeaders">State:</label>
                        <input
                          type="text"
                          className="modalInput"
                          placeholder="State Abbr: XX"
                          readOnly={editButton}
                          value={newUser.state}
                          maxLength={getStateLength(newUser)}
                          onChange={(e) => {
                            let val = e.target.value;
                            if (val) val = val.toUpperCase();
                            setNewUser({
                              ...newUser,
                              state: getValidSpaces(val),
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="first-last-name-wrapper">
                      <div className="form-group-wrapper ">
                        <label className="modalLineHeaders">Country:</label>
                        <Select
                          options={countriesOption.filter((f) => f.value === "United States")}
                          blurInputOnSelect={true}
                          isDisabled={true}
                          value={
                            newUser.country
                              ? {
                                  label: newUser.country,
                                  value: newUser.country,
                                }
                              : null
                          }
                          menuPlacement="auto"
                          placeholder="Select Country"
                          className="w-100"
                          onChange={(e) => {
                            const country = countryListAllIsoData.find((f) => f.name === e.value) || { code: "US" };
                            setNewUser({
                              ...newUser,
                              country: e.value,
                              isoCode: country.code,
                            });
                          }}
                        />
                      </div>
                      <div className="form-group-wrapper">
                        <label className="mb-1">Zip Code:</label>
                        <input
                          value={newUser.isoCode === "US" ? formatZip(newUser.zip) : newUser.zip}
                          type="text"
                          readOnly={editButton}
                          className="modalInput"
                          placeholder="Enter Zip Code"
                          maxLength={10}
                          onChange={(e) => {
                            let val = e.target.value;
                            if (val) val = val.toUpperCase();
                            setNewUser({
                              ...newUser,
                              zip: newUser.isoCode === "US" ? formatZip(val) : getValidSpaces(val),
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-group-wrapper emp-select-area">
                      <label className="modalLineHeaders emp-gender-mb">Gender: </label>
                      <div className="d-flex justify-content-between w-100 flex-wrap">
                        <RadioButton
                          name="gender"
                          id="male"
                          label="Male"
                          disabled={editButton}
                          checked={newUser?.sex === "M" ? true : false}
                          handleChange={(e) =>
                            setNewUser({
                              ...newUser,
                              sex: e.target.checked ? "M" : "",
                            })
                          }
                        />
                        <RadioButton
                          name="gender"
                          id="female"
                          disabled={editButton}
                          checked={newUser?.sex === "F" ? true : false}
                          label="Female"
                          handleChange={(e) =>
                            setNewUser({
                              ...newUser,
                              sex: e.target.checked ? "F" : "",
                            })
                          }
                        />
                        <RadioButton
                          id="other"
                          checked={newUser?.sex === "X" ? true : false}
                          name="gender"
                          disabled={editButton}
                          label="Not Specified"
                          handleChange={(e) =>
                            setNewUser({
                              ...newUser,
                              sex: e.target.checked ? "X" : "",
                            })
                          }
                        />
                      </div>
                    </div>
                    {/* <div className="form-group-wrapper phoneInput position-relative">
                      <div className="form-group-wrapper">
                        <label className="modalLineHeaders">
                          Medicare/Medicaid Number:
                        </label>
                        <input
                          value={newUser?.medicalNo}
                          className="modalInput"
                          maxLength={16}
                          placeholder="Medicare/Medicaid Number"
                          style={{
                            width: "100%",
                          }}
                          onChange={(e) =>
                            setNewUser({
                              ...newUser,
                              medicalNo: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div> */}
                    <div className="form-group-wrapper">
                      <label className="modalLineHeaders">Insurance Card Front:</label>
                      <ImageInput
                        type="Camera"
                        setSelectedImage={setInsuranceCardFront}
                        selectedImage={insuranceCardFront}
                        imageCss="patientImageSize"
                        disabled={editButton}
                      />
                    </div>

                    <div className="form-group-wrapper">
                      <label className="modalLineHeaders">Insurance Card Back:</label>
                      <ImageInput
                        type="Camera"
                        setSelectedImage={setInsuranceCardBack}
                        selectedImage={insuranceCardBack}
                        imageCss="patientImageSize"
                        disabled={editButton}
                      />
                    </div>
                  </div>
                  {showImageCroper && (
                    <ImageCropModal selectedImage={copyImage} handleConfirm={handleCropDone}></ImageCropModal>
                  )}
                  <div id="section-to-print" className="printSide">
                    {newUser && <QrCodeCard profileImage={profileImage} newUser={newUser} appContext={appContext} />}
                  </div>
                </div>
              </Card.Body>
              <div className="px-4 my-2" style={{ height: "2vh" }}>
                {show.length > 0 && <strong style={{ color: "Red" }}>{show}</strong>}
              </div>
              <Card.Footer>
                <Button
                  className="my-3 me-auto headerButton btn-fill"
                  onClick={() => handleEditInfo(newUser)}
                  disabled={editButton}
                >
                  Update info
                </Button>
              </Card.Footer>
            </Card>
          ) : (
            <Loader />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Profile;
