import { useState, useEffect, useRef } from "react";
import InputField from "components/InputField/InputField";
import { setOpenInsruModal } from "store/features/insurances/insuranceSlice";
import { Button, Modal, Row, Col, Table, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { invoiceDocsSelector } from "store/features/invoiceDocuments/invoiceDocumentSelectors";
import { setMessage } from "store/features/general/generalAction";
import { claimSelector } from "store/features/employeeClaims/employeeClaimsSelectors";
import ClaimInfoModal from "./ClaimInfoSection/ClaimInfoModal";
import MultiImageInput from "components/Input/MultiImageInput";
import graphqlApi from "graphqlApi";
import ShowAlertMessage from "components/ShowAlertMessage";
import InputCPTCodeDropDown from "components/InputField/InputCPTCodeDropDown";
import { PAYMENT_TYPES } from "constant";
import { parseStringIntoFloat } from "utils";
import { getValidDep } from "utils";
import { selectLoginUser } from "store/features/authentication/authenticationSelectors";
import { processPaymentSelector } from "store/features/processPayment/processPaymentSelectors";

import { CLAIM_SUBMIT_STATUS } from "constant";
import { MESSAGE_MODES } from "constant";
import ErrorMessage from "components/Message/ErrorMessage";
import InvoiceDocumentModal from "./InvoiceDocument/InvoiceDocumentModal";
import api from "api";
import moment from "moment";
import { userCompanyID } from "utils";
import { CUSTOM_INS } from "constant";
import { setInvoiceDocs } from "store/features/invoiceDocuments/invoiceDocumentSlice";
import { INSURANCE_TYPES } from "constant";
import Loader from "components/Loader/Loader";
import { IS_PAYMENT_TYPE } from "constant";
import { LSProcessPaymentData } from "utils";
import { CONFIRMATION_TYPE } from "constant";
import ConfirmationModal from "../ConfirmationModal";
import { generateOpenAmountOfCPT } from "utils";
import { getFloatVal } from "utils";
import { INSURANCE_PROVIDER } from "constant";
import {
  setClaimsIDs,
  setLastRemoteClaimId,
  setAlreadyPostedClaim,
} from "store/features/processPayment/processPaymentSlice";

const PaymentProcessModal = ({ data, title, handleClose }) => {
  const [insuranceData, setInsuranceData] = useState({});
  const [claims, setClaims] = useState([]);
  const [filteredClaims, setFilteredClaims] = useState([]);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [patientSearch, setPatientSearch] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [claimError, setClaimError] = useState("");
  const [openFileModal, setOpenFileModal] = useState(false);
  const [calculationError, setCalculationError] = useState(null);
  const loginUser = useSelector(selectLoginUser);
  const invoiceDocs = useSelector(invoiceDocsSelector);
  const [openConfirmation, setOpenConfirmation] = useState({ isShow: false });
  const { employeeClaims } = useSelector(claimSelector);
  const { alreadyPostedClaim, claimsIDs, showFromBarCode, lastRemoteClaimId } = useSelector(processPaymentSelector);

  const [disabledLoadNext, setDisabledLoadNext] = useState(false);

  const dispatch = useDispatch();

  const refreshInputField = (arr) => {
    return [...arr].map((m) => {
      // Bill Patient / Bill Client Secondary Time  Format
      if (CUSTOM_INS.includes(m.payerid) && m.paid_insurance) {
        return { ...m, claimNo: "", proc_array: m.proc_array.map((p) => ({ ...p, write_off: 0 })) };
      }

      if (CUSTOM_INS.includes(m.payerid) || !m.paid_insurance) return { ...m };

      //Other Insurance Secondary Time  Format
      return {
        ...m,
        total_paid: 0,
        claimNo: "",
        proc_array: m.proc_array.map((p) => ({
          ...p,
          co_insurance: 0,
          paid: 0,
          allowed: 0,
          deductible: 0,
          status: "",
          copay: 0,
          write_off: 0,
        })),
      };
    });
  };

  useEffect(() => {
    // let filteredClaims = employeeClaims.filter((claim) => {
    //   return data.includes(claim.id) && claim.status !== CLAIM_SUBMIT_STATUS.draft;
    // });

    const cacheData = LSProcessPaymentData.get();

    let selectedClaimsData = data;

    if (cacheData && Object.keys(cacheData).length > 0) {
      const LSClaims = [...selectedClaimsData].filter((f) => cacheData.claims.findIndex((s) => f.id === s.id) === -1);
      
      selectedClaimsData = [...selectedClaimsData].filter(
        (f) => cacheData.filteredClaims.findIndex((s) => f.id === s.id) === -1
      );

      setClaims([...LSClaims, ...cacheData.claims]);
      setFilteredClaims([...selectedClaimsData, ...cacheData.filteredClaims]);
      setInsuranceData(cacheData.insuranceData);
      dispatch(setAlreadyPostedClaim(cacheData.alreadyPostedClaim));
      return;
    }

    if (!selectedClaimsData || selectedClaimsData.length === 0) return;

    // Generate open amount of all process because of previous paid claims doesn't have open amount
    const updateClaims = generateOpenAmountOfCPT(selectedClaimsData);

    setInsuranceData({ ...insuranceData, payer_name: updateClaims[0].payer_name, payerid: updateClaims[0].payerid });
    setClaims(updateClaims);
    setFilteredClaims(refreshInputField([...updateClaims]));

    return () => dispatch(setOpenInsruModal(false));
  }, []);

  const loadMoreClaims = async () => {
    if (claims.length === 0) return;
    const invId = claims[0].invoiceID;
    const lastClaimId = lastRemoteClaimId;
    const findClaim = await api.getInvoiceClaims({
      id: invId,
      last_claim_id: lastClaimId,
    });
    if (findClaim && findClaim.length > 0) {
      const updateClaimsWithCpt = generateOpenAmountOfCPT(findClaim);
      setClaims([...claims, ...updateClaimsWithCpt]);
      setFilteredClaims([...filteredClaims, ...refreshInputField([...updateClaimsWithCpt])]);
      dispatch(setClaimsIDs([...claimsIDs, ...findClaim]));
      dispatch(setLastRemoteClaimId(findClaim[findClaim.length - 1].remote_claimid));
    } else {
      setDisabledLoadNext(true);
    }
  };

  // Save draft Data in Client side cache of process payment
  useEffect(() => {
    if (!filteredClaims || !claims) return;
    LSProcessPaymentData.save({
      filteredClaims: filteredClaims,
      insuranceData: insuranceData,
      claims: claims,
      alreadyPostedClaim: alreadyPostedClaim,
    });
  }, [filteredClaims, insuranceData, claims, alreadyPostedClaim]);

  const selectClaimFromSearch = (opt) => {
    if (opt) {
      const isClaimExists = claims.some((s) => s.id === opt.id);
      //
      if (isClaimExists && alreadyPostedClaim.some((s) => s.claimID && opt.id)) {
        setFilteredClaims((prev) =>
          prev.map((m) => {
            if (m.id === opt.id) {
              return refreshInputField(generateOpenAmountOfCPT([opt]))[0];
            }
            return m;
          })
        );

        setClaims((prev) =>
          prev.map((m) => {
            if (m.id === opt.id) {
              return generateOpenAmountOfCPT([opt])[0];
            }
            return m;
          })
        );

        dispatch(setAlreadyPostedClaim(alreadyPostedClaim.filter((f) => f.claimID !== opt.id)));
        return;
      }

      if (isClaimExists) {
        setErrorMessage("Claim Already have in list");
        return;
      }

      const processClaims = [...claims, opt];
      if (processClaims.length === 1) {
        setInsuranceData({
          ...insuranceData,
          payer_name: processClaims[0].payer_name,
          payerid: processClaims[0].payerid,
        });
      }
      setClaims((prev) => {
        const newClaims = [...prev, opt];
        return generateOpenAmountOfCPT(newClaims);
      });
      setFilteredClaims((prev) => {
        const newClaims = [...prev, refreshInputField(generateOpenAmountOfCPT([opt]))[0]];
        return newClaims;
      });
    }
  };

  const handleCloseSave = () => {
    handleClose();
  };

  const checkStatusCodeIns = (singleClaim, realClaim) => {
    if (singleClaim.status_code && singleClaim.status_code === "8") {
      return { value: "00001", label: "Bill Patient", ins: "" };
    }
    if (singleClaim.status_code && singleClaim.status_code === "9") {
      return { value: "00002", label: "Bill Client", ins: "" };
    }
    if (singleClaim.status_code && singleClaim.status_code === "1") {
      return { value: realClaim.payerid, label: realClaim.payer_name, ins: realClaim.ins_number };
    }
    return null;
  };

  const handleClaimEdit = (claimIndex, fieldName, newValue) => {
    setFilteredClaims((prevClaims) => {
      let updatedClaims = JSON.parse(JSON.stringify(prevClaims));

      updatedClaims[claimIndex] = {
        ...updatedClaims[claimIndex],
        [fieldName]: newValue,
      };

      // Change Claim View on Update Process as
      if (fieldName === "status_code") {
        const checkIns = checkStatusCodeIns(updatedClaims[claimIndex], claims[claimIndex]);

        if (checkIns) {
          const [claim] = refreshInputField([
            {
              ...claims[claimIndex],
              payerid: checkIns.value,
              payer_name: checkIns.label,
              ins_number: checkIns.ins,
              status_code: newValue,
            },
          ]);

          updatedClaims[claimIndex] = claim;
        }
      }

      if (fieldName === "total_paid") {
        const unPostedAmount = returnUnPostedAmount(updatedClaims, insuranceData);

        setInsuranceData({ ...insuranceData, show_unposted_amount: unPostedAmount });
      }
      return updatedClaims;
    });
  };

  const isCalculationError = (obj, claim) => {
    if (claim && CUSTOM_INS.includes(claim.payerid)) return true;
    if (obj) {
      const { deductible, co_insurance, write_off, copay, paid, charge, allowed } = obj;
      let totalOfAllowed =
        parseFloat(deductible || 0) + parseFloat(co_insurance || 0) + parseFloat(copay || 0) + parseFloat(paid || 0);

      let totalOfBilled = parseFloat(write_off || 0) + parseFloat(allowed || 0);
      return parseFloat(allowed || 0) == totalOfAllowed && parseFloat(charge || 0) == totalOfBilled;
    }
    return false;
  };

  const autoCPTStatusUpdate = (obj, key) => {
    if (key === "status") return "";
    if (obj) {
      const { deductible, co_insurance, write_off, copay, paid, charge, allowed } = obj;

      if ((deductible && deductible > 0) || (co_insurance && co_insurance > 0)) {
        return "pr";
      }
      if (paid && paid < 0) {
        return "refund";
      }

      if (paid && paid > 0) {
        return "paid";
      }
      return "";
    }
    return null;
  };

  const totalOfAmounts = (proc_array) => {
    return proc_array.reduce((result, obj) => {
      let resObj = { ...result };
      resObj["total_deductible"] = (resObj["total_deductible"] || 0) + parseFloat(obj.deductible || 0);
      resObj["total_allowed"] = (resObj["total_allowed"] || 0) + parseFloat(obj.allowed || 0);
      resObj["total_co_insurance"] = (resObj["total_co_insurance"] || 0) + parseFloat(obj.co_insurance || 0);
      resObj["total_copay"] = (resObj["total_copay"] || 0) + parseFloat(obj.copay || 0);
      resObj["total_write_off"] = (resObj["total_write_off"] || 0) + parseFloat(obj.write_off || 0);
      resObj["chargeOfTotal"] = (resObj["chargeOfTotal"] || 0) + parseFloat(obj.paid || 0);
      resObj["total_patient_amount"] = (resObj["total_patient_amount"] || 0) + parseFloat(obj.patient_amount || 0);

      return resObj;
    }, {});
  };

  const isCheckAmtRecEqlToAllClaimPaidAmt = (claims, amountReceived) => {
    if (!claims || claims.length === 0) return false;
    const claimsAmount = claims.reduce((res, obj) => (res = res + parseFloat(obj.total_paid)), 0);
    return parseFloat(amountReceived || 0) === parseFloat(claimsAmount || 0);
  };

  const autoUpdateWriteOff = (obj, key, realCpt, isOpenCharge) => {
    if (key === "write_off") return "";
    if (!obj.allowed) return 0;
    if (obj.allowed < 0) {
      return -(parseFloat(obj.charge || 0) - parseFloat(Math.abs(obj.allowed) || 0));
    }
    if (isOpenCharge) return parseFloat(realCpt.open || 0) - parseFloat(obj.allowed || 0);
    return parseFloat(obj.charge || 0) - parseFloat(obj.allowed || 0);
  };

  const autoUpdateOpenAmount = (obj, key, realCpt, isOpenCharge) => {
    if (isOpenCharge)
      return (
        +parseFloat(realCpt.open || 0).toFixed(2) -
        parseFloat(obj.paid || 0) -
        parseFloat(obj.write_off || 0) -
        (obj.mw_off ? parseFloat(mw_off || 0) - parseFloat(obj.write_off || 0) : 0)
      );
    return (
      parseFloat(obj.charge || 0) -
      parseFloat(obj.paid || 0) -
      parseFloat(obj.write_off || 0) -
      (obj.mw_off ? parseFloat(mw_off || 0) - parseFloat(obj.write_off || 0) : 0)
    );
  };

  const autoUpdateAllowed = (obj, key) => {
    if (key === "allowed") return "";
    return (
      parseFloat(obj.deductible || 0) +
      parseFloat(obj.copay || 0) +
      parseFloat(obj.co_insurance || 0) +
      parseFloat(obj.paid || 0)
    );
  };

  const handleChargeEdit = (claimIndex, chargeIndex, fieldName, newValue, proc_id) => {
    const processChargeClaim = (prevClaims) => {
      let updatedClaims = JSON.parse(JSON.stringify(prevClaims));
      let updatedProcArray = [...updatedClaims[claimIndex].proc_array];

      const proc_index = updatedProcArray.findIndex((f) => f.id === proc_id);
      const realColumn = claims[claimIndex]?.proc_array.find((f) => f.id === proc_id);
      if (proc_index === -1 || !realColumn) return prevClaims;

      let paidInc = null;

      if (fieldName === "patient_amount") {
        paidInc = parseFloat(realColumn.paid || 0) + parseFloat(newValue || 0);
      }

      const isPatientORClientPaid = CUSTOM_INS.includes(updatedClaims[claimIndex]?.payerid);
      const isOpenCharge = updatedClaims[claimIndex]?.paid_insurance && !isPatientORClientPaid;

      updatedProcArray[proc_index] = {
        ...updatedProcArray[proc_index],
        [fieldName]: newValue,
        ...(paidInc !== null && { paid: paidInc }),
        error: false,
      };

      if (!isCalculationError(updatedProcArray[proc_index], updatedClaims[claimIndex])) {
        updatedProcArray[proc_index] = { ...updatedProcArray[proc_index], error: true };
      }

      let status = autoCPTStatusUpdate(updatedProcArray[proc_index], fieldName);

      if (status) {
        updatedProcArray[proc_index] = { ...updatedProcArray[proc_index], status };
      }

      let allowedAmount = autoUpdateAllowed(updatedProcArray[proc_index], fieldName);

      if (allowedAmount !== "") {
        updatedProcArray[proc_index] = { ...updatedProcArray[proc_index], allowed: +allowedAmount.toFixed(2) };
      }

      const needsWriteOff = isPatientORClientPaid && updatedClaims[claimIndex]?.paid_insurance;

      if (!needsWriteOff) {
        const writeOff = autoUpdateWriteOff(updatedProcArray[proc_index], fieldName, realColumn, isOpenCharge);

        if (writeOff !== "") {
          updatedProcArray[proc_index] = { ...updatedProcArray[proc_index], write_off: +writeOff.toFixed(2) };
        }
      }

      let open = autoUpdateOpenAmount(updatedProcArray[proc_index], fieldName, realColumn, isOpenCharge);

      if (open !== "") {
        updatedProcArray[proc_index] = { ...updatedProcArray[proc_index], open: +parseFloat(open).toFixed(2) };
      }

      updatedClaims[claimIndex].proc_array = updatedProcArray;

      const atLeastOnePaid = updatedProcArray.some((item) => item.status === "paid");
      const allPaid = updatedProcArray.every((item) => item.status === "paid");

      if (atLeastOnePaid) {
        updatedClaims[claimIndex].status = "partial";
      }
      if (allPaid) {
        updatedClaims[claimIndex].status = "paid";
      }

      return updatedClaims;
    };
    setFilteredClaims(processChargeClaim(filteredClaims));
  };

  const validationCheck = (postClaims = [], singleClaimPost) => {
    const checkAmount = getFloatVal(insuranceData.check_amount);

    if (!insuranceData.payment_type) {
      setErrorMessage("Payment Type is Required");
      return;
    }
    if (!insuranceData.paid_date) {
      setErrorMessage("Payment Date is Required");
      return;
    }
    if (!insuranceData.payment_no || !insuranceData.payment_no.trim()) {
      setErrorMessage("Payment Number is Required");
      return;
    }

    if (insuranceData.payment_type && (!insuranceData.check_number || !insuranceData.check_number.trim())) {
      setErrorMessage("Check Number is Required");
      return;
    }

    if (!insuranceData.check_amount) {
      setErrorMessage("Amount Received is Required");
      return;
    }

    if (!insuranceData.payerid) {
      setErrorMessage("Billed Insurance is Required");
      return;
    }

    if (!postClaims || postClaims.length === 0) {
      setErrorMessage("At Least one claim is required for manual payment processing");
      return;
    }
    if (postClaims.some((s) => (!s.claimNo || !s.claimNo.trim()) && (s.payerId !== "00001" || s.payerId !== "00002"))) {
      setErrorMessage("Claim ID is required for all claims");
      return;
    }
    if (postClaims.some((s) => !s.claim_received_date && (s.payerId !== "00001" || s.payerId !== "00002"))) {
      setErrorMessage("Claim Received Date is required of all claims");
      return;
    }

    // allow to post the payment when check amount is 0
    if (checkAmount > 0 && postClaims.some((s) => !s.total_paid || !s.total_paid.trim())) {
      setErrorMessage("Amount Received is required of all claims");
      return;
    }
    if (postClaims.some((s) => s.status === CLAIM_SUBMIT_STATUS.submitted || s.status === CLAIM_SUBMIT_STATUS.sent)) {
      setErrorMessage("Change Status will be required of Claims");
      return;
    }

    // required this condition for all payment process not for single process
    if (!singleClaimPost) {
      if (
        !isCheckAmtRecEqlToAllClaimPaidAmt(
          postClaims,
          insuranceData.id ? insuranceData.unposted_amount : insuranceData.check_amount
        )
      ) {
        setErrorMessage("Claims Paid Amount and Check Amount should be equal");
        return;
      }
    }
    // this checks required for future cases
    // let error = [];
    // for (const claim of filteredClaims) {
    //   console.log(claim);
    //   const total = totalOfAmounts(claim.proc_array);
    //   if (parseFloat(claim.total_paid) !== parseFloat(total.chargeOfTotal)) {
    //     error.push({ id: claim.id, message: "Amount recieved and paid amount should be equal" });
    //   }
    // }
    // if (error.length > 0) {
    //   setClaimError(error);
    //   return;
    // }

    return true;
  };

  const returnUnPostedAmount = (claims, insuranceData) => {
    let processClaims = [...claims];

    // Filter out already posted claims on submit
    if (alreadyPostedClaim && alreadyPostedClaim.length > 0) {
      processClaims = processClaims.filter((f) => alreadyPostedClaim.findIndex((a) => f.id === a.claimID) === -1);
    }

    const totalSubmitAmount = processClaims.reduce((total, claim) => total + parseFloat(claim.total_paid || 0), 0);

    const unpostedAmount = parseFloat(
      insuranceData.id ? insuranceData.unposted_amount : insuranceData.check_amount || 0
    );

    return +parseFloat(unpostedAmount - totalSubmitAmount).toFixed(2);
  };

  const handleRemoveClaim = (claim) => {
    setClaims((prev) => prev.filter((f) => f.id !== claim.id));
    const processFilteredClaims = filteredClaims.filter((f) => f.id !== claim.id);
    setFilteredClaims(processFilteredClaims);
    const unposted_amount = returnUnPostedAmount(processFilteredClaims, insuranceData);
    setInsuranceData({ ...insuranceData, unposted_amount, show_unposted_amount: unposted_amount });
  };

  const getInvoiceID = (claim) => {
    if (!CUSTOM_INS.includes(claim.payerid)) return "";
    return claim.toData?.find((f) => f.payerid === claim.payerid)?.invoiceID;
  };

  // for single claim process
  const handleSingleClaimPost = async (singleClaim, isConfirm) => {
    try {
      // check validation for single process payment
      if (!validationCheck([singleClaim], "single")) return;

      const InvoiceID = getInvoiceID(singleClaim);
      if (!isConfirm && InvoiceID) {
        setOpenConfirmation({
          isShow: true,
          actionType: CONFIRMATION_TYPE.INVOICE_UPDATE_SINGLE,
          title: "Invoice Update",
          message: "Do you want to update the associated invoice payment?",
          closeBtn: true,
          singleClaim,
          InvoiceID,
        });
        return;
      }
      setLoading(true);

      // Call API Func for payment Process with single process
      await callAPI([singleClaim]);

      dispatch(setInvoiceDocs([]));
      dispatch(setMessage("Payment Processed Successfully", MESSAGE_MODES.success));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log("Error:-", err.message);
    }
  };

  const callAPI = async (filteredClaims) => {
    const [filteredClaim] = filteredClaims;

    const { bill_npi, bill_name, bill_taxid } = filteredClaim;
    // calculate un posted amount
    const unposted_amount = returnUnPostedAmount(filteredClaims, insuranceData);

    const param = {
      payer_name: insuranceData.payer_name,
      payerid: insuranceData.payerid,
      prov_npi: bill_npi,
      prov_name: bill_name,
      id: insuranceData.id,
      prov_taxid: bill_taxid,
      unposted_amount,
      payment_no: insuranceData.payment_no,
      payment_format: insuranceData.payment_type,
      payment_method: "EOB",
      payment_type: insuranceData.payment_type,
      paid_date: moment(insuranceData.paid_date).format("YYYY-MM-DD"),
      payment_date: moment(insuranceData.paid_date).toISOString(),
      paid_amount: insuranceData.check_amount,
      check_number: insuranceData.check_number,
      files: invoiceDocs,
      clientID: userCompanyID.get(),
      userName: loginUser.name,
      userID: loginUser.sub,
      claims: filteredClaims.map(mapClaim),
    };

    const res = await api.processPaymentAPI({ item: param });

    if (res && res.id) {
      // save eraid for next payment process
      setInsuranceData({ ...insuranceData, id: res.id, unposted_amount });
    }

    if (res.claims && res.claims.length > 0) {
      dispatch(setAlreadyPostedClaim([...(alreadyPostedClaim || []), ...res.claims]));
    }
  };

  const getUnpostedPayments = async (eraid) => {
    if (!eraid || !eraid.trim() || eraid === insuranceData.prev_eraid) return;

    try {
      const res = await api.getUnPosted(eraid);

      if (eraid !== insuranceData.prev_eraid && alreadyPostedClaim.length > 0) {
        //clear that claims posted on Different payment Number
        setClaims((prev) => prev.filter((f) => alreadyPostedClaim.findIndex((p) => p.claimID === f.id) === -1));
        setFilteredClaims((prev) => prev.filter((f) => alreadyPostedClaim.findIndex((p) => p.claimID === f.id) === -1));
      }

      const [responsePay] = res;
      if (responsePay) {
        const {
          check_number,
          eraid,
          id,
          paid_amount,
          paid_date,
          payer_name,
          payment_format,
          payment_type,
          unposted_amount,
          payment_method,
          claims: resClaims,
        } = responsePay;

        setInsuranceData({
          ...insuranceData,
          check_number,
          payment_no: eraid,
          prev_eraid: eraid,
          id,
          check_amount: paid_amount,
          paid_date,
          payer_name,
          payment_format,
          payment_type,
          unposted_amount,
          show_unposted_amount: unposted_amount,
          payment_method,
        });

        const filteredProcessClaims = resClaims.filter(
          (f) => filteredClaims.findIndex((cl) => cl.id === f.claimID) === -1
        );
        dispatch(setAlreadyPostedClaim([...filteredProcessClaims]));
      } else {
        setInsuranceData({
          ...insuranceData,
          id: "",
          prev_eraid: eraid,
          unposted_amount: 0,
          show_unposted_amount: 0,
        });

        dispatch(setAlreadyPostedClaim([]));
      }
    } catch (err) {
      console.log("Error:-", err);
    }
  };

  const handleSave = async (filteredClaims, isConfirm) => {
    try {
      // check Validation for all payment process
      let processClaims = [...filteredClaims];

      // Filter out already posted claims on submit
      if (alreadyPostedClaim && alreadyPostedClaim.length > 0) {
        processClaims = processClaims.filter((f) => alreadyPostedClaim.findIndex((a) => f.id === a.claimID) === -1);
      }

      if (processClaims.length === 0) {
        handleClose();
        LSProcessPaymentData.clear();
        dispatch(setAlreadyPostedClaim([]));
        dispatch(setMessage("Processed Successfully", MESSAGE_MODES.success));
        return;
      }

      if (!validationCheck(processClaims)) return;

      const customPayerClaims = filteredClaims.filter(
        (f) =>
          CUSTOM_INS.includes(f.payerid) &&
          f.toData &&
          f.toData.findIndex((i) => i.payerid === f.payerid && i.invoiceNo) !== -1
      );

      if (customPayerClaims.length > 0 && !isConfirm) {
        setOpenConfirmation({
          isShow: true,
          actionType: CONFIRMATION_TYPE.INVOICE_UPDATE,
          title: "Invoice Update",
          message: `${customPayerClaims.length} Claim(s) ${
            customPayerClaims.length > 1 ? "have" : "has"
          } sent invoice. Do you want to update the associated invoice payment?`,
          closeBtn: true,
          filteredClaims,
          customPayerClaims: customPayerClaims.map((m) => m.id),
        });
        return;
      }

      setLoading(true);

      // Call API Func for payment Process with all process
      await callAPI(processClaims);

      dispatch(setInvoiceDocs([]));
      dispatch(setMessage("Payment Processed Successfully", MESSAGE_MODES.success));
      setLoading(false);
      handleClose();
      LSProcessPaymentData.clear();
    } catch (error) {
      console.error(error);
      setLoading(false);
      dispatch(setMessage("Processing Failed!", MESSAGE_MODES.error));
    }
  };

  const mapClaim = (m) => {
    const realClaim = claims.find((f) => m.id === f.id && f.paid_insurance);
    const isBillAndSubmitSec = CUSTOM_INS.includes(m.payerid) && m.paid_insurance;

    const mapProcedureForBill = (p) => {
      const realProc = realClaim.proc_array.find((f) => f.id === p.id);
      return {
        ...p,
        allowed: p.patient_amount,
        paid: p.patient_amount,
        open: parseFloat(realProc?.open || 0) - parseFloat(p.patient_amount || 0) - parseFloat(p.write_off || 0),
        deductible: 0,
        copay: 0,
        co_insurance: 0,
        from_dos: moment(p.from_date).format("YYYYMMDD"),
        chgid: p.remote_chgid,
      };
    };

    const customProcArray = m.proc_array.map(isBillAndSubmitSec ? mapProcedureForBill : mapProcedure);
    const total = totalOfAmounts(customProcArray);

    return {
      employeeID: m.employeeID,
      pcn: m.pcn,
      status_code: parseInt(m.status_code || 0) || 1,
      payer_icn: m.claimNo,
      pat_name_f: m.pat_name_f,
      pat_name_l: m.pat_name_l,
      pat_name_m: m.pat_name_m,
      ins_number: m.ins_number,
      payer_name: m.payer_name,
      invoiceID: m.invoiceID,
      payerid: m.payerid,
      status: m.status,
      place_of_service: 81,
      total_allowed: parseFloat(total.total_allowed || 0) + parseFloat(realClaim?.total_allowed || 0),
      total_copay: total.total_copay,
      total_deductible: total.total_deductible,
      total_co_insurance: total.total_co_insurance,
      total_write_off: total.total_write_off,
      total_patient_amount: total.total_patient_amount,
      patient_responsibility: total.total_deductible + total.total_co_insurance + total.total_copay,
      total_paid: parseFloat(m.total_paid) + parseFloat(realClaim?.total_paid || 0),
      total_charge: m.total_charge,
      message: "",
      claimID: m.id,
      claim_received_date: m.claim_received_date,
      lab_type: "BRM",
      from_dos: moment(m.from_date_1).format("YYYYMMDD"),
      proc_array: customProcArray,
      ...(realClaim && {
        primary_proc_array: realClaim.proc_array.map((pr) => {
          const proc = customProcArray.find((f) => f.id === pr.id);

          return {
            ...pr,
            allowed: parseFloat(pr.allowed || 0) + parseFloat(proc?.allowed || 0),
            deductible: parseFloat(pr.deductible || 0) + parseFloat(proc?.deductible || 0),
            copay: parseFloat(pr.copay || 0) + parseFloat(proc?.copay || 0),
            co_insurance: parseFloat(pr.co_insurance || 0) + parseFloat(proc?.co_insurance || 0),
            write_off: parseFloat(pr.write_off || 0) + parseFloat(proc?.write_off || 0),
            paid: parseFloat(pr.paid || 0) + parseFloat(proc?.paid || 0),
            open: proc?.open ?? pr.open,
            from_dos: moment(pr.from_date).format("YYYYMMDD"),
            chgid: pr.remote_chgid,
          };
        }),
      }),
    };
  };

  const mapProcedure = (p) => ({
    ...p,
    from_dos: moment(p.from_date).format("YYYYMMDD"),
    chgid: p.remote_chgid,
  });

  const handleConfirm = async (isConfirm, type, note) => {
    setOpenConfirmation({ isShow: false });
    if (!isConfirm) {
      switch (type) {
        case CONFIRMATION_TYPE.DRAFT_SAVE:
          LSProcessPaymentData.clear();
          dispatch(setAlreadyPostedClaim([]));
          handleClose();
          break;
        case CONFIRMATION_TYPE.INVOICE_UPDATE_SINGLE:
          handleSingleClaimPost({ ...openConfirmation.singleClaim }, true);
          break;
        case CONFIRMATION_TYPE.INVOICE_UPDATE:
          handleSave(openConfirmation.filteredClaims, true);
          break;
      }
      return;
    }
    switch (type) {
      case CONFIRMATION_TYPE.DRAFT_SAVE:
        handleClose();
        break;
      case CONFIRMATION_TYPE.INVOICE_UPDATE_SINGLE:
        handleSingleClaimPost({ ...openConfirmation.singleClaim, invoiceID: openConfirmation.InvoiceID }, true);
        break;
      case CONFIRMATION_TYPE.INVOICE_UPDATE:
        const processClaims = openConfirmation.filteredClaims.map((m) => {
          if (openConfirmation.customPayerClaims.includes(m.id)) {
            const invoiceID = getInvoiceID(m);
            return { ...m, invoiceID };
          }
          return m;
        });
        handleSave(processClaims, true);
        break;
    }
  };

  return (
    <>
      <Modal
        animation={true}
        onHide={() => handleCloseSave()}
        className="seperateModal"
        show={true}
        style={{ paddingLeft: "0" }}
        centered
        size={"2x2"}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            {title ? title : " "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingTop: 0,
          }}
        >
          <div className="px-3">
            <Row>
              <Col md={12}>
                <div className="testAdmin createClientsWrapper">
                  <div className={insuranceData.payment_type ? "crew-profile-col7" : "crew-profile-same"}>
                    <InputField
                      type="dropDown"
                      groupWrapper="form-group-wrapper"
                      labelStyle="mt-0 modalLineHeaders text-capitalize"
                      label="Payment Type"
                      options={PAYMENT_TYPES}
                      value={
                        insuranceData.payment_type
                          ? PAYMENT_TYPES.find((f) => f.value === insuranceData.payment_type)
                          : null
                      }
                      index="insuranceCompany"
                      placeholder="Select Payment Type"
                      handleChange={(e) => {
                        if (!e.disabled) setInsuranceData({ ...insuranceData, payment_type: e.value });
                      }}
                      required
                    />

                    <InputField
                      type="datePicker"
                      inputStyle="w-100 orderTestDob"
                      labelStyle="modalLineHeaders mt-0 text-capitalize"
                      label="Payment/EOB Date"
                      value={
                        insuranceData.paid_date && new Date(insuranceData.paid_date) != "Invalid Date"
                          ? moment(insuranceData.paid_date, "YYYY-MM-DD").toDate()
                          : null
                      }
                      index="paid_date"
                      placeholder="Payment/EOB Date"
                      handleChange={(e) =>
                        setInsuranceData({
                          ...insuranceData,
                          paid_date:
                            moment(e).format("YYYY-MM-DD") != "Invalid date" ? moment(e).format("YYYY-MM-DD") : "",
                        })
                      }
                      required
                    />

                    <InputField
                      type="text"
                      groupWrapper="form-group-wrapper me-2"
                      inputStyle="modalInput"
                      labelStyle="mt-0 modalLineHeaders text-capitalize"
                      label="Payment/EOB No"
                      value={insuranceData.payment_no}
                      onBlur={(e) => getUnpostedPayments(e.target.value)}
                      index="payment_no"
                      maxLength={50}
                      placeholder="Enter Payment/EOB No"
                      handleChange={(e) => {
                        setInsuranceData({ ...insuranceData, payment_no: e.target.value.toUpperCase(), id: "" });
                      }}
                      required
                    />
                    {insuranceData.payment_type && (
                      <InputField
                        type="text"
                        groupWrapper="form-group-wrapper me-2"
                        inputStyle="modalInput"
                        labelStyle="mt-0 modalLineHeaders text-capitalize"
                        label="Check/Reference No"
                        value={insuranceData.check_number}
                        index="check_number"
                        maxLength={50}
                        placeholder={
                          IS_PAYMENT_TYPE.credit === insuranceData.payment_type
                            ? "Enter Credit Number (XXXX-0000)"
                            : `Enter Check/Reference No`
                        }
                        handleChange={(e) => {
                          setInsuranceData({ ...insuranceData, check_number: e.target.value.toUpperCase() });
                        }}
                        required
                      />
                    )}

                    <InputField
                      type="number"
                      groupWrapper="form-group-wrapper me-2"
                      inputStyle="modalInput"
                      labelStyle="mt-0 modalLineHeaders text-capitalize"
                      label="Amount Received"
                      value={insuranceData.check_amount}
                      index="check_amount"
                      maxLength={50}
                      placeholder="Enter Amount"
                      handleChange={(e) => {
                        setInsuranceData((prev) => {
                          const obj = {
                            ...insuranceData,
                            check_amount: e.target.value,
                          };
                          const unpostedAmount = returnUnPostedAmount(filteredClaims, obj);
                          Object.assign(obj, { show_unposted_amount: unpostedAmount });

                          return obj;
                        });
                      }}
                      required
                    />

                    <InputField
                      type="number"
                      groupWrapper="form-group-wrapper me-2"
                      inputStyle="modalInput"
                      labelStyle="mt-0 modalLineHeaders text-capitalize"
                      label="UnPosted Amount"
                      value={insuranceData.show_unposted_amount}
                      index="show_unposted_amount"
                      maxLength={50}
                      placeholder="0"
                      handleChange={(e) => {
                        setInsuranceData({ ...insuranceData, show_unposted_amount: e.target.value });
                      }}
                      readOnly={true}
                    />

                    <div className="d-flex justify-content-center align-items-center">
                      <Button
                        variant="secondary"
                        className="modalButtons headerButton btn-fill w-75"
                        onClick={() => setOpenFileModal(true)}
                      >
                        Add Files ({invoiceDocs.length})
                      </Button>
                      <span></span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="d-flex align-items-center justify-content-between px-3">
            <h4 className="text-decoration-underline text-black mt-0 section-title">Claim Details:</h4>
            <div className="d-flex align-items-center justify-content-between px-3 w-75">
              <InputField
                type="dropDown"
                labelStyle="text-capitalize"
                inputStyle="w-70"
                groupWrapper="w-50 d-flex justify-content-around align-items-center mt-3"
                label="Billed Insurance: "
                options={INSURANCE_PROVIDER}
                value={insuranceData.payerid ? INSURANCE_PROVIDER.find((f) => f.value === insuranceData.payerid) : null}
                index="payer_name"
                placeholder="Select Insurance"
                handleChange={(e) => {
                  setInsuranceData({
                    ...insuranceData,
                    payerid: e.value,
                    payer_name: e.label,
                  });
                }}
              />
              <InputCPTCodeDropDown
                type="text"
                inputStyle="modalInput"
                labelStyle="text-capitalize"
                label="Search Claim:"
                groupWrapper="w-50 d-flex justify-content-center align-items-center mt-3 form-group-wrapper1"
                placeholder="Search Claim"
                index={"payer_name"}
                optionList={[]}
                value={
                  patientSearch?.pat_name_f
                    ? `${patientSearch?.pat_name_f} ${patientSearch?.pat_name_l && patientSearch?.pat_name_l}`
                    : ""
                }
                handleChange={selectClaimFromSearch}
                capsCase={true}
                searchtype="claims"
              />
            </div>
          </div>

          <ClaimInfoModal
            claims={filteredClaims}
            realClaims={claims}
            handleClaimEdit={handleClaimEdit}
            handleChargeEdit={handleChargeEdit}
            setClaims={setFilteredClaims}
            setCalculationError={setCalculationError}
            insuranceData={insuranceData}
            claimError={claimError}
            handleSingleClaimPost={handleSingleClaimPost}
            handleRemoveClaim={handleRemoveClaim}
          />
        </Modal.Body>
        {openConfirmation.isShow && (
          <ConfirmationModal
            show={openConfirmation.isShow}
            actionType={openConfirmation.actionType}
            title={openConfirmation.title}
            message={openConfirmation.message}
            note={openConfirmation.note}
            handleConfirm={handleConfirm}
            handleClose={() => setOpenConfirmation({ isShow: false })}
            closeBtn={openConfirmation.closeBtn}
            cssClass="seperateModal"
          />
        )}
        {openFileModal && <InvoiceDocumentModal handleClose={() => setOpenFileModal(false)} cssClass="seperateModal" />}
        <div className="px-4 my-2" style={{ height: "2vh" }}>
          {/* {errorMessage && <ErrorMessage error={errorMessage} handleChange={() => setErrorMessage("")} />} */}
          {errorMessage && <ShowAlertMessage message={errorMessage} error handleClose={() => setErrorMessage("")} />}
        </div>
        {loading && <Loader />}
        <Modal.Footer>
          <div className="saveButton">
            <Button
              style={{ marginBottom: 10 }}
              variant="primary"
              className="modalButtons headerButton btn-fill"
              onClick={() => handleCloseSave()}
            >
              Close
            </Button>
            {showFromBarCode && (
              <Button
                style={{ marginBottom: 10 }}
                variant="primary"
                className="modalButtons headerButton btn-fill"
                onClick={loadMoreClaims}
                disabled={disabledLoadNext}
              >
                Load Next
              </Button>
            )}

            <Button
              style={{ marginBottom: 10 }}
              variant="secondary"
              className="modalButtons headerButton btn-fill"
              onClick={() => handleSave(filteredClaims)}
            >
              {"Submit"}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PaymentProcessModal;
