import React, { useState } from "react";
import MainTable from "components/Table/MainTable";
import { Button, Modal } from "react-bootstrap";
import { BULK_CLAIM_PERSONALIZE } from "constants/personalization";
import { useSelector, useDispatch } from "react-redux";
import { bulkClaimList } from "store/features/bulkInvoices/bulkInvoicesSelector";
import { resetClaimList, removeClaimItem } from "store/features/bulkInvoices/bulkInvoicesAction";
import { selectLoginUser } from "store/features/authentication/authenticationSelectors";
import { createBulkInvoiceData } from "utils";
import api from "api";
import { SYSTEM_TIME_ZONE } from "utils";
import { setErrorForSelectedClaims } from "store/features/bulkInvoices/bulkInvoicesAction";
import { setMessage as setAlertMessage } from "store/features/general/generalAction";
import { MESSAGE_MODES } from "constant";
import Loader from "components/Loader/Loader";
import { addSelectedInvoice } from "store/features/invoicePreview/invoicePreviewAction";
import MessageSubjectNoteModel from "components/Modal/MessageSubjectNoteModel";
import { updateClaimMessage } from "store/features/bulkInvoices/bulkInvoicesAction";
import { updateSelectedClaim } from "store/features/bulkInvoices/bulkInvoicesAction";

const BulkInvoices = () => {
  const dispatch = useDispatch();
  const bulkSelectedClaims = useSelector(bulkClaimList);

  const [selectedClaim, setSelectedClaim] = useState(null);
  const loginUser = useSelector(selectLoginUser);

  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    dispatch(resetClaimList());
  };

  const handelRemoveRow = (row) => {
    dispatch(removeClaimItem(row.id));
  };
  const handelViewInvoice = (row) => {
    dispatch(addSelectedInvoice({ selectedInvoice: row, isBulk: true }));
  };

  const handelDisclaimerRow = (row) => {
    if (!row.invoiceAlreadySent) {
      setSelectedClaim(row);
    }
  };

  const renderTd = (item, row) => {
    if (item.itemKey === "action") {
      return (
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }} key={item.id}>
          <td
            className="ellipsis"
            style={{
              textAlign: item.textAlign,
              textOverflow: item.textOverflow,
            }}
            onClick={() => handelRemoveRow(row)}
          >
            <label style={{ color: "red", cursor: "pointer" }}>Remove</label>
          </td>
          <td
            className="ellipsis"
            style={{
              textAlign: item.textAlign,
              textOverflow: item.textOverflow,
            }}
            onClick={() => handelViewInvoice(row)}
          >
            <label style={{ color: "green", cursor: "pointer" }}>View</label>
          </td>
        </div>
      );
    }
    if (item.itemKey === "disclaimer") {
      return (
        <td
          key={item.id}
          className="ellipsis"
          style={{
            textAlign: item.textAlign,
            textOverflow: item.textOverflow,
          }}
          onClick={() => handelDisclaimerRow(row)}
        >
          <label style={{ color: "blue", cursor: "pointer" }}>Edit/View</label>
        </td>
      );
    }
  };

  const sendInvoice = async (loginUser, invoiceData, resendInvoiceData) => {
    setLoading(true);
    try {
      const loginUserData = {
        userID: loginUser?.sub,
        userName: loginUser?.name,
        timezone: SYSTEM_TIME_ZONE,
      };
      const createInvoicePromise =
        invoiceData.length > 0
          ? api
              .createInvoices(invoiceData, loginUserData)
              .then((response) => ({ success: true, data: response.data }))
              .catch((error) => ({ success: false, error }))
          : Promise.resolve({ success: true, data: [] });

      const resendInvoicePromise =
        resendInvoiceData.length > 0
          ? api
              .reSendInvoice(resendInvoiceData, loginUserData)
              .then((response) => ({ success: true, data: response.data }))
              .catch((error) => ({ success: false, error }))
          : Promise.resolve({ success: true, data: [] });

      const [createInvResult, resendInvResult] = await Promise.allSettled([createInvoicePromise, resendInvoicePromise]);

      const resultArr = [...createInvResult.value.data, ...resendInvResult.value.data];

      dispatch(updateSelectedClaim(resultArr));
      dispatch(setErrorForSelectedClaims(resultArr));
      setLoading(false);
      dispatch(setAlertMessage("Processed Successfully!", MESSAGE_MODES.success));
      const anyError = resultArr.some((item) => item.status === false);
      if (!anyError) {
        dispatch(resetClaimList());
      }
    } catch (error) {
      console.log("Error", error);
      setLoading(false);
      dispatch(setAlertMessage("Something Went Wrong!", MESSAGE_MODES.error));
    }
  };

  const handelSendBulkInvoices = async () => {
    const { invoiceData, resendInvoiceData } = createBulkInvoiceData(bulkSelectedClaims);

    await sendInvoice(loginUser, invoiceData, resendInvoiceData);
  };

  const handelSaveFaxMessage = (data) => {
    dispatch(
      updateClaimMessage({
        id: selectedClaim.claimID,
        message: data.note,
        minimumAmount: data.minAmount,
      })
    );
    setSelectedClaim(null);
  };

  return (
    <>
      {selectedClaim !== null && (
        <MessageSubjectNoteModel
          show={selectedClaim !== null}
          handleSave={handelSaveFaxMessage}
          handleClose={() => setSelectedClaim(null)}
          existingData={selectedClaim}
        />
      )}

      <Modal
        show={bulkSelectedClaims.length > 0}
        backdrop="static"
        onHide={handleClose}
        animation={true}
        size={"2xl"}
        scrollable
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0">Bulk Invoices</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading && <Loader />}
          <MainTable
            individualRowCssClass={(row) => row.isError && "isClaimFalse"}
            cssClass={"overFlow-y-hidden"}
            columns={BULK_CLAIM_PERSONALIZE}
            rows={bulkSelectedClaims}
            personalisationKey={"document"}
            widthToSkip={80}
            customColumnCellRenderer={renderTd}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="primary"
            className="modalButtons headerButton btn-fill"
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            style={{ marginBottom: 10 }}
            variant="secondary"
            className="modalButtons headerButton btn-fill"
            onClick={handelSendBulkInvoices}
          >
            Send
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BulkInvoices;
