import { createAsyncThunk } from "@reduxjs/toolkit";
import CONSTANTS from "./generalConstant";
import { showMessage } from "./generalSlice";

const { SHOW_CONFIRM_MODAL } = CONSTANTS;

export const setMessage =
  (text = "", mode = "") =>
  (dispatch) => {
    dispatch(showMessage({ text, mode }));
  };

export const showConfirmationModal = createAsyncThunk(SHOW_CONFIRM_MODAL, (confirmationModalData) => {
  return confirmationModalData;
});
