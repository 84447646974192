import { Button, Card, Col, Modal, Row, Table } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { t } from "stringConstants";
import { draggablePersonalizationLocalStorage, getValidName } from "../../utils";
import { authSelector, selectLoginUser } from "store/features/authentication/authenticationSelectors";
import api from "../../api";
import { documentSelector } from "../../store/features/documents/documentSelectors";
import {
  createDocumentAsync,
  fetchDocuemntAsync,
  setDocumentTypes,
  updateDocumentAsync,
} from "../../store/features/documents/documentSlice";
import FileInput from "components/Input/FileInput";
import ErrorMessage from "components/Message/ErrorMessage";
import EditableDropDown from "components/Dropdown/EditableDropDown";
import { setLoader } from "store/features/general/generalSlice";

const DocumentForm = (props) => {
  const { handleClose, emp, cssClass, document, setting } = props;
  const [newDocument, setNewDocument] = useState(document);
  const [file, setFile] = useState("");
  const [fileType, setFileType] = useState("");
  const [fileSize, setFileSize] = useState("");
  const [selectedFileName, setSelectedFileName] = useState("");
  const [error, setError] = useState("");

  const loginUser = useSelector(selectLoginUser);
  
  const { documentTypes } = useSelector(documentSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!document.isNew) {
      setNewDocument(document);
      setFileType(document.fileType);
      setFileSize(document.fileSize);
      const parts = document.fileName?.split("$");
      const slicedString = parts[parts.length - 1];
      setSelectedFileName(slicedString);
    }
  }, [document]);

  const handleCancel = () => {
    setNewDocument({ isNew: true });
    setFile("");
    setFileType("");
    setSelectedFileName("");
    setFileSize("");
  };

  const validateDocumentForm = (doc) => {
    if (!doc.docType) {
      setError("Document Type is Required");
      return;
    }
    if (!doc.file && newDocument.isNew) {
      setError("Document File is Required.");
      return;
    }

    return true;
  };

  const handleUploadDocument = async () => {
    const doc = { ...newDocument, file, fileType, selectedFileName, fileSize };
    let schrID = emp.schrID;
    dispatch(setLoader(true));
    if (validateDocumentForm(doc)) {
      if (!schrID) {
        schrID = await api.getEmployeeNo("e", setting.id);
      }
      if (doc.isNew) {
        await dispatch(createDocumentAsync({ ...doc, employeeID: emp.id, schrID: schrID }));
      } else {
        await dispatch(updateDocumentAsync({ ...doc, employeeID: emp.id, schrID: schrID }));
      }

      handleClose(schrID);
    }
  };

  return (
    <Modal
      show
      backdrop="static"
      animation={true}
      className={cssClass ?? ""}
      onHide={() => handleClose()}
      centered
      size={"md"}
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          {t("documentDetails")}
        </Modal.Title>{" "}
      </Modal.Header>

      <Modal.Body className="table-full-width desktop-noScroll">
        <div className="row">
          <div className="col-sm-12 createClientsWrapper">
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Document Type: </label>
              <EditableDropDown
                data={documentTypes.map((t) => {
                  return { value: t.id, label: getValidName(t.title) };
                })}
                title="Select Document Type"
                selectedOptionLabel={newDocument.docType}
                onOptionChange={(val) => setNewDocument({ ...newDocument, docType: val.label })}
                addNewItem={(item) => dispatch(setDocumentTypes([...documentTypes, item]))}
              />
              {/* <input
                value={newDocument.docType || ""}
                className="modalInput w-100"
                placeholder="Enter Document Type"
                onChange={(e) =>
                  setNewDocument({
                    ...newDocument,
                    docType: getValidName(e.target.value),
                  })
                }
              /> */}
            </div>
          </div>
          <div className="col-sm-12 createClientsWrapper">
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Document Label: </label>
              <input
                value={newDocument.docLabel || ""}
                className="modalInput w-100"
                placeholder="Enter Document Label"
                onChange={(e) =>
                  setNewDocument({
                    ...newDocument,
                    docLabel: getValidName(e.target.value),
                  })
                }
              />
            </div>
          </div>
          <div className="col-sm-12 createClientsWrapper">
            <div className="form-group-wrapper">
              <h4 className="profile-title">Additional Notes</h4>
              <div className="mb-3">
                <textarea
                  className="form-control"
                  style={{ minHeight: "60px" }} // Set the minimum height
                  value={newDocument?.notes || ""}
                  onChange={(e) =>
                    setNewDocument({
                      ...newDocument,
                      notes: e.target.value,
                    })
                  }
                ></textarea>
              </div>
            </div>
          </div>

          <div className="col-sm-12 createClientsWrapper">
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Document File: </label>
              <FileInput
                setFileType={setFileType}
                setFile={setFile}
                setSelectedFileName={setSelectedFileName}
                selectedFileName={selectedFileName}
                setFileSize={setFileSize}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <div className="px-4 my-2" style={{ height: "2vh" }}>
        {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
      </div>
      <Modal.Footer className="my-2 d-flex justify-content-between">
        <Button variant="primary" className="modalButtons headerButton btn-fill" onClick={() => handleClose()}>
          Cancel
        </Button>
        <Button variant="secondary" className="modalButtons headerButton btn-fill" onClick={handleUploadDocument}>
          {newDocument.isNew ? "Upload" : "Update"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DocumentForm;
