import { INSURANCE_PROVIDER, MESSAGE_MODES } from "constant";
import FileSaver from "file-saver";
import moment from "moment";
import { useDispatch } from "react-redux";
import { setMessage } from "store/features/general/generalAction";
import { formatDate, formatOrderedDate, formatPhoneNumber } from "utils";
import * as XLSX from "xlsx-js-style";
import Icon from "./Icon";

const ExportToExcel = (props) => {
  const { items, selectedItem, title, filter, timeFilter, sortBy, orderTests } = props;
  const dispatch = useDispatch();

  const insuranceProviderFormatter = (insuranceCompany) => {
    if (!insuranceCompany) return "-";
    const item = INSURANCE_PROVIDER.find((i) => i.value === insuranceCompany);
    if (!item) return "-";
    return item?.label;
  };

  const exportData = () => {
    if (!selectedItem) return items;
    return items.map((item) => {
      let obj = {};
      selectedItem.forEach((c) => {
        if (c.itemKey === "isVaccinated") {
          Object.assign(obj, { [c.itemKey]: item[c.itemKey] ? "Yes" : "No" });
        } else if (c.itemKey === "payerId") {
          const val = insuranceProviderFormatter(item[c.itemKey]);
          Object.assign(obj, { [c.itemKey]: val });
        } else if (c.itemKey === "orderDate") {
          const val = formatOrderedDate(item[c.itemKey]);
          Object.assign(obj, { [c.itemKey]: val });
        } else if (c.itemKey === "claim") {
          Object.assign(obj, {
            [c.itemKey]: item[c.itemKey] === "1" ? "✔" : "X",
          });
        } else if (c.itemKey === "phoneNumber") {
          const val = formatPhoneNumber(item[c.itemKey]);
          Object.assign(obj, { [c.itemKey]: val });
        } else if (c.itemKey === "updatedAt") {
          const val = formatDate(item[c.itemKey]);
          Object.assign(obj, { [c.itemKey]: val });
        } else if (c.itemKey === "street" && orderTests) {
          const val = item["details"][c.itemKey];
          Object.assign(obj, { [c.itemKey]: val });
        } else if (c.itemKey === "zip" && orderTests) {
          const val = item["details"][c.itemKey];
          Object.assign(obj, { [c.itemKey]: val });
        } else if (c.itemKey === "eligibilityStatus") {
          const val = item?.eligibilityStatus?.message;
          Object.assign(obj, { [c.itemKey]: val, note: item?.note });
        } else {
          Object.assign(obj, { [c.itemKey]: item[c.itemKey] });
        }
      });
      return obj;
    });
  };
  const handleExportToExcel = () => {
    if (items.length > 0) {
      const data = exportData();
      const colLength = Object.keys(data[0]).length;
      const rowLength = Object.keys(data).length;

      const filters = {
        updatedAt: { data: undefined, label: "Updated At" },
        firstName: { data: filter?.firstName, label: "First Name" },
        lastName: { data: filter?.lastName, label: "Last Name" },
        email: { data: filter?.email, label: "Email" },
        phoneNumber: { data: filter?.phoneNumber, label: "Phone" },
        phone: { data: filter?.phone, label: "Phone Number" },
        street: { data: filter?.street, label: "Address" },
        dob: { data: filter?.dob, label: "Date of Birth" },
        jobTitle: { data: filter?.jobTitle, label: "Job Title" },
        scheduleName: { data: filter?.scheduleName, label: "Schedule" },
        isVaccinated: { data: filter?.isVaccinated, label: "Vaccinated" },
        departmentName: { data: filter?.departmentName, label: "Department" },
        gender: { data: filter?.gender, label: "Gender" },
        region: { data: filter?.region, label: "Region" },
        programName: { data: filter?.programName, label: "Program Name" },
        zoneColor: { data: undefined, label: "Zone Color" },
        location: { data: filter?.location, label: "Office" },
        callTime: { data: filter?.callTime, label: "Call Time" },
        schrID: { data: filter?.schrID, label: "SCHR ID" },
        testingGroup: { data: filter?.testingGroup, label: "Screening Type" },
        onBoardingTesting: { data: undefined, label: "Test Now" },
        qaDone: { data: filter?.qaDone, label: "Q/A" },
        localNo: { data: filter?.localNo, label: "Local #" },
        testDone: { data: filter?.testDone, label: "Test" },
        tests: { data: undefined, label: "Test Type" },
        associated: { data: filter?.associated, label: "Associated" },
        checkIn: { data: filter?.checkIn, label: "Check In" },
        role: { data: filter?.role, label: "Role" },
        status: { data: filter?.status, label: "Status" },
        isSchedule: { data: filter?.isSchedule, label: "Program Status" },
        logDate: { data: undefined, label: "Date" },
        medicalNo: {
          data: filter?.medicalNo,
          label: "Primary Insurance Number",
        },
        medicareNo: { data: filter?.medicareNo, label: "Medicare Number" },
        testOrdered: { data: filter?.testOrdered, label: "Test Ordered" },
        testAvailable: { data: filter?.testAvailable, label: "Test Available" },
        createdAt: { data: undefined, label: "Created At" },
        siteName: { data: undefined, label: "Facility/Agent" },
        orderId: { data: undefined, label: "Order ID" },
        orderStatus: { data: undefined, label: "Order Status" },
        testQty: { data: undefined, label: "Test Quantity" },
        requestedByName: { data: undefined, label: "Request By Name" },
        locationName: { data: filter?.locationName, label: "Location Name" },
        subAgentName: { data: undefined, label: "Sub Agent" },
        street2: { data: filter?.street2, label: "Apt/Unit#" },
        zip: { data: undefined, label: "Zip" },
        state: { data: undefined, label: "State" },
        groupNumber: { data: undefined, label: "Group Number" },
        insuranceCompany: { data: undefined, label: "Primary Insurance" },
        insuranceGroupId: { data: undefined, label: "Group ID" },
        clientName: { data: undefined, label: "Client" },
        orderDate: { data: undefined, label: "Order Date" },
        claimSubmitDate: { data: undefined, label: "Claim Submit Date" },
        claimSubmitDate: { data: undefined, label: "Claim Submit Date" },
        payerId: { data: undefined, label: "Primary Insurance" },
        claimStatus: { data: undefined, label: "Claim Status" },
        claimAmount: { data: undefined, label: "Claim Amount" },
        amountRecieved: { data: undefined, label: "Amount Received" },
        amountRecievedDate: { data: undefined, label: "Amount Received Date" },
        InsuranceStatus: { data: undefined, label: "Insurance Status" },
        trackingNumber: {
          data: filter?.trackingNumber,
          label: "Tracking ID",
        },
        employeeClaimID: { data: filter?.employeeClaimID, label: "Claim ID" },
        pcn: { data: filter?.pcn, label: "LTCS ID" },
        city: { data: undefined, label: "City" },
        claim: { data: undefined, label: "Claim Submitted" },
        note: { data: undefined, label: "Note" },
        eligibilityStatus: { data: filter?.eligibilityStatus?.message, label: "Eligibility Status" },
        Name: { data: undefined, label: "Name" },
        claims: { data: undefined, label: "Claims" },
        claimAmount: { data: undefined, label: "Claim Amount" },
        openAmount: { data: undefined, label: "Open Amount" },
        paid: { data: undefined, label: "Paid" },
        totalAppeal: { data: undefined, label: "Total Appeal" },
        avgTime: { data: undefined, label: "Average Time" },
        lastClaim: { data: undefined, label: "Last Claim" },
        days30: { data: undefined, label: "Days (30)" },
        days60: { data: undefined, label: "Days (60)" },
        days90: { data: undefined, label: "Days (90)" },
        older: { data: undefined, label: "Older" },
        shortName: { data: filter?.shortName, label: "Alias" },
        fax: { data: filter?.fax, label: "Fax" },
        from_date_1: { data: filter?.fax, label: "DOS" },
        reSubmissionDate: { data: filter?.fax, label: "Claim Date" },
        pat_name_f: { data: filter?.pat_name_f, label: "First Name" },
        pat_email: { data: filter?.pat_email, label: "Email" },
        pat_name_l: { data: filter?.pat_name_l, label: "Last Name" },
        pat_phone: { data: filter?.pat_phone, label: "Phone" },
        pat_addr_1: { data: filter?.pat_addr_1, label: "Address" },
        pat_addr_2: { data: filter?.pat_addr_2, label: "Room #" },
        pat_state: { data: filter?.pat_state, label: "State" },
        pat_city: { data: filter?.pat_city, label: "City" },
        pat_zip: { data: filter?.pat_zip, label: "Zip" },
        pat_sex: { data: filter?.pat_sex, label: "Gender" },
        payer_name: { data: filter?.payer_name, label: "Insurance" },
        ins_number: { data: filter?.ins_number, label: "Insurance Number" },
        paid_ins_processed: { data: undefined, label: "Paid By" },
        claimNo: { data: filter?.claimNo, label: "Claim ID" },
        total_charge: { data: filter?.total_charge, label: "Claim Amount" },
        paidAmount: { data: filter?.paidAmount, label: "Paid Amount" },
        status: { data: filter?.status, label: "Status" },
        remote_claimid: { data: filter?.remote_claimid, label: "B-ID" },
        submittedByName: { data: filter?.submittedByName, label: "Processed By" },
        eraIds: { data: filter?.eraIds, label: "ERA No" },
        paymentCheckNo: { data: undefined, label: "Cheque No" },
        checkDate: { data: undefined, label: "Paid Date" },
        pat_dob: { data: filter?.pat_dob, label: "DOB" },
        adjustedAmount: { data: filter?.pat_dob, label: "Adjusted Amount" },
        note: { data: filter?.note, label: "Note" },
        accNo: { data: filter?.accNo, label: "Acc. No" },
        invoiceAmount: { data: undefined, label: "Invoice Amount" },
        discount: { data: undefined, label: "Discount" },
        paymentMethod: { data: undefined, label: "Payment Method" },
        invoiceNo: { data: undefined, label: "Invoice ID" },
        postGridStatus: { data: undefined, label: "Sent" },
        sendTo: { data: undefined, label: "Sent To" },
        dueDate: { data: undefined, label: "Due Date" },
        invoiceDate: { data: undefined, label: "Invoice Date" },
      };
      const arr = Object.keys(data[0]).map((c) => filters[c]?.label || "");

      const columnsWidth = [];
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] === "email" || arr[i] === "testingGroup") {
          columnsWidth.push({ wch: 18 });
          continue;
        }
        if (arr[i] === "street" || arr[i] === "isSchedule" || arr[i] === "dob" || arr[i] === "phoneNumber") {
          columnsWidth.push({ wch: 15 });
          continue;
        }
        if (arr[i] === "scheduleName" || arr[i] === "departmentName") {
          columnsWidth.push({ wch: 13 });
          continue;
        }
        if (arr[i] === "SCREENING TYPE" || arr[i] === "programName") {
          columnsWidth.push({ wch: 16 });
          continue;
        }
        columnsWidth.push({ wch: 12 });
      }

      function gapFilteredVal(filters) {
        var str = "";
        for (const [key, value] of Object.entries(filters)) {
          if (key === "isVaccinated" && value.data !== undefined && value.data.length > 0) {
            const val = value.data === "1" ? "Yes" : "No";
            str += `${value.label} - ${val}, `;
            continue;
          }
          if (key === "testDone" && value.data !== undefined && value.data.length > 0) {
            const val = value.data === "1" ? "✔" : value.data === "X" ? "X" : "Empty";
            str += `${value.label} - ${val}, `;
            continue;
          }
          if (key === "qaDone" && value.data !== undefined && value.data.length > 0) {
            const val = value.data === "1" || value.data === "M" ? "✔" : value.data === "X" ? "X" : "Empty";
            str += `${value.label} - ${val}, `;
            continue;
          }
          if (key === "isSchedule" && value.data !== undefined && value.data.length > 0) {
            const val = value.data == "1" ? "✔" : value.data == "2" ? "⚠" : "Empty";
            str += `${value.label} - ${val}, `;
            continue;
          }
          if (key === "checkIn" && value.data !== undefined && Object.keys(value.data).length > 0) {
            const val = value.data == "1" ? "✔" : "Empty";
            // const { startDate, endDate } = value.data;
            // const val = `${moment(startDate._d).format("MMM DD, YYYY")} to ${moment(endDate._d).format(
            //   "MMM DD, YYYY"
            // )}`;
            str += `${value.label} - ${val}, `;
            continue;
          }
          if (value.data !== undefined && value.data.length > 0) {
            str += `${value.label} - ${value.data}, `;
          }
        }
        return str;
      }
      // Function for gap in excel row skip rows
      // function create_gap_rows(ws, nrows) {
      //   var ref = XLSX.utils.decode_range(ws["!ref"]); // get original range
      //   ref.e.r += nrows; // add to ending row
      //   ws["!ref"] = XLSX.utils.encode_range(ref); // reassign row
      // }
      const sortedVal = filters[sortBy];
      var wsrows = [
        { hpx: 80, level: 1 }, // "points"
      ];
      const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: colLength - 1 } }];

      const fileName = `brm_claim.xlsx`;
      const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let dateRange = "";
      if (timeFilter) {
        const { start, end } = timeFilter;
        dateRange = `Date Range: ${start ? moment(start._id).format("MMM DD, YYYY") : "-"} to ${
          end ? moment(end._id).format("MMM DD, YYYY") : "-"
        }`;
      }

      const ws = XLSX.utils.aoa_to_sheet([arr]);
      XLSX.utils.sheet_add_json(ws, data, { skipHeader: true, origin: -1 });

      let columnMaxWidths = {};

      // get all header name from data obejct
      const headers = Object.keys(data[0]);

      // set the max number of character length against each column data
      data.forEach((item) => {
        headers.forEach((header) => {
          const cellValue = item[header];
          const headerLength = String(header).length;
          const cellContentLength = String(cellValue).length;
          columnMaxWidths[header] = Math.max(headerLength, cellContentLength, columnMaxWidths[header] || 0);
        });
      });

      // now push the column character width as per the max character length of each column data
      const columnWidths = [];

      headers.forEach((header) => {
        let val = parseInt(columnMaxWidths[header]);
        if (header === "emailSend") {
          val = 15;
        }
        columnWidths.push({ wch: val });
      });

      //ws["!rows"] = wsrows;
      // ws["!merges"] = merge;
      ws["!cols"] = columnWidths;

      for (let i = 65; i < colLength + 65; i++) {
        ws[`${String.fromCharCode(i)}1`].s = {
          alignment: {
            horizontal: "center",
          },
          font: {
            name: "Calibri",
            sz: 12,
            bold: true,
            underline: true,
            color: { rgb: "#000" },
          },
        };
      }
      // for (let i = 65; i < colLength + 65; i++) {
      //   for(let j=0; j<rowLength; j++){
      //   ws[`${String.fromCharCode(i)}${j+3}`].s = {
      //     alignment: {
      //       horizontal: "center",
      //       wrapText: true
      //     },
      //   };
      // }
      // }
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const fileContent = new Blob([excelBuffer], { type: fileType });
      try {
        FileSaver.saveAs(fileContent, fileName);
        dispatch(setMessage("Excel file exported successfully", MESSAGE_MODES.success));
      } catch (err) {
        dispatch(setMessage("file can't be exported", MESSAGE_MODES.error));
      }
    }
  };

  return <Icon handleClick={handleExportToExcel} title={"Export to Excel"} label="Export" iconType={"XLSIcon"} />;
};

export default ExportToExcel;
