import { getReportsData } from "api";
import InputCPTCodeDropDown from "components/InputField/InputCPTCodeDropDown";
import InputField from "components/InputField/InputField";
import ErrorMessage from "components/Message/ErrorMessage";
import RadioButtonOptions from "components/RadioButton/RadioButtonOptions";
import SwitchToggle from "components/SwitchToggle/SwitchToggle";
import { CONFIG } from "constant";
import {
  OPEN_STATUS_REPORT_AMOUNT_OPTIONS,
  REPORT_CLAIM_STATUS_OPTIONS,
  CLAIM_STATUS_REPORT,
  REPORT_TYPES_OPTIONS,
  BILLING_ENTITY_REPORT,
  CLAIM_STATUS_REPORT_DATA,
  SORTING_REPORT,
  BILL_TYPE,
  REPORT_NAME,
  PAYMENT_TYPE_REPORT,
} from "constant";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { locationsSelectors } from "store/features/locations/locationsSelectors";
import { fetchSaleFilter } from "store/features/salePerson/salePersonAction";
import { saleReportFilterSelector } from "store/features/salePerson/salePersonSelector";

import { downloadFileFromS3 } from "utils";
import {
  ReactSelectCustomContainer,
  Option,
  customStyles,
} from "components/CustomReactSelect/ReactSelectCustomOptionContainer";
import { MESSAGE_MODES, INSURANCE_LIST } from "constant";
import { customIsEmpty, customUniqBy, customCompact } from "util/customLodash";
import { setMessage } from "store/features/general/generalAction";
import Loader from "components/Loader/Loader";
import Badge from "components/Badge";

const DEFAULT_VALUES = {
  reportType: "",
  startDateService: null,
  endDateService: null,
  startDateClaim: null,
  endDateClaim: null,
  startDatePayment: null,
  endDatePayment: null,
  billingEntity: [],
  paymentType: [],
  clients: [],
  salesRepresentatives: [],
  claimStatus: [],
  includeDetails: false,
  amountType: "",
  selectedInsurance: [],
  billType: [],
  isDOS: false,
  sortBy: "",
  sortOrder: false,
  cptCodes: [],
  includeCpt: true,
};

const ALL_OPTION = { label: "All", value: "all" };

const ReportFilterModal = (props) => {
  const { handleClose, saveFilter } = props;

  const [item, setItem] = useState(DEFAULT_VALUES);

  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const saleReportFilter = useSelector(saleReportFilterSelector);

  const [loading, setLoading] = useState(false);

  const [clientsDropdownOptions, setClientsDropdownOptions] = useState([]);

  useEffect(() => {
    dispatch(fetchSaleFilter({ noShowLoader: true }));
  }, []);

  const salesPersonDropdownOptions = useMemo(() => {
    const salePersons = saleReportFilter.salePersons;
    if (salePersons.length === 0) return [];
    return [ALL_OPTION, ...salePersons];
  }, [saleReportFilter]);

  const getClientDropDownList = () => {
    const saleRep = item.salesRepresentatives;
    if (!customIsEmpty(saleRep)) {
      const clients = customCompact(
        customUniqBy(
          saleRep.flatMap((item) => item.client_data),
          "value"
        )
      );
      setClientsDropdownOptions([ALL_OPTION, ...clients]);
    } else {
      setClientsDropdownOptions([]);
    }
  };

  useEffect(() => {
    getClientDropDownList();
  }, [item.salesRepresentatives]);

  const formatArrayParams = (items) =>
    items?.reduce((acc, item) => {
      const val = item?.value || item;
      if (val !== "all") {
        acc.push(val);
      }
      return acc;
    }, []);

  const handleFilter = async () => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (customIsEmpty(item.reportType)) return;

    const {
      reportType,
      startDateService,
      endDateService,
      startDateClaim,
      endDateClaim,
      startDatePayment,
      endDatePayment,
      billingEntity,
      clients,
      salesRepresentatives,
      claimStatus,
      amountType,
      selectedInsurance,
      billType,
      paymentType,
      isDOS,
      sortBy,
      sortOrder,
      cptCodes,
      includeCpt,
    } = item;

    const selectedStatus = [...claimStatus];

    if (selectedStatus.includes("sent")) selectedStatus.push("submitted");
    if (selectedStatus.includes("paid")) selectedStatus.push("partial");

    try {
      const filterParams = {
        params: {
          startDateService,
          endDateService,
          startDateClaim,
          endDateClaim,
          startDatePayment,
          endDatePayment,
          billingEntity: formatArrayParams(billingEntity),
          claimStatus: formatArrayParams(selectedStatus),
          clients: formatArrayParams(clients),
          selectedInsurance,
          billType: formatArrayParams(billType),
          salesRepresentatives: formatArrayParams(salesRepresentatives),
          paymentType: formatArrayParams(paymentType),
          reportType,
          amountType: customIsEmpty(amountType) ? "billed" : amountType,
          isDOS,
          timeZone,
          sortBy,
          sortOrder: sortOrder ? "ASC" : "DESC",
          cptCodes,
          includeCpt,
          ...(reportType === "daily" && { currentDate: moment().format("YYYY-MM-DD") }),
        },
      };

      setLoading(true);

      const result = await getReportsData(filterParams);
      // console.log("result",result)
      if (result.fileName) {
        const downlodedFileMonth = moment().format("DDMMYY");
        const fullFileName = `${REPORT_NAME[item.reportType]} ${downlodedFileMonth}`;

        await downloadFileFromS3(result.fileName, `${fullFileName}.xlsx`, CONFIG.eligibilityBucket);
        setLoading(false);
        dispatch(setMessage("Excel file export successfully !!", MESSAGE_MODES.success));

        return;
      }
      dispatch(setMessage("No record found.", MESSAGE_MODES.error));
    } catch (error) {
      setLoading(false);
      console.log("handleFilter Error", error);
      dispatch(setMessage("No record found", MESSAGE_MODES.error));
    }
  };

  const handelBillTypeChange = (selected) => {
    const isAllSelected = selected.some((option) => option.value === "all");
    const isAllJustDeselected = item.billType.find((option) => option.value === "all") && !isAllSelected;

    if (isAllSelected) {
      setItem({ ...item, billType: BILL_TYPE });
    } else if (isAllJustDeselected) {
      setItem({ ...item, billType: [] });
    } else {
      setItem({ ...item, billType: selected });
    }
  };

  const handelInsuranceChange = (selected) => {
    const isAllSelected = selected.some((option) => option.value === "all");
    const isAllJustDeselected = item.selectedInsurance.find((option) => option.value === "all") && !isAllSelected;

    if (isAllSelected) {
      setItem({ ...item, selectedInsurance: [ALL_OPTION, ...saleReportFilter.insurances] });
    } else if (isAllJustDeselected) {
      setItem({ ...item, selectedInsurance: [] });
    } else {
      setItem({ ...item, selectedInsurance: selected });
    }
  };
  const handelPaymentTypeChange = (selected) => {
    const isAllSelected = selected.some((option) => option.value === "all");
    const isAllJustDeselected = item.paymentType.find((option) => option.value === "all") && !isAllSelected;

    if (isAllSelected) {
      setItem({ ...item, paymentType: PAYMENT_TYPE_REPORT });
    } else if (isAllJustDeselected) {
      setItem({ ...item, paymentType: [] });
    } else {
      setItem({ ...item, paymentType: selected });
    }
  };

  const handelClaimStatusChange = (selected) => {
    const isAllSelected = selected.some((option) => option.value === "all");
    const isAllJustDeselected = item.claimStatus.find((option) => option.value === "all") && !isAllSelected;
    if (isAllSelected) {
      setItem({ ...item, claimStatus: CLAIM_STATUS_REPORT });
    } else if (isAllJustDeselected) {
      setItem({ ...item, claimStatus: [] });
    } else {
      setItem({ ...item, claimStatus: selected });
    }
  };

  const handleCptCode = (opt) => {
    if (opt) {
      setItem({ ...item, cptCodes: [...item.cptCodes, opt.code] });
    }
  };
  const handelRemoveCode = (codeToRemove) => {
    const cptCodes = item.cptCodes.filter((code) => code !== codeToRemove);
    setItem({ ...item, cptCodes: cptCodes });
  };

  const handelChangeSalePerson = (selected) => {
    const isAllSelected = selected.some((option) => option.value === "all");
    const isAllJustDeselected = item.salesRepresentatives.find((option) => option.value === "all") && !isAllSelected;

    if (isAllSelected) {
      setItem({ ...item, salesRepresentatives: salesPersonDropdownOptions });
    } else if (isAllJustDeselected) {
      setItem({ ...item, salesRepresentatives: [] });
    } else {
      setItem({ ...item, salesRepresentatives: selected });
    }
  };
  const handelClientsSalePerson = (selected) => {
    const isAllSelected = selected.some((option) => option.value === "all");
    const isAllJustDeselected = item.clients.find((option) => option.value === "all") && !isAllSelected;

    if (isAllSelected) {
      setItem({ ...item, clients: clientsDropdownOptions });
    } else if (isAllJustDeselected) {
      setItem({ ...item, clients: [] });
    } else {
      setItem({ ...item, clients: selected });
    }
  };

  return (
    <Modal
      show
      backdrop="static"
      animation={true}
      centered
      size={"lg"}
      onHide={() => {
        handleClose();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          Sales Report Settings
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0 }}>
        <RadioButtonOptions
          label={"Report Type:"}
          options={REPORT_TYPES_OPTIONS}
          name={`REPORT_TYPES_OPTIONS`}
          checkedVal={item?.reportType}
          handleChange={(e, obj) => e.target.checked && setItem({ ...item, isDOS: false, reportType: obj.value })}
        />
        <div className="createClientsWrapper">
          <div className="leftSide">
            <div className="row mb-3">
              <InputField
                type="datePicker"
                inputStyle="w-100 orderTestDob"
                labelStyle="modalLineHeaders mt-0 text-capitalize"
                label="Date Of Service Start"
                value={
                  item.startDateService && new Date(item.startDateService) != "Invalid Date"
                    ? moment(item.startDateService, "YYYY-MM-DD").toDate()
                    : null
                }
                index="startDateService"
                placeholder="Service Start Date"
                handleChange={(e) =>
                  setItem({
                    ...item,
                    startDateService:
                      moment(e).format("YYYY-MM-DD") != "Invalid date" ? moment(e).format("YYYY-MM-DD") : "",
                  })
                }
                minDate={new Date("1900-01-01")}
                maxDate={new Date()}
              />

              <InputField
                type="datePicker"
                inputStyle="w-100 orderTestDob"
                labelStyle="modalLineHeaders mt-0 text-capitalize"
                label="Date of Claim Start"
                value={
                  item.startDateClaim && new Date(item.startDateClaim) != "Invalid Date"
                    ? moment(item.startDateClaim, "YYYY-MM-DD").toDate()
                    : null
                }
                index="startDateClaim"
                placeholder="Date Of Claim Start"
                handleChange={(e) =>
                  setItem({
                    ...item,
                    startDateClaim:
                      moment(e).format("YYYY-MM-DD") != "Invalid date" ? moment(e).format("YYYY-MM-DD") : "",
                  })
                }
                minDate={new Date("1900-01-01")}
                maxDate={new Date()}
              />

              <InputField
                type="datePicker"
                inputStyle="w-100 orderTestDob"
                labelStyle="modalLineHeaders mt-0 text-capitalize"
                label="Date Of Payment Start"
                value={
                  item.startDatePayment && new Date(item.startDatePayment) != "Invalid Date"
                    ? moment(item.startDatePayment, "YYYY-MM-DD").toDate()
                    : null
                }
                index="startDatePayment"
                placeholder="Payment Start Date"
                handleChange={(e) =>
                  setItem({
                    ...item,
                    startDatePayment:
                      moment(e).format("YYYY-MM-DD") != "Invalid date" ? moment(e).format("YYYY-MM-DD") : "",
                  })
                }
                minDate={new Date("1900-01-01")}
                maxDate={new Date()}
              />

              <Form.Group className="form-group-wrapper">
                <label className="modalLineHeaders mt-0 text-capitalize">Billing Entity</label>
                <Select
                  options={BILLING_ENTITY_REPORT}
                  className="w-100"
                  menuPlacement="auto"
                  autoComplete="none"
                  placeholder=""
                  isMulti
                  hideSelectedOptions={false}
                  value={item?.billingEntity}
                  onChange={(e) => setItem({ ...item, billingEntity: e })}
                  components={{ Option, ValueContainer: ReactSelectCustomContainer }}
                  styles={customStyles}
                />
              </Form.Group>

              <Form.Group className="form-group-wrapper">
                <label className="modalLineHeaders mt-0 text-capitalize">Sales Representatives</label>

                <Select
                  options={salesPersonDropdownOptions}
                  className="w-100"
                  menuPlacement="auto"
                  autoComplete="none"
                  placeholder=""
                  isMulti
                  hideSelectedOptions={false}
                  onChange={handelChangeSalePerson}
                  components={{ Option, ValueContainer: ReactSelectCustomContainer }}
                  styles={customStyles}
                  value={item.salesRepresentatives}
                />
              </Form.Group>

              <Form.Group className="form-group-wrapper">
                <label className="modalLineHeaders mt-0 text-capitalize">Clients</label>
                <Select
                  options={clientsDropdownOptions}
                  className="w-100"
                  menuPlacement="auto"
                  autoComplete="none"
                  placeholder=""
                  isMulti
                  hideSelectedOptions={false}
                  onChange={handelClientsSalePerson}
                  components={{ Option, ValueContainer: ReactSelectCustomContainer }}
                  styles={customStyles}
                  value={item.clients}
                />
              </Form.Group>

              <InputCPTCodeDropDown
                type="text"
                inputStyle="modalInput"
                labelStyle=" text-capitalize"
                groupWrapper="mb-0 form-group-wrapper"
                label={"Procedure Codes"}
                placeholder={"Procedure Codes"}
                optionList={[]}
                value={null}
                handleChange={handleCptCode}
                searchtype="cpt"
                isClear={true}
              />
              {item.cptCodes.length > 0 && (
                <div className="d-flex align-items-center justify-content-between profile- detail mt-3">
                  <span
                    style={{
                      fontSize: "14px",
                      marginLeft: "9px",
                    }}
                  >
                    {item.includeCpt ? `Include Cpt:` : `Exclude Cpt:`}
                  </span>
                  <SwitchToggle
                    checked={item.includeCpt}
                    handleChange={(e) =>
                      setItem({
                        ...item,
                        includeCpt: e.target.checked,
                      })
                    }
                  />
                </div>
              )}

              <div className="mt-2">
                {item?.cptCodes.map((cptCode) => {
                  return <Badge label={cptCode} defaultClasses="m-2 p-2" handleClose={handelRemoveCode} />;
                })}
              </div>
            </div>

            {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
          </div>
          <div className="rightSide">
            <InputField
              type="datePicker"
              inputStyle="w-100 orderTestDob"
              labelStyle="modalLineHeaders mt-0 text-capitalize"
              label="Date Of Service End"
              value={
                item.endDateService && new Date(item.endDateService) != "Invalid Date"
                  ? moment(item.endDateService, "YYYY-MM-DD").toDate()
                  : null
              }
              index="endDateService"
              placeholder="Service End Date"
              handleChange={(e) =>
                setItem({
                  ...item,
                  endDateService:
                    moment(e).format("YYYY-MM-DD") != "Invalid date" ? moment(e).format("YYYY-MM-DD") : "",
                })
              }
              minDate={new Date("1900-01-01")}
              maxDate={new Date()}
            />

            <InputField
              type="datePicker"
              inputStyle="w-100 orderTestDob"
              labelStyle="modalLineHeaders mt-0 text-capitalize"
              label="Date Of Claim End"
              value={
                item.endDateClaim && new Date(item.endDateClaim) != "Invalid Date"
                  ? moment(item.endDateClaim, "YYYY-MM-DD").toDate()
                  : null
              }
              index="endDateClaim"
              placeholder="Claim End Date"
              handleChange={(e) =>
                setItem({
                  ...item,
                  endDateClaim: moment(e).format("YYYY-MM-DD") != "Invalid date" ? moment(e).format("YYYY-MM-DD") : "",
                })
              }
              minDate={new Date("1900-01-01")}
              maxDate={new Date()}
            />

            <InputField
              type="datePicker"
              inputStyle="w-100 orderTestDob"
              labelStyle="modalLineHeaders mt-0 text-capitalize"
              label="Date Of Payment End"
              value={
                item.endDatePayment && new Date(item.endDatePayment) != "Invalid Date"
                  ? moment(item.endDatePayment, "YYYY-MM-DD").toDate()
                  : null
              }
              index="endDatePayment"
              placeholder="Payment End Date"
              handleChange={(e) =>
                setItem({
                  ...item,
                  endDatePayment:
                    moment(e).format("YYYY-MM-DD") != "Invalid date" ? moment(e).format("YYYY-MM-DD") : "",
                })
              }
              minDate={new Date("1900-01-01")}
              maxDate={new Date()}
            />

            <Form.Group className="form-group-wrapper">
              <label className="modalLineHeaders mt-0 text-capitalize">Claim Status</label>

              <Select
                options={CLAIM_STATUS_REPORT}
                className="w-100"
                menuPlacement="auto"
                autoComplete="none"
                placeholder=""
                isMulti
                hideSelectedOptions={false}
                onChange={handelClaimStatusChange}
                value={item.claimStatus}
                components={{ Option, ValueContainer: ReactSelectCustomContainer }}
                styles={customStyles}
              />
            </Form.Group>

            <Form.Group className="form-group-wrapper">
              <label className="modalLineHeaders mt-0 text-capitalize">Claim Value Type</label>
              <Select
                options={OPEN_STATUS_REPORT_AMOUNT_OPTIONS}
                className="w-100"
                menuPlacement="auto"
                autoComplete="none"
                value={OPEN_STATUS_REPORT_AMOUNT_OPTIONS.find((opt) => opt.value === item?.amountType)}
                onChange={(e) => setItem({ ...item, amountType: e.value })}
                components={{ Option, ValueContainer: ReactSelectCustomContainer }}
                styles={customStyles}
              />
            </Form.Group>

            <Form.Group className="form-group-wrapper">
              <label className="modalLineHeaders mt-0 text-capitalize">Bill Type:</label>

              <Select
                options={BILL_TYPE}
                className="w-100"
                menuPlacement="auto"
                autoComplete="none"
                placeholder=""
                isMulti
                hideSelectedOptions={false}
                onChange={handelBillTypeChange}
                components={{ Option, ValueContainer: ReactSelectCustomContainer }}
                styles={customStyles}
                value={item.billType}
              />
            </Form.Group>
            {item?.billType.some((obj) => obj.value.toLowerCase().includes("private")) && (
              <Form.Group className="form-group-wrapper">
                <label className="modalLineHeaders mt-0 text-capitalize">Insurances:</label>

                <Select
                  options={[ALL_OPTION, ...saleReportFilter.insurances]}
                  className="w-100"
                  menuPlacement="auto"
                  autoComplete="none"
                  placeholder=""
                  isMulti
                  hideSelectedOptions={false}
                  onChange={handelInsuranceChange}
                  styles={customStyles}
                  value={item.selectedInsurance}
                  components={{ Option, ValueContainer: ReactSelectCustomContainer }}
                />
              </Form.Group>
            )}

            <Form.Group className="form-group-wrapper">
              <label className="modalLineHeaders mt-0 text-capitalize">Payment Type</label>
              <Select
                options={PAYMENT_TYPE_REPORT}
                className="w-100"
                menuPlacement="auto"
                autoComplete="none"
                placeholder=""
                isMulti
                hideSelectedOptions={false}
                value={item.paymentType}
                onChange={handelPaymentTypeChange}
                components={{ Option, ValueContainer: ReactSelectCustomContainer }}
                styles={customStyles}
              />
            </Form.Group>

            <Form.Group className="form-group-wrapper">
              <label className="modalLineHeaders mt-0 text-capitalize">Sorting</label>
              <Select
                options={SORTING_REPORT}
                className="w-100"
                menuPlacement="auto"
                autoComplete="none"
                placeholder=""
                value={SORTING_REPORT.find((opt) => opt.value === item?.sortBy)}
                onChange={(e) => setItem({ ...item, sortBy: e.value })}
                components={{ Option, ValueContainer: ReactSelectCustomContainer }}
                styles={customStyles}
              />
            </Form.Group>

            <div className="d-flex align-items-center justify-content-between profile- detail mb-3">
              <span
                style={{
                  fontSize: "14px",
                  marginLeft: "9px",
                }}
              >
                {item.sortOrder ? `Sort By Ascending:` : `Sort By Deascending:`}
              </span>
              <SwitchToggle
                checked={item.sortOrder}
                handleChange={(e) =>
                  setItem({
                    ...item,
                    sortOrder: e.target.checked,
                  })
                }
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" className="headerButton btn-fill" onClick={handleClose}>
          Close
        </Button>
        <div>
          <Button
            variant="secondary"
            className="headerButton btn-fill"
            onClick={handleFilter}
            disabled={customIsEmpty(item.reportType)}
          >
            Show Report
          </Button>
          {loading && <Loader />}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ReportFilterModal;
