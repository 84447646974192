import { AWS_API_METHODS, callAmplifyAPI } from "./defaultApi";

const listEmployeesApi = async (params) => {
  try {
    return await callAmplifyAPI("/employees/list", { params:{...params, limit: 100} }, AWS_API_METHODS.post);
  } catch (err) {
    console.error("Error listEmployees:", err);
    return null;
  }
};

const getEmployeeById = async (id) => {
  try {
    return await callAmplifyAPI("/employee", { id }, AWS_API_METHODS.post);
  } catch (err) {
    console.error("Error listEmployees:", err);
    return null;
  }
};

export default {
  listEmployeesApi,
  getEmployeeById,
};
