import React, { useEffect, useState, useMemo } from "react";
import { formatDateMDY, formatCurrency } from "utils";
import { CUSTOM_INS } from "constant";

const ProcedureTable = (props) => {
  const { data, chargeIndex, handleChargeEdit } = props;
  const [editable, setEditable] = useState({ row: null, column: null });
  const detailsColumns = useMemo(() => {
    const columns = ["allowed", "deductible", "co_insurance", "copay", "write_off", "paid"];
    if (CUSTOM_INS.includes(data.payerid)) {
      return columns.filter((f) => f === "paid");
    }
    return columns;
  }, [data]);
  const handleEdit = (row, column) => {
    setEditable({ row, column });
  };

  const handleInputChange = (column, inputValue) => {
    if (/^(?!0\d*$)\d*\.?\d*$/.test(inputValue)) {
      handleChargeEdit(chargeIndex, column, inputValue);
    }
  };
  const handleSave = () => {
    setEditable({ row: null, column: null });
  };
  return (
    <tr>
      <td className={`table-list-data text-center`}>{formatDateMDY(data.from_date || data.from_dos)}</td>
      <td className={`table-list-data text-center`} title={data?.proc_code}>
        {data?.proc_code}
      </td>
      <td className={`table-list-data text-center ellipsis`} title={data?.proc_desc}>
        {data?.proc_desc}
      </td>
      <td className={`table-list-data text-center`} title={data?.charge}>
        {data?.charge}
      </td>
      {detailsColumns.map((column, index) => (
        <td
          className="text-center p-2 custom-height  custom-width"
          key={column}
          onClick={() => handleEdit(chargeIndex, column)}
          tabIndex={index + 1}
          style={{
            color: data.error ? "red" : "",
          }}
        >
          {editable.row === chargeIndex && editable.column === column ? (
            <input
              className="custom-input"
              type="text"
              value={data[column] || ""}
              onChange={(e) => handleInputChange(column, e.target.value)}
              onBlur={handleSave}
              placeholder="$0.00"
              autoFocus
            />
          ) : (
            formatCurrency(data[column] || 0)
          )}
        </td>
      ))}
    </tr>
  );
};

export default ProcedureTable;
