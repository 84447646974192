import React from "react";
import { ProgressBar } from "react-bootstrap";

const Progress = ({ item, total }) => {
  const completed = item.filter((item) => item.errorStatus).length;
  const progress = Math.round((completed / total) * 100);
  const successCount = item.filter((item) => item.errorStatus === "0").length;
  const failCount = completed - successCount;

  return (
    <>
      <ProgressBar now={progress} />
      <div className="progressCounter">
        <div className="counters">
          <div className="successCounter">Succeed: {successCount}</div>
          <div className="failCounter">Failed: {failCount}</div>
        </div>
        <p className="progress_total">
          {completed} of {total}{" "}
        </p>
      </div>
    </>
  );
};

export default Progress;
