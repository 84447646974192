import React, { useState, useMemo } from "react";
import { Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import ErrorMessage from "components/Message/ErrorMessage";
import { ERA_PROCEDURE_CODE_DETAIL_HEADER, countryListAllIsoData, CUSTOM_INS } from "constant";
import ProcedureTable from "./ProcedureTable";
import { getValidSpaces, getStateLength } from "utils";


import InputField from "components/InputField/InputField";
import moment from "moment";
import countriesOption from "countries";
import { useDispatch } from "react-redux";
import { claimInvoiceUpdateAsync } from "store/features/claimInvoice/claimInvoiceSlice";
import InvoicePreviewModal from "views/EmployeeClaims/InvoicePreview/InvoicePreviewModal";

const ClaimInvoiceModal = ({ handleClose, data }) => {
  const [openConfirmation, setOpenConfirmation] = useState({ isShow: false });
  const [error, setError] = useState("");
  const [dataList, setDataList] = useState(data);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const dispatch = useDispatch();

  const handleConfirm = async (isConfirm, type) => {
    setOpenConfirmation({ isShow: false });
    if (!isConfirm) return;
    switch (type) {
      case "updateInvoice":
        handleUpdate();
        break;
    }
  };

  const handleUpdate = async () => {
    try {
      let data = {
        ...dataList,
        dueDate: `${dataList.dueDate}T00:00:00Z`,
      };

      await dispatch(claimInvoiceUpdateAsync(data));

      handleClose();
    } catch (error) {
      console.log("error in handle update invoice", error);
    }
  };

  const handleUpdateAddress = (val, flag) => {
    if (!flag) {
      setDataList({ ...dataList, toData: { ...dataList.toData, street: val } });
    } else {
      const addressObj = {
        street: val.street,
        country: val.country,
        city: val.city,
        zip: val.zipcode,
        state: val.state,
        isoCode: val.isoCode,
      };
      setDataList({ ...dataList, toData: { ...dataList.toData, ...addressObj } });
    }
  };

  const handleChargeEdit = (chargeIndex, fieldName, newValue) => {
    setDataList((prevList) => {
      let prevClaim = JSON.parse(JSON.stringify(prevList));
      let updatedProcArray = [...prevClaim.details];
      updatedProcArray[chargeIndex] = {
        ...updatedProcArray[chargeIndex],
        [fieldName]: newValue,
        error: false,
      };
      // if (!isCalculationError(updatedProcArray[chargeIndex], updatedClaims[claimIndex])) {
      //   updatedProcArray[chargeIndex] = { ...updatedProcArray[chargeIndex], error: true };
      // }

      prevClaim.details = updatedProcArray;
      return prevClaim;
    });
  };
  const totalOfAmounts = useMemo(() => {
    if (dataList.details && dataList.details.length > 0) {
      return dataList.details.reduce((result, obj) => {
        let resObj = { ...result };
        if (!CUSTOM_INS.includes(dataList.payerid)) {
          resObj["total_allowed"] = (resObj["total_allowed"] || 0) + parseFloat(obj.allowed || 0);
          resObj["total_deductible"] = (resObj["total_deductible"] || 0) + parseFloat(obj.deductible || 0);
          resObj["total_co_insurance"] = (resObj["total_co_insurance"] || 0) + parseFloat(obj.co_insurance || 0);
          resObj["total_copay"] = (resObj["total_copay"] || 0) + parseFloat(obj.copay || 0);
          resObj["total_write_off"] = (resObj["total_write_off"] || 0) + parseFloat(obj.write_off || 0);
        }
        resObj["chargeOfTotal"] = (resObj["chargeOfTotal"] || 0) + parseFloat(obj.paid || 0);

        return resObj;
      }, {});
    }
  }, [dataList.details]);

  return (
    <Modal show backdrop="static" animation={true} onHide={() => handleClose()} centered size={"2xl"} keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title className="my-0 text-capitalize" id="contained-modal-title-vcenter">
          {`${dataList?.invoiceNo} - ${dataList?.status}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="crew-member-profile">
          <div className="crew-member-profile-inner">
            <div className="d-md-flex  justify-content-between">
              <Col md="7" className="mr-2" style={{ marginRight: "3px" }}>
                <Card className="">
                  <div className="personal-info-wrapper pt-3 px-3">
                    <div className="d-flex justify-content-between">
                      <div className="patient-info-title">
                        <h4 className="text-decoration-underline text-black mt-0 mb-0 section-title">Recipient</h4>
                      </div>
                    </div>
                    <div className="testAdmin createClientsWrapper allowShrink resubmissionFormWrapper">
                      <div className={"crew-profile-info1 crew-profile-row crew-profile-again"}>
                        {dataList?.sendTo == "Client" ? (
                          <>
                            <InputField
                              type="text"
                              inputStyle="modalInput"
                              labelStyle="mt-0 modalLineHeaders text-capitalize"
                              label="Name"
                              value={dataList.toData.firstName}
                              index="invoiceAmount"
                              maxLength={100}
                              placeholder="Name"
                              handleChange={(e) =>
                                setDataList({
                                  ...dataList,
                                  toData: {
                                    ...dataList.toData,
                                    firstName: e.target.value,
                                  },
                                })
                              }
                              required
                            />
                          </>
                        ) : (
                          <>
                            <InputField
                              type="text"
                              inputStyle="modalInput"
                              labelStyle="mt-0 modalLineHeaders text-capitalize"
                              label="First Name"
                              value={dataList.toData.firstName}
                              index="invoiceAmount"
                              maxLength={100}
                              placeholder="Enter First Name"
                              handleChange={(e) =>
                                setDataList({
                                  ...dataList,
                                  toData: {
                                    ...dataList.toData,
                                    firstName: e.target.value,
                                  },
                                })
                              }
                              required
                            />
                            <InputField
                              type="text"
                              inputStyle="modalInput"
                              labelStyle="mt-0 modalLineHeaders text-capitalize"
                              label="Last Name"
                              value={dataList.toData.lastName}
                              index="paidAmount"
                              maxLength={100}
                              placeholder="Enter Last Name"
                              handleChange={(e) =>
                                setDataList({
                                  ...dataList,
                                  toData: {
                                    ...dataList.toData,
                                    lastName: e.target.value,
                                  },
                                })
                              }
                              required
                            />
                          </>
                        )}
                        <InputField
                          type="email"
                          inputStyle="modalInput"
                          labelStyle="mt-0 modalLineHeaders text-capitalize"
                          label="Email"
                          value={dataList.toData.email}
                          index="email"
                          maxLength={25}
                          placeholder="Enter Email"
                          handleChange={(e) =>
                            setDataList({
                              ...dataList,
                              toData: {
                                ...dataList.toData,
                                email: e.target.value,
                              },
                            })
                          }
                        />
                      </div>
                      <div className="crew-profile-info1 crew-profile-row crew-profile-again">
                        <InputField
                          type="address"
                          groupWrapper="form-group-wrapper w-100"
                          inputStyle="modalInput"
                          labelStyle="modalLineHeaders text-capitalize"
                          label="Address"
                          value={dataList.toData.street}
                          index="street"
                          maxLength={255}
                          placeholder="Enter Address"
                          handleChange={(val, flag) => handleUpdateAddress(val, flag)}
                        />
                        <InputField
                          type="text"
                          groupWrapper="form-group-wrapper w-100"
                          inputStyle="modalInput"
                          labelStyle="mt-0 modalLineHeaders text-capitalize"
                          label="Apt/Unit#"
                          value={dataList.toData.street2}
                          index="street2"
                          maxLength={255}
                          placeholder="Enter Apt/Unit"
                          handleChange={(e) =>
                            setDataList({
                              ...dataList,
                              toData: {
                                ...dataList.toData,
                                street2: e.target.value,
                              },
                            })
                          }
                        />
                        <InputField
                          type="text"
                          groupWrapper="form-group-wrapper w-100"
                          inputStyle="modalInput"
                          labelStyle="mt-0 modalLineHeaders text-capitalize"
                          label="State"
                          value={dataList.toData.state}
                          index="state"
                          maxLength={getStateLength(dataList.toData)}
                          placeholder="Enter State"
                          handleChange={(e) => {
                            let val = e.target.value;
                            if (val) val = val.toUpperCase();
                            setDataList({
                              ...dataList,
                              toData: {
                                ...dataList.toData,
                                state: getValidSpaces(val),
                              },
                            });
                          }}
                        />
                        <InputField
                          type="text"
                          groupWrapper="form-group-wrapper w-100"
                          inputStyle="modalInput"
                          labelStyle="mt-0 modalLineHeaders text-capitalize"
                          label="City"
                          value={dataList.toData.city}
                          index="city"
                          maxLength={65}
                          placeholder="Enter City"
                          handleChange={(e) =>
                            setDataList({
                              ...dataList,
                              toData: {
                                ...dataList.toData,
                                city: e.target.value,
                              },
                            })
                          }
                        />
                        <InputField
                          type="text"
                          groupWrapper="form-group-wrapper w-100"
                          inputStyle="modalInput"
                          labelStyle="mt-0 modalLineHeaders text-capitalize"
                          label="Zip Code"
                          value={dataList.toData.zip}
                          index="zip"
                          maxLength={10}
                          placeholder="Enter Zip Code"
                          handleChange={(e) => {
                            let val = e.target.value;
                            if (val) val = val.toUpperCase();
                            setDataList({
                              ...dataList,
                              toData: {
                                ...dataList.toData,
                                zip: dataList.toData.isoCode === "US" ? val : getValidSpaces(val),
                              },
                            });
                          }}
                        />
                        <InputField
                          type="dropDown"
                          labelStyle="mt-0 modalLineHeaders text-capitalize"
                          label="Country"
                          options={countriesOption.filter((f) => f.value === "United States")}
                          value={
                            dataList.toData.country
                              ? {
                                  label: dataList.toData.country,
                                  value: dataList.toData.country,
                                }
                              : null
                          }
                          index="country"
                          maxLength={20}
                          placeholder="Select Country"
                          handleChange={(e) => {
                            const country = countryListAllIsoData.find((f) => f.name === e.value) || { code: "US" };
                            setDataList({
                              ...dataList,
                              toData: {
                                ...dataList.toData,
                                country: e.value,
                                isoCode: country.code,
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Card>
              </Col>
              <Col md="5">
                <Card className="mb-3">
                  <div className="personal-info-wrapper pt-3 px-3">
                    <div className="d-flex justify-content-between">
                      <div className="patient-info-title">
                        <span className="">Invoice #</span>
                        <span className="text-secondary px-3">{dataList.invoiceNo}</span>
                      </div>
                      <span className="">Bill To:</span>
                      <span className="text-secondary">{dataList.sendTo}</span>
                    </div>
                    <div className="form-group-wrapper d-flex justify-content-between mb-0">
                      <span>Invoice Amount: </span>
                      <span className="text-secondary">${dataList.invoiceAmount}</span>
                    </div>
                    {dataList.status == "paid" ? (
                      <>
                        <div className="form-group-wrapper d-flex justify-content-between mb-0">
                          <span>Paid Amount: </span>
                          <span className="text-secondary">${dataList.paidAmount}</span>
                        </div>
                        <InputField
                          type="datePicker"
                          inputStyle="w-100 orderTestDob"
                          labelStyle="modalLineHeaders m-0 text-capitalize p-0"
                          label="Paid Date: "
                          value={
                            dataList?.paidDate && new Date(dataList?.paidDate) != "Invalid Date"
                              ? moment(dataList.paidDate, "YYYY-MM-DD").toDate()
                              : null
                          }
                          index="due_date"
                          placeholder="Paid Date"
                          disabled
                        />
                      </>
                    ) : (
                      <InputField
                        type="datePicker"
                        inputStyle="w-100 orderTestDob"
                        labelStyle="modalLineHeaders mt-0 text-capitalize"
                        label="Due Date"
                        value={
                          dataList.dueDate && new Date(dataList.dueDate) != "Invalid Date"
                            ? moment(dataList.dueDate, "YYYY-MM-DD").toDate()
                            : null
                        }
                        index="due_date"
                        placeholder="Due Date"
                        handleChange={(e) =>
                          setDataList({
                            ...dataList,
                            dueDate:
                              moment(e).format("YYYY-MM-DD") != "Invalid date" ? moment(e).format("YYYY-MM-DD") : "",
                          })
                        }
                        // minDate={new Date()}
                      />
                    )}
                    <InputField
                      type="number"
                      groupWrapper="form-group-wrapper w-100"
                      inputStyle="modalInput"
                      labelStyle="mt-0 modalLineHeaders text-capitalize"
                      label="Minimum Amount:"
                      value={dataList.minAmount}
                      index="minAmount"
                      maxLength={10}
                      placeholder="Enter Min Amount"
                      handleChange={(e) => {
                        let val = e.target.value;
                        setDataList({
                          ...dataList,
                          minAmount: +parseFloat(val).toFixed(2),
                        });
                      }}
                    />
                  </div>
                </Card>
                {/* <Card> */}
                <div>
                  <span className="profile-title mx-3">Note</span>
                  <textarea
                    className="form-control"
                    style={{ height: "46px" }}
                    value={dataList?.note || ""}
                    onChange={(e) =>
                      setDataList({
                        ...dataList,
                        note: e.target.value,
                      })
                    }
                  ></textarea>
                </div>
                {/* </Card> */}
              </Col>
            </div>
          </div>
        </div>
        <Card className="striped-table-card charge-info-table">
          <Card.Body>
            <div className="form-group-wrapper d-block">
              <div className="table-responsive overFlow-y-hidden">
                <Table className="table layout-fixed">
                  <thead>
                    <tr>
                      {ERA_PROCEDURE_CODE_DETAIL_HEADER.map((title) => (
                        <th key={title.item} className="centered p-2">
                          {title.item}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {dataList?.details?.map((data, index) => (
                      <ProcedureTable key={index} chargeIndex={index} data={data} handleChargeEdit={handleChargeEdit} />
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </Card.Body>
        </Card>
        <Row></Row>
      </Modal.Body>

      {error && (
        <div className="px-4 my-2" style={{ height: "2vh" }}>
          <ErrorMessage error={error} handleChange={() => setError("")} />
        </div>
      )}

      {openConfirmation.isShow && (
        <ConfirmationModal
          show={openConfirmation.isShow}
          actionType={openConfirmation.actionType}
          title={openConfirmation.title}
          message={openConfirmation.message}
          handleConfirm={handleConfirm}
          cssClass="seperateModal"
        />
      )}

      <Modal.Footer>
        <Button
          style={{ marginBottom: 10 }}
          variant="primary"
          className="modalButtons headerButton btn-fill"
          onClick={() => {
            handleClose();
          }}
        >
          Close
        </Button>
        {/* <Button
          style={{ marginBottom: 10 }}
          variant="secondary"
          className="modalButtons headerButton btn-fill"
          onClick={() =>
            setOpenPreviewModal({
              ...dataList,
              proc_array: dataList.details,
              pat_name_f: dataList.firstName,
              pat_name_l: dataList.lastName,
              pat_addr_1: dataList?.street,
              pat_state: dataList?.state,
              pat_zip: dataList?.zip,
              pat_city: dataList?.city,
              pat_country: dataList?.country,
              pat_email: dataList?.email,
              total_charge: dataList.invoiceAmount,
              totalPaid: dataList.paidAmount,
            })
          }
        >
          {"Preview"}
        </Button> */}
        <Button
          style={{ marginBottom: 10 }}
          variant="secondary"
          className="modalButtons headerButton btn-fill"
          onClick={() =>
            setOpenConfirmation({
              isShow: true,
              message: `Are you sure, you want to Update Invoice?`,
              actionType: "updateInvoice",
              title: "Update Invoice",
            })
          }
        >
          {"Update"}
        </Button>
      </Modal.Footer>
      {openPreviewModal && (
        <InvoicePreviewModal close={() => setOpenPreviewModal(false)} data1={openPreviewModal} isPreview={true} />
      )}
    </Modal>
  );
};

export default ClaimInvoiceModal;
