import { Button, Col, Card, Row, Table } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { t } from "stringConstants";
import { invoiceDocsSelector } from "store/features/invoiceDocuments/invoiceDocumentSelectors";
import { setInvoiceDocs } from "store/features/invoiceDocuments/invoiceDocumentSlice";
import { draggablePersonalizationLocalStorage, getValidName } from "utils";
import { authSelector, selectLoginUser } from "store/features/authentication/authenticationSelectors";
import api from "api";
import { documentSelector } from "store/features/documents/documentSelectors";
import { v4 as uuidv4 } from "uuid";
import {
  createDocumentAsync,
  fetchDocuemntAsync,
  setDocumentTypes,
  updateDocumentAsync,
} from "store/features/documents/documentSlice";
import FileInput from "components/Input/FileInput";
import ErrorMessage from "components/Message/ErrorMessage";
import EditableDropDown from "components/Dropdown/EditableDropDown";
import { setLoader } from "store/features/general/generalSlice";
import InputField from "components/InputField/InputField";
import { FILE_TYPES_OPT } from "constant";
import moment from "moment";
import { createInvoiceDocsAsync, updateInvoiceDocsAsync } from "store/features/invoiceDocuments/invoiceDocumentSlice";

const InvoiceDocumentForm = (props) => {
  const { handleClose, emp, cssClass, document, setting } = props;
  const [newDocument, setNewDocument] = useState(document);
  const [file, setFile] = useState("");
  const [fileType, setFileType] = useState("");
  const [fileSize, setFileSize] = useState("");
  const [selectedFileName, setSelectedFileName] = useState("");
  const [error, setError] = useState("");

  const invoiceDocs = useSelector(invoiceDocsSelector);

  const loginUser = useSelector(selectLoginUser);
  const { documentTypes } = useSelector(documentSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!document.isNew) {
      setNewDocument(document);
      setFileType(document.fileType);
      setFileSize(document.fileSize);
      const parts = document.fileName?.split("$");
      const slicedString = parts[parts.length - 1];
      setSelectedFileName(slicedString);
    } else {
      handleCancel();
    }
  }, [document]);

  const handleCancel = () => {
    setNewDocument({ isNew: true });
    setFile("");
    setFileType("");
    setSelectedFileName("");
    setFileSize("");
  };

  const validateDocumentForm = (doc) => {
    if (!doc.docType) {
      setError("Document Type is Required");
      return;
    }
    if (!doc.file && newDocument.isNew) {
      setError("Document File is Required.");
      return;
    }

    return true;
  };

  const handleUploadDocument = async () => {
    const doc = { ...newDocument, file, fileType, selectedFileName, fileSize };

    if (!validateDocumentForm(doc)) return;
    if (newDocument.isNew) {
      dispatch(createInvoiceDocsAsync({ ...doc, id: uuidv4() }));
    } else {
      dispatch(updateInvoiceDocsAsync(doc));
    }
    handleCancel();
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title className="my-0" id="contained-Card-title-vcenter">
          {t("documentDetails")}
        </Card.Title>{" "}
      </Card.Header>

      <Card.Body className="table-full-width desktop-noScroll">
        <div className="row">
          <div className="col-sm-12 createClientsWrapper">
            <InputField
              type="dropDown"
              groupWrapper="form-group-wrapper"
              labelStyle="mt-0 modalLineHeaders text-capitalize"
              label="File Type"
              options={FILE_TYPES_OPT}
              value={newDocument.docType ? { value: newDocument.docType, label: newDocument.docType } : null}
              index="docType"
              placeholder="Select File Type"
              handleChange={(e) => {
                setNewDocument({ ...newDocument, docType: e.label });
              }}
              required
            />
          </div>
          <div className="col-sm-12 createClientsWrapper">
            <div className="form-group-wrapper">
              <label className="CardLineHeaders">Document File: </label>
              <FileInput
                setFileType={setFileType}
                setFile={setFile}
                setSelectedFileName={setSelectedFileName}
                selectedFileName={selectedFileName}
                setFileSize={setFileSize}
              />
            </div>
          </div>
        </div>
      </Card.Body>
      <div className="px-4 my-2" style={{ height: "2vh" }}>
        {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
      </div>
      <Card.Footer className="my-2 d-flex justify-content-between">
        <Button variant="primary" className="CardButtons headerButton btn-fill" onClick={() => handleClose()}>
          Close
        </Button>
        <Button variant="secondary" className="CardButtons headerButton btn-fill" onClick={handleUploadDocument}>
          {newDocument.isNew ? "Upload" : "Update"}
        </Button>
      </Card.Footer>
    </Card>
  );
};

export default InvoiceDocumentForm;
