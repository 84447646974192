import api from "api";
import Loader from "components/Loader/Loader";
import ErrorMessage from "components/Message/ErrorMessage";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import OrderDetailItem from "components/Modal/Order/OrderDetailItem";
import { MESSAGE_MODES } from "constant";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import DatePicker from "react-date-picker";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { setMessage } from "store/features/general/generalAction";
import { calculateTdWidth } from "utils";
import { t } from "../../../stringConstants";
import Progress from "../ProgressBar";

const OrderDetailModal = (props) => {
  const { handleClose, employees, loginUser } = props;
  const [error, setError] = useState("");
  const [newOrder, setNewOrder] = useState({ quantity: 1 });
  const [loading, setLoading] = useState(false);
  const [setting, setSetting] = useState(6);
  const [employeeData, setEmployeeData] = useState(employees);
  const [openConfimation, setOpenConfirmation] = useState(false);
  const [showTable, setshowTable] = useState(false);
  const [eligibilityStatus, setEligibilityStatus] = useState([]);
  const componentRef = useRef();
  const [billingOnly, setBillingOnly] = useState("No");
  const [orderMonth, setOrderMonth] = useState();
  const mainWidth = useRef();
  const [width, setWidth] = useState(0);
  const [enableCancel, setEnableCancel] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    setWidth(componentRef.current.offsetWidth);
  }, [componentRef]);

  const handleResize = () => {
    if (componentRef.current) setWidth(componentRef.current.offsetWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => handleResize(), [mainWidth]);

  const tdWidth = calculateTdWidth(width - 20, 11);
  console.log("tdWidth", tdWidth);
  useEffect(() => {
    loadSetting();
  }, []);

  useEffect(async () => {
    if (eligibilityStatus.length === employeeData.length) {
      try {
        await api.updateOrderQuantity(eligibilityStatus);

        if (eligibilityStatus.length > 1) {
          const successData = eligibilityStatus.filter((data) => !data.error);

          if (successData.length === 0) {
            dispatch(setMessage(t("multipleOrderFailedMsg"), MESSAGE_MODES.error));
          } else if (eligibilityStatus.length - successData.length === 0) {
            const text = `${t("multipleOrderSuccessMsg", {
              total: employeeData.reduce((val, obj) => {
                return (obj.testSendQty || 0) + val;
              }, 0),
            })}.`;
            dispatch(setMessage(text, MESSAGE_MODES.success));
          } else {
            const text = `${successData.length} members have successfully placed order and ${
              eligibilityStatus.length - successData.length
            } others are no longer eligible for this program.`;
            dispatch(setMessage(text, MESSAGE_MODES.success));
          }
        } else {
          if (!eligibilityStatus[0].error) {
            const text = `${t("orderSuccessMsg", {
              total: employeeData.reduce((val, obj) => {
                return (obj.testSendQty || 0) + val;
              }, 0),
            })}.`;
            dispatch(setMessage(text, MESSAGE_MODES.success));
          } else {
            dispatch(setMessage(t("orderFailedMsg"), MESSAGE_MODES.error));
          }
        }
        setLoading(false);
        // handleClose();
      } catch (err) {
        console.log("Error:-", err.message);
        setLoading(false);
      }
    }
  }, [eligibilityStatus]);

  const loadSetting = async () => {
    const obj = await api.getCompanySetting();
    setSetting(obj);
  };

  const responseEligibility = (res) => {
    const empData = employeeData.find((data) => data.id === res.empId);
    if (empData) setEligibilityStatus((prevRes) => [...prevRes, { ...empData, ...res }]);
  };
  const handleSaveChange = async (isConfirm) => {
    setOpenConfirmation(false);
    setEnableCancel(false);
    setshowTable(false);

    if (!isConfirm) {
      return;
    }
    try {
      // setLoading(true);
      await api.createBulkOrder(employeeData, loginUser, responseEligibility, orderMonth, billingOnly);

      // handleClose(true, limitExccedEmployees);
    } catch (err) {
      console.log("Error", err);
      setLoading(false);
      dispatch(setMessage("Fail to add the order", MESSAGE_MODES.error));
    }
  };

  console.log("orderMonth", orderMonth);

  const handleSave = () => {
    if (!orderMonth || orderMonth === "Invalid date") {
      setError("Please Select Order Month");
      return;
    }
    setOpenConfirmation(true);
    setshowTable(true);
    setEnableCancel(false);
  };

  const onHandleChangeQty = (value, index, key) => {
    const prevEmployeeData = [...employeeData];
    const currentRecord = employeeData[index];
    currentRecord[`${key}`] = value;
    prevEmployeeData.splice(index, 1, { ...currentRecord });
    setEmployeeData([...prevEmployeeData]);
  };

  const getTestOrderQuantity = () => {
    let options = [];
    for (let i = 2; i <= 8; i = i + 2) {
      options.push({ value: i, label: i });
    }
    return options;
  };

  const resetTestOrderQty = (e) => {
    const newQty = e.value;

    const newList = employeeData.map((e) => {
      if (e.testAvailable >= newQty && e.minTestToOrder <= newQty) {
        return { ...e, testSendQty: newQty };
      }
      if (newQty >= e.testAvailable) {
        return { ...e, testSendQty: e.testAvailable };
      }
      return e;
    });

    setEmployeeData([...newList]);
  };
  return (
    <>
      {!showTable ? (
        <Modal show backdrop="static" animation={true} onHide={() => handleClose(false)} centered size={"2xl"}>
          <Modal.Header closeButton>
            <Modal.Title className="my-0" id="contained-modal-title-vcenter">
              Order Test ({employees.length})
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ paddingTop: 0 }}>
            {!enableCancel && (
              <div>
                <Progress item={eligibilityStatus} total={employeeData.length} />
              </div>
            )}
            <div style={{ flex: 1, marginTop: "10px" }}>
              <Row>
                <Col md="12">
                  <Card className="strpied-tabled-with-hover">
                    <Card.Body>
                      <div className="d-flex align-items-center createClientsWrapper">
                        <div className="first-last-name-wrapper">
                          <div className="form-group-wrapper col-lg-6 col-6">
                            <label className="modalLineHeaders">Select Order Month:</label>
                            <DatePicker
                              className="w-100 orderTestDob"
                              openCalendarOnFocus={false}
                              dayPlaceholder="dd"
                              monthPlaceholder="mm"
                              yearPlaceholder="yyyy"
                              format="MM/dd/y"
                              onChange={(e) => setOrderMonth(moment(e).format("YYYY-MM-DD"))}
                              value={
                                orderMonth && new Date(orderMonth) != "Invalid Date"
                                  ? moment(orderMonth, "YYYY-MM-DD").toDate()
                                  : null
                              }
                              maxDate={new Date()}
                              minDate={new Date("2022-09-01")}
                            />
                          </div>
                          {employees.length > 1 && (
                            <div className="form-group-wrapper col-lg-4 col-4 mb-3">
                              <label className="modalLineHeaders mt-0">Set Default Quantity:</label>
                              <Select
                                options={getTestOrderQuantity()}
                                blurInputOnSelect={true}
                                menuPlacement="auto"
                                placeholder="Quantity"
                                className="w-100"
                                onChange={resetTestOrderQty}
                              />
                            </div>
                          )}
                        </div>

                        <div className="checkbox checkBoxWrapper justify-content-between w-15 align-items-center mb-3">
                          <label className="modalLineHeaders">Bill Only</label>
                          <div className="d-flex justify-content-start align-items-center mt-2">
                            <Form.Check>
                              <Form.Check.Input
                                type="checkbox"
                                checked={billingOnly === "Yes" ? true : false}
                                onChange={(e) => setBillingOnly(e.target.checked ? "Yes" : "No")}
                              />
                            </Form.Check>
                          </div>
                        </div>
                      </div>
                      <div className="form-group-wrapper d-block">
                        <div>
                          <Table className="table">
                            <thead ref={componentRef}>
                              <tr>
                                <th
                                  style={{
                                    maxWidth: "30px",
                                    minWidth: "30px",
                                    width: "30px",
                                  }}
                                >
                                  Sr#
                                </th>
                                <th
                                  className="border-0 centered"
                                  style={{
                                    maxWidth: tdWidth,
                                    minWidth: tdWidth,
                                    width: tdWidth,
                                  }}
                                >
                                  First Name
                                </th>
                                <th
                                  className="border-0 centered"
                                  style={{
                                    maxWidth: tdWidth,
                                    minWidth: tdWidth,
                                    width: tdWidth,
                                  }}
                                >
                                  Last Name
                                </th>
                                <th
                                  className="border-0 centered"
                                  style={{
                                    maxWidth: tdWidth + 30,
                                    minWidth: tdWidth + 30,
                                    width: tdWidth + 30,
                                  }}
                                >
                                  Address
                                </th>
                                <th
                                  className="border-0 centered"
                                  style={{
                                    maxWidth: tdWidth,
                                    minWidth: tdWidth,
                                    width: tdWidth,
                                  }}
                                >
                                  City
                                </th>
                                <th
                                  className="border-0 centered"
                                  style={{
                                    maxWidth: tdWidth - 10,
                                    minWidth: tdWidth - 10,
                                    width: tdWidth - 10,
                                  }}
                                >
                                  State
                                </th>
                                <th
                                  className="border-0 centered"
                                  style={{
                                    maxWidth: tdWidth - 10,
                                    minWidth: tdWidth - 10,
                                    width: tdWidth - 10,
                                  }}
                                >
                                  Zip-Code
                                </th>
                                <th
                                  className="border-0 centered"
                                  style={{
                                    maxWidth: tdWidth - 10,
                                    minWidth: tdWidth - 10,
                                    width: tdWidth - 10,
                                  }}
                                >
                                  Qty Available
                                </th>
                                <th
                                  className="border-0 centered"
                                  style={{
                                    maxWidth: tdWidth,
                                    minWidth: tdWidth,
                                    width: tdWidth,
                                  }}
                                >
                                  Order Qty
                                </th>
                                <th
                                  className="border-0 centered"
                                  style={{
                                    maxWidth: tdWidth,
                                    minWidth: tdWidth,
                                    width: tdWidth,
                                  }}
                                >
                                  Eligibility
                                </th>
                                <th
                                  className="border-0 centered"
                                  style={{
                                    maxWidth: tdWidth,
                                    minWidth: tdWidth,
                                    width: tdWidth,
                                  }}
                                >
                                  Auto-Shipment
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {employeeData.length > 0 &&
                                employeeData.map((item, i) => {
                                  return (
                                    <OrderDetailItem
                                      key={i}
                                      index={i}
                                      item={item}
                                      loading={loading}
                                      eligibilityStatus={eligibilityStatus}
                                      handleChangeQuantity={onHandleChangeQty}
                                    />
                                  );
                                })}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
            {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
          </Modal.Body>
          {!loading ? (
            <Modal.Footer>
              <Button
                variant="primary"
                className={`headerButton btn-fill}`}
                onClick={() => handleClose(false)}
                disabled={!enableCancel && eligibilityStatus.length != employeeData.length}
              >
                {`${t("close")}`}
              </Button>
              <Button
                variant="secondary"
                className={`headerButton btn-fill`}
                onClick={() => handleSave()}
                disabled={!enableCancel}
              >
                {`${t("submitOrder")}`}
              </Button>
            </Modal.Footer>
          ) : (
            <Loader />
          )}
        </Modal>
      ) : (
        <ConfirmationModal
          show={openConfimation}
          title="Order Confirmation"
          message={`${t("orderConfirmationDescription", {
            total: employeeData.reduce((val, obj) => {
              return obj.testSendQty + val;
            }, 0),
          })}?`}
          handleConfirm={handleSaveChange}
          closeBtn
        />
      )}
    </>
  );
};

export default OrderDetailModal;
