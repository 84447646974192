import React, { useState } from "react";
import { Table, Card } from "react-bootstrap";
import { BCC_VALUE } from "../../../constant";
import { formatCurrency } from "../../../utils";
import { getDeductible } from "./common";
const HealthBenefitTabularForm = (props) => {
  const { newUser } = props;

  const collapaseRow = [
    {
      label: "In Network Out Of Pocket (Stop Loss)",
      benefitValue: BCC_VALUE.OUT_OF_POCKET,
      inNetwork: "Y",
    },
    {
      label: "Out of Network Benefit Plan",
      benefitValue: BCC_VALUE.DEDUCTIBLE,
      inNetwork: "N",
    },
    {
      label: "Out of Network Out Of Pocket (Stop Loss)",
      benefitValue: BCC_VALUE.OUT_OF_POCKET,
      inNetwork: "N",
    },
  ];

  const columns = [
    {
      itemKey: "type",
      title: "Type",
      textAlign: "left",
    },
    {
      itemKey: "planStartDate",
      title: "Start Date",
      textAlign: "center",
    },
    {
      itemKey: "planStartDate",
      title: "End Date",
      textAlign: "center",
    },
    {
      itemKey: "deductibleInYear",
      title: "Deductible Amount",
      textAlign: "center",
    },
    {
      itemKey: "deductibleRemaning",
      title: "Deductible Remaining",
      textAlign: "center",
    },
    {
      itemKey: "coInsurance",
      title: "CoInsurance%",
      textAlign: "center",
    },
    {
      itemKey: "coPayment",
      title: "Co Payment",
      textAlign: "center",
    },
  ];

  const TableRow = ({ user, index }) => {
    const benefitObj = getDeductible(newUser, user.benefitValue, user.inNetwork);
    return (
      <>
        <tr key={index}>
          <td className="ellipsis w-30" style={{ textAlign: "left" }} title={user.label}>
            {user.label}
          </td>
          <td className="ellipsis" style={{ textAlign: "center" }}>
            {benefitObj.deductibleInYear?.planStartDate || "-"}
          </td>
          <td className="ellipsis" style={{ textAlign: "center" }}>
            {benefitObj.deductibleInYear?.planEndDate || "-"}
          </td>

          <td className="ellipsis" style={{ textAlign: "center" }}>
            {`${formatCurrency(benefitObj.deductibleInYear?.benefit_amount || 0)}`}
          </td>
          <td className="ellipsis" style={{ textAlign: "center" }}>
            {`${formatCurrency(benefitObj.deductibleRemaning?.benefit_amount || 0)}`}
          </td>
          <td className="ellipsis" style={{ textAlign: "center" }}>
            {benefitObj.coInsurance?.benefit_percent || "-"}
          </td>
          <td className="ellipsis" style={{ textAlign: "center" }}>
            {`${formatCurrency(benefitObj.coPayment?.benefit_amount || 0)}`}
          </td>
        </tr>
      </>
    );
  };

  return (
    <div className="boxborder mt-2">
      <div className="table-responsive table-wrapper print-table">
        <Table className="table healthBenefitTabular">
          <thead>
            <tr>
              {columns.length > 0 &&
                columns.map((m) => (
                  <th className="ellipsis centered" style={{ textAlign: m.textAlign }}>
                    {m.title}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {collapaseRow.length > 0 &&
              collapaseRow.map((user, index) => <TableRow user={user} index={index} key={index} />)}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default HealthBenefitTabularForm;
