import { createAsyncThunk } from "@reduxjs/toolkit";
import {listTemplate,createMessageTemplateGQL,updateMessageTemplateGQL,deleteMessageTemplateGQL} from "../../../api/messageTemplateApi";
import {
  CREATE_MESSAGE_TEMPLATE,
  DELETE_MESSAGE_TEMPLATE,
  GET_MESSAGE_TEMPLATE_LIST,
  UPDATE_MESSAGE_TEMPLATE,
} from "./messageTemplateConstant";

export const fetchMessageTemplateLists = createAsyncThunk(GET_MESSAGE_TEMPLATE_LIST, async () => {
  const res = await listTemplate();
  return res;
});

export const createNewMessageTemplate = createAsyncThunk(CREATE_MESSAGE_TEMPLATE, async (newMessageTemplate) => {
  const res = await createMessageTemplateGQL(newMessageTemplate);
  return res;
});

export const updateMessageTemplate = createAsyncThunk(UPDATE_MESSAGE_TEMPLATE, async (updatedMessageTemplateData) => {
  const res = await updateMessageTemplateGQL(updatedMessageTemplateData);
  return { updatedTemplate: res, prevTemplateId: updatedMessageTemplateData.id };
});

export const deleteMessageTemplate = createAsyncThunk(DELETE_MESSAGE_TEMPLATE, async (template) => {
  await deleteMessageTemplateGQL(template);
  return template.id;
});
