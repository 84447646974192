import React from "react";
import { Form } from "react-bootstrap";
import RadioButton from "./RadioButton";

const RadioButtonOptions = (props) => {
  const { options, label, handleChange, isDisabled, checkedVal, name, cssClass } = props;
  return (
    <>
 
      <Form.Group className={`form-group-wrapper order-select-area ${cssClass ?? ""}`}>
        <label className="modalLineHeaders mt-0 text-capitalize">{label}</label>
        <div className="d-flex justify-content-between w-100 flex-wrap">
          {options.map((opt, i) => (
            <RadioButton
              key={i}
              name={name || "radio-opts"}
              id={`${name}${opt.value}`}
              label={opt.label}
              checked={checkedVal === opt.value}
              disabled={isDisabled}
              handleChange={(e) => handleChange(e, opt)}
            />
          ))}
        </div>
      </Form.Group>
    </>
  );
};

export default RadioButtonOptions;
