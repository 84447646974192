import React from "react";
import { Form } from "react-bootstrap";
const CheckBoxBar = (props) => {
  const { label, value, checked, handleChange } = props;
  return (
    <>
      <div className="checkBoxBar">
        <Form.Check className="mt-1">
          <Form.Check.Input type="checkbox" checked={checked} onChange={handleChange} />
        </Form.Check>
        <span>{label}</span>
      </div>
    </>
  );
};
export default CheckBoxBar;
