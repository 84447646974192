import { createSlice } from "@reduxjs/toolkit";
import { BULK_INVOICES } from "./bulkInvoicesConstant";
import {
  addSelectedClaim,
  resetClaimList,
  removeClaimItem,
  updateClaimMessage,
  setErrorForSelectedClaims,
  updateSelectedClaim,
} from "./bulkInvoicesAction";

const bulkInvoicesSlice = createSlice({
  name: BULK_INVOICES,
  initialState: {
    selectedClaims: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addSelectedClaim.fulfilled, (state, action) => {
      state.selectedClaims = action.payload;
    });
    builder.addCase(resetClaimList.fulfilled, (state) => {
      state.selectedClaims = [];
    });

    builder.addCase(removeClaimItem.fulfilled, (state, action) => {
      state.selectedClaims = state.selectedClaims.filter((item) => item.id !== action.payload);
    });
    builder.addCase(updateClaimMessage.fulfilled, (state, action) => {
      const { id, message, minimumAmount, discount, details, discountType } = action.payload;
      const claimIndex = state.selectedClaims.findIndex((item) => item.claimID === id);
      if (claimIndex !== -1) {
        message && (state.selectedClaims[claimIndex].message = message);
        minimumAmount && (state.selectedClaims[claimIndex].minAmount = Number(minimumAmount));
        discount && (state.selectedClaims[claimIndex].discount = Number(discount));
        details && (state.selectedClaims[claimIndex].details = details);
        discountType && (state.selectedClaims[claimIndex].discountType = discountType);
      }
    });
    builder.addCase(updateSelectedClaim.fulfilled, (state, action) => {
      const invArr = action.payload;

      for (const invObject of invArr) {
        if (invObject?.status === true) {
          const serarchKey = invObject.sendTo === "Patient" ?"employeeID":"clientID";

          const selectedClaimsIndex = state.selectedClaims.findIndex((item) => item[serarchKey] === invObject.local_id);
          
          if (selectedClaimsIndex !== -1) {
            state.selectedClaims[selectedClaimsIndex] = { ...(state.selectedClaims[selectedClaimsIndex]), claimStatus: "sent", id: invObject.invoice };
          }
        }
      }
    })
    builder.addCase(setErrorForSelectedClaims.fulfilled, (state, action) => {
      const updatedClaims = action.payload;

      const updatedClaimsMap = updatedClaims.reduce((map, claim) => {
        map[claim.local_id] = claim;
        return map;
      }, {});

      state.selectedClaims = state.selectedClaims.map((claim) => {
        const updateObj = updatedClaimsMap[claim.id];
        if (updateObj) {
          return {
            ...claim,
            isError: !updateObj.status,
            errorMessage: updateObj.message,
          };
        }
        return claim;
      });
    });
  },
});

export default bulkInvoicesSlice.reducer;
