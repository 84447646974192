import { claimInvoiceCancelledAsync, fetchAllClaimInvoiceAsync } from "./claimInvoiceSlice";

export const fetchAllClaimInvoice =
  ({ timeFilter, pageNo, filter, isNewCall, sortBy, sortDescending }) =>
  async (dispatch) => {
    await dispatch(
      fetchAllClaimInvoiceAsync({ timeFilter, pageNo, filter, isNewCall, sortBy, orderByDirection: sortDescending })
    );
  };
export const cancelledClaimInvoice = (invoices) => async (dispatch) => {
  await dispatch(claimInvoiceCancelledAsync({ invoices }));
};
