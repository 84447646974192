import React, { useState, useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setMessage as setAlertMessage } from "store/features/general/generalAction";
import moment from "moment-timezone";
import { selectLoginUser } from "store/features/authentication/authenticationSelectors";
import { updateClaimMessage, updateSelectedClaim } from "store/features/bulkInvoices/bulkInvoicesAction";
import api from "api";
import { SYSTEM_TIME_ZONE } from "utils";
import { labFormTemplate3, biolab2, biolab1 } from "templates/invoiceTemplates";
import { INVOICE_DEFAULT_MESSAGE, invoicePreviewTypes, MESSAGE_MODES, CONFIG } from "constant";
import { resetInvoice } from "store/features/invoicePreview/invoicePreviewAction";
import MessageSubjectNoteModel from "components/Modal/MessageSubjectNoteModel";
import { selectedInvoiceData } from "store/features/invoicePreview/invoicePreviewSelector";
import EditDetailsInvoiceModal from "components/Modal/EditDetailInvoice/EditDetailsInvoiceModal";
import Loader from "components/Loader/Loader";
import RadioButtonOptions from "components/RadioButton/RadioButtonOptions";
import {
  generateInvoiceDataClientSummary,
  generateInvoiceDataClientDetail,
  generateInvoiceDataBillPatient,
  formatDataClientDetailView,
  transformDataBillPatientAndClientSummary,
  fillInvoiceTemplates,
  generateBarCode,
  getQRCode,
  addPrefixToIds,
  handleConvertToPdf,
} from "./InvoiceDataFormator";
import { TIMEZONE_ABBREVIATIONS } from "constant";
import { getTimeZoneAbr } from "utils";

const InvoicePreviewModal = ({ data1, isPreview, isBulk = false, viewOnly = false }) => {
  const [data, setData] = useState({
    ...data1,
  });

  const [isEditInvoice, setIsEditInvoice] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [minimumAmount, setMinimumAmount] = useState(data.minAmount || 0);

  const [discountAmount, setDiscountAmount] = useState(data.discount || 0);

  useEffect(() => {
    const timezone = getTimeZoneAbr();
    setData({ ...data1, timezone });
    setDiscountAmount(data1.discount || 0);
  }, [data1]);

  const [invoicePreviewType, setInvoicePreviewType] = useState("detail");

  const [qrCodeState, setQrCode] = useState(null);

  const [barCodeState, setBarCode] = useState(null);

  const { selectedInvoice } = useSelector(selectedInvoiceData);

  const [message, setFaxMessage] = useState(data.message || INVOICE_DEFAULT_MESSAGE);

  const [totalBilledValue, setTotalBilled] = useState(0);

  const [faxSubject, setFaxSubject] = useState("");

  const [showMessageSubjectModel, setShowMessageSubjectModel] = useState(false);

  const [showEditDetailsModel, setShowEditDetailsModel] = useState(false);

  const [templates, setTemplates] = useState({ filledTemplate: null, filledTemplate1: null });

  const dispatch = useDispatch();

  const loginUser = useSelector(selectLoginUser);

  useEffect(() => {
    setFaxMessage(data1.message);
  }, [data1]);

  useEffect(() => {
    let isCancelled = false;

    async function fetchTemplates() {
      let invoiceData;

      if (data.sendTo === "Patient") {
        const patientData = transformDataBillPatientAndClientSummary(data.details);
        invoiceData = generateInvoiceDataBillPatient(patientData);
      } else if (data.sendTo === "Client" && invoicePreviewType === "detail") {
        const newFormatedData = formatDataClientDetailView(data.details);
        invoiceData = generateInvoiceDataClientDetail(newFormatedData);
      } else {
        const clientSummaryData = transformDataBillPatientAndClientSummary(data.details);
        invoiceData = generateInvoiceDataClientSummary(clientSummaryData);
      }

      const totalDiscountAmount =
        (data1.discountType === "percentage" ? invoiceData.totalBilled * (data1.discount / 100) : data1.discount) || 0;

      const result = await fillInvoiceTemplates(
        data,
        message,
        invoiceData,
        parseFloat(totalDiscountAmount),
        invoicePreviewType,
        barCodeState,
        qrCodeState
      );

      if (!isCancelled) {
        setTemplates(result);
      }
    }

    fetchTemplates();

    return () => {
      isCancelled = true;
    };
  }, [data, message, discountAmount, invoicePreviewType]);

  const sendInvoice = async () => {
    setIsLoading(true);
    try {
      let res = null;
      const loginUserData = {
        userID: loginUser?.sub,
        userName: loginUser?.name,
        timezone: SYSTEM_TIME_ZONE,
        subject: faxSubject,
        message: message,
        minAmount: minimumAmount,
        discount: discountAmount,
      };

      if (data.invoiceNo !== "00000") {
        const invoiceObj = {
          invoiceID: data.id,
          local_id: data.id,
          message: message,
        };

        res = await api.reSendInvoice([invoiceObj], loginUserData);
      } else {
        const fileName = `${moment.now()}.csv`;
        const claimsLimit = 10;
        if (isEditInvoice && selectedInvoice.details.length > claimsLimit) {
          await api.saveCsvFileOnBucket(selectedInvoice.details, fileName);
        }

        res = await api.createInvoices(
          {
            ...selectedInvoice,
            details: isEditInvoice && selectedInvoice.details.length > claimsLimit ? fileName : selectedInvoice.details,
          },
          loginUserData,
          isEditInvoice,
          invoicePreviewType
        );
      }

      if (res?.data && Array.isArray(res.data)) {
        const serarchKey = data.sendTo === "Patient" ? "employeeID" : "clientID";

        const resObj = res.data[0] || { message: "Failed to send invoice.", status: false, local_id: data[serarchKey] };

        dispatch(updateSelectedClaim([resObj]));

        dispatch(setAlertMessage(resObj.message, resObj.status ? MESSAGE_MODES.success : MESSAGE_MODES.error));

        dispatch(resetInvoice());
      }
    } catch (error) {
      console.log(error);
      dispatch(setAlertMessage("Something Went Wrong!", MESSAGE_MODES.error));
    } finally {
      setIsLoading(false);
    }
  };

  const fetchData = async () => {
    let invoiceNo = data.invoiceNo ? data.invoiceNo : "121212";

    let barcode = await generateBarCode(data.invoiceNo);

    let qrCodeId = data.sendTo === "Patient" ? data.employeeID : data.companyID;

    let qrCode = await getQRCode(qrCodeId);
    setQrCode(qrCode);
    setBarCode(barcode);
    const timezone = getTimeZoneAbr();

    setData({ ...data, invoiceNo, barcode, qrCode, timezone });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const isAlreadySend = () => {
    return data?.id;
  };

  const handelSaveFaxMessage = (faxMessageData) => {
    dispatch(
      updateClaimMessage({
        id: data.claimID,
        message: faxMessageData.note,
        minimumAmount,
      })
    );

    setShowMessageSubjectModel(false);
  };

  const handelShowDisclaimer = () => {
    if (!data.invoiceAlreadySent) {
      setShowMessageSubjectModel(true);
    }
  };

  const handelShowEditDetails = () => {
    setShowEditDetailsModel(true);
  };

  const updateDetailsInInvoice = (details) => {
    const timezone = getTimeZoneAbr();

    setData({ ...data, details: details, timezone });
  };

  const handelInvoicePreviewChange = (event, obj) => {
    const checked = event.target.checked;
    if (checked) {
      setInvoicePreviewType(obj.value);
    }
  };

  return (
    <>
      {showMessageSubjectModel && (
        <MessageSubjectNoteModel
          show={showMessageSubjectModel}
          handleSave={handelSaveFaxMessage}
          handleClose={() => setShowMessageSubjectModel(false)}
          existingData={data}
        />
      )}

      {showEditDetailsModel && (
        <EditDetailsInvoiceModal
          show={showEditDetailsModel}
          updateDetailsInInvoice={(details) => updateDetailsInInvoice(details)}
          handleClose={() => setShowEditDetailsModel(false)}
          selectedInvoice={data1}
          isBulk={isBulk}
          totalBilled={totalBilledValue}
          isInvoiceEdit={(value) => setIsEditInvoice(value)}
        />
      )}
      {isLoading && <Loader />}

      <Modal
        show
        onHide={() => dispatch(resetInvoice())}
        animation={true}
        size={"xl"}
        scrollable
        centered
        className="seperateModal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0">Claim Invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "0" }}>
          {ReactHtmlParser(addPrefixToIds(templates.filledTemplate, "template1_", data))}
          {ReactHtmlParser(addPrefixToIds(biolab1(CONFIG, data), "template1", data))}
          {ReactHtmlParser(addPrefixToIds(biolab2({ qr: qrCodeState }), "template3", data))}
          {ReactHtmlParser(addPrefixToIds(labFormTemplate3(CONFIG), "template4", data))}
        </Modal.Body>
        {!isAlreadySend() && data.sendTo === "Client" && (
          <div style={{ display: "flex" }} className="order-patient-custom">
            <div className="order-patient-wrapper">
              <RadioButtonOptions
                cssClass={""}
                label={"Invoice Preview:"}
                options={invoicePreviewTypes}
                name={`invoice_preview_type`}
                checkedVal={invoicePreviewType}
                handleChange={handelInvoicePreviewChange}
              />
            </div>
          </div>
        )}

        <Modal.Footer>
          <Button variant="primary" className="headerButton btn-fill" onClick={() => dispatch(resetInvoice())}>
            Close
          </Button>
          {!isAlreadySend() && (
            <>
              <Button variant="secondary" className="headerButton btn-fill" onClick={handelShowEditDetails}>
                Edit Amount
              </Button>
              <Button variant="secondary" className="headerButton btn-fill" onClick={handelShowDisclaimer}>
                Show Disclaimer
              </Button>
            </>
          )}

          {!isPreview && (
            <>
              <Button
                variant="secondary"
                className="headerButton btn-fill"
                onClick={() => handleConvertToPdf(templates, data)}
              >
                Download {isAlreadySend() ? "" : "Draft"}
              </Button>

              <Button variant="secondary" className="headerButton btn-fill" onClick={sendInvoice}>
                {isAlreadySend() ? "Re Send" : "Send"}
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default React.memo(InvoicePreviewModal);
