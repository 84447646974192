import { CONFIG, MESSAGE_MODES } from "constant";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { CSVReader } from "react-papaparse";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { setMessage } from "store/features/general/generalAction";
import { userCompanyID } from "utils";
import { selectLoginUser } from "store/features/authentication/authenticationSelectors";
import { selectedSubAgent } from "store/features/subAgents/subAgentsSelectors";
import { selectedLocation } from "store/features/locations/locationsSelectors";
import { downloadDataAsCSV } from "utils";

const EmployeeImportModal = (props) => {
  const { handleClose, handleImport, title, subAgent } = props;
  const dispatch = useDispatch();
  const loginUser = useSelector(selectLoginUser);
  const { locations } = useSelector(selectedLocation);
  const { subAgents } = useSelector(selectedSubAgent);

  const getSelectedCompany = () => {
    if (loginUser?.isSubAgent() && !subAgents) {
      return userCompanyID.get();
    } else if (loginUser?.isSite() && subAgents) {
      return userCompanyID.get();
    }
    if ((subAgent ? locations : subAgents).length === 1) {
      return (subAgent ? locations : subAgents)[0].id;
    }

    return "";
  };

  const addRequiredLabelToKeys = (data, requiredFields) => {
    const modifiedData = {};
    let data1 = data[0];
    for (const key in data1) {
      const label = requiredFields.includes(key) ? `${key} (required)` : key;
      modifiedData[label] = data1[key];
    }
    console.log(modifiedData);
    return [modifiedData];
  };

  const downloadTemplateInfo = async () => {
    const data = [
      { "Filed Name": "firstName", STATUS: "( required )", VALUE: "Jhon" },
      { "Filed Name": "middleName", STATUS: "( optional )", VALUE: "" },
      { "Filed Name": "lastName", STATUS: "( required )", VALUE: "Fedric" },
      { "Filed Name": "gender", STATUS: "( required )", VALUE: "M" },
      { "Filed Name": "dob", STATUS: "( required )", VALUE: "09/26/1975" },
      { "Filed Name": "address", STATUS: "( optional )", VALUE: "1249 Abc Town" },
      { "Filed Name": "address2", STATUS: "( optional )", VALUE: "" },
      { "Filed Name": "city", STATUS: "( optional )", VALUE: "Clifornia" },
      { "Filed Name": "state", STATUS: "( optional )", VALUE: "CF" },
      { "Filed Name": "zip", STATUS: "( optional )", VALUE: "20657" },
      { "Filed Name": "country", STATUS: "( optional )", VALUE: "United State" },
      { "Filed Name": "phoneNumber", STATUS: "( optional )", VALUE: "+18189750320" },
      { "Filed Name": "email", STATUS: "( optional )", VALUE: "test@yopmail.com" },
      { "Filed Name": "insuranceCompany", STATUS: "( required )", VALUE: "01192 (payer Id) or medicare (payer name)" },
      { "Filed Name": "medicalNo", STATUS: "( required )", VALUE: "6230df8dj26 (insurnce Number)" },
      { "Filed Name": "groupId", STATUS: "( optional )", VALUE: "(insurance group Id)" },
      { "Filed Name": "accountNo", STATUS: "( optional )", VALUE: "" },
      { "Filed Name": "renderingProviderNpi", STATUS: "( optional )", VALUE: "1326756545 (NPI of Rendering Provider)" },
      {
        "Filed Name": "referringProviderNpi",
        STATUS: "( optional )",
        VALUE: "1326756545 (NPI of Referring  Provider)",
      },
      { "Filed Name": "orderingProviderNpi", STATUS: "( optional )", VALUE: "1326756545 (NPI of Ordering Provider)" },
    ];
    await downloadDataAsCSV(data, "Import Sample Info");
  };

  const handleDownloadSample = async () => {
    const data = [
      {
        firstName: "Jhon",
        middleName: "",
        lastName: "Fedric",
        gender: "M",
        dob: "09/26/1975",
        address: "1249 Abc Town",
        address2: "",
        city: "Clifornia",
        state: "CF",
        zip: "20657",
        country: "United State",
        phoneNumber: "+18189750320",
        email: "test@yopmail.com",
        insuranceCompany: "01192",
        medicalNo: "6230df8dj26",
        groupId: "",
        accountNo: "9289293",
        renderingProviderNpi: "1326756546",
        referringProviderNpi: "1326756545",
        orderingProviderNpi: "1326756547",
      },
    ];
    await downloadDataAsCSV(data, "Patient Import Sample");
  };

  const [selectedCompany, setSelectedCompany] = useState(getSelectedCompany());

  const handleOnDrop = (data) => {
    if (!selectedCompany) {
      dispatch(setMessage("Please select the facility/agent first then import", MESSAGE_MODES.error));
      // return;
    } else {
      handleImport(data, selectedCompany);
    }
  };
  const handleOnError = (err) => {
    console.log({ err });
  };

  console.log("selectedCompany", selectedCompany);

  const handleOnRemoveFile = (data) => {
    console.log("Remove Data", data);
  };

  return (
    <Modal show animation={true} backdrop="static" onHide={() => handleClose()} centered size={"lg"}>
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          {title}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="form-group-wrapper d-block">
          {CONFIG.isLabType && (
            <div className="d-flex align-items-center justify-content-around">
              <div
                style={{
                  textAlign: "right",
                  color: "#42cef5",
                  textAlign: "center",
                  cursor: "Pointer",
                }}
                onClick={() => downloadTemplateInfo()}
              >
                Download Template Info
              </div>
              <div
                style={{
                  textAlign: "right",
                  color: "#42cef5",
                  textAlign: "center",
                  cursor: "Pointer",
                }}
                onClick={() => handleDownloadSample()}
              >
                Download Upload Template
              </div>
            </div>
          )}
          {(loginUser?.isAdmin() || loginUser?.isClient() || loginUser?.isSite()) && !CONFIG.isLabType && (
            <div className="col-lg-12 col-12 mb-3">
              <label className="modalLineHeaders mt-0">{subAgent ? "Facility/Agent" : "Sub Agents"}</label>
              <Select
                options={subAgent ? locations : subAgents}
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
                value={
                  selectedCompany ? (subAgent ? locations : subAgents).find((f) => f.id === selectedCompany) : null
                }
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                blurInputOnSelect={true}
                menuPlacement="auto"
                placeholder={subAgent ? "Facility/Agent" : "Select Sub Agent"}
                className="w-100"
                onChange={(e) => setSelectedCompany(e.id)}
              />
            </div>
          )}
          {selectedCompany && (
            <CSVReader
              onDrop={handleOnDrop}
              onError={handleOnError}
              addRemoveButton
              config={{
                header: true,
                skipEmptyLines: true,
              }}
              onRemoveFile={handleOnRemoveFile}
            >
              <span>Drop CSV file here or click to upload.</span>
            </CSVReader>
          )}
        </div>
      </Modal.Body>

      <Modal.Footer className="flex-row-reverse">
        <Button variant="secondary" className="headerButton btn-fill" onClick={() => handleClose()}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EmployeeImportModal;
